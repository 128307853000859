import { createSlice } from '@reduxjs/toolkit'

const exampleDataset = {
    Name: 'Example dataset',
    DatasetId: '_example_dataset_',
    ParentFolderId: '_dataset_root_folder_',
    Data: null,
    Created: Date(),
}

const datasetsSlice = createSlice({
    name: 'datasets',
    initialState: [exampleDataset],
    reducers: {
        addDataset(state, action) {
            return ([
                ...state,
                action.payload,
            ])
        },
        deleteDataset(state, action) {
            const { id } = action.payload
            return state.filter(dataset => dataset.DatasetId !== id)
        },
        updateDatasetName(state, action) {
            const { datasetId, newName } = action.payload
            const existingDataset = state.find(dataset => dataset.DatasetId === datasetId)
            if(existingDataset) {
                existingDataset.Name = newName
            }
        },
        updateDatasetData(state, action) {
            const { datasetId, newData } = action.payload
            const existingDataset = state.find(dataset => dataset.DatasetId === datasetId)
            if(existingDataset) {
                existingDataset.Data = newData
            }
        },
        addProjectReferenceToDataset(state, action) {
            const { datasetId, projectId } = action.payload
            const existingDataset = state.find(dataset => dataset.DatasetId === datasetId)
            if(existingDataset) {
                existingDataset.ReferencingProjects.push(projectId)
            }
        },
        removeProjectReferenceFromDataset(state, action) {
            const { datasetId, projectId } = action.payload
            const existingDataset = state.find(dataset => dataset.DatasetId === datasetId)
            if(existingDataset) {
                existingDataset.ReferencingProjects = existingDataset.ReferencingProjects.filter(projId => projId !== projectId)
            }
        }
    }
})

export const { addDataset, deleteDataset, addProjectReferenceToDataset, removeProjectReferenceFromDataset,
    updateDatasetName, updateDatasetData } = datasetsSlice.actions
export const selectDatasetById = (state, datasetId) => state.datasets.find(dataset => dataset.DatasetId === datasetId)

export default datasetsSlice.reducer

