import Dialog from '@material-ui/core/Dialog'
import { nanoid } from '@reduxjs/toolkit'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useSession } from '../../../hooks/store/sessionHook'
import { useGetProjectFolderByIdQuery, usePutProjectFolderMutation } from '../../../services/databaseApi'
import { AddNamedItem } from '../../ui/AddNamedItem'

const AddProjectFolder = ({onClose, parentFolderId, ...otherProps }) => {

    const { userId } = useSession()

    const [ putProjectFolder ] = usePutProjectFolderMutation()
    const { data: parentFolderData,
        isLoading: isParentFolderDataLoading,
        isSuccess: isParentFolderDataSuccess }  = useGetProjectFolderByIdQuery(parentFolderId ?? skipToken)

    const getErrorMessage = (projectFolderName) => {
        
        // if(allProjectFolders.find(projectFolder => projectFolder.Name === projectFolderName)) {
        //     return('A project folder already exists with that name.')
        // } else {
        //     return null
        // }
    }

    const onAdd = (projectFolderName) => {

        if(!parentFolderId) {
            parentFolderId = userId + "_ROOT_PROJECT_FOLDER_"
        }
        
        const newProjectFolderId = nanoid()

        putProjectFolder({
            ProjectFolderId: newProjectFolderId,
            Name: projectFolderName,
            ParentFolderId: parentFolderId,
            Children: [],
            Projects: [],
            OwnerId: userId,
        })

        if(parentFolderData) {
            putProjectFolder({
                ...parentFolderData,
                Children: [...parentFolderData.Children, { FolderId: newProjectFolderId, Name: projectFolderName }],
            })  
        }
    }

    return(
        <AddNamedItem 
            title='Add Project Folder'
            label='Project Folder Name'
            onAdd={onAdd}
            getErrorMessage={getErrorMessage}
            onClose={onClose}
            {...otherProps}
        />
    )

}

const AddProjectFolderModal = ({open, onClose, ...otherProps}) => {

    return(
        <Dialog open={open} onClose={onClose} >
            <AddProjectFolder onClose={onClose} {...otherProps}/>
        </Dialog>
    )
}

export { AddProjectFolder, AddProjectFolderModal }
