import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import axios from 'axios'

import AutocompleteDropdown from '../../ui/AutocompleteDropdown'

import { useErrorHandler } from '../../../utils/apiUtils'
import { useDatasets } from '../../../hooks/useDatasets'
import { useUpdateOutput } from '../../../hooks/store/useUpdateOutput'
import { useSelector } from 'react-redux'
import { base64ToFloats, base64ToInts, getDefaultColorByIndex } from '../../../utils'
import { roundPValue } from '../../../utils/dataUtils'
import { nanoid } from '@reduxjs/toolkit'

const api = process.env.REACT_APP_API_URL

const Torgersons = (props) => {

    const [ prodNames, setProdNames ] = useState([]);
    const [ referenceProduct, setReferenceProduct ] = useState("");
    const [ referenceProductError, setReferenceProductError ] = useState(false);
    const [ isUnivariate, setIsUnivariate ] = useState('yes')
    const [ error, setError ] = useState(false)
    
    const { checkAndPostError } = useErrorHandler()

    const { selectedDataset, setSelectedDataset, selectedDatasetData, datasetData, datasetOptionsList, datasetError, getDatasetSize } = useDatasets()

    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    useEffect(() => {
        if(datasetData && Array.isArray(datasetData)) {
            setProdNames([...new Set([datasetData.slice(1).map(row => row[0]), datasetData.slice(1).map(row => row[1]), datasetData.slice(1).map(row => row[2])].flat())].map((element, index) => ({ label: element, id: index })))
        }
    }, [datasetData])

    const onSubmit = (event) => {
 
        event.preventDefault()
        
        setError(false)
        setReferenceProductError(false)

        if(!(datasetData && referenceProduct)) {
            if(!datasetData) {
                setError(true)
            }
            if(!referenceProduct) {
                setReferenceProductError(true)
            }
            return;
        }

        const { numRows, numCols } = getDatasetSize()
        const numComps = numRows - 1

        let orderedProdNames = prodNames.map(pair => pair.label).filter(name => name !== referenceProduct.label)
        orderedProdNames.splice(0, 0, referenceProduct.label)

        let codes = Array(3 * numComps) // (reference, alt 1, alt 2)
        let counts = Array(2 * numComps) // (# times alt 1 chosen, sample size)

        for(let i = 0; i < numComps; i++) {
            codes[i] = orderedProdNames.findIndex(a => a === datasetData[i+1][0])
            codes[i + numComps] = orderedProdNames.findIndex(a => a === datasetData[i+1][1])
            codes[i + 2 * numComps] = orderedProdNames.findIndex(a => a === datasetData[i+1][2])
            let output = Number(datasetData[i+1][3])
            if(!isNaN(output)) {
                counts[i] = output
            }
            output = Number(datasetData[i+1][4])
            if(!isNaN(output)) {
                counts[i + numComps] = output
            }
        }

        // TODO: check if codes or counts has any empty elements

        let data = {
            "function_group": "torgerson",
            "function_name": "",
            "alpha": 0.05,
            "multi": isUnivariate === 'yes' ? false : true,
            "num_prods": prodNames.length,
            "num_comps": numComps,
            "codes": codes,
            "counts": counts,
        }

        axios
            .post(api, data)
            .then((response) => {

                if(checkAndPostError(response)) {
                    return
                }
                
                if(checkAndPostError(response)) {
                    return
                }
                
                const dPrimes = base64ToFloats(response.data.deltas)
                // const dPrimeVCV = base64ToFloats(response.data.d_prime_vcv)
                const dPrimeDiffs = base64ToFloats(response.data.d_prime_diffs)
                const compVars = base64ToFloats(response.data.comp_vars)
                const pValues = base64ToFloats(response.data.p_values)
                
                // const codesCheck = base64ToFloats(response.data.codes)
                // const countsCheck = base64ToFloats(response.data.counts)

                // const codesTest = base64ToFloats(response.data.codesTest)
                // const countsTest = base64ToFloats(response.data.countsTest)

                const sortedProds = orderedProdNames.map((name, index) => ({ Name: name, dPrime: dPrimes[index], sortIndex: index})).sort(a => -a.dPrime)
                
                const modelTableContent = [
                    ['Model', '# Parameters', '-2*log(Likelihood)'], 
                    ['Saturated', numComps, response.data.sat_n2ll.toFixed(2)],
                    ['Specified', 2 * prodNames.length - 3, response.data.spec_n2ll.toFixed(2)],
                    ['Null', 0, response.data.null_n2ll.toFixed(2)],
                ]

                const modelCompTableContent = [
                    ['Model 1', 'Model 2', 'p-Value'], 
                    ['Saturated', 'Specified', roundPValue(response.data.spec_vs_sat)],
                    ['Saturated', 'Null', roundPValue(response.data.null_vs_sat)],
                    ['Specified', 'Null', roundPValue(response.data.spec_vs_null)]
                ]

                let deltaCoords
                if(isUnivariate !== 'yes') {
                    deltaCoords = [
                        ['Product', 'Dimension 1', 'Dimension 2']
                    ].concat(
                        sortedProds.map((prodObject, index) => 
                            [ prodObject.Name, 
                                dPrimes[prodObject.sortIndex].toFixed(2), 
                                dPrimes[prodObject.sortIndex + prodNames.length].toFixed(2)
                            ])
                    )
                }

                let prodCompTableContent
                if(isUnivariate === 'yes') {
                    prodCompTableContent = [['Product 1', 'Product 2', 'd\'', 'Variance', 'p-Value']]
                    for(let prodCount1 = 0; prodCount1 < prodNames.length; prodCount1++) {
                        for(let prodCount2 = prodCount1 + 1; prodCount2 < prodNames.length; prodCount2++) {
                            const dataIndex = sortedProds[prodCount1].sortIndex * sortedProds.length + sortedProds[prodCount2].sortIndex
                            prodCompTableContent.push([sortedProds[prodCount1].Name , sortedProds[prodCount2].Name, 
                                dPrimeDiffs[dataIndex].toFixed(2), 
                                compVars[dataIndex].toFixed(2), 
                                roundPValue(pValues[dataIndex])])
                        }
                    }
                } else {
                    prodCompTableContent = [['Product 1', 'Product 2', 'd\'']]
                    for(let prodCount1 = 0; prodCount1 < prodNames.length; prodCount1++) {
                        for(let prodCount2 = prodCount1 + 1; prodCount2 < prodNames.length; prodCount2++) {
                            const dataIndex = sortedProds[prodCount1].sortIndex * sortedProds.length + sortedProds[prodCount2].sortIndex
                            prodCompTableContent.push([sortedProds[prodCount1].Name , sortedProds[prodCount2].Name, dPrimeDiffs[dataIndex].toFixed(2)])
                        }
                    }
                }

                let refProdTableContent
                if(isUnivariate === 'yes') {
                    refProdTableContent = [
                        ['Product', 'd\'', 'Variance', 'Probability']
                    ].concat(
                        sortedProds.slice(1).map((prodObject, index) => 
                            [ prodObject.Name, 
                                dPrimeDiffs[prodObject.sortIndex].toFixed(2), 
                                compVars[prodObject.sortIndex].toFixed(2),
                                roundPValue(pValues[prodObject.sortIndex])
                            ])
                    )
                } else { //multivariate
                    refProdTableContent = [
                        ['Product', 'd\'']
                    ].concat(
                        sortedProds.slice(1).map((prodObject, index) => 
                            [ prodObject.Name, dPrimeDiffs[prodObject.sortIndex].toFixed(2) ])
                    )
                }

                const modelTable = {
                    TableId: nanoid(),
                    Title: "Models",
                    OptionsList: { ColumnHeaders: true },
                    TableContent: modelTableContent
                }
                const modelCompTable = {
                    TableId: nanoid(),
                    Title: "Model Tests",
                    OptionsList: { ColumnHeaders: true },
                    TableContent: modelCompTableContent
                }
                const prodCompTable = {
                    TableId: nanoid(),
                    Title: "Product Comparisons",
                    OptionsList: { ColumnHeaders: true },
                    TableContent: prodCompTableContent
                }
                const refProdTable = {
                    TableId: nanoid(),
                    Title: "Comparisons to Reference Product " + referenceProduct.label,
                    OptionsList: { ColumnHeaders: true },
                    TableContent: refProdTableContent
                }
                
                const newPlotId = nanoid()
                const newOutputId = nanoid()

                const title = (isUnivariate === 'yes' ? 'Univariate' : ' Multivariate') + " Torgerson's: " + selectedDatasetData.Name

                let tempPlotData
                if(isUnivariate === 'yes') {
                    tempPlotData = dPrimes.map((d_prime, index) => ({
                        curveType: 'normal',
                        x_lb: -4,
                        x_ub: d_prime + 4.0,
                        mean: d_prime,
                        sd: 1,
                        type: 'line',
                        color: getDefaultColorByIndex(index),
                        name: orderedProdNames[index],
                        showlegend: false,
                        hoverinfo: 'none',
                        //hovertemplate: 'Delta: %{x:.2f}  Prob.: %{y:.2f}',
                    }))

                    tempPlotData.concat({
                        x: dPrimes,
                        y: Array(dPrimes.length).fill(0.4),
                        mode: 'text',
                        name: 'labels',
                        text: orderedProdNames,
                        textposition: 'top',
                        type: 'scatter',
                        align: 'center'
                    })
                } else {
                    tempPlotData = [{
                        x: dPrimes.slice(0, prodNames.length),
                        y: dPrimes.slice(prodNames.length, 2 * prodNames.length),
                        text: orderedProdNames,
                        mode: 'text'
                      }]
                }
                
                let plotObject = {
                    OwnerId: userId,
                    PlotId: newPlotId,
                    Title: title,
                    Data: tempPlotData,
                    OutputReferences: [newOutputId]
                }

                if(isUnivariate !== 'yes') {
                    plotObject.Layout = {
                        xaxis: {
                            range: [Math.min(...dPrimes.slice(0, prodNames.length)) - 0.5, Math.max(...dPrimes.slice(0, prodNames.length)) + 0.5],
                            zeroline: false
                        },
                        yaxis: {
                            range: [Math.min(...dPrimes.slice(prodNames.length, 2 * prodNames.length)) - 0.5, Math.max(...dPrimes.slice(prodNames.length, 2 * prodNames.length)) + 0.5],
                            zeroline: false
                        },
                        shapes: prodNames.map((prodName, index) => ({
                            type: 'circle',
                            xref: 'x',
                            yref: 'y',
                            x0: dPrimes[index] - 1,
                            x1: dPrimes[index] + 1,
                            y0: dPrimes[index + prodNames.length] - 1,
                            y1: dPrimes[index + prodNames.length] + 1,
                            line: { color: 'black' }
                        }))
                    }
                }

                    
                const deltaCoordsTableId = nanoid()
                let stream = [
                    { Type: 'table', Content: modelTable.TableId },
                    { Type: 'table', Content: modelCompTable.TableId }
                ]
                if(isUnivariate !== 'yes') {
                    stream.push({ Type: 'table', Content: deltaCoordsTableId })
                }
                stream.push({ Type: 'table', Content: prodCompTable.TableId })
                stream.push({ Type: 'table', Content: refProdTable.TableId })
                stream.push({ Type: 'plot', Content: newPlotId })

                let tables = [modelTable, modelCompTable, prodCompTable, refProdTable]
                if(isUnivariate !== 'yes') {
                    tables.push({
                        TableId: deltaCoordsTableId,
                        Title: "Product Coordinates",
                        OptionsList: { ColumnHeaders: true },
                        TableContent: deltaCoords
                    })
                }

                const outputObject = {
                    OwnerId: userId,
                    OutputId: newOutputId,
                    Title: title,
                    Stream: stream,
                    Tables: tables,
                    Plots: [newPlotId],
                    ReferencingProjectIds: currentProjectId,
                }
                
                addOutput(outputObject, plotObject)
            })            
            .catch((error) => {
                console.log('error: ')
                console.log(error)
            })
    };

    return (
        <div id='torgersonsDialog'>
            <h2>Torgerson's Method of Triads</h2>
            <br />
            <form onSubmit={onSubmit}>
                <AutocompleteDropdown options={datasetOptionsList ?? []}
                    value={selectedDataset} setValue={setSelectedDataset} label="Choose dataset:" variant="outlined" 
                    getOptionLabel={option => option.label ? option.label : ""}/>
                <br />
                <br />
                <AutocompleteDropdown options={prodNames}
                    value={referenceProduct} setValue={setReferenceProduct} label="Choose reference product:" variant="outlined" />
                <br />
                <br />
                <RadioGroup
                    style={{ display: 'flex', alignItems: 'center' }}
                    aria-label="method"
                    name="method"
                    value={isUnivariate}
                    onChange={(event, newValue) => { setIsUnivariate(newValue)}}
                >
                    <div id='radioGroupContainer' style={{ display: 'flex', width: 'max-content', flexFlow: 'column nowrap', alignItems: 'left' }}>
                        <FormControlLabel value={'yes'} control={<Radio />} label = "Univariate" />
                        <FormControlLabel value={'no'} control={<Radio />} label = "Multivariate" />    
                    </div>
                </RadioGroup>
                <br />
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </form>
        </div>
    )
}

export default Torgersons
