import { useState } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
// import TabPanel from '@material-ui/lab/TabPanel';
//import TabContext from '@material-ui/lab/TabContext';
import NumericInput from '../../ui/NumericInput';
import Button from '@material-ui/core/Button';
import { Switch, Route, Link, Redirect } from 'react-router-dom'
import { useTabRouter } from '../../../hooks/tabRouter';
import { useAllOutput } from '../../../hooks/store/allOutputHook';
import { useErrorHandler } from '../../../utils/apiUtils';
import { useMainOutput } from '../../../hooks/store/mainOutputHook';
import axios from 'axios';
import { nanoid } from '@reduxjs/toolkit';
import { useUpdateOutput } from '../../../hooks/store/useUpdateOutput';
import { useSelector } from 'react-redux';
import { GetPlotlyPairOfNormalsData } from '../../../utils/plotUtils';

const api = process.env.REACT_APP_API_URL

const TwoAC = (props) => {

    const { path, tabName } = useTabRouter(props, ['no_difference', 'identicality_norm'])
    
    return(
        <div id="twoACDialog">
            <Tabs value={tabName} scrollButtons='auto' centered>
                <Tab label="2-AC Difference Test" value="no_difference" component={Link} to={`${path}/no_difference`} />
                <Tab label="Identicality Norm" value="identicality_norm" component={Link} to={`${path}/identicality_norm`} />
            </Tabs>
            <Switch>
                <Route path={`${path}/no_difference`} component={NoDifference} />
                <Route path={`${path}/identicality_norm`} component={IdenticalityNorm} />
                <Redirect from={`${path}`} to={`${path}/no_difference`} />
            </Switch>
        </div>
    )
}

export default TwoAC

const NoDifference = () => {

    const [ numA, setNumA ] = useState()
    const [ numNoDiff, setNumNoDiff ] = useState()
    const [ numB, setNumB ] = useState()
    const [ isValidating, setIsValidating ] = useState(false)

    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const { checkAndPostError } = useErrorHandler()

    const onSubmit = (event) => {

        event.preventDefault()
        
        setIsValidating(true)

        if(!(numA || numA === 0) && (numB || numB === 0) && (numNoDiff || numNoDiff === 0)) {
            return
        }

        setIsValidating(false)

        const apiData = {
            function_group: 'two_ac',
            function_name: 'two_ac',
            pref_A_count: numA,
            no_pref_count: numNoDiff,
            pref_B_count: numB,
        }

        axios
            .post(api, apiData)
            .then((response) => {
                
                if(checkAndPostError(response)) {
                    return
                }

                const title = '2-AC Difference Test'

                const summaryTableContent = [
                    [ "Sample Size", numA + numB + numNoDiff ],
                    [ "d'", response.data.d_prime.toFixed(2) ],
                    [ "d' variance", response.data.d_prime_var.toFixed(2) ],
                    [ "Tau", response.data.b_prime.toFixed(2) ],
                    [ "Tau variance", response.data.b_prime_var.toFixed(2) ],
                    [ "1-tailed prob.", response.data.prob.toFixed(3) ],
                    [ "2-tailed prob.", (2 * response.data.prob).toFixed(3) ],
                ]

                const summaryTable = {
                    TableId: nanoid(),
                    Title: "Difference Summary",
                    TableContent: summaryTableContent
                }
                
                const idealTableContent = [
                    [ "Proportion A", response.data.ideal_A.toFixed(2) ],
                    [ "Proportion No difference", response.data.ideal_np.toFixed(2) ],
                    [ "Proportion B", response.data.ideal_B.toFixed(2) ]
                ]

                const idealTable = {
                    TableId: nanoid(),
                    Title: "Predicted Identicality Norm",
                    TableContent: idealTableContent,
                }

                const newOutputId = nanoid()
                const newPlotId = nanoid()

                let tempPlotData = GetPlotlyPairOfNormalsData(response.data.d_prime)
                const plotObject = {
                    OwnerId: userId,
                    PlotId: newPlotId,
                    Title: '2-AC Difference',
                    Data: tempPlotData,
                    OutputReferences: [newOutputId]
                }

                const outputObject = {
                    OwnerId: userId,
                    OutputId: newOutputId,
                    Title: title, 
                    Stream: [
                        { Type: 'table', Content: summaryTable.TableId },
                        { Type: 'table', Content: idealTable.TableId },
                        { Type: 'plot', Content: newPlotId }
                    ],
                    Tables: [summaryTable, idealTable], 
                    Plots: [newPlotId],
                    ReferencingProjectIds: currentProjectId,
                }
            
                addOutput(outputObject, plotObject)
            })
            .catch((error) => {
                console.log('error: ')
                console.log(error)
            })
    }

    return (
        <form onSubmit={onSubmit}>
            <NumericInput
                setValidatedValue={setNumA}
                isValidating={isValidating}
                label="# A"
                minValue="0"
                minInclusive
                errorMessage="Count cannot be negative"
            />
            <NumericInput
                setValidatedValue={setNumNoDiff}
                isValidating={isValidating}
                label="# No difference"
                minValue="0"
                minInclusive
                errorMessage="Count cannot be negative"
            />
            <NumericInput
                setValidatedValue={setNumB}
                isValidating={isValidating}
                label="# B"
                minValue="0"
                minInclusive
                errorMessage="Count cannot be negative"
            />
            <br />
            <Button
                style={{ padding: 3 }}
                variant="contained"
                color="primary"
                type="submit"
            >
                Run
            </Button>
        </form>
    )
}

const IdenticalityNorm = () => {

    const [ tau, setTau ] = useState()
    const [ isValidating, setIsValidating ] = useState(false)
    
    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const { checkAndPostError } = useErrorHandler()

    const onSubmit = (event) => {

        event.preventDefault()

        setIsValidating(true)

        if(!tau) {
            return
        }

        setIsValidating(false)

        const apiData = {
            function_group: 'two_ac',
            function_name: 'identicality_norm',
            tau: tau,
        }

        axios
            .post(api, apiData)
            .then((response) => {
                
                if(checkAndPostError(response)) {
                    return
                }

                const title = 'Identicality Norm'
                    
                const tableContent = [
                    [ "Prefer A", response.data.pref_A.toFixed(2) ],
                    [ "No preference", response.data.no_pref.toFixed(2) ],
                    [ "Prefer B", response.data.pref_B.toFixed(2) ]
                ]

                const table = {
                    TableId: nanoid(),
                    TableContent: tableContent,
                }

                const newOutputId = nanoid()

                const outputObject = {
                    OwnerId: userId,
                    OutputId: newOutputId,
                    Title: title, 
                    Stream: [
                        { Type: "table", Content: table.TableId },
                    ],
                    Tables: [ table ], 
                    Plots: [],
                    ReferencingProjectIds: currentProjectId,
                }
            
                addOutput(outputObject)
            })
            .catch((error) => {
                console.log('error: ')
                console.log(error)
            })
    }

    return (
        <form onSubmit={onSubmit} >
            <NumericInput
                setValidatedValue={setTau}
                isValidating={isValidating}
                label="Tau"
                minValue="0"
                errorMessage="Tau must be greater than 0."
            />
            <br />
            <Button
                style={{ padding: 3 }}
                variant="contained"
                color="primary"
                type="submit"
            >
                Run
            </Button>
        </form>
    )
}
