import { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import ProjectsSelector from '../../ui/ProjectsSelector'
import { useSession } from '../../../hooks/store/sessionHook'

const SwitchProjects = ({ onClose }) => {
    
    const [ selectedProject, setSelectedProject ] = useState(null)
    
    const { setCurrentProjectId } = useSession()

    const onSubmit = (event) => {
        
        event.preventDefault()

        setCurrentProjectId(selectedProject.ProjectId)

        onClose()
    }

    return(
        <div id="switchProjectsDialog" style={{ margin: '0px 10px 10px 10px' }}>
            <form onSubmit={onSubmit}>
                <h4>Switch Projects</h4>
                <ProjectsSelector selectedProject={selectedProject} setSelectedProject={setSelectedProject} />
            
                <div style={{margin: '10px'}}/>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{ display: 'inline-block', margin: '0 auto' }}>
                        <Button
                            style={{ padding: 3, marginRight: '10px' }}
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Switch
                        </Button>
                        <Button
                            style={{ padding: 3 }}
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

const SwitchProjectsModal = ({ open, onClose }) => {
    return(
        <Dialog open={open} onClose={onClose}>
            <SwitchProjects onClose={onClose}/>
        </Dialog>
    )
}

export { SwitchProjects, SwitchProjectsModal }
