import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { removePlotReference } from './plotsSlice'
//import { getOutput } from '../services/dynamoDB'

const initialState = [
    {
        OutputId: '1',
        Title: 'Main Output',
        TextContent: 'Results from new analyses will appear here!',
        Table: null,
        Plots: null,
        ReferencingProjectIds: [ '_no_project_selected_' ],
        Loading: false,
    },
]

// export const fetchOutputById = createAsyncThunk(
//     'output/fetchById',
//     async (outputId) => {
//         const response = await getOutput(outputId)
//         return response.data
//     }
// )

const allOutputSlice = createSlice({
    name: 'allOutput',
    initialState,
    reducers: {
        addEntry(state, action) {
            return([
                action.payload,
                ...state
            ])
        },
        updateComment(state, action) {
            const { outputId, newComment } = action.payload
            const existingEntry = state.find(output => output.OutputId === outputId)
            if(existingEntry) {
                existingEntry.Comment = newComment
            }
        },
        toggleIsOpenComment(state, { payload: outputId }) {
            const existingEntry = state.find(output => output.OutputId === outputId)
            if(existingEntry) {
                existingEntry.isOpenComment = !existingEntry.isOpenComment
            }
        },
        deleteEntry(state, action) {
            const { id } = action.payload
            return state.filter(entry => entry.OutputId !== id)
        },
        addReferencingProjectId(state, action) {
            const { outputId, projectId } = action.payload
            const output = state.find(entry => entry.OutputId === outputId)
            output.referencingProjectIds.push(projectId)
        },
        removeReferencingProjectId(state, action) {
            const { outputId, projectId } = action.payload
            const output = state.find(entry => entry.OutputId === outputId)
            if(output) {
                output.ReferencingProjectIds = output.ReferencingProjectIds.filter(projId => projId !== projectId)
            }
        },
    },
    extraReducers: (builder) => {
        // builder.addCase(fetchOutputById.fulfilled, (state, action) => {
        //     const output = state.output.find(entry => entry.OutputId === action.payload.id)
        //     if(output) {
        //         //output = action.payload
        //         output.Loading = false
        //     }
            
        // })
        // builder.addCase(fetchOutputById.pending, (state, action) => {
        //     const pendingOutput = {
        //         ...action.payload
        //     }
        //     pendingOutput.Loading = true
        //     state.output.push(action.payload)
        // })
    }
})

export const { addEntry, deleteEntry, updateComment, toggleIsOpenComment, 
    addReferencingProjectId, removeReferencingProjectId } = allOutputSlice.actions

export default allOutputSlice.reducer
