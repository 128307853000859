import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { addCard, removeCard, removeAllCards, toggleSavedToProject } from '../../features/mainOutputSlice'
import { scrollMainOutputToTop } from '../../customEvents'
import { useSession } from './sessionHook'
import { useAllOutput } from './allOutputHook'
import { removeOutputFromProject } from '../../features/projectsSlice'
import { useProjects } from './projectsHook'

export function useMainOutput() {
    
    //const { currentProjectId, currentProject} = useSession()
    const dispatch = useDispatch()
    const { allEntries, fetchOutputById, removeReferencingProjectId } = useAllOutput()
    const { getProjectById } = useProjects()
    const _cards = useSelector(store => store.mainOutput)
    const currentProjectId = useSelector(store => store.session.currentProjectId)

    const _addCard = useCallback(
        (id, savedToProject = false) => {

            dispatch(
                addCard({
                    OutputId: id,
                    savedToProject,
                })
            )

            scrollMainOutputToTop()
        },
        [dispatch]
    )

    const _toggleSavedToProject = useCallback(
        (outputId) => {
            dispatch(toggleSavedToProject(outputId))
        },
        [dispatch]
    )

    const _removeCard = useCallback((outputId) => {
           
        removeReferencingProjectId(outputId, currentProjectId)

        dispatch(removeOutputFromProject({
            projectId: currentProjectId,
            outputId: outputId,
        }))

        dispatch(
            removeCard({
                id: outputId
            })
        )

    }, [ dispatch, currentProjectId, removeReferencingProjectId ])

    const _removeAllCards = useCallback(() => dispatch(removeAllCards()), [dispatch])

    const unloadMainOutput = useCallback(() => {

        _cards.forEach(card => { 
            if(!card.savedToProject) { 
                dispatch(removeOutputFromProject({ projectId: currentProjectId, outputId: card.OutputId }))
            }
        })

        _removeAllCards()

    }, [dispatch, currentProjectId, _cards, _removeAllCards])

    const loadMainOutput = useCallback(async () => {
        
        _removeAllCards()

        const projectData = await getProjectById(currentProjectId)

        if(projectData) {

            if(projectData.output) {
                // const timeout = 1000
                // let promiseArray = []
                // projectData.output.forEach(outputId => {
                //     promiseArray.push(makeTimeout(getOutput(outputId), timeout))
                // })

                // Promise.all(promiseArray).then(() => {
                //     console.log(promiseArray)
                // })
                // const outputData = projectData.output.forEach(outputId => {
                //     await 
                // })
        //
                //console.log(outputData)
                
                // projectData.output.forEach(outputId => {
                //     if state.
                // }

                projectData.output.forEach(outputId => dispatch(addCard({
                    id: outputId,
                    savedToProject: true,
                })))
            }
        }
    }, [dispatch, getProjectById, currentProjectId, _removeAllCards])

    return {
        allCards: _cards,
        addCard: _addCard,
        removeCard: _removeCard,
        unloadMainOutput: unloadMainOutput,
        removeAllCards: _removeAllCards,
        toggleSavedToProject: _toggleSavedToProject,
        loadMainOutput: loadMainOutput,
    }
}
