import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
// import TabContext from '@material-ui/lab/TabContext'
// import TabPanel from '@material-ui/lab/TabPanel'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import { DecisionRuleChooserRadio } from '../../../ui/DecisionRuleChoosers'
import NumericInput from '../../../ui/NumericInput'
import { methodNameDict } from '../../../../app/thurstonianDicts'
import { useTabRouter } from '../../../../hooks/tabRouter'
import { Switch, Link, Redirect } from 'react-router-dom'
import { useErrorHandler } from '../../../../utils/apiUtils'
import { useSelector } from 'react-redux'
import { useUpdateOutput } from '../../../../hooks/store/useUpdateOutput'
import { nanoid } from '@reduxjs/toolkit'

const api = process.env.REACT_APP_API_URL

const ProportionDiscriminators = (props) => {
    
    const { checkAndPostError } = useErrorHandler()
    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const { path, tabName } = useTabRouter(props, ['delta_to_pd', 'pd_to_delta'])

    const [ method, setMethod ] = React.useState(null)
    const [ delta, setDelta ] = React.useState()
    const [ propDiscriminators, setPropDiscriminators ] = React.useState()
    const [ isValidating, setIsValidating ] = React.useState()

    const handleSubmit = (event) => {

        event.preventDefault()

        setIsValidating(true)

        if(!(method && ((delta && tabName === 'delta_to_pd') || ((propDiscriminators || propDiscriminators === 0) && tabName === 'pd_to_delta')))) {
            return
        }

        setIsValidating(false)

        let data = null
        let title = ""

        if(tabName === "delta_to_pd") {

            data = {
                function_group: 'proportion_discriminators',
                function_name: 'delta_to_prop_discrim',
                method: method,
                delta: delta,
            }
        } else {

            data = {
                function_group: 'proportion_discriminators',
                function_name: 'prop_discrim_to_delta',
                method: method,
                prop_discrim: propDiscriminators,
            }
        }
    
        if(method === 'twoAFC') {
            data = {
                ...data,
                method: 'mafc',
                num_choices: 2
            }
        } else if(method === 'threeAFC') {
            data = {
                ...data,
                method: 'mafc',
                num_choices: 3
            }
        }

        axios
            .post(api, data)
            .then((response) => {
                
                if(checkAndPostError(response)) {
                    return
                }

                let tableContent = []
                let title = ""
                let message = ""
                if(tabName === "delta_to_pd") {
                    tableContent = [
                        [ 'Delta', 'Proportion Discriminators' ],
                        [ delta.toFixed(2), response.data.prop_discrim.toFixed(2) ]
                    ]
                    title = 'Delta to Proportion Discriminators: ' + methodNameDict[method]
                    // message = 'A delta of ' + delta.toFixed(2) + ' corresponds to a proportion of discriminators of ' + response.data.prop_discrim.toFixed(2)
                } else {
                    tableContent = [
                        ['Proportion Discriminators', 'Delta' ],
                        [ propDiscriminators.toFixed(2), response.data.delta.toFixed(2) ]
                    ]
                    title = 'Proportion Discriminators to Delta: ' + methodNameDict[method]
                    // message = "A proportion of discriminiators of " + propDiscriminators.toFixed(2) + ' corresponds to a delta of ' + response.data.delta.toFixed(2)
                }

                const table = {
                    TableId: nanoid(),
                    TableContent: tableContent,
                }

                const newOutputId = nanoid()

                const outputObject = {
                    OwnerId: userId,
                    OutputId: newOutputId,
                    Title: 'Delta Estimation: ' + methodNameDict[method], 
                    Stream: [
                        { Type: 'text', Content: message},
                        { Type: 'table', Content: table.TableId },
                    ],
                    Tables: [table], 
                    ReferencingProjectIds: currentProjectId,
                }
            
            addOutput(outputObject)
            })
            .catch((error) => {
                console.log('error: ')
                console.log(error)
            })
    }

    return (
        <div id="propDiscriminatorsDialog">
            <Switch>
                <Redirect from={`${path}`} to={`${path}/delta_to_pd`} />
            </Switch>
            <form onSubmit={handleSubmit}>
                <Tabs value={tabName} centered>
                    <Tab label="Delta to PD" value="delta_to_pd" component={Link} to={`${path}/delta_to_pd`} />
                    <Tab label="PD to Delta" value="pd_to_delta" component={Link} to={`${path}/pd_to_delta`} />
                </Tabs>
                <br />
                <DecisionRuleChooserRadio
                    methodNameDict={methodNameDict}
                    setValidatedValue={setMethod}
                    isValidating={isValidating}
                />
                <br />
                { tabName === 'delta_to_pd' &&
                    <NumericInput label="Delta" setValidatedValue={setDelta} isValidating={isValidating}
                        minValue='0' errorMessage='Delta must be greater than 0.' />
                }
                { tabName === 'pd_to_delta' &&
                    <NumericInput label="Prop. Discriminators" setValidatedValue={setPropDiscriminators} isValidating={isValidating}
                    minValue='0' maxValue='1' errorMessage='Prop. Discriminators must be between 0 and 1.' />
                }
                <br />
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </form>
        </div>
    )
}

export default ProportionDiscriminators
