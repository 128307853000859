
const methodColorDict = {
    twoAFC: "#008000",
    duoTrio: "#800080",
    triangle: "#FF0000",
    tetrad: "#FFA500",
    dualPair: "ADD8E6",
    threeAFC: "#0000FF",
    specifiedTetrad: "#A52A2A",
}

const methodNameDict = {
    twoAFC: "2-AFC",
    duoTrio: "Duo-Trio",
    triangle: "Triangle",
    tetrad: "Tetrad",
    dualPair: "Dual-Pair",
    threeAFC: "3-AFC",
    specifiedTetrad: "Specified Tetrad",
}

export { methodColorDict, methodNameDict }
