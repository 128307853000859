
import { createSlice } from '@reduxjs/toolkit'

const initialProjectFolderState = [
    {
        FolderId: '_root_project_folder_',
        Name: 'Root',
        ParentFolderId: null,
        ChildIds: [],
        ProjectIds: ['_no_project_selected_'],
    }
]

const projectFoldersSlice = createSlice({
    name: 'projectFolders',
    initialState: initialProjectFolderState,
    reducers: {
        addProjectFolder(state, action) {

            return ([
                action.payload,
                ...state,
            ])
        },
        deleteProjectFolder(state, { payload: id }) {
            return state.filter(projectFolder => projectFolder.FolderId !== id)
        },
        addProjectToProjectFolder(state, action) {
            const { parentFolderId, newProjectId } = action.payload
            let existingProjectFolder = state.find(projectFolder => projectFolder.FolderId === parentFolderId)
            if(existingProjectFolder) {
                existingProjectFolder.ProjectIds.push(newProjectId)
            }
        },
        addFolderToProjectFolder(state, action) {
            const { parentFolderId, childFolderId } = action.payload
            const existingProjectFolder = state.find(projectFolder => projectFolder.FolderId === parentFolderId)
            if(existingProjectFolder) {
                existingProjectFolder.ChildIds.push(childFolderId)
            }
        },
        removeProjectFromProjectFolder(state, action) {
            const { projectFolderId, projectId } = action.payload
            const existingProjectFolder = state.filter(projectFolder => projectFolder.FolderId === projectFolderId)
            existingProjectFolder.ProjectIds = existingProjectFolder.ProjectIds.filter(projId => projId !== projectId)
        },
        removeFolderFromProjectFolder(state, action) {
            const { projectFolderId, childFolderId } = action.payload
            const existingProjectFolder = state.filter(projectFolder => projectFolder.FolderId === projectFolderId)
            existingProjectFolder.ChildIds = existingProjectFolder.ChildIds.filter(childId => childId !== childFolderId)
        },
        changeProjectFolderName(state, action) {
            const { projectFolderId, newName } = action.payload
            const existingProjectFolder = state.filter(projectFolder => projectFolder.FolderId === projectFolderId)
            existingProjectFolder.Name = newName
        },
        changeProjectFolderParentFolder(state, action) {
            const { projectFolderId, newParentFolderId } = action.payload
            const existingProjectFolder = state.filter(projectFolder => projectFolder.FolderId === projectFolderId)
            existingProjectFolder.ParentFolder = newParentFolderId
        }
    }
})

export const { addProjectFolder, deleteProjectFolder, addProjectToProjectFolder, removeProjectFromProjectFolder, 
    addFolderToProjectFolder, removeFolderFromProjectFolder } = projectFoldersSlice.actions

export default projectFoldersSlice.reducer
