import React, { useState } from 'react'
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from '@material-ui/core/FormHelperText'
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import { render } from '@testing-library/react';

const useStyles = makeStyles({
    formLabel: {
        textAlign: 'left',
        color: 'black',
    }
})

const AutocompleteDropdown = (props) => {

    const [ selectorText, setSelectorText ] = useState(null)

    let {
        id,
        label,
        options,
        value,
        setValue,
        getOptionLabel,
        getOptionSelected,
        errorText,
        renderOption,
        variant,
        width,
        disabled,
    } = props

    // if(!renderOption) {
    //     renderOption = (option) => {
    //         return(<p>{option}</p>)
    //     }
    // }

    if(!width) {
        width = '300px'
    }

    const classes = useStyles()

    const makeSelectComponent = (params) => {

        const { 
            errorText, 
            InputLabelProps,
            InputProps,
            inputProps,
            label,
            onBlur,
            onChange,
            onFocus,
            rows,
            variant,
            ...other
        } = params;

        const InputComponent = variant === "outlined" ? OutlinedInput : Input

        return(<div>
                <FormControl style={{width: '100%'}}>
                    <FormLabel className={classes.formLabel} >{label}</FormLabel>
                    {variant === 'outlined' && <div style={{ height: '10px' }} />}
                    <InputComponent {...InputProps} {...other} inputProps={inputProps} style={{width: '100%'}} />
                    <FormHelperText>{errorText}</FormHelperText>
                </FormControl>
            </div>
        )
    }

    if(!options) {
        options = []
    }

    if(!getOptionLabel) {
        getOptionLabel = option => option.label ? option.label : ""
    }

    if(!renderOption) {
        renderOption = (option) => (
            <React.Fragment>
              {option.label}
            </React.Fragment>
          )
    }

    return(
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Autocomplete 
                        id={id}
                        options={options}
                        getOptionLabel={getOptionLabel}
                        getOptionSelected={getOptionSelected}
                        style={{ width: width, alignSelf: 'center' }}
                        value={value}
                        onChange={(event, newValue) => setValue(newValue)}
                        inputValue={selectorText}
                        onInputChange={(event, newValue) => {setSelectorText(newValue)}}
                        selectOnFocus
                        clearOnBlur
                        clearOnEscape
                        handleHomeEndKeys={false}
                        autoComplete
                        fullWidth
                        disabled={disabled}
                        renderOption={renderOption}
                        renderInput={(params) => makeSelectComponent({...params, label: label, errorText: errorText, variant: variant})}
                    />
        </div>
    )
}

export default AutocompleteDropdown
