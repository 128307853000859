import { useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { setCurrentProjectId, setCurrentSessionToken, setUserId, setRootProjectFolderId, setDefaultProjectId } from "../../features/sessionSlice"
import { useProjects } from './projectsHook'

export function useSession() {

    const dispatch = useDispatch()
    //const projectsHook = useProjects()

    const isLoggedIn = useSelector(state => Boolean(state.session.currentSessionToken))
    const currentSessionToken = useSelector(state => state.session.currentSessionToken)
    const currentProjectId = useSelector(state => state.session.currentProjectId)
    //const currentProject = getProjectById(currentProjectId)
    const userId = useSelector(state => state.session.userId)
    const rootProjectFolderId = useSelector(state => state.session.rootProjectFolderId)
    const defaultProjectId = useSelector(state => state.session.defaultProjectId)
    const [ currentProjectName, setCurrentProjectName ] = useState('')

    // useEffect(() => {

    //     if(currentProjectId && projectsHook) {
    //         projectsHook.getProjectById(currentProjectId).then(
    //             currentProject => { 
    //                 if(currentProject) { setCurrentProjectName(currentProject.Name) } 
    //             },
    //             (reject) => {
    //                 setCurrentProjectName("Loading...")
    //             }
    //         )
    //     }
    // }, [currentProjectId, projectsHook]) //currentProjectId

    const _setDefaultProjectId = (projectId) => {
            dispatch(setDefaultProjectId({ projectId: projectId }))
    }

    const _setCurrentProjectId = (newId) => {
        
        dispatch(setCurrentProjectId({projectId: newId}))
    }

    const _setCurrentSessionToken = (newToken) => {
        dispatch(setCurrentSessionToken({ token: newToken }))
    }

    const _setUserId = (userId) => {
        dispatch(setUserId({ userId: userId }))
    }

    const _setRootProjectFolderId = (rootProjectFolderId) => {
        dispatch(setRootProjectFolderId({ rootProjectFolderId: rootProjectFolderId }))
    }

    return({
        currentProjectId,
        currentProjectName,
        setCurrentProjectId: _setCurrentProjectId,
        currentSessionToken,
        setCurrentSessionToken: _setCurrentSessionToken,
        userId,
        setUserId: _setUserId,
        isLoggedIn,
        rootProjectFolderId,
        setRootProjectFolderId: _setRootProjectFolderId,
        defaultProjectId,
        setDefaultProjectId: _setDefaultProjectId,
    })
}
