import { useState, useEffect } from 'react'
import { Button, FormControlLabel, Tab, Table, TableBody, TableCell, TableContainer, TableRow, Tabs } from "@mui/material/"
import { TabContext, TabPanel } from "@material-ui/lab"
import NumericInput from '../../ui/NumericInput'
import { useErrorHandler } from '../../../utils/apiUtils'
import { useUpdateOutput } from '../../../hooks/store/useUpdateOutput'
import { roundPValue } from '../../../utils/dataUtils'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { nanoid } from '@reduxjs/toolkit'

const api = process.env.REACT_APP_API_URL

const MultiplicativeComparison = (props) => {

    const [ selectedTab, setSelectedTab ] = useState("Multiplier")
    const [ dPrimeA, setDPrimeA ] = useState()
    const [ dPrimeB, setDPrimeB ] = useState()
    const [ varianceA, setVarianceA ] = useState()
    const [ varianceB, setVarianceB ] = useState()
    const [ covariance, setCovariance ] = useState()
    const [ isValidating, setIsValidating ] = useState(false)

    const sharedData = { dPrimeA, dPrimeB, varianceA, varianceB, covariance, isValidating, setIsValidating }

    return(
        <div id="MultiplicativeComparisonDialog" style={{ width: 'max-content', margin: '0 auto' }} >
            <h4 style={{ textAlign: 'left' }}>Multiplicative Comparison</h4>
            <TabContext value={selectedTab}>
                <Tabs value={selectedTab} onChange={(event, newValue) => setSelectedTab(newValue)} scrollButtons='auto' centered>
                    <Tab label="Multiplier" value="Multiplier" />
                    <Tab label="Probability" value="Probability"/>
                </Tabs>
                <TableContainer >
                <Table style={{ width: 'auto', tableLayout: 'auto',  height: 'auto', margin: 'auto'}} size='small' >
                    <TableBody sx={{border: '0px solid blue'}}>
                        <TableRow sx={{border: '0px'}}>
                            <TableCell sx={{border: '0px'}}><label style={{display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>d' Values:</label></TableCell>
                            <TableCell sx={{border: '0px'}}><NumericInput label="" setValidatedValue={setDPrimeA} isValidating={isValidating} /></TableCell>
                            <TableCell sx={{border: '0px'}}><NumericInput label="" setValidatedValue={setDPrimeB} isValidating={isValidating} /></TableCell>
                        </TableRow>
                        <TableRow sx={{border: '0px'}}>
                            <TableCell sx={{border: '0px'}}><label style={{display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>d' Variances:</label></TableCell>
                            <TableCell sx={{border: '0px'}}><NumericInput label="" setValidatedValue={setVarianceA} isValidating={isValidating} /></TableCell>
                            <TableCell sx={{border: '0px'}}><NumericInput label="" setValidatedValue={setVarianceB} isValidating={isValidating} /></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                </TableContainer>
                <div style={{ display: 'flex', flexFlow: 'column nowrap', margin: '0 auto', width: 'max-content', alignItems: 'center' }}>
                <br style={{margin: '6px'}}/>
                    <NumericInput label="Covariance" setValidatedValue={setCovariance} isValidating={isValidating} 
                        minValue='0' maxValue='1' minInclusive maxInclusive errorMessage='Must be between 0 and 1.'/>
                    <TabPanel value="Multiplier">
                        <MultiplierDialog {...sharedData}/>
                    </TabPanel>
                    <TabPanel value="Probability">
                        <ProbabilityDialog {...sharedData}/>
                    </TabPanel>
                </div>
            </TabContext>
        </div>
    )
}

const MultiplierDialog = ({dPrimeA, dPrimeB, varianceA, varianceB, covariance, isValidating, setIsValidating }) => {

    const [ alpha, setAlpha ] = useState(0.05)

    const { checkAndPostError } = useErrorHandler()
    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const onSubmit = (event) => {

        event.preventDefault()

        setIsValidating(true)

        let data = {
            function_group: 'statistical_tests',
            function_name: 'multiplicative_bound',
            "mu_x": dPrimeA,
            "mu_y": dPrimeB,
            "var_x": varianceA,
            "var_y": varianceB,
            "cov": covariance,
            "conf_level": 1.0 - alpha,
            "upper_bound": 100,
        }

        axios
            .post(api, data)
            .then((response) => {

                if(checkAndPostError(response)) {
                    return
                }
                
                const tableContent = [
                    [ 'Product A d', dPrimeA.toFixed(2) ],
                    [ 'Product B d', dPrimeB.toFixed(2) ],
                    [ 'Product A Variance', varianceA.toFixed(2) ],
                    [ 'Product A Variance', varianceB.toFixed(2) ],
                    [ 'Covariance', covariance.toFixed(2) ],
                    [ (100 * (1.0 - alpha)).toFixed(1).toString() + '% Lower Confidence Bound', 
                        response.data.delta_bound < 0 ? 'Less than 1' : response.data.delta_bound.toFixed(3) ], 
                ]

                const table = {
                    TableId: nanoid(),
                    TableContent: tableContent
                }

                const newOutputId = nanoid()

                const outputObject = {
                    OwnerId: userId,
                    OutputId: newOutputId,
                    Title: 'Multiplicative Comparison: ',
                    Stream: [
                        { Type: 'table', Content: table.TableId },
                    ],
                    Tables: [table ], 
                    ReferencingProjectIds: currentProjectId,
                }
                
                addOutput(outputObject)
            })
            .catch((error) => {
                console.log('error: ')
                console.log(error)
            })

            setIsValidating(false)
    }

    return(
        <form onSubmit={onSubmit}>
            <div style={{ display: 'flex', flexFlow: 'column nowrap', width: 'max-content', alignItems: 'center' }}>
                <NumericInput label="Alpha" setValidatedValue={setAlpha} isValidating={isValidating} defaultValue='0.05'
                    minValue='0.0' maxValue='1.0' errorMessage='Must be between 0 and 1.'/>
                <br style={{margin: '12px'}}/>
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </div>
        </form>
    )
}

const ProbabilityDialog = ({dPrimeA, dPrimeB, varianceA, varianceB, covariance, isValidating, setIsValidating }) => {

    const [ multiplier, setMultiplier ] = useState()

    const { checkAndPostError } = useErrorHandler()
    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const onSubmit = (event) => {

        event.preventDefault()

        setIsValidating(true)

        let data = {
            function_group: 'statistical_tests',
            function_name: 'multiplicative_comparison',
            "mu_x": dPrimeA,
            "mu_y": dPrimeB,
            "var_x": varianceA,
            "var_y": varianceB,
            "cov": covariance,
            "multiplier": multiplier,
        }

        axios
            .post(api, data)
            .then((response) => {

                if(checkAndPostError(response)) {
                    return
                }
                
                const tableContent = [
                    [ "Product A d'", dPrimeA.toFixed(2) ], 
                    [ "Product B d'", dPrimeB.toFixed(2) ], 
                    [ "Product A Variance", varianceA.toFixed(3) ], 
                    [ "Product B Variance", varianceB.toFixed(3) ], 
                    [ "Covariance", covariance.toFixed(3) ],
                    [ "P(d' A ≥ " + multiplier.toFixed(2) + " * d' B)", response.data.conf_level.toFixed(3) ]
                ]

                const table = {
                    TableId: nanoid(),
                    TableContent: tableContent
                }

                const newOutputId = nanoid()

                const outputObject = {
                    OwnerId: userId,
                    OutputId: newOutputId,
                    Title: 'Mutliplicative Bound',
                    Stream: [
                        { Type: 'table', Content: table.TableId },
                    ],
                    Tables: [table ], 
                    ReferencingProjectIds: currentProjectId,
                }
                
                addOutput(outputObject)
            })
            .catch((error) => {
                console.log('error: ')
                console.log(error)
            })

            setIsValidating(false)
    }

    return(
        <form onSubmit={onSubmit}>
            <div style={{ display: 'flex', flexFlow: 'column nowrap', width: 'max-content', alignItems: 'center' }}>
                <NumericInput label="Multiplier" setValidatedValue={setMultiplier} isValidating={isValidating}
                    minValue='0.0' errorMessage='Must be greater than 0.'/>
                <br style={{margin: '12px'}}/>
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </div>
        </form>
    )
}

export default MultiplicativeComparison
