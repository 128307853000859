import CustomTreeView from './CustomTreeView';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AlarmIcon from '@material-ui/icons/Alarm'
import DeleteIcon from '@material-ui/icons/Delete'
import Folder from '@material-ui/icons/Folder';
import HourglassEmpty from '@material-ui/icons/HourglassEmpty'
import { useState } from 'react'

const testData = {
    id: 'root',
    name: 'Parent',
    children: [
        {
            id: 'child_1',
            name: 'Child 1',
        },
        {
            id: 'child_2',
            name: 'Child 2',
        },
        {
            id: 'child_3',
            name: 'Child 3',
            children: [
                {
                    id: 'grandchild_1',
                    name: 'Grandchild 1',
                }
            ]
        }
    ]
}

const BuildTreeFromFolderData = (folders, allFolders) => {

    return(folders.map(node => {
        if(node.FolderId) {
            return({
                id: node.FolderId,
                name: node.Name === '_project_root_dataset_folder_' ? "Project Data Root" : node.Name,
                labelIcon: node.Name === '_project_root_dataset_folder_' ? AccountTreeIcon : Folder,
                children: node.Children ? 
                    BuildTreeFromFolderData(node.Children.map(stubChild => allFolders.find(folder => folder.FolderId === stubChild.FolderId)), allFolders) : null,
            }) 
        } else {
            return(null)
        }
    }))
}

const DatasetTree = ({ folderData, onFolderChange, selectedFolderId }) => {

    let treeStructure = null

    if(!folderData || (folderData && folderData.length === 0)) {
        treeStructure = [
            { id: "_loading", name: "Loading...", children: [], labelIcon: HourglassEmpty },
            // { id: '_datatree', name: 'Data Root', children: [testData], labelIcon: AccountTreeIcon},
            // { id: '_temp', name: 'Temp', labelIcon: AlarmIcon},
            // { id: '_trash', name: 'Trash', labelIcon: DeleteIcon},
        ]
    } else {

        const rootFolder = folderData.find(folder => folder.Name === "_project_root_dataset_folder_")
        if(rootFolder) {
            treeStructure = BuildTreeFromFolderData([rootFolder], folderData)
        } else {
            treeStructure = BuildTreeFromFolderData([folderData[0]], folderData)
        }
    }

    return (
        <CustomTreeView onNodeSelect={onFolderChange} selectedNodeId={selectedFolderId}>
            { treeStructure }
        </CustomTreeView>
    )

}

export default DatasetTree


// const DatasetTree = (props) => {

//     const classes = useStyles()

//     const [ expandedKeys, setExpandedKeys ] = useState(['0-0-key', '0-0-0-key', '0-0-0-0-key'])
//     const [ autoExpandParent, setAutoExpandParent ] = useState(true)
//     const [ gData, setGData ] = useState(testData)

//     const motion = {
//         motionName: 'node-motion',
//         motionAppear: false,
//         onAppearStart: node => {
//           console.log('Start Motion:', node);
//           return { height: 0 };
//         },
//         onAppearActive: node => ({ height: node.scrollHeight }),
//         onLeaveStart: node => ({ height: node.offsetHeight }),
//         onLeaveActive: () => ({ height: 0 }),
//       };

//     const onDragEnter = ({ expandedKeys }) => {
//         console.log('enter', expandedKeys)
//         setExpandedKeys(expandedKeys)
//     }

//     const onDrop = info => {
//         console.log('drop', info);
//         const dropKey = info.node.props.eventKey;
//         const dragKey = info.dragNode.props.eventKey;
//         const dropPos = info.node.props.pos.split('-');
//         const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    
//         const loop = (data, key, callback) => {
//           data.forEach((item, index, arr) => {
//             if (item.key === key) {
//               callback(item, index, arr);
//               return;
//             }
//             if (item.children) {
//               loop(item.children, key, callback);
//             }
//           });
//         };
//         const data = [...gData];
    
//         // Find dragObject
//         let dragObj;
//         loop(data, dragKey, (item, index, arr) => {
//           arr.splice(index, 1);
//           dragObj = item;
//         });
    
//         if (!info.dropToGap) {
//           // Drop on the content
//           loop(data, dropKey, item => {
//             item.children = item.children || [];
//             // where to insert
//             item.children.push(dragObj);
//           });
//         } else if (
//           (info.node.props.children || []).length > 0 && // Has children
//           info.node.props.expanded && // Is expanded
//           dropPosition === 1 // On the bottom gap
//         ) {
//           loop(data, dropKey, item => {
//             item.children = item.children || [];
//             // where to insert
//             item.children.unshift(dragObj);
//           });
//         } else {
//           // Drop on the gap
//           let ar;
//           let i;
//           loop(data, dropKey, (item, index, arr) => {
//             ar = arr;
//             i = index;
//           });
//           if (dropPosition === -1) {
//             ar.splice(i, 0, dragObj);
//           } else {
//             ar.splice(i + 1, 0, dragObj);
//           }
//         }
    
//         setGData(data)
//       };

//       const onExpand = expandedKeys => {
//         console.log('onExpand', expandedKeys);
        
//         setExpandedKeys(expandedKeys)
//         setAutoExpandParent(false)
//     }

//     return (
//         <div id="datasetsTree" className={classes.draggableContainer}>
//             <Tree
//                 expandedKeys={expandedKeys}
//                 onExpand={onExpand}
//                 autoExpandParent={autoExpandParent}
//                 draggable
//                 onDragEnter={onDragEnter}
//                 onDrop={onDrop}
//                 treeData={gData}
//                 motion={motion}
//             />
//         </div>
//     )
// }