import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

const ConfirmationDialog =({ title, text, isOpen, onClose, onConfirm }) => {

    const handleConfirm = () => {
        onClose()
        onConfirm()
    }

    return (
      <Dialog open={isOpen} onClose={onClose} >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{text}</DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleConfirm} color="secondary" >
            Yes
          </Button>
          <Button variant="contained" onClick={onClose} color="default" >
            No
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  
  export default ConfirmationDialog
