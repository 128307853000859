import { configureStore } from '@reduxjs/toolkit'

import sessionSlice from '../features/sessionSlice'
import allOutputReducer from '../features/allOutputSlice'
import mainOutputReducer from '../features/mainOutputSlice'
import plotReducer from '../features/plotsSlice'
import projectFoldersSlice from '../features/projectFoldersSlice'
import projectsSlice from '../features/projectsSlice'
import datasetFoldersSlice from '../features/datasetFoldersSlice'
import datasetsSlice from '../features/datasetsSlice'
import { databaseApi } from '../services/databaseApi'

export default configureStore({
    reducer: {
        [databaseApi.reducerPath]: databaseApi.reducer,
        session: sessionSlice,
        allOutput: allOutputReducer,
        mainOutput: mainOutputReducer,
        plots: plotReducer,
        projectFolders: projectFoldersSlice,
        projects: projectsSlice,
        datasetFolders: datasetFoldersSlice,
        datasets: datasetsSlice,
    },
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware().concat(databaseApi.middleware),
})
