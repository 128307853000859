import { useState, useEffect } from 'react'
import { Button, FormControlLabel, FormLabel, Radio, RadioGroup, Tab, Tabs } from "@material-ui/core"
import { TabContext, TabPanel } from "@material-ui/lab"
import AutocompleteDropdown from "../../ui/AutocompleteDropdown"
import NumericInput from '../../ui/NumericInput'
import { useDatasets } from '../../../hooks/useDatasets'
import { useErrorHandler } from '../../../utils/apiUtils'
import { useUpdateOutput } from '../../../hooks/store/useUpdateOutput'
import { roundPValue, trimDataVector } from '../../../utils/dataUtils'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { nanoid } from '@reduxjs/toolkit'

const api = process.env.REACT_APP_API_URL

const TTest = (props) => {

    const [ selectedTab, setSelectedTab ] = useState("oneSampleTTest")

    return(
        <div id="tTestDialog" style={{ width: 'max-content', margin: '0 auto' }} >
            {/* <h4 style={{ textAlign: 'left' }}>t-Test</h4> */}
            <TabContext value={selectedTab}>
                <Tabs value={selectedTab} onChange={(event, newValue) => setSelectedTab(newValue)} scrollButtons='auto' centered>
                    <Tab label="One-Sample t-Test" value="oneSampleTTest" />
                    <Tab label="Two-Sample t-Test" value="twoSampleTTest"/>
                </Tabs>
                <TabPanel value="oneSampleTTest">
                    <OneSampleTTest />
                </TabPanel>
                <TabPanel value="twoSampleTTest">
                    <TwoSampleTTest />
                </TabPanel>
            </TabContext>
        </div>
    )
}

const OneSampleTTest = (props) => {

    const [ mu, setMu ] = useState(0.0)
    const [ alpha, setAlpha ] = useState(0.05)
    const [ alternative, setAlternative ] = useState('Lower')
    const [ columnNamesList, setColumnNamesList ] = useState([])
    const [ selectedColumn, setSelectedColumn ] = useState(false)
    const [ isValidating, setIsValidating ] = useState(false)

    const { selectedDataset, setSelectedDataset, datasetData, datasetOptionsList, datasetError, columnNames, getColumnData } = useDatasets()

    const { checkAndPostError } = useErrorHandler()
    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    useEffect(() => {
        setColumnNamesList(columnNames.map((element, index) => ({ label: element, id: index })))
    }, [columnNames])

    const onSubmit = (event) => {

        event.preventDefault()

        setIsValidating(true)

        const sampleData = trimDataVector(getColumnData(selectedColumn.id).slice(1))
        const numericData = sampleData.map(str => {
            const out = parseFloat(str)
            if(isNaN(out)) {
                throw Error("Error parsing sample data")
            } else {
                return(out)
            }
        })

        let data = {
            "function_group": "statistical_tests",
            "function_name": "t_test",
            "len_data_1": numericData.length,
            "data_1": numericData,
            "mu": mu,
            "alpha": alpha,
            "alternative": alternative === "Lower" ? "Less" : alternative
        }

        axios
            .post(api, data)
            .then((response) => {

                if(checkAndPostError(response)) {
                    return
                }
                
                const tableContent = [
                    ['Test Statistic', response.data.test_statistic.toFixed(3) ], 
                    ['Degrees of Freedom', response.data.degrees_of_freedom.toFixed(0) ],
                    ['Sample Mean', (numericData.reduce((acc, val) => acc + val) / numericData.length).toFixed(3) ],
                    ['Hypothesized Mean', mu.toFixed(3) ],
                    ['p-Value', roundPValue(response.data.p_value) ],
                    ['Alpha', alpha.toFixed(3) ],
                    ['Lower Bound', alternative === "Lower" ? "-Infinity" : response.data.lower_conf_bound.toFixed(3) ],
                    ['Upper Bound', alternative === "Greater" ? "Infinity" : response.data.upper_conf_bound.toFixed(3) ],
                    ['Standard Error', response.data.standard_error.toFixed(3) ],
                ]

                const table = {
                    TableId: nanoid(),
                    TableContent: tableContent
                }

                const newOutputId = nanoid()

                const outputObject = {
                    OwnerId: userId,
                    OutputId: newOutputId,
                    Title: '1-Sample t-Test: ' + selectedDataset.label,
                    Stream: [
                        { Type: 'table', Content: table.TableId },
                    ],
                    Tables: [table ], 
                    ReferencingProjectIds: currentProjectId,
                }
                
                addOutput(outputObject)
            })
            .catch((error) => {
                console.log('error: ')
                console.log(error)
            })

            setIsValidating(false)
    }

    return(
        <form onSubmit={onSubmit}>
            <div style={{ display: 'flex', flexFlow: 'column nowrap', width: 'max-content', alignItems: 'center' }}>
                <AutocompleteDropdown options={datasetOptionsList ?? []}
                    value={selectedDataset} setValue={setSelectedDataset} label="Choose dataset:" variant="outlined" 
                    getOptionLabel={option => option.label ? option.label : ""}/>
                <br style={{margin: '12px'}}/>
                <AutocompleteDropdown options={columnNamesList ?? []}
                    value={selectedColumn} setValue={setSelectedColumn} label="Sample Column:" variant="outlined" 
                    getOptionLabel={option => option.label ? option.label : ""}/>
                <br style={{margin: '12px'}}/>
                <NumericInput label="Hypothesized Mean" setValidatedValue={setMu} isValidating={isValidating} defaultValue='0.0'/>
                <br style={{margin: '12px'}}/>
                <NumericInput label="Alpha" setValidatedValue={setAlpha} isValidating={isValidating} defaultValue='0.05'
                    minValue='0.0' maxValue='1.0' errorMessage='Must be between 0 and 1.'/>
                <br style={{margin: '12px'}}/>
                <RadioGroup
                    style={{ display: 'flex', alignItems: 'center' }}
                    aria-label="method"
                    name="method"
                    value={alternative}
                    onChange={(event, newValue) => { setAlternative(newValue)}}
                >
                    <div id='radioGroupContainer' style={{ display: 'flex', width: 'max-content', flexFlow: 'row nowrap', alignItems: 'start' }}>
                        {/* <FormLabel style={{ color: 'black', alignSelf: 'start' }}>Tails</FormLabel> */}
                        <FormControlLabel value='Lower' control={<Radio />} label = "Lower" />
                        <FormControlLabel value='Greater' control={<Radio />} label = "Greater" />
                        <FormControlLabel value='TwoSided' control={<Radio />} label = "2-Tailed" />
                    </div>
                </RadioGroup>
                <br style={{margin: '12px'}}/>
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </div>
        </form>
    )
}

const TwoSampleTTest = (props) => {

    const [ paired, setPaired ] = useState('unpaired')
    const [ varEqual, setVarEqual ] = useState('unequal')
    const [ alpha, setAlpha ] = useState(0.05)
    const [ alternative, setAlternative ] = useState('Lower')
    const [ columnNamesList, setColumnNamesList ] = useState([])
    const [ sample1Column, setSample1Column ] = useState([])
    const [ sample2Column, setSample2Column ] = useState(false)
    const [ isValidating, setIsValidating ] = useState(false)

    const { selectedDataset, setSelectedDataset, datasetOptionsList, datasetError, getColumnData, columnNames } = useDatasets()

    const { checkAndPostError } = useErrorHandler()
    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    useEffect(() => {
        setColumnNamesList(columnNames.map((element, index) => ({ label: element, id: index })))
    }, [columnNames])

    const onSubmit = (event) => {

        event.preventDefault()

        setIsValidating(true)

        const sample1Data = trimDataVector(getColumnData(sample1Column.id).slice(1))
        const sample2Data = trimDataVector(getColumnData(sample2Column.id).slice(1))
        const sample1Numeric = sample1Data.map(str => {
            const out = parseFloat(str)
            if(isNaN(out)) {
                throw Error("Error parsing sample data")
            } else {
                return(out)
            }
        })
        const sample2Numeric = sample2Data.map(str => {
            const out = parseFloat(str)
            if(isNaN(out)) {
                throw Error("Error parsing sample data")
            } else {
                return(out)
            }
        })

        let data = {
            "function_group": "statistical_tests",
            "function_name": "t_test",
            "len_data_1": sample1Numeric.length,
            "data_1": sample1Numeric,
            "len_data_2": sample2Numeric.length,
            "data_2": sample2Numeric,
            "mu": 0.0,
            "alpha": alpha,
            "alternative": alternative,
            "paired": paired === "paired",
            "var_equal": varEqual === "equal",
        }

        axios
            .post(api, data)
            .then((response) => {

                if(checkAndPostError(response)) {
                    return
                }
                
                const tableContent = [
                    ['Test Statistic', response.data.test_statistic.toFixed(3) ], 
                    ['Degrees of Freedom', response.data.degrees_of_freedom.toFixed(3) ],
                    ['Sample 1 Mean', (sample1Numeric.reduce((acc, val) => acc + val) / sample1Numeric.length).toFixed(3) ],
                    ['Sample 2 Mean', (sample2Numeric.reduce((acc, val) => acc + val) / sample2Numeric.length).toFixed(3) ],
                    ['p-Value', roundPValue(response.data.p_value) ],
                    ['Alpha', alpha.toFixed(3) ],
                    ['Lower Bound (Difference of Means)', alternative === "Lower" ? "-Infinity" : response.data.lower_conf_bound.toFixed(3) ],
                    ['Upper Bound (Difference of Means)', alternative === "Greater" ? "Infinity" : response.data.upper_conf_bound.toFixed(3) ],
                    ['Standard Error', response.data.standard_error.toFixed(3) ],
                ]

                const table = {
                    TableId: nanoid(),
                    TableContent: tableContent
                }

                const newOutputId = nanoid()

                const titlePrefix = paired === "paired" ? 'Paired t-Test: ' : (varEqual === "unequal" ? "Welch 2-Sample t-Test: " : '2-Sample t-Test: ')

                const outputObject = {
                    OwnerId: userId,
                    OutputId: newOutputId,
                    Title: titlePrefix + selectedDataset.label,
                    Stream: [
                        { Type: 'table', Content: table.TableId },
                    ],
                    Tables: [table ], 
                    ReferencingProjectIds: currentProjectId,
                }
                
                addOutput(outputObject)
            })
            .catch((error) => {
                console.log('error: ')
                console.log(error)
            })

            setIsValidating(false)
    }

    return(
        <form onSubmit={onSubmit}>
            <div style={{ display: 'flex', flexFlow: 'column nowrap', width: 'max-content', alignItems: 'center' }}>
                <AutocompleteDropdown options={datasetOptionsList ?? []}
                    value={selectedDataset} setValue={setSelectedDataset} label="Choose dataset:" variant="outlined" 
                    getOptionLabel={option => option.label ? option.label : ""}/>
                <br style={{margin: '12px'}}/>
                <AutocompleteDropdown options={columnNamesList ?? []}
                    value={sample1Column} setValue={setSample1Column} label="Sample 1 Column:" variant="outlined" 
                    getOptionLabel={option => option.label ? option.label : ""}/>
                <br style={{margin: '12px'}}/>
                <AutocompleteDropdown options={columnNamesList ?? []}
                    value={sample2Column} setValue={setSample2Column} label="Sample 2 Column:" variant="outlined" 
                    getOptionLabel={option => option.label ? option.label : ""}/>
                <br style={{margin: '12px'}}/>
                <RadioGroup
                    style={{ display: 'flex', alignItems: 'center' }}
                    aria-label="paired"
                    name="paired"
                    value={paired}
                    onChange={(event, newValue) => { setPaired(newValue)}}
                >
                    {/* <FormLabel style={{ color: 'black', alignSelf: 'center' }}>Unpaired/Paired</FormLabel> */}
                    <div id='radioGroupContainer' style={{ display: 'flex', width: 'max-content', flexFlow: 'row nowrap', alignItems: 'start' }}>
                        <FormControlLabel value='unpaired' control={<Radio />} label = "Unpaired" />
                        <FormControlLabel value='paired' control={<Radio />} label = "Paired" />
                    </div>
                </RadioGroup>
                <br style={{margin: '12px'}}/>
                <RadioGroup
                    style={{ display: 'flex', alignItems: 'center' }}
                    aria-label="method"
                    name="method"
                    value={varEqual}
                    onChange={(event, newValue) => { setVarEqual(newValue)}}
                >
                    {/* <FormLabel style={{ color: 'black', alignSelf: 'start' }}>Variance</FormLabel> */}
                    <div id='radioGroupContainer' style={{ display: 'flex', width: 'max-content', flexFlow: 'row nowrap', alignItems: 'start' }}>
                        <FormControlLabel value='unequal' control={<Radio disabled={paired==="paired"}/>} label = "Unequal Variance" />
                        <FormControlLabel value='equal' control={<Radio disabled={paired==="paired"}/>} label = "Equal Variance" />
                    </div>
                </RadioGroup>
                <br style={{margin: '12px'}}/>
                <NumericInput label="Alpha" setValidatedValue={setAlpha} isValidating={isValidating} defaultValue='0.05'
                    minValue='0.0' maxValue='1.0' errorMessage='Must be between 0 and 1.'/>
                <br style={{margin: '12px'}}/>
                <RadioGroup
                    style={{ display: 'flex', alignItems: 'center' }}
                    aria-label="method"
                    name="method"
                    value={alternative}
                    onChange={(event, newValue) => { setAlternative(newValue)}}
                >
                    {/* <FormLabel style={{ color: 'black', alignSelf: 'start' }}>Tails</FormLabel> */}
                    <div id='radioGroupContainer' style={{ display: 'flex', width: 'max-content', flexFlow: 'row nowrap', alignItems: 'start' }}>
                        <FormControlLabel value='Lower' control={<Radio />} label = "Lower" />
                        <FormControlLabel value='Greater' control={<Radio />} label = "Greater" />
                        <FormControlLabel value='TwoSided' control={<Radio />} label = "2-Tailed" />
                    </div>
                </RadioGroup>
                <br style={{margin: '12px'}}/>
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </div>
        </form>
    )
}

export default TTest
