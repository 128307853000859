import { useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Input from '@material-ui/core/Input'
import FormHelperText from '@material-ui/core/FormHelperText'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'

const AddNamedItem = ({title, label, onClose, onAdd, getErrorMessage, addButtonLabel='Add' }) => {

    const [ itemName, setItemName ] = useState('')
    const [ errorText, setErrorText ] = useState(null)

    const handleBlur = (event) => {
        if(errorText) {
            if(!getErrorMessage(itemName)) {
                setErrorText(null)
            }
        }
    }

    const onSubmit = (event) => {
        
        event.preventDefault()

        const errorMessage = getErrorMessage(itemName)

        if(errorMessage) {
            setErrorText(errorMessage)
            return
        } else if(itemName === "") {
            setErrorText('Please enter a name.')
            return
        } else {
            setErrorText(null)
        }

        onAdd(itemName)
       
        onClose()
    }

    return(
        <div id="newItemDialog" style={{ margin: '0px 10px 10px 10px' }}>
            <form onSubmit={onSubmit}>
                <h4>{title}</h4>
                <FormControl error={errorText ? true : false} style={{width: '100%'}}>
                    <FormLabel>{label}</FormLabel>
                    <Input style={{width: '100%'}} value={itemName} onChange={(event) => setItemName(event.target.value)} onBlur={handleBlur} />
                    <FormHelperText error>{errorText}</FormHelperText>
                    <div style={{margin: '10px'}}/>
                    <div style={{ display: 'inline-block', margin: '0 auto' }}>
                        <Button
                            style={{ padding: 3, marginRight: '10px' }}
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {addButtonLabel}
                        </Button>
                        <Button
                            style={{ padding: 3 }}
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </div>
                </FormControl>
            </form>
        </div>
    )

}

const AddNamedItemModal = ({open, onClose, ...otherProps}) => {

    return(
        <Dialog open={open} onClose={onClose} {...otherProps}>
            <AddNamedItem onClose={onClose}/>
        </Dialog>
    )
}

export { AddNamedItem, AddNamedItemModal }
