import { useState } from "react"
import { useSelector } from "react-redux";
import Button from '@material-ui/core/Button'
import axios from 'axios'

import NumericInput from "../../ui/NumericInput";
import { Typography } from "@material-ui/core";
import { useUpdateOutput } from "../../../hooks/store/useUpdateOutput";
import { nanoid } from '@reduxjs/toolkit'
import { useErrorHandler } from "../../../utils/apiUtils";
import { GetPlotlyPairOfNormalsData } from "../../../utils/plotUtils";

const api = process.env.REACT_APP_API_URL

const ANotA = () => {

    const [ aSeenAResponded, setASeenAResponded ] = useState()
    const [ aSeenNotAResponded, setASeenNotAResponded ] = useState()
    const [ notASeenAResponded, setNotASeenAResponded ] = useState()
    const [ notASeenNotAResponded, setNotASeenNotAResponded ] = useState()
    const [ isValidating, setIsValidating ] = useState(false)

    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const { checkAndPostError } = useErrorHandler()

    const onSubmit = (event) => {

        event.preventDefault()
        
        setIsValidating(true)

        if(!(aSeenAResponded || aSeenAResponded === 0) && (aSeenNotAResponded || aSeenNotAResponded === 0) && 
            (notASeenAResponded || notASeenAResponded === 0) && (notASeenNotAResponded || notASeenNotAResponded === 0)) {
                return
        }

        setIsValidating(false)

        const apiData = {
            function_group: 'a_not_a',
            a_seen_a_chosen: aSeenAResponded,
            a_seen_not_a_chosen: aSeenNotAResponded,
            not_a_seen_a_chosen: notASeenAResponded,
            not_a_seen_not_a_chosen: notASeenNotAResponded,
        }

        axios
        .post(api, apiData)
        .then((response) => {

            if(checkAndPostError(response)) {
                return
            }

            const title = 'A/Not A Analysis'
            const text = ''
            const tableContent = [
                ["d'", response.data.delta.toFixed(2)],
                ["Variance of d'", response.data.d_prime_var.toFixed(2)],
                ["Beta", response.data.beta.toFixed(2)],
                ["Criterion", response.data.critical_val.toFixed(3)]
            ]

            //let tempPlotData = GetPlotlyPairOfNormalsData(response.data.delta)
            
            let AGivenAPlot = [{
                curveType: 'normal',
                x_lb: response.data.beta,
                x_ub: response.data.delta + 4.0,
                mean: response.data.delta,
                sd: 1.0,
                fill: 'tozeroy',
                type: 'scatter',
                mode: 'none',
                fillcolor: 'blue',
                showlegend: false,
                hoverinfo: 'none',
                name: "P(A|A)"
            }].concat(GetPlotlyPairOfNormalsData(response.data.delta))
            AGivenAPlot[0].xaxis = "x"
            AGivenAPlot[0].yaxis = "y"
            AGivenAPlot[1].xaxis = "x"
            AGivenAPlot[1].yaxis = "y"
            AGivenAPlot[2].xaxis = "x"
            AGivenAPlot[2].yaxis = "y"
            let AGivenNotAPlot = [{
                curveType: 'normal',
                x_lb: response.data.beta,
                x_ub: response.data.delta + 4.0,
                mean: 0,
                sd: 1.0,
                fill: 'tozeroy',
                type: 'scatter',
                mode: 'none',
                fillcolor: 'red',
                showlegend: false,
                hoverinfo: 'none',
                name: "P(A|Not A)"
            }].concat(GetPlotlyPairOfNormalsData(response.data.delta))
            AGivenNotAPlot[0].xaxis = "x2"
            AGivenNotAPlot[0].yaxis = "y2"
            AGivenNotAPlot[1].xaxis = "x2"
            AGivenNotAPlot[1].yaxis = "y2"
            AGivenNotAPlot[2].xaxis = "x2"
            AGivenNotAPlot[2].yaxis = "y2"

            const AGivenNotAX = response.data.beta + (4.0 - response.data.beta) / 3
            const prefix = 1.0 / (1.0 * Math.sqrt(2 * Math.PI))
            const AnnotationY = prefix * Math.exp(-0.5 * (AGivenNotAX * AGivenNotAX))
            //const AGivenAY = prefix * Math.exp(-0.5 * (AGivenNotAX - response.data.d_prime) * (response.data.beta - response.data.d_prime))
            
            const layout = {
                grid: {rows: 2, columns: 1, pattern: 'independent', roworder: 'bottom to top' },
                annotations: [
                    {
                      x: AGivenNotAX,
                      y: AnnotationY,
                      xref: 'x2',
                      yref: 'y2',
                      text: 'P(A|Not A)',
                      showarrow: true,                
                      arrowhead: 7,
                      ax: 10,
                      ay: -10
                    },
                    {
                        x: AGivenNotAX + response.data.delta,
                        y: AnnotationY,
                        xref: 'x',
                        yref: 'y',
                        text: 'P(A|A)',
                        showarrow: true,                
                        arrowhead: 7,
                        ax: 10,
                        ay: -10
                      }
                  ]
            }
            
            const newPlotId = nanoid()
            const newOutputId = nanoid()
            const plotObject = {
                    OwnerId: userId,
                    PlotId: newPlotId,
                    Title: title,
                    Data: [ ...AGivenAPlot, ...AGivenNotAPlot ],
                    Layout: layout,
                    OutputReferences: [newOutputId]
                }

            const tableId = nanoid()

            const table = {
                TableId: tableId,
                TableContent: tableContent
            }
            
            const outputObject = {
                OwnerId: userId,
                OutputId: newOutputId,
                Title: title, 
                Stream: [
                    { Type: 'table', Content: tableId },
                    { Type: 'plot', Content: newPlotId }
                ],
                Tables: [table], 
                Plots: [newPlotId],
                ReferencingProjectIds: currentProjectId,
            }
            
            addOutput(outputObject, plotObject)
            
        })
        .catch((error) => {
            console.log("error: ")
            console.log(error)
        })
    }

    return(
        <div id='aNotADialog' style={{ display: 'flex', justifyContent: 'center' }}>
            <form onSubmit={onSubmit}>
                <div style={{ display: 'flex', flexFlow: 'column nowrap', alignItems: 'flex-end'}} >
                    <Typography style={{ marginRight: '8px', alignSelf: 'flex-start' }} variant='h6'>'A' Seen: </Typography>
                    <div style={{ display: 'flex', flex: '1', flexFlow: 'row nowrap', alignItems: 'center', marginLeft: '16px'}} >
                        <div style={{ display: 'flex', flexFlow: 'column nowrap'}}>
                            <NumericInput label="'A' Responded" setValidatedValue={setASeenAResponded} isValidating={isValidating}
                                minValue='0' minInclusive errorMessage='Must be positive.' isInteger />
                            <NumericInput label="'Not A' Responded" setValidatedValue={setASeenNotAResponded} isValidating={isValidating}
                                minValue='0' minInclusive errorMessage='Must be positive.' isInteger />
                        </div>
                    </div>
                    <div style={{ display: 'flex', borderBottom: '0px solid gray', alignSelf: 'stretch', margin: '12px 5px'}} />
                    <Typography style={{ marginRight: '8px', alignSelf: 'flex-start' }} variant='h6'>'Not A' Seen: </Typography>
                    <div style={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', marginLeft: '16px'}}>
                        <div style={{ display: 'flex', flexFlow: 'column nowrap'}}>
                            <NumericInput label="'A' Responded" setValidatedValue={setNotASeenAResponded} isValidating={isValidating}
                                minValue='0' minInclusive errorMessage='Must be positive.' isInteger />
                            <NumericInput label="'Not A' Responded" setValidatedValue={setNotASeenNotAResponded} isValidating={isValidating}
                                minValue='0' minInclusive errorMessage='Must be positive.' isInteger />
                        </div>
                    </div>
                </div>
                <br />
                    <Button
                        style={{ padding: 3 }}
                        variant="contained"
                        color="primary"
                        type="submit"
                    >
                        Run
                    </Button>
            </form>
        </div>
    )
}

export default ANotA
