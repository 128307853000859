import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import axios from 'axios'

import AutocompleteDropdown from '../../ui/AutocompleteDropdown'

import { useErrorHandler } from '../../../utils/apiUtils'
import { useSelector } from 'react-redux'
import { base64ToFloats, getDefaultColorByIndex } from '../../../utils'
import { roundPValue, transposeArray } from '../../../utils/dataUtils'
import { nanoid } from '@reduxjs/toolkit'
import { useUpdateOutput } from '../../../hooks/store/useUpdateOutput'
import { useDatasets } from '../../../hooks/useDatasets'
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core'

const api = process.env.REACT_APP_API_URL

const DegreeOfDifference = (props) => {

    const [ direction, setDirection ] = useState('leftSimilar')

    const { selectedDataset, setSelectedDataset, selectedDatasetData, datasetData, datasetOptionsList, getDatasetSize } = useDatasets()

    const { checkAndPostError } = useErrorHandler()

    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const onSubmit = (event) => {
 
        event.preventDefault()
        
        const { numRows, numCols } = getDatasetSize()
        const numProds = numRows - 1 // minus 1 for column headers
        const numCats = numCols - 1 // first column is product name
        let catCounts
        if(direction === "leftSimilar") {
            catCounts = transposeArray(datasetData.slice(1).map(row => row.slice(1, numCols).reverse())).flat().map(a => Number(a))
        } else {
            catCounts = transposeArray(datasetData.slice(1).map(row => row.slice(1, numCols))).flat().map(a => Number(a))
        }
        const prodNames = datasetData.slice(1).map(row => row[0])

        let data = {
            function_group: "degree_of_difference",
            function_name: "degree_of_difference",
            num_prods: numProds,
            num_cats: numCats,
            alpha: 0.05,
            cat_counts: catCounts,
        }

        axios
            .post(api, data)
            .then((response) => {

                if(checkAndPostError(response)) {
                    return
                }
                
                const prodMeans = base64ToFloats(response.data.prod_means)
                const dPrimes = base64ToFloats(response.data.deltas)
                const scaleBoundaries = base64ToFloats(response.data.scale_bounds)
                const dPrimeVCV = base64ToFloats(response.data.d_prime_vcv)
                const dPrimeConfBounds = base64ToFloats(response.data.d_prime_conf_bounds)
                const scaleVars = base64ToFloats(response.data.scale_vars)
                const scaleConfbounds = base64ToFloats(response.data.scale_conf_bounds)
                const pValues = base64ToFloats(response.data.p_values)
                const exactPValues = base64ToFloats(response.data.exact_p_values)
                const variance = base64ToFloats(response.data.variance)
                const compVars = base64ToFloats(response.data.comp_vars)
                
                const boundariesTableContent = [
                    ['Boundary', 'Variance', '95% Lower Bound', '95% Upper Bound'], 
                    ...scaleBoundaries.map((boundary, index) => [boundary.toFixed(2), scaleVars[index].toFixed(2), 
                                                                 scaleConfbounds[index].toFixed(2), scaleConfbounds[index + (numCats - 1)].toFixed(2)]).reverse()
                ]

                const ratingMeansTableContent = [
                    ['Product', 'Ratings Mean', 'd\'', '95% Lower Bound', '95% Upper Bound'], 
                    [ prodNames[0], (direction === "leftSimilar" ? numCats + 1 - prodMeans[0] : prodMeans[0]).toFixed(2), dPrimes[0].toFixed(2), '', ''],
                    ...prodNames.slice(1).map((prodName, index) => ([prodName, (direction === "leftSimilar" ? numCats + 1 - prodMeans[index + 1] : prodMeans[index + 1]).toFixed(2), 
                                                            dPrimes[index + 1].toFixed(2), 
                                                            dPrimeConfBounds[index + 1].toFixed(2), dPrimeConfBounds[index + numProds + 1].toFixed(2) ]))
                ]

                let prodCompTableContent = [['Product 1', 'Product 2', 'd\'', 'Variance', 'p-Value']]
                for(let prodIndex1 = 0; prodIndex1 < prodNames.length; prodIndex1++) {
                    for(let prodIndex2 = prodIndex1 + 1; prodIndex2 < prodNames.length; prodIndex2++) {
                        const dataIndex = prodIndex1 * prodNames.length + prodIndex2
                        prodCompTableContent.push([prodNames[prodIndex1] , prodNames[prodIndex2], 
                            Math.abs(dPrimes[prodIndex2] - dPrimes[prodIndex1]).toFixed(2), 
                            compVars[dataIndex].toFixed(2), 
                            roundPValue(pValues[dataIndex])])
                    }
                }

                const boundariesTable = {
                    TableId: nanoid(),
                    Title: "Boundaries",
                    OptionsList: { ColumnHeaders: true },
                    TableContent: boundariesTableContent
                }
                const ratingMeansTable = {
                    TableId: nanoid(),
                    Title: "Products Summary",
                    OptionsList: { ColumnHeaders: true },
                    TableContent: ratingMeansTableContent
                }
                const prodCompTable = {
                    TableId: nanoid(),
                    Title: "Product Comparisons",
                    OptionsList: { ColumnHeaders: true },
                    TableContent: prodCompTableContent
                }

                let tempPlotData = dPrimes.map((d_prime, index) => ({
                    curveType: 'normal',
                    x_lb: -4,
                    x_ub: d_prime + 4.0,
                    mean: d_prime,
                    sd: 1,
                    type: 'line',
                    color: getDefaultColorByIndex(index),
                    name: 'name',
                    showlegend: false,
                    hoverinfo: 'none',
                    //hovertemplate: 'Delta: %{x:.2f}  Prob.: %{y:.2f}',
                }))

                const newPlotId = nanoid()
                const newOutputId = nanoid()
                const plotObject = { //addPlot({
                        OwnerId: userId,
                        PlotId: newPlotId,
                        Title: 'Degree of Difference: ' + selectedDatasetData.Name,
                        Data: tempPlotData,
                        OutputReferences: [newOutputId]
                    }

                const outputObject = {
                    OwnerId: userId,
                    OutputId: newOutputId,
                    Title: 'Degree of Difference: ' + selectedDatasetData.Name,
                    Stream: [
                        { Type: 'table', Content: boundariesTable.TableId },
                        { Type: 'table', Content: ratingMeansTable.TableId },
                        { Type: 'table', Content: prodCompTable.TableId },
                        { Type: 'plot', Content: newPlotId }
                    ],
                    Tables: [boundariesTable, ratingMeansTable, prodCompTable ], 
                    Plots: [newPlotId],
                    ReferencingProjectIds: currentProjectId,
                }
                
                addOutput(outputObject, plotObject)
            })
            .catch((error) => {
                console.log('error: ')
                console.log(error)
            })
    };

    return (
        <div id='DegreeOfDifferenceDialog'>
            <h2>Degree of Difference</h2>
            <form onSubmit={onSubmit}>
                <AutocompleteDropdown 
                    label="Choose dataset:"
                    variant="outlined"
                    options={datasetOptionsList} 
                    value={selectedDataset} setValue={setSelectedDataset}
                />
                <br />
                <br />
                <RadioGroup
                    style={{ display: 'flex', alignItems: 'center' }}
                    aria-label="templated"
                    name="directionRadioGroup"
                    value={direction}
                    onChange={(event, newValue) => { setDirection(newValue)}}
                >
                    <div id='radioGroupContainer' style={{ display: 'flex', width: 'max-content', flexFlow: 'column nowrap', alignItems: 'center' }}>
                        <FormLabel style={{ color: 'black', alignSelf: 'start' }}>Data Format:</FormLabel>
                        <FormControlLabel value='leftSimilar' control={<Radio />} label = "More Similar (Left) to More Different (Right)" />
                        <FormControlLabel value='rightSimilar' control={<Radio />} label = "More Different (Left) to More Similar (Right)" />    
                    </div>
                </RadioGroup>
                <br />
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </form>
        </div>
    )
}

export default DegreeOfDifference
