import React from 'react'
import Button from '@material-ui/core/Button'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import axios from 'axios'

import { base64ToFloats } from '../../../../utils'
import { DecisionRuleChooserRadio, DecisionRuleChooserCheckbox, decisionRuleDictToArray } from '../../../ui/DecisionRuleChoosers'
import NumericInput from '../../../ui/NumericInput';
import { methodNameDict, methodColorDict } from '../../../../app/thurstonianDicts';
import { useUpdateOutput } from '../../../../hooks/store/useUpdateOutput';
import { useErrorHandler } from '../../../../utils/apiUtils';
import { useSelector } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';

const api = 'https://ci86z1c6w3.execute-api.us-east-1.amazonaws.com/default/ifplip_api_test'

const PsychometricFunctions = (props) => {

    const [ selectedTab, setSelectedTab ] = React.useState("psychometricFunction")

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue)
    }
    
    return (
        <TabContext value={selectedTab}>
            <Tabs value={selectedTab} onChange={handleTabChange}>
                <Tab
                    label="Psychometric Function"
                    value="psychometricFunction"
                />
                <Tab
                    label="Inverse Psychometric Function"
                    value="inversePsychometricFunction"
                />
                <Tab label="Psychometric Curve" value="psychometricCurve" />
            </Tabs>
            <TabPanel value="psychometricFunction">
                <PsychometricFunction />
            </TabPanel>
            <TabPanel value="inversePsychometricFunction">
                <InversePsychometricFunction />
            </TabPanel>
            <TabPanel value="psychometricCurve">
                <PsychometricCurve />
            </TabPanel>
        </TabContext>
    )
}

const PsychometricFunction = (props) => {

    const [ method, setMethod ] = React.useState(null)
    const [ delta, setDelta ] = React.useState()

    const [isValidating, setIsValidating] = React.useState(false)

    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const { checkAndPostError } = useErrorHandler()

    const onSubmit = (event) => {
        
        event.preventDefault()

        setIsValidating(true)

        if(!(method && delta)) {
            return
        }

        setIsValidating(false)

        let methodName = method
        if(method === 'twoAFC' || method === 'threeAFC') {
            methodName = 'mafc'
        }

        let data = {
            "function_group": "psychometric_calculation",
            "function_name": "psychometric_function",
            "method": methodName,
            "delta": delta,
        }

        if(method === 'twoAFC' || method === 'threeAFC') {
            data = {
                ...data,
                "num_choices": (method === 'twoAFC' ? 2 : 3)
            }
        }

        axios
            .post(api, data)
            .then((response) => {
                const title = 'Psychometric Calculation: ' + methodNameDict[method]
                // const text = 'A delta of ' + delta.toFixed(2) + ' corresponds to a probability correct of ' +
                //     Number(response.data.prob_corr).toFixed(2) + ' with the ' + methodNameDict[method] + ' method.'

                const table = [
                    ['Delta', delta.toFixed(2)], 
                    ['Proportion Correct', response.data.prob_corr.toFixed(2)]
                ]

                const newOutputId = nanoid()
                const outputObject = {
                        OwnerId: userId,
                        OutputId: newOutputId,
                        Title: title,
                        Table: table,
                        TextContent: '', 
                        Plots: [],
                        ReferencingProjectIds: currentProjectId,
                    }
                
                addOutput(outputObject)
            })
            .catch((error) => {
                console.log("error: ")
                console.log(error)
            })
            
    }

    return (
        <div id="psychometricFunctionDialog">
            <form onSubmit={onSubmit}>
                <DecisionRuleChooserRadio methodNameDict={methodNameDict} setValidatedValue={setMethod} isValidating={isValidating} />
                <NumericInput label="Delta" setValidatedValue={setDelta} isValidating={isValidating}
                    minValue='0' errorMessage='Delta must be greater than 0.' />
                <br />
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </form>
        </div>
    )
}

const InversePsychometricFunction = (props) => {

    const [ method, setMethod ] = React.useState(null)
    const [ probCorr, setProbCorr ] = React.useState(null)
    const [ isValidating, setIsValidating ] = React.useState(false)

    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    
    const onSubmit = (event) => {
        
        event.preventDefault()

        setIsValidating(true)

        if(!(method && probCorr)) {
            return
        }

        setIsValidating(false)

        let methodName = method
        if(method === 'twoAFC' || method === 'threeAFC') {
            methodName = 'mafc'
        }

        let data = {
            "function_group": "psychometric_calculation",
            "function_name": "inv_psychometric_function",
            "method": methodName,
            "prob_corr": probCorr,
        }

        if(method === 'twoAFC' || method === 'threeAFC') {
            data = {
                ...data,
                "num_choices": (method === 'twoAFC' ? 2 : 3)
            }
        }

        axios
            .post(api, data)
            .then((response) => {
                const title = 'Psychometric Calculation: ' + methodNameDict[method]
                // const text = 'A probability correct of ' + probCorr.toFixed(2) + ' corresponds to a delta of ' +
                //     Number(response.data.delta).toFixed(2) + ' with the ' + methodNameDict[method] + ' method.'
                
                const table = [
                    ['Proportion Correct', probCorr.toFixed(2)],
                    ['Delta', response.data.delta.toFixed(2)]
                ]
                    const newOutputId = nanoid()
                    const outputObject = {
                            OwnerId: userId,
                            OutputId: newOutputId,
                            Title: title,
                            Table: table,
                            TextContent: '', 
                            Plots: [],
                            ReferencingProjectIds: currentProjectId,
                        }
                    
                    addOutput(outputObject)
            })
            .catch((error) => {
                console.log("error: ")
                console.log(error)
            })
            
    }

    return (
        <div id="InversePsychometricFunctionDialog">
            <form onSubmit={onSubmit}>
                <DecisionRuleChooserRadio methodNameDict={methodNameDict} setValidatedValue={setMethod} isValidating={isValidating} />
                <NumericInput label="Probability Correct" setValidatedValue={setProbCorr} isValidating={isValidating}
                    minValue='0' errorMessage='Prob. Corr. must be between 0 and 1.' />
                <br />
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </form>
        </div>
    )
}

const PsychometricCurve = (props) => {

    const [ methods, setMethods ] = React.useState()
    const [ deltaMin, setDeltaMin ] = React.useState()
    const [ deltaMax, setDeltaMax ] = React.useState()
    const [ isValidating, setIsValidating ] = React.useState()
 
    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const { checkAndPostError } = useErrorHandler()

    const onSubmit = (event) => {
        
        event.preventDefault()

        setIsValidating(true)

        // 0 is falsy
        if(!(methods && (deltaMin || deltaMin === 0) && deltaMax)) {
            return
        }

        setIsValidating(false)

        const methodArray = decisionRuleDictToArray(methods)

        const apiData = {
            "function_group": "psychometric_calculation",
            "function_name": "psychometric_curve",
            "methods": methodArray.join('|'),
            "delta_min": deltaMin,
            "delta_max": deltaMax
        }

        axios
            .post(api, apiData)
            .then((response) => {

                if(checkAndPostError(response)) {
                    return
                }

                let tempPlotData = []
                methodArray.forEach((method) => {

                    const delta_vals = base64ToFloats(response.data[method + "_delta_vals"])
                    const power_vals = base64ToFloats(response.data[method + "_prob_corr_vals"])
                    
                    tempPlotData.push({
                        x: delta_vals,
                        y: power_vals,
                        type: 'line',
                        marker: { color: methodColorDict[method] },
                        name: methodNameDict[method],
                        showlegend: true,
                        hovertemplate: 'Delta: %{x:.2f}  Prob. Corr.: %{y:.2f}',
                    })
                })

                const title = 'Psychometric Curve: ' + methodArray.map(method => methodNameDict[method]).join(', ')

                const newPlotId = nanoid()
                const newOutputId = nanoid()
                const plotObject = {
                    OwnerId: userId,
                    PlotId: newPlotId,
                    Title: title,
                    Data: tempPlotData,
                    OutputReferences: [newOutputId]
                }

                const outputObject = {
                    OwnerId: userId,
                    OutputId: newOutputId,
                    Title: title, 
                    Stream: [
                        { Type: 'plot', Content: newPlotId }
                    ],
                    Plots: [newPlotId],
                    ReferencingProjectIds: currentProjectId,
                }

                addOutput(outputObject, plotObject)
                
            })
            .catch((error) => {
                console.log("error: ")
                console.log(error)
            })

    }

    return (
        <div id="PsychometricCurveDialog">
            <form onSubmit={onSubmit}>
            <DecisionRuleChooserCheckbox methodNameDict={methodNameDict} setValidatedValue={setMethods} isValidating={isValidating} />
                <NumericInput label="Delta Min." setValidatedValue={setDeltaMin} isValidating={isValidating}
                    minInclusive minValue='0' errorMessage='Delta Min. must be greater than or equal to 0.' />
                <NumericInput label="Delta Max." setValidatedValue={setDeltaMax} isValidating={isValidating}
                    minValue='0' errorMessage='Delta Max. must be greater than 0.' />
                <br />
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </form>
        </div>
    )

}

export default PsychometricFunctions
