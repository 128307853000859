import { useState } from "react"
import Button from '@material-ui/core/Button'
import axios from 'axios'

import { useMainOutput } from "../../../../hooks/store/mainOutputHook";
import NumericInput from "../../../ui/NumericInput";
//import { usePlots } from "../../../hooks/store/plotsHook";
import { Typography } from "@material-ui/core";
import { useAllOutput } from "../../../../hooks/store/allOutputHook";
import { useErrorHandler } from "../../../../utils/apiUtils";
import { roundPValue } from "../../../../utils/dataUtils";
import { nanoid } from "@reduxjs/toolkit";
import { GetPlotlyPairOfNormalsData } from "../../../../utils/plotUtils";
import { useUpdateOutput } from "../../../../hooks/store/useUpdateOutput";
import { useSelector } from "react-redux";

const api = process.env.REACT_APP_API_URL

const Applicability = () => {

    const [ aAppliesBApplies, setAAppliesBApplies ] = useState()
    const [ aAppliesBNotApplies, setAAppliesBNotApplies ] = useState()
    const [ aNotAppliesBApplies, setANotAppliesBApplies ] = useState()
    const [ aNotAppliesBNotApplies, setANotAppliesBNotApplies ] = useState()
    const [ isValidating, setIsValidating ] = useState(false)

    const { checkAndPostError } = useErrorHandler()
    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const onSubmit = (event) => {

        event.preventDefault()
        
        setIsValidating(true)

        if(!(aAppliesBApplies || aAppliesBApplies === 0) && (aAppliesBNotApplies || aAppliesBNotApplies === 0) && 
            (aNotAppliesBApplies || aNotAppliesBApplies === 0) && (aNotAppliesBNotApplies || aNotAppliesBNotApplies === 0)) {
                return
        }

        setIsValidating(false)

        const apiData = {
            function_group: 'applicability_delta',
            yesAyesB: aAppliesBApplies,
            yesAnotB: aAppliesBNotApplies,
            notAyesB: aNotAppliesBApplies,
            notAnotB: aNotAppliesBNotApplies,
        }

        axios
        .post(api, apiData)
        .then((response) => {

            if(checkAndPostError(response)) {
                return
            }

            const numDecimals = 2

            const title = 'Applicability - Delta Calculation'
            const tableContent = [
                ["d'", response.data.d_prime.toFixed(numDecimals)],
                ["Variance of d'", response.data.d_prime_var.toFixed(numDecimals)],
                ["c'", response.data.c_prime.toFixed(numDecimals)],
                ["Variance of c'", response.data.d_prime_var.toFixed(numDecimals)],
                ["Covariance", response.data.covar.toFixed(numDecimals)],
                ["Chi-squared value", response.data.chi_sq.toFixed(numDecimals)],
                ["Probability of difference", roundPValue(response.data.prob, 3, true)],
            ]
            
            const tableId = nanoid()

            const table = {
                TableId: tableId,
                TableContent: tableContent
            }

            let tempPlotData = GetPlotlyPairOfNormalsData(response.data.d_prime)

            const newPlotId = nanoid()
            const newOutputId = nanoid()
            const plotObject = { //addPlot({
                    OwnerId: userId,
                    PlotId: newPlotId,
                    Title: 'Applicability - Delta Estimation',
                    Data: tempPlotData,
                    OutputReferences: [newOutputId]
                }

            const outputObject = {
                OwnerId: userId,
                OutputId: newOutputId,
                Title: title, 
                Stream: [
                    { Type: 'table', Content: tableId },
                    { Type: 'plot', Content: newPlotId }
                ],
                Tables: [table], 
                Plots: [newPlotId],
                ReferencingProjectIds: currentProjectId,
            }
            
            addOutput(outputObject, plotObject)
            
        })
        .catch((error) => {
            console.log("error: ")
            console.log(error)
        })
    }

    return(
        <div id='applicabilityDialog' style={{ display: 'flex', justifyContent: 'center' }}>
            <form onSubmit={onSubmit}>
                <div style={{ display: 'flex', flexFlow: 'column nowrap', alignItems: 'flex-end'}} >
                    <Typography style={{ marginRight: '8px', alignSelf: 'flex-start' }} variant='h6'>A Applies: </Typography>
                    <div style={{ display: 'flex', flex: '1', flexFlow: 'row nowrap', alignItems: 'center', marginLeft: '16px'}} >
                        <div style={{ display: 'flex', flexFlow: 'column nowrap'}}>
                            <NumericInput label="B Applies" setValidatedValue={setAAppliesBApplies} isValidating={isValidating}
                                minValue='0' minInclusive errorMessage='Must be positive.' isInteger />
                            <NumericInput label="B Does Not Apply" setValidatedValue={setAAppliesBNotApplies} isValidating={isValidating}
                                minValue='0' minInclusive errorMessage='Must be positive.' isInteger />
                        </div>
                    </div>
                    <div style={{ display: 'flex', borderBottom: '0px solid gray', alignSelf: 'stretch', margin: '12px 5px'}} />
                    <Typography style={{ marginRight: '8px', alignSelf: 'flex-start' }} variant='h6'>A Does Not Apply: </Typography>
                    <div style={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', marginLeft: '16px'}}>
                        <div style={{ display: 'flex', flexFlow: 'column nowrap'}}>
                            <NumericInput label="B Applies" setValidatedValue={setANotAppliesBApplies} isValidating={isValidating}
                                minValue='0' minInclusive errorMessage='Must be positive.' isInteger />
                            <NumericInput label="B Does Not Apply" setValidatedValue={setANotAppliesBNotApplies} isValidating={isValidating}
                                minValue='0' minInclusive errorMessage='Must be positive.' isInteger />
                        </div>
                    </div>
                </div>
                <br />
                    <Button
                        style={{ padding: 3 }}
                        variant="contained"
                        color="primary"
                        type="submit"
                    >
                        Run
                    </Button>
            </form>
        </div>
    )
}

export default Applicability
