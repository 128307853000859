import { useRouteMatch } from 'react-router-dom'

export function useTabRouter(props, tabOptions, defaultTabName = tabOptions[0]) {

    const routeMatch = useRouteMatch(tabOptions.map(tabName => `${props.match.path}/${tabName}`))

    const _path = props.match.path
    
    let tabName = undefined
    let full_path = undefined

    if(tabOptions) {
        full_path = routeMatch?.path
    } else {
        full_path = props.location.pathname
    }
    
    if(full_path) {
        const numParts = full_path.split('/').length
        if(full_path.split('/')[numParts - 1].length > 0) {
            tabName = full_path.split('/')[numParts - 1]
        } else {
            tabName = full_path.split('/')[numParts - 2]
        }
    }

    const _tabName = (tabOptions.includes(tabName) ? tabName : defaultTabName)

    return({
        path: _path,
        tabName: _tabName
    })
}
