import { useState } from "react"
import Button from '@material-ui/core/Button'
import axios from 'axios'

import { methodColorDict, methodNameDict } from '../../../../../app/thurstonianDicts'
import { useMainOutput } from "../../../../../hooks/store/mainOutputHook";
import { usePlots } from "../../../../../hooks/store/plotsHook";
import { DecisionRuleChooserCheckbox } from "../../../../ui/DecisionRuleChoosers";
import NumericInput from "../../../../ui/NumericInput";
import { useAllOutput } from "../../../../../hooks/store/allOutputHook";
import { useErrorHandler } from "../../../../../utils/apiUtils";
import { nanoid } from "@reduxjs/toolkit";
import { useUpdateOutput } from "../../../../../hooks/store/useUpdateOutput";
import { useSelector } from "react-redux";

const api = process.env.REACT_APP_API_URL

const FCPowerDelta = ({ methods, setMethods, alpha, setAlpha, power, setPower, numTrials, setNumTrials }) => {
    
    const { checkAndPostError } = useErrorHandler()
        
    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)


    const [ isValidating, setIsValidating ] = useState(false)
    
    const onSubmit = (event) => {
        
        event.preventDefault()

        setIsValidating(true)
      
        if(!(methods && power && alpha && numTrials)) {
            return
        }
        
        setIsValidating(false)

        const methodsArray = Object.keys(Object.fromEntries(Object.entries(methods).filter(([key, value]) => value)))
        
        const apiData = {
            "function_group": "forced_choice_power",
            "function_name": "delta_calculation",
            "methods": methodsArray.join('|'),
            "power": Number(power),
            "alpha": Number(alpha),
            "num_trials": Number(numTrials)
        }
        
        axios
            .post(api, apiData)
            .then((response) => {
                
                if(checkAndPostError(response)) {
                    return
                }

                let tempTable = [['Method:', 'Delta:']]
                methodsArray.forEach((method) => {
                    tempTable.push([methodNameDict[method], response.data[method + '_delta']])
                })

                const title = 'Delta Calculation: ' + methodsArray.map(method => methodNameDict[method]).join(', ')
                const text = 'Alpha = ' + alpha.toFixed(3) + '   |   Power = ' + power.toFixed(2) + '   |   # Trials = ' + numTrials.toString()
                
                let tempPlotData = [
                    {
                        x: methodsArray.map((method) => methodNameDict[method]),
                        y: methodsArray.map(
                            (method) => response.data[method + '_delta']
                        ),
                        type: 'bar',
                        text: methodsArray
                            .map((method) => response.data[method + '_delta'])
                            .map((val) => val.toFixed(2)),
                        textposition: 'auto',
                        width: 0.3,
                        customColors: methodsArray.map(
                            (method) => methodColorDict[method]
                        ),
                        hideTrace: true,
                        showlegend: false,
                        hovertemplate: ' %{x}: %{y:.2f}<extra></extra>',
                    },
                ]

                
                const newPlotId = nanoid()
                const newOutputId = nanoid()
                const plotObject = {
                        OwnerId: userId,
                        PlotId: newPlotId,
                        Title: title,
                        Data: tempPlotData,
                        OutputReferences: [newOutputId]
                    }
                
                const outputObject = {
                    OwnerId: userId,
                    OutputId: newOutputId,
                    Title: title, 
                    Stream: [
                        { Type: 'plot', Content: newPlotId }
                    ],
                    Plots: [newPlotId],
                    ReferencingProjectIds: currentProjectId,
                }
                
                addOutput(outputObject, plotObject)
                  
            })
            .catch((error) => {
                console.log("error: ")
                console.log(error)
            })

    }

    return (
        <div id="FCPowerDeltaCalculationDialog">
            <form onSubmit={onSubmit}>
                    <DecisionRuleChooserCheckbox methodNameDict={methodNameDict} setValidatedValue={setMethods} isValidating={isValidating} defaultValue={methods} />
                    <NumericInput label="Alpha" id="alpha"
                        setValidatedValue={setAlpha}
                        defaultValue={ alpha ? alpha.toString() : '0.05' }
                        minValue='0' maxValue='1' 
                        errorMessage='Alpha must be between 0 and 1.'
                        isValidating={isValidating}
                    />
                    <NumericInput label="Power" id="power"
                        setValidatedValue={setPower}
                        defaultValue={ power ? power.toString() : ''}
                        minValue='0' maxValue='1' 
                        errorMessage='Power must be between 0 and 1.'
                        isValidating={isValidating}
                    />
                    <NumericInput label="# Trials" id="numTrials"
                        setValidatedValue={setNumTrials}
                        defaultValue={ numTrials ? numTrials.toString() : ''}
                        minValue='0' maxValue='100000000' 
                        errorMessage='# Trials must be greater than 0.'
                        isValidating={isValidating}
                    />
                <br />
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </form>
        </div>
    )
}

export default FCPowerDelta
