
export function calcCurveCoords(plotData) {
    if(!plotData) {
        return null
    }

    if(plotData.curveType === 'normal') {
        const [ x, y ] = makeNormalCurve(plotData.mean, plotData.sd, plotData.x_lb, plotData.x_ub)
        return({ x, y })
    }
}

export function GetPlotlyPairOfNormalsData(d_prime, name_1 = "X", name_2 = "Y", color_1 = "blue", color_2 = "red") {
    return([GetPlotlyNormalCurveData(d_prime, name_1, color_1, 1, d_prime + 4, -4), 
            GetPlotlyNormalCurveData(0, name_2, color_2, 1, d_prime + 4, -4)])
}

export function GetPlotlyNormalCurveData(mean, name, color, sd = 1, ub = mean + 4, lb = -4) {
    return({
        curveType: 'normal',
        x_lb: lb,
        x_ub: ub,
        mean: mean,
        sd: sd,
        type: 'line',
        color: color,
        name: name,
        showlegend: false,
        hoverinfo: 'none',
        //hovertemplate: 'Delta: %{x:.2f}  Prob.: %{y:.2f}',
    })
}

export function makeNormalCurve(mean, sd, lb = -4, ub = 4, numVals = 1000) {
    const x = range(lb, ub, (ub - lb) / (numVals - 1))
    const prefix = 1.0 / (sd * Math.sqrt(2 * Math.PI))
    const y = x.map(val => prefix * Math.exp(-0.5 * (val - mean) * (val - mean) / (sd * sd)))

    return [ x, y ]
}

export function calcNormalFunction(val, mean = 0, sd = 1) {
    const prefix = 1.0 / (sd * Math.sqrt(2 * Math.PI))
    return prefix * Math.exp(-0.5 * (val - mean) * (val - mean) / (sd * sd))
}

export function range(start, stop, step) { 
    return Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step))
}
