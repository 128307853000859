import { nanoid } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { addEntry, addReferencingProjectId, deleteEntry, updateComment, 
    toggleIsOpenComment, removeReferencingProjectId } from '../../features/allOutputSlice'
import { addOutputReference, removeOutputReference } from '../../features/plotsSlice'
import { useSession } from './sessionHook'
import { addOutputToProject, removeOutputFromProject } from '../../features/projectsSlice'
import { usePlots } from './plotsHook'
//import '../../services/dynamoDB'
//import { getAPITest, getAllUserOutput, putUserOutput } from '../../services/dynamoDB'

export function useAllOutput() {
    
    const dispatch = useDispatch()
    const { currentProjectId } = useSession()
    const { selectPlotById, removePlot } = usePlots()

    const _entries = useSelector(store => store.allOutput)

    const _addEntry = useCallback(
        (title, textContent, table, plots) => {
            const _id = nanoid()

            const plotsArray = plots ? (Array.isArray(plots) ? plots : [plots]) : []

            const newEntry = {
                Title: title,
                TextContent: textContent,
                Table: table,
                Plots: plotsArray,
                OutputId: _id,
                ReferencingProjectIds: [ currentProjectId ],
                Loading: false,
            };

            dispatch(
                addEntry(newEntry)
            )

            dispatch(
                addOutputToProject({
                    projectId: currentProjectId, 
                    outputId: _id
                })
            )

            plotsArray.forEach(plotId => {
                dispatch(
                    addOutputReference({
                        plotId: plotId,
                        outputId: _id,
                    })
                )
            })

            //putOutput(newEntry)
            // const test_data = getAllUserOutput()

            return _id
        },
        [dispatch, currentProjectId]
    )

    const _deleteEntry = useCallback((id) => {
            const theEntry = _entries.find(entry => entry.OutputId === id)
            
            if(theEntry) {
                if(theEntry.plots) {
                    theEntry.plots.forEach(onePlotId => {
                        dispatch(removeOutputReference({ plotId: onePlotId, outputId: id }))
                        const thePlot = selectPlotById(onePlotId)
                        if(thePlot && thePlot.OutputReferences.length === 1 && thePlot.OutputReferences[0] === id) {
                            removePlot(onePlotId)
                        }
                    })
                }
                if(theEntry.ReferencingProjectIds) {
                    theEntry.ReferencingProjectIds.forEach(projectId => removeOutputFromProject({ projectId, outputId: theEntry.OutputId }))
                }
            }

            dispatch(
                deleteEntry({
                    id: id
                })
            )
    }, [dispatch, _entries, removePlot, selectPlotById])

    const _updateComment = useCallback((outputId, newComment) => {
        
        dispatch(
            updateComment({
                outputId,
                newComment
            })
        )
    }, [dispatch])

    const _toggleIsOpenComment = useCallback((outputId) => {
        dispatch(toggleIsOpenComment(outputId))
    }, [dispatch])

    const _getEntry = useCallback((id) => {
        const entry =  _entries.find(entry => entry.OutputId === id)
        if(!entry) {
            //dispatch(fetchOutputById(id))
        }
        return(entry)
    }, [_entries, dispatch])

    const _addReferencingProjectId = useCallback((outputId, projectId) => {
        dispatch(addReferencingProjectId({
            outputId,
            projectId
        }))
    }, [dispatch])

    const _removeReferencingProjectId = useCallback((outputId, projectId) => {
        dispatch(removeReferencingProjectId({outputId, projectId}))
        const entry = _getEntry(outputId)
        if(entry && entry.ReferencingProjectIds?.length === 1 && entry.ReferencingProjectIds[0] === projectId) {
            _deleteEntry(outputId)
        }
    }, [dispatch, _getEntry, _deleteEntry])

    return {
        allEntries: _entries,
        addEntry: _addEntry,
        deleteEntry: _deleteEntry,
        updateComment: _updateComment,
        toggleIsOpenComment: _toggleIsOpenComment,
        getEntry: _getEntry,
        addReferencingProjectId: _addReferencingProjectId,
        removeReferencingProjectId: _removeReferencingProjectId,
    }
}
