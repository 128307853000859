import { useState } from "react"
import Button from '@material-ui/core/Button'
import axios from 'axios'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Typography } from "@material-ui/core";
import { Switch, Route, Link, Redirect } from 'react-router-dom'

import { useMainOutput } from "../../../hooks/store/mainOutputHook";
import NumericInput from "../../ui/NumericInput";
import { useTabRouter } from "../../../hooks/tabRouter"
import { useAllOutput } from "../../../hooks/store/allOutputHook"
import { useErrorHandler } from "../../../utils/apiUtils"
import { useUpdateOutput } from "../../../hooks/store/useUpdateOutput"
import { useSelector } from "react-redux"
import { GetPlotlyPairOfNormalsData } from "../../../utils/plotUtils"
import { nanoid } from "@reduxjs/toolkit"
import { roundPValue } from "../../../utils/dataUtils"
import { base64ToFloats } from "../../../utils"

const api = process.env.REACT_APP_API_URL

const SameDifferent = (props) => {

    const { path, tabName } = useTabRouter(props, ['delta', 'power'])

    return(
        <div id="sameDifferentDialog">
            <h2>Same Different</h2>
            <Tabs value={tabName} scrollButtons='auto' centered>
                <Tab label="Delta" value="delta" component={Link} to={`${path}/delta`} />
                <Tab label="Power" value="power" component={Link} to={`${path}/power`} />
            </Tabs>
            <br />
            <Switch>
                <Route path={`${path}/delta`} component={SameDifferentDelta} />
                <Route path={`${path}/power`} component={SameDifferentPower} />
                <Redirect from={`${path}`} to={`${path}/delta`} />
            </Switch>
        </div>
    )
}

export default SameDifferent

const SameDifferentDelta = () => {

    const [ sameSeenSameResponded, setASeenSameResponded ] = useState()
    const [ sameSeenDiffResponded, setASeenDiffResponded ] = useState()
    const [ diffSeenSameResponded, setNotASeenSameResponded ] = useState()
    const [ diffSeenDiffResponded, setNotASeenDiffResponded ] = useState()
    const [ isValidating, setIsValidating ] = useState(false)

    const { checkAndPostError } = useErrorHandler()
    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const onSubmit = (event) => {

        event.preventDefault()
        
        setIsValidating(true)

        if(!(sameSeenSameResponded || sameSeenSameResponded === 0) && (sameSeenDiffResponded || sameSeenDiffResponded === 0) && 
            (diffSeenSameResponded || diffSeenSameResponded === 0) && (diffSeenDiffResponded || diffSeenDiffResponded === 0)) {
                return
        }

        setIsValidating(false)

        const apiData = {
            function_group: 'degree_of_difference',
            num_prods: 2,
            num_cats: 2,
            alpha: 0.05,
            cat_counts: [ sameSeenDiffResponded, diffSeenDiffResponded,
                          sameSeenSameResponded, diffSeenSameResponded ]
        }

        axios
        .post(api, apiData)
        .then((response) => {

            if(checkAndPostError(response)) {
                return
            }

            const prodMeans = base64ToFloats(response.data.prod_means)
            const dPrimes = base64ToFloats(response.data.deltas)
            const compVars = base64ToFloats(response.data.comp_vars)
            const pValues = base64ToFloats(response.data.p_values)
            const exactPValues = base64ToFloats(response.data.exact_p_values)
            const scaleBounds = base64ToFloats(response.data.scale_bounds)
            const scaleVars = base64ToFloats(response.data.scale_vars)

            const numDecimals = 2

            const title = 'Same Different - Delta Calculation'
            const tableContent = [
                ["p-value", roundPValue(pValues[1])],
                ["d'", dPrimes[1].toFixed(numDecimals)],
                ["Variance of d'", compVars[1].toFixed(numDecimals)],
                ["Same N", sameSeenSameResponded + sameSeenDiffResponded ],
                ["Different N", diffSeenSameResponded + diffSeenDiffResponded ],
                ["Tau", scaleBounds[0].toFixed(numDecimals)], // TODO - Check if this should have an index of 1
                ["Variance of Tau", scaleVars[0].toFixed(numDecimals)], // TODO - Check if this should have an index of 1
            ]

            const tableId = nanoid()

            const table = {
                TableId: tableId,
                TableContent: tableContent
            }

            let tempPlotData = GetPlotlyPairOfNormalsData(dPrimes[1])

            const newPlotId = nanoid()
            const newOutputId = nanoid()
            const plotObject = { //addPlot({
                    OwnerId: userId,
                    PlotId: newPlotId,
                    Title: 'Applicability - Delta Estimation',
                    Data: tempPlotData,
                    OutputReferences: [newOutputId]
                }

            const outputObject = {
                OwnerId: userId,
                OutputId: newOutputId,
                Title: title, 
                Stream: [
                    { Type: 'table', Content: tableId },
                    { Type: 'plot', Content: newPlotId }
                ],
                Tables: [table], 
                Plots: [newPlotId],
                ReferencingProjectIds: currentProjectId,
            }
            
            addOutput(outputObject, plotObject)
            
        })
        .catch((error) => {
            console.log("error: ")
            console.log(error)
        })
    }

    return(
        <div id='sameDifferentDelta' style={{ display: 'flex', justifyContent: 'center' }}>
            <form onSubmit={onSubmit}>
                <div style={{ display: 'flex', flexFlow: 'column nowrap', alignItems: 'flex-end'}} >
                    <Typography style={{ marginRight: '8px', alignSelf: 'flex-start' }} variant='h6'>Same Seen: </Typography>
                    <div style={{ display: 'flex', flex: '1', flexFlow: 'row nowrap', alignItems: 'center', marginLeft: '16px'}} >
                        <div style={{ display: 'flex', flexFlow: 'column nowrap'}}>
                            <NumericInput label="Same Responded" setValidatedValue={setASeenSameResponded} isValidating={isValidating}
                                minValue='0' minInclusive errorMessage='Must be positive.' isInteger />
                            <NumericInput label="Different Responded" setValidatedValue={setASeenDiffResponded} isValidating={isValidating}
                                minValue='0' minInclusive errorMessage='Must be positive.' isInteger />
                        </div>
                    </div>
                    <div style={{ display: 'flex', borderBottom: '0px solid gray', alignSelf: 'stretch', margin: '12px 5px'}} />
                    <Typography style={{ marginRight: '8px', alignSelf: 'flex-start' }} variant='h6'>Different Seen: </Typography>
                    <div style={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', marginLeft: '16px'}}>
                        <div style={{ display: 'flex', flexFlow: 'column nowrap'}}>
                            <NumericInput label="Same Responded" setValidatedValue={setNotASeenSameResponded} isValidating={isValidating}
                                minValue='0' minInclusive errorMessage='Must be positive.' isInteger />
                            <NumericInput label="Different Responded" setValidatedValue={setNotASeenDiffResponded} isValidating={isValidating}
                                minValue='0' minInclusive errorMessage='Must be positive.' isInteger />
                        </div>
                    </div>
                </div>
                <br />
                    <Button
                        style={{ padding: 3 }}
                        variant="contained"
                        color="primary"
                        type="submit"
                    >
                        Run
                    </Button>
            </form>
        </div>
    )
}

const SameDifferentPower = () => {

    const [ probSameSame, setProbSameSame ] = useState()
    const [ delta, setDelta ] = useState()
    const [ alpha, setAlpha ] = useState()
    const [ power, setPower ] = useState()
    const [ isValidating, setIsValidating ] = useState(false)
    
    const { addEntry } = useAllOutput()
    const { addCard } = useMainOutput()
    const { checkAndPostError } = useErrorHandler()

    const onSubmit = (event) => {

        event.preventDefault()

        setIsValidating(true)

        if(!(probSameSame && delta && alpha && power)) {
            return
        }

        setIsValidating(false)


    }

    return (
        <div id="sameDifferentPower">
            <form onSubmit={onSubmit}>
                <NumericInput label="Prob. of Same | Same" setValidatedValue={setProbSameSame} isValidating={isValidating}
                    minValue='0' maxValue='1' errorMessage='Must be between 0 and 1.'/>
                <NumericInput label="Delta" setValidatedValue={setDelta} isValidating={isValidating}
                    minValue='0' errorMessage='Delta must be greater than 0.'/>
                <NumericInput label="Alpha" setValidatedValue={setAlpha} isValidating={isValidating}
                    minValue='0' maxValue='1' errorMessage='Alpha must be between 0 and 1.'/>
                <NumericInput label="Power" setValidatedValue={setPower} isValidating={isValidating}
                    minValue='0' maxValue='1' minInclusive errorMessage='Power must be between 0 and 1.'/>
                <br />
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </form>
        </div>

    )
}
