import { useState, useEffect, useCallback } from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Input from '@material-ui/core/Input'
import FormHelperText from '@material-ui/core/FormHelperText'
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
    root: {
        marginTop: '14px',
    },
    formLabel: {
        textAlign: 'left',
        color: 'black'
    },
})

const NumericInput = ({label, id, setValidatedValue, defaultValue='', minValue=undefined, maxValue=undefined, minInclusive=false, maxInclusive=false, 
    errorMessage='', forceLeadingZero=true, isInteger=false, isRequired=true, isValidating, disabled, style }) => {
    
    const classes = useStyles()

    const [ helperText, setHelperText ] = useState('')
    const [ value, setValue ] = useState(defaultValue)

    useEffect(() => {
        if(defaultValue) {
            setValidatedValue(Number(defaultValue))
        }
    }, [setValidatedValue, defaultValue])

    const handleNewHelperTest = useCallback((newText) => {
        
        setValidatedValue(null)

        if(newText && isValidating) {
            setHelperText(newText)
        } else {
            setHelperText('')
        }
    }, [setHelperText, setValidatedValue, isValidating])

    const handleBlur = useCallback((value, isValidating) => {

        // Remove the whitespace
        setValue(value.replace(/\s/g, ""))

        // Remove the error if the field is empty
        if(value === '') {
            if(isValidating && isRequired) {
                handleNewHelperTest('Please specify ' + label)
            } else {
                handleNewHelperTest('')
            }
            return
        }

        // Check that input is a number
        if(isNaN(value)) {
            handleNewHelperTest(label + ' must be a number.')
            return
        }

        const newValue = Number(value)
        
        // Add a leading 0 to decimals if user omitted it
        if(forceLeadingZero && !isInteger) {
            if(Math.abs(newValue) > 0 && Math.abs(newValue) < 1) {
                const firstNumber = value[0] === '-' ? value[1] : value[0]
                if(firstNumber !== '0') {
                    if(value[0] === '-') {
                        setValue('-0' + value.substr(1))
                    } else {
                        setValue('0' + value)
                    }
                }
            }
        }

        // check that input is an integer if it should be
        if(isInteger && !Number.isInteger(newValue)) {
            handleNewHelperTest(label + ' must be an integer.')
            return
        }

        // check that value meets the minValue if it exists
        if(!isNaN(minValue)) {
            if(newValue < Number(minValue)) {
                handleNewHelperTest(errorMessage)
                return
            } else if(!minInclusive && newValue <= Number(minValue)) {
                handleNewHelperTest(errorMessage)
                return
            }
        }

        // check that value meets the maxValue if it exists
        if(!isNaN(maxValue)) {
            if(newValue > Number(maxValue)) {
                handleNewHelperTest(errorMessage)
                return
            } else if(!maxInclusive && newValue >= Number(maxValue)) {
                handleNewHelperTest(errorMessage)
                return
            }
        }

        // Remove error message if value is within the bounds
        setHelperText('')
        setValidatedValue(Number(value))
        
    }, [handleNewHelperTest, setHelperText, setValidatedValue, minValue, maxValue, minInclusive, maxInclusive, errorMessage, forceLeadingZero, isInteger, isRequired, label])

    useEffect(() => {
        if(isValidating) {
            handleBlur(value, isValidating)
        }
    }, [isValidating, handleBlur, value])

    return(
        <div id='NumericInputDivWrapper' style={style}>
            {/* <TextField label={label} id={id} value={value} onChange={(event) => setValue(event.target.value) } 
                error={helperText !== ''} helperText={helperText} onBlur={handleBlur} style={{ marginTop: '4px' }} /> */}
            <FormControl error={helperText !== ''} className={classes.root} disabled={disabled}>
                <FormLabel htmlFor={id} className={classes.formLabel} >
                    {label}
                </FormLabel>
                <Input value={value} onChange={(event) => setValue(event.target.value) } id={id} onBlur={()=> handleBlur(value, isValidating)} style={{ marginTop: '0px' }} />
                <FormHelperText error>{helperText}</FormHelperText>
            </FormControl>
        </div>
    )
}

export default NumericInput
