import { useState } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import NumericInput from '../../ui/NumericInput';
import AutocompleteDropdown from '../../ui/AutocompleteDropdown';
import { useUpdateOutput } from '../../../hooks/store/useUpdateOutput';
import { useErrorHandler } from '../../../utils/apiUtils';
import { useDatasets } from '../../../hooks/useDatasets';
import { useSelector } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import axios from 'axios';

const api = process.env.REACT_APP_API_URL

const DPrimeTest = (props) => {

    const [ selectedTab, setSelectedTab ] = useState("manualEntry")

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue)
    }

    return(
        <div id="forcedChoicePowerDialog">
            <TabContext value={selectedTab} >
                <Tabs value={selectedTab} onChange={handleTabChange} scrollButtons='auto' centered>
                    <Tab label="Manual Entry" value="manualEntry" />
                    <Tab label="Dataset" value="datasetEntry"/>
                </Tabs>
                <TabPanel value="manualEntry">
                    <DPrimeTestManualEntry />
                </TabPanel>
                <TabPanel value="datasetEntry">
                    <DPrimeTestDatasetEntry />
                </TabPanel>
            </TabContext>
        </div>
    )
}

const DPrimeTestManualEntry = () => {

    const [ dPrime1, setDPrime1 ] = useState()
    const [ dPrime2, setDPrime2 ] = useState()
    const [ dPrime3, setDPrime3 ] = useState()
    const [ dPrimeVar1, setDPrimeVar1 ] = useState()
    const [ dPrimeVar2, setDPrimeVar2 ] = useState()
    const [ dPrimeVar3, setDPrimeVar3 ] = useState()
    const [ isValidating, setIsValidating ] = useState()

    const { addOutput } = useUpdateOutput()

    const { checkAndPostError } = useErrorHandler()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const onSubmit = (event) => {
        
        event.preventDefault()

        setIsValidating(true)

        if((dPrime1 && !dPrimeVar1) || (!dPrime1 && dPrimeVar1) || (dPrime2 && !dPrimeVar2) || (!dPrime2 && dPrimeVar2) || (dPrime3 && !dPrimeVar3) || (!dPrime3 && dPrimeVar3)) {
            return
        }

        if((dPrime1 && !dPrime2 && !dPrime3) || (!dPrime1 && dPrime2 && !dPrime3) || (!dPrime1 && !dPrime2 && dPrime3) || (!dPrime1 && !dPrime2 && !dPrime3)) {
            return
        }
        
        let dPrimes = [dPrime1, dPrime2, dPrime3]
        let variances = [dPrimeVar1, dPrimeVar2, dPrimeVar3]

        dPrimes = dPrimes.filter(val => val)
        variances = variances.filter(val => val)

        const apiData = {
            function_group: 'statistical_tests',
            function_name: 'd_prime_test',
            d_primes: dPrimes,
            variances: variances,
            num_values: dPrimes.length,
            alpha: 0.05,
        }

        axios
        .post(api, apiData)
        .then((response) => {

            if(checkAndPostError(response)) {
                return
            }

            const title = "d' Test"
            const tableContent = [
                ["Chi-squared Value", response.data.chi_squared.toFixed(3)],
                ["Critical Value", response.data.critical_value.toFixed(3)],
                ["p-Value", response.data.p_value.toFixed(3)],
            ]

            const newOutputId = nanoid()
            const tableId = nanoid()

            const table = {
                TableId: tableId,
                TableContent: tableContent
            }
            
            const outputObject = {
                OwnerId: userId,
                OutputId: newOutputId,
                Title: title, 
                Stream: [
                    { Type: 'table', Content: tableId },
                ],
                Tables: [table], 
                ReferencingProjectIds: currentProjectId,
            }
            
            addOutput(outputObject)
            
        })
        .catch((error) => {
            console.log("error: ")
            console.log(error)
        })

        setIsValidating(false)
    }

    return(
        <div id="dPrimeTestManualEntryDialog">
        <form onSubmit={onSubmit}>
            {/* <TableContainer component={Paper} style={{display: 'inline-block', width: 'auto'}}> */}
                <Table style={{ width: 'auto', tableLayout: 'auto',  height: 'auto', margin: 'auto'}} size='small' >
                    <TableBody>
                        <TableRow sx={{border: '0px'}}>
                            <TableCell sx={{border: '0px'}}><label style={{display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>d' Values:</label></TableCell>
                            <TableCell sx={{border: '0px'}}><NumericInput label=" " setValidatedValue={setDPrime1} isValidating={isValidating} /></TableCell>
                            <TableCell sx={{border: '0px'}}><NumericInput label=" " setValidatedValue={setDPrime2} isValidating={isValidating} /></TableCell>
                            <TableCell sx={{border: '0px'}}><NumericInput label="" setValidatedValue={setDPrime3} isValidating={isValidating} /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{border: '0px'}}><label style={{display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>d' Variances:</label></TableCell>
                            <TableCell sx={{border: '0px'}}><NumericInput label=" " setValidatedValue={setDPrimeVar1} isValidating={isValidating} /></TableCell>
                            <TableCell sx={{border: '0px'}}><NumericInput label=" " setValidatedValue={setDPrimeVar2} isValidating={isValidating} /></TableCell>
                            <TableCell sx={{border: '0px'}}><NumericInput label="" setValidatedValue={setDPrimeVar3} isValidating={isValidating} /></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            {/* </TableContainer> */}
            <br/>
            <Button
                style={{ padding: 3 }}
                variant="contained"
                color="primary"
                type="submit"
            >
                Run
            </Button>
        </form>
    </div>
    )

}


const DPrimeTestDatasetEntry = () => {

    const { selectedDataset, setSelectedDataset, datasetData, datasetOptionsList, getDatasetSize } = useDatasets()

    const { checkAndPostError } = useErrorHandler()

    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)


    const onSubmit = (event) => {

        event.preventDefault()
    }

    return(
        <div id='dPrimeDatasetEntryDialog'>
        <form onSubmit={onSubmit}>
            <AutocompleteDropdown 
                label="Choose dataset:"
                variant="outlined"
                options={datasetOptionsList} 
                value={selectedDataset} setValue={setSelectedDataset}
            />
            <br />
            <br />
            <Button
                style={{ padding: 3 }}
                variant="contained"
                color="primary"
                type="submit"
            >
                Run
            </Button>
        </form>
    </div>
    )   
}

export default DPrimeTest
