import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import TabPanel from '@material-ui/lab/TabPanel'
import TabContext from '@material-ui/lab/TabContext'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import { DecisionRuleChooserRadio } from '../../ui/DecisionRuleChoosers'
import NumericInput from '../../ui/NumericInput'
import { methodNameDict } from '../../../app/thurstonianDicts'
import { makeStyles } from '@material-ui/core/styles'
import { useErrorHandler } from '../../../utils/apiUtils'
import { useUpdateOutput } from '../../../hooks/store/useUpdateOutput'
import { nanoid } from '@reduxjs/toolkit'
import { roundPValue, transposeArray, oneToTwoDimensions } from '../../../utils/dataUtils'
import { range } from '../../../utils/plotUtils'
import { base64ToFloats } from '../../../utils'
import { useDatasets } from '../../../hooks/useDatasets'
import AutocompleteDropdown from '../../ui/AutocompleteDropdown'

const api = process.env.REACT_APP_API_URL

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));

const BetaBinomial = () => {

    const [ selectedTab, setSelectedTab ] = useState('bbAnalysis')

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue)
    }

    return (
        <div id="betaBinomialDialog">
        <TabContext value={selectedTab}>
            <Tabs value={selectedTab} onChange={handleTabChange} scrollButtons='auto' centered>
                <Tab label="Beta-Binomial Analysis" value="bbAnalysis" />
                <Tab label="Beta-Binomial Power" value="bbPower"/>
            </Tabs>
            <TabPanel value="bbAnalysis">
               <BetaBinomialAnalysis />
            </TabPanel>
            <TabPanel value="bbPower">
                <BetaBinomialPower />
            </TabPanel>
        </TabContext>
    </div>
    )
}

const BetaBinomialAnalysis = (props) => {
    
    const [ method, setMethod ] = useState()
    const [ maxReps, setMaxReps ] = useState()
    const [ tails, setTails ] = useState()
    const [ isValidating, setIsValidating ] = useState()
    const [ referenceProduct, setReferenceProduct ] = useState("");
    const [ referenceProductError, setReferenceProductError ] = useState(false);
    
    const { selectedDataset, setSelectedDataset, selectedDatasetData, datasetData, datasetOptionsList, datasetError, getDatasetSize } = useDatasets()

    const { checkAndPostError } = useErrorHandler()
    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const handleSubmit = (event) => {
        
        event.preventDefault()

        if(!datasetData || !getDatasetSize) {
            return
        }

        const { numRows, numCols } = getDatasetSize()
        
        let numChoices = -1
        if(method === "twoAFC" || method === "duoTrio" || method === "dualPair") {
            numChoices = 2
        } else if(method === "threeAFC" || method === "triangle" || method === "tetrad" || method === "specifiedTetrad") {
            numChoices = 3
        }

        setMaxReps(numCols - 1)

        const equalReps = numRows === 2

        let numCorrMatrix
        if(equalReps) {
            numCorrMatrix = datasetData.slice(1)[0].map(a => Array(numCols - 2).fill(0).concat(Number(a))).flat()
        } else {
            numCorrMatrix = transposeArray(datasetData.slice(1)).flat().map(a => Number(a)).map(a => isNaN(a) ? 0 : a)
        }

        const data = {
            function_group: 'beta_binomial',
            function_name: 'beta_binomial',
            max_reps: numCols - 1,
            num_choices: numChoices,
            num_correct_matrix: numCorrMatrix
        }
        axios
            .post(api, data)
            .then((response) => {

                if(checkAndPostError(response)) {
                    return
                }
        
                let tempPlotData
                let layout
                if(equalReps) {
                    const graphVals = transposeArray(oneToTwoDimensions(base64ToFloats(response.data.graph_vals), 3))
        
                    const maxHeight = Math.max(...graphVals.flat())

                    tempPlotData = [
                        {
                            x: range(0, maxReps + 1, 1),
                            y: graphVals[0],
                            type: 'bar',
                            // text: methodsArray
                            //     .map((method) => response.data[method + '_sample_size'])
                            //     .map((val) => val.toFixed(0)),
                            // textposition: 'auto',
                            width: 0.3,
                            marker: {color: 'rgb(0, 128, 255)'},
                            // customColors: methodsArray.map(
                            //     (method) => methodColorDict[method]
                            // ),
                            hideTrace: true,
                            showlegend: false,
                            hoverinfo: 'y',
                        },
                        {
                            x: range(0, maxReps + 1, 1),
                            y: graphVals[1],
                            type: 'bar',
                            width: 0.3,
                            marker: {color: 'red'}, //'rgb(255, 24, 7)'
                            hideTrace: true,
                            showlegend: false,
                            yaxis: 'y2',
                            xaxis: 'x2',
                            hoverinfo: 'y',
                        },
                        {
                            x: range(0, maxReps + 1, 1),
                            y: graphVals[2],
                            type: 'bar',
                            width: 0.3,
                            marker: {color: 'rgb(46, 139, 87)'},
                            hideTrace: true,
                            showlegend: false,
                            yaxis: 'y3',
                            xaxis: 'x3',
                            hoverinfo: 'y',
                        }
                    ]

                    layout = {
                        yaxis: { showticklabels: true, zeroline: true },
                        yaxis1: { showticklabels: true, zeroline: true },
                        yaxis2: { showticklabels: true, zeroline: true },
                        xaxis: { showticklabels: true, zeroline: false },
                        annotations: [{
                            text: "Observed Proportion",
                            font: {
                            size: 16,
                            color: 'black',
                            },
                            showarrow: false,
                            xanchor: 'left',
                            x: -0.5, //position in x domain
                            y: 1.1 * maxHeight, //position in y domain
                            xref: 'x1',
                            yref: 'y1',
                        },
                            {
                            text: "Proportion Predicted by Binomial",
                            font: {
                            size: 16,
                            color: 'black',
                            },
                            showarrow: false,
                            xanchor: 'left',
                            x: -0.5, //position in x domain
                            y: 1.1 * maxHeight,  // position in y domain
                            xref: 'x2',
                            yref: 'y2',
                            },
                            {
                                text: "Proportion Predicted by Beta-Binomial",
                                font: {
                                size: 16,
                                color: 'black',
                            },
                            showarrow: false,
                            xanchor: 'left',
                            x: -0.5, //position in x domain
                            y: 1.1 * maxHeight,  // position in y domain
                            xref: 'x3',
                            yref: 'y3',
                            }
                        ],
                        grid: { rows: 3, 
                                columns: 1, 
                                pattern: 'independent', 
                                roworder: 'top to bottom',
                                //subplots: [['xy'], ['xy2'], ['xy3']]
                            },
                    }
                }

                const newPlotId = nanoid()      
                const newOutputId = nanoid()
                const tableId = nanoid()

                const title = 'Beta Binomial Analysis: ' + selectedDatasetData.Name
                const tableContent = [
                    ["Null Probability", 0.5 ],
                    [ equalReps ? "# of Replications" : "Max. # of Replications", numCols - 1 ],
                    ["Choice Proportion", response.data.prop_corr.toFixed(3)],
                    ["Estimated Gamma Value", response.data.gamma.toFixed(3)],
                    ["BB vs Binomial p-Value", roundPValue(response.data.bb_vs_binom_prob)],
                    ["1-sided p-Value", roundPValue(response.data.mu_prob)],
                ]

                const table = {
                    TableId: tableId,
                    Title: equalReps ? 'Equal Replications' : 'Unequal Replications',
                    TableContent: tableContent,
                    OptionsList: { ColumnHeaders: false },
                }
                                
                const plotObject = {
                    OwnerId: userId,
                    PlotId: newPlotId,
                    Title: title,
                    Data: tempPlotData,
                    OutputReferences: [newOutputId],
                    Layout: layout,
                }

                let stream
                if(equalReps) {
                    stream = [
                        // { Type: 'text', Content: numRows > 2 ? "Unequal replications" : "Equal replications" },
                        { Type: 'table', Content: tableId },
                        { Type: 'plot', Content: newPlotId, HeightMultiplier: 0.5 },
                    ]
                } else {
                    stream = [{ Type: 'table', Content: tableId }]
                }
    
                const outputObject = {
                        OwnerId: userId,
                        OutputId: newOutputId,
                        Title: title, 
                        Stream: stream,
                        Tables: [ table ], 
                        // Plots: [ newPlotId ],
                        ReferencingProjectIds: currentProjectId,
                    }
                
                addOutput(outputObject, plotObject)
            })
            .catch((error) => {
                console.log('error: ')
                console.log(error)
            })
    }
    
    return (
        <div id="betaBinomialAnalysis" style={{ width: 'max-content', margin: '0 auto' }} >
            {/* <h4 style={{ textAlign: 'left' }}>Beta-Binomial</h4> */}
            <form onSubmit={handleSubmit}>
                <div style={{ display: 'flex', flexFlow: 'column nowrap', width: 'max-content', alignItems: 'center' }}>
                    <AutocompleteDropdown options={datasetOptionsList ?? []}
                        value={selectedDataset} setValue={setSelectedDataset} label="Choose dataset:" variant="outlined" 
                        getOptionLabel={option => option.label ? option.label : ""}/>
                    {/* <NumericInput label="Max. Repetitions" setValidatedValue={setMaxReps} isValidating={isValidating}
                        minValue='1' errorMessage='Max. Repetitions must be greater than 1' /> */}
                    <br style={{margin: '12px'}}/>
                    <DecisionRuleChooserRadio methodNameDict={methodNameDict} setValidatedValue={setMethod} isValidating={isValidating} />
                    <br style={{margin: '12px'}}/>
                    <RadioGroup
                        style={{ display: 'flex', alignItems: 'center' }}
                        aria-label="method"
                        name="method"
                        value={tails}
                        onChange={(event, newValue) => { setTails(newValue)}}
                    >
                        <div id='radioGroupContainer' style={{ display: 'flex', width: 'max-content', flexFlow: 'column nowrap', alignItems: 'center' }}>
                            <FormLabel style={{ color: 'black', alignSelf: 'start' }}>Tails</FormLabel>
                            <FormControlLabel value='oneSided' control={<Radio />} label = "1-Sided" />
                            <FormControlLabel value='twoSided' control={<Radio />} label = "2-Sided" />    
                        </div>
                    </RadioGroup>
                    <br style={{margin: '12px'}}/>
                    <Button
                        style={{ padding: 3 }}
                        variant="contained"
                        color="primary"
                        type="submit"
                    >
                        Run
                    </Button>
                </div>
            </form>
        </div>
    )
}

const BetaBinomialPower = () => {

    const [ activeStep, setActiveStep ] = useState(0)
    const [ method, setMethod ] = useState()
    const [ numReps, setNumReps ] = useState()
    const [ numTrials, setNumTrials ] = useState()
    const [ alpha, setAlpha ] = useState()
    const [ isUsingDelta, setIsUsingDelta ] = useState('yes')
    const [ delta, setDelta ] = useState()
    const [ altProb, setAltProb ] = useState()
    const [ gamma, setGamma ] = useState()
    const [ isValidating, setIsValidating ] = useState()

    const classes = useStyles()

    const { checkAndPostError } = useErrorHandler()
    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    function getSteps() {
        return ['Select method', 'Specify parameters'];
      }
      
    function getStepContent(step) {
        switch (step) {
            case 0:
                return(
                    <DecisionRuleChooserRadio
                    methodNameDict={methodNameDict}
                    setValidatedValue={setMethod}
                    isValidating={isValidating}
                    defaultValue={method}
                    />
                )
            case 1:
                return(
                    <>
                        <NumericInput
                            setValidatedValue={setNumReps}
                            isValidating={isValidating}
                            label="# of Replications"
                            minValue="0"
                            errorMessage="# of Replications must be greater than 0."
                        />
                        <NumericInput
                            setValidatedValue={setNumTrials}
                            isValidating={isValidating}
                            label="# of Trials"
                            minValue="0"
                            errorMessage="# of Trials must be greater than 0."
                        />
                        <NumericInput
                            setValidatedValue={setAlpha}
                            isValidating={isValidating}
                            label="Alpha"
                            minValue="0"
                            maxValue="1"
                            errorMessage="Alpha must be between 0 and 1."
                        />
                        <br />
                        <RadioGroup
                            style={{ display: 'flex', alignItems: 'center' }}
                            aria-label="method"
                            name="deltaVsAltProb"
                            value={isUsingDelta}
                            onChange={(event, newValue) => {
                                setIsUsingDelta(newValue)
                            }}
                        >
                            <div
                                id="radioGroupContainer"
                                style={{
                                    display: 'flex',
                                    width: 'max-content',
                                    flexFlow: 'column nowrap',
                                    alignItems: 'left',
                                }}
                            >
                                <div
                                    style={{ display: 'flex', flexFlow: 'row nowrap' }}
                                >
                                    <FormControlLabel
                                        value={'yes'}
                                        control={<Radio />}
                                        label="Delta"
                                    />
                                    <div style={{ alignItems: 'end' }}>
                                        <NumericInput
                                            disabled={isUsingDelta !== 'yes'}
                                            setValidatedValue={setDelta}
                                            isValidating={isValidating}
                                            label=""
                                            minValue="0"
                                            errorMessage="Delta must be greater than 0."
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{ display: 'flex', flexFlow: 'row nowrap' }}
                                >
                                    <FormControlLabel
                                        value={'no'}
                                        control={<Radio />}
                                        label="Alternative Prob."
                                    />
                                    <NumericInput
                                        disabled={isUsingDelta !== 'no'}
                                        style={{ alignSelf: 'end' }}
                                        setValidatedValue={setAltProb}
                                        isValidating={isValidating}
                                        label=""
                                        minValue="0"
                                        maxValue="1"
                                        errorMessage="Alt. Prob. must be between 0 and 1."
                                    />
                                </div>
                            </div>
                        </RadioGroup>
                        <br />
                        <NumericInput
                            setValidatedValue={setGamma}
                            isValidating={isValidating}
                            label="Gamma"
                            minValue="0"
                            maxValue="1"
                            errorMessage="Gamma must be between 0 and 1."
                        />
                    </>)
                default:
                    return null
        }
    }

    const handleNext = () => {

        if(activeStep === 0) {
            if(!method) {
                setIsValidating(true)
                return
            }
        }

        setIsValidating(false)

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const onSubmit = (event) => {
        
        event.preventDefault()

        let nullProb = -1

        if(method === 'twoAFC' || method === 'duoTrio' || method === 'dualPair') {
            nullProb = 1.0/2
        } else if(method === 'triangle' || method === 'tetrad' || method === 'threeAFC') {
            nullProb = 1.0/3
        } else if(method === 'specifiedTetrad') {
            nullProb = 1.0/6
        } else {
            nullProb = -1
            return // Error - Shouldn't be possible
        }
        
        let data = {
            function_group: 'beta_binomial',
            function_name: 'beta_binomial_power',
            null_prob: nullProb,
            use_delta: isUsingDelta ==='yes' ? true : false,
            method: method,
            delta: isUsingDelta ==='yes'  ? delta : -1,
            alt_prob: isUsingDelta ==='yes'  ? -1 : altProb,
            gamma: gamma,
            num_reps: numReps,
            num_trials: numTrials,
            alpha: alpha,
            one_sided: true
        }

        axios
            .post(api, data)
            .then((response) => {

                if(checkAndPostError(response)) {
                    return
                }
    
                const title = 'Beta Binomial - Power Analysis'
                const tableContent = [
                    ["Null probability", nullProb.toFixed(2)],
                    ["Delta", isUsingDelta === 'yes' ? delta.toFixed(2) : response.data.delta.toFixed(2) ],
                    ["Alternative probability", isUsingDelta === 'yes' ? response.data.alt_prob.toFixed(2) : altProb.toFixed(2)],
                    ["Gamma", gamma.toFixed(2)],
                    ["Number of replications", numReps],
                    ["Number of trials", numTrials],
                    ["Alpha", alpha.toFixed(3)],
                    ["Hypothesis", "One-sided"],
                    ["Power", response.data.power.toFixed(3) ]
                ]

                const tableId = nanoid()

                const table = {
                    TableId: tableId,
                    TableContent: tableContent
                }

                const newOutputId = nanoid()
    
                const outputObject = {
                        OwnerId: userId,
                        OutputId: newOutputId,
                        Title: title, 
                        Stream: [
                            { Type: 'table', Content: tableId },
                        ],
                        Tables: [ table ], 
                        Plots: [],
                        ReferencingProjectIds: currentProjectId,
                    }
                
                addOutput(outputObject)
            })
            .catch((error) => {
                console.log('error: ')
                console.log(error)
            })
    }

    return (
        <div
            id="betaBinomialAnalysis"
            style={{ width: 'max-content', margin: '0 auto' }}
        >
            <Stepper activeStep={activeStep}>
                {getSteps().map((label, index) => {
                    const stepProps = {}
                    const labelProps = {}

                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
            <form onSubmit={onSubmit}>
                <div>
                    {
                        <div>
                            { getStepContent(activeStep)}
                            <div>
                                <br />
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.button}
                                >
                                    Back
                                </Button>
                                {activeStep < getSteps().length - 1 ? (<Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                >Next</Button>) : <Button
                                style={{ padding: 3 }}
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Run
                            </Button> }
                                    
                            </div>
                        </div>
                    }
                </div>
            </form>
        </div>
    )

}

export default BetaBinomial
