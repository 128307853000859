import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
//import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import ListIcon from '@material-ui/icons/List'
import DeleteIcon from '@material-ui/icons/Delete'

import { useSession } from '../../hooks/store/sessionHook';
import { AddProjectModal } from '../dialogs/projects/AddProject';
import { SwitchProjectsModal } from '../dialogs/projects/SwitchProjects';
import { useGetProjectByIdQuery } from '../../services/databaseApi';
import { skipToken } from '@reduxjs/toolkit/query'

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    }
}));

const ProjectMenuButton = (props) => {

    const { currentProjectId, setCurrentProjectId, defaultProjectId } = useSession()

    const { data: projectData,
        isLoading: isProjectLoading,
        isSuccess: isProjectSuccess }  = useGetProjectByIdQuery(currentProjectId ?? skipToken)

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [ isOpenAddProject, setIsOpenAddProject ] = useState(false)
    const [ isOpenSwitchProjects, setIsOpenSwitchProjects ] = useState(false)
    const [ projectName, setProjectName ] = useState("Loading...")

    useEffect(() => {
        //const newProject = getProjectById(currentProjectId)
        if(isProjectLoading) {
            setProjectName("Loading...")
        } else if(projectData) {
            setProjectName(projectData.Name)
        } else {
            setProjectName("Error loading project")
        }
    }, [projectData, isProjectLoading, isProjectSuccess])

    const open = Boolean(anchorEl);
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    }

    const openNewProjectDialog = (event) => {
        handleClose()
        setIsOpenAddProject(true)
    }
    const handleAddProjectClose = () => {
        setIsOpenAddProject(false)
    }

    const openSwitchProjectsDialog = (event) => {
        handleClose()
        setIsOpenSwitchProjects(true)
    }
    const handleSwitchProjectsClose = () => {
        setIsOpenSwitchProjects(false)
    }

    const handleCloseProject = () => {
        handleClose()
        setCurrentProjectId(defaultProjectId)
    }

    return (
        <div >
            <Button 
                style={{ minWidth: '60px', maxWidth: '400px', justifyContent: 'start' }}
                color="secondary" 
                variant="contained" 
                onClick={handleMenu}
                className={classes.menuButton} 
                startIcon={<AccountTreeIcon />}
                aria-controls="menu-project"
                aria-haspopup="true"
            >
                    {projectName}
            </Button>
            <Menu 
                id="menu-project" 
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
            >
                <MenuItem onClick={openNewProjectDialog}>
                    <AddIcon className={classes.icon} />
                    <p>Add new project</p>
                </MenuItem>
                <MenuItem onClick={openSwitchProjectsDialog}>
                    <SwapHorizIcon className={classes.icon} />
                    <p>Switch project</p>
                </MenuItem>
                <MenuItem onClick={handleCloseProject}>
                    <CloseIcon className={classes.icon} />
                    <p>Close project</p>
                </MenuItem>
                <MenuItem component={Link} to='/projects'>
                  <ListIcon className={classes.icon} />
                  <p>Manage projects</p>
                </MenuItem>
                <MenuItem >
                    <DeleteIcon className={classes.icon} />
                    <p>Delete project</p>
                </MenuItem>
            </Menu>
            <AddProjectModal open={isOpenAddProject} onClose={handleAddProjectClose} switchProjectsOnAdd />
            <SwitchProjectsModal open={isOpenSwitchProjects} onClose={handleSwitchProjectsClose} />
        </div>
    )
}

export default ProjectMenuButton
