import { useState } from "react"
import Button from '@material-ui/core/Button'
import axios from 'axios'
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import NumericInput from "../../ui/NumericInput";
import { useUpdateOutput } from "../../../hooks/store/useUpdateOutput";
import { useErrorHandler } from "../../../utils/apiUtils";
import { nanoid } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const api = process.env.REACT_APP_API_URL

const BinomialTest = (props) => {

    const [ numSuccesses, setNumSuccesses ] = useState()
    const [ numTotal, setNumTotal ] = useState()
    const [ prob, setProb ] = useState(0.5)
    const [ numTails, setNumTails ] = useState('oneTailedLower')
    const [ isValidating, setIsValidating ] = useState(false)

    const { addOutput } = useUpdateOutput()

    const { checkAndPostError } = useErrorHandler()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const onSubmit = (event) => {

        event.preventDefault()

        setIsValidating(true)

        if(!(numSuccesses && numTotal && prob)) {
            return
        }

        if(numSuccesses > numTotal) {
            return
        }

        if(prob > 1.0 || prob < 0.0) {
            return
        }

        setIsValidating(false)
        
        const apiData = {
            function_group: 'statistical_tests',
            function_name: 'binomial_test',
            num_successes: numSuccesses,
            num_total: numTotal,
            prob: prob,
            two_tailed: numTails === 'twoTailed' ? true : false,
        }

        axios
        .post(api, apiData)
        .then((response) => {

            if(checkAndPostError(response)) {
                return
            }

            if(numSuccesses > numTotal) {
                return //TODO - Error message
            }

            const title = 'Binomial Test'
            const tableContent = [
                ["# Successes", numSuccesses],
                ["# Total", numTotal],
                ["Direction", numTails === 'twoTailed' ? "Two-tailed" : "One-tailed"],
                ["Test P(Success)", prob.toFixed(3)],
                ["Sample P(Success)", (numSuccesses / numTotal).toFixed(3)],
                ["p-Value", response.data.p_value.toFixed(3)],
            ]

            const newOutputId = nanoid()
            const tableId = nanoid()

            const table = {
                TableId: tableId,
                TableContent: tableContent
            }
            
            const outputObject = {
                OwnerId: userId,
                OutputId: newOutputId,
                Title: title, 
                Stream: [
                    { Type: 'table', Content: tableId },
                ],
                Tables: [table], 
                ReferencingProjectIds: currentProjectId,
            }
            
            addOutput(outputObject)
            
        })
        .catch((error) => {
            console.log("error: ")
            console.log(error)
        })
    }

    return (
        <div id="consumerRelevantConfidenceDialog">
            <form onSubmit={onSubmit}>
                <NumericInput label="# Successes" setValidatedValue={setNumSuccesses} isValidating={isValidating}
                    minValue='1' errorMessage='Must be greater than 0.' isInteger={true}/>
                <NumericInput label="# Total" setValidatedValue={setNumTotal} isValidating={isValidating}
                    minValue='1' errorMessage="Must be greater than 0." isInteger={true}/>
                <NumericInput label="Hypothesized probability" setValidatedValue={setProb} isValidating={isValidating} defaultValue='0.5'
                    minValue='0' minInclusive={true} maxValue='1' maxInclude={true} errorMessage='Must be between 0 and 1.'/>
                <br />
                <RadioGroup
                        style={{ display: 'flex', alignItems: 'center' }}
                        aria-label="method"
                        name="method"
                        value={numTails}
                        onChange={(event, newValue) => { setNumTails(newValue)}}
                    >
                        <div id='radioGroupContainer' style={{ display: 'flex', width: 'max-content', flexFlow: 'column nowrap', alignItems: 'start' }}>
                            <FormLabel style={{ color: 'black', alignSelf: 'left' }}>Direction</FormLabel>
                            <FormControlLabel value='oneTailedLower' control={<Radio />} label = "One-tailed (Lower)" />    
                            <FormControlLabel value='oneTailedGreater' control={<Radio />} label = "One-tailed (Greater)" />    
                            <FormControlLabel value='twoTailed' control={<Radio />} label = "Two-tailed" />
                        </div>
                    </RadioGroup>
                <br />
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </form>
        </div>

    )
}

export default BinomialTest
