import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import axios from 'axios'

import AutocompleteDropdown from '../../../ui/AutocompleteDropdown'

import { useErrorHandler } from '../../../../utils/apiUtils'
import { useGetFoldersByProjectIdQuery, useGetDatasetByIdQuery } from '../../../../services/databaseApi'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useSelector } from 'react-redux'
// import { TextField } from '@material-ui/core'
import { base64ToFloats, getDefaultColorByIndex } from '../../../../utils'
import { roundPValue } from '../../../../utils/dataUtils'
import { nanoid } from '@reduxjs/toolkit'
import { useUpdateOutput } from '../../../../hooks/store/useUpdateOutput'

const api = process.env.REACT_APP_API_URL

const MultipleTwoAFC = (props) => {

    const [ selectedDataset, setSelectedDataset ] = useState(null);
    const [ selectedDatasetId, setSelectedDatasetId ] = useState(null);
    const [ datasetOptions, setDatasetOptions ] = useState([])
    const [ datasetData, setDatasetData ] = useState(null)
    const [ prodNames, setProdNames ] = useState([])
    const [ referenceProduct, setReferenceProduct ] = useState("");
    const [ datasetError, setDatasetError ] = useState(false);
    const [ referenceProductError, setReferenceProductError ] = useState(false);
    
    const { checkAndPostError } = useErrorHandler()

    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)
    
    const { data: dataFolders,
        isLoading: isDataFoldersLoading,
        isSuccess: isDataFoldersSuccess } = useGetFoldersByProjectIdQuery(currentProjectId ?? skipToken)

    const { data: selectedDatasetData,
        isLoading: isSelectedDatasetDataLoading,
        isSuccess: isSelectedDatasetDataSuccess } = useGetDatasetByIdQuery(selectedDatasetId ?? skipToken)

    useEffect(() => {
        if(dataFolders) {
            setDatasetOptions(dataFolders.map(dataFolderInfo => dataFolderInfo.Datasets.map(datasetInfo => ({ label: datasetInfo.Name, id: datasetInfo.DatasetId }))).flat())
        } else {
            setDatasetOptions([])
            setSelectedDataset(null)
            setSelectedDatasetId(null)
            setDatasetData(null)
        }
    }, [dataFolders, isDataFoldersLoading, isDataFoldersSuccess])

    useEffect(() => {
        if(selectedDatasetData && selectedDatasetData.Data) {
            const datasetData = JSON.parse(selectedDatasetData.Data)
            if(datasetData[0].length < 4) {
                setDatasetError(true)
            } else {
                setDatasetError(false)
                setDatasetData(datasetData)
                setProdNames([...new Set([datasetData.map(row => row[0]), datasetData.map(row => row[1])].flat())].map((element, index) => ({ label: element, id: index })))
            }
            setReferenceProduct("")
        } else {
            setProdNames([])
            setReferenceProduct("")
            setDatasetData(null)
        }
    }, [selectedDatasetData, isSelectedDatasetDataLoading, isSelectedDatasetDataSuccess, selectedDatasetId])

    const handleSetSelectedDataset = (newSelectedDataset) => {
        setSelectedDataset(newSelectedDataset)
        if(newSelectedDataset && newSelectedDataset.id) {
            setSelectedDatasetId(newSelectedDataset.id)
        }
    }

    const onSubmit = (event) => {
 
        event.preventDefault()
        
        setDatasetError(false)
        setReferenceProductError(false)

        if(!(selectedDatasetData && referenceProduct)) {
            if(!selectedDatasetData) {
                setDatasetError(true)
            }
            if(!referenceProduct) {
                setReferenceProductError(true)
            }
            return;
        }       

        let num_comps = datasetData.map(row => row[0]).length;
        let num_prods = prodNames.length;
        let prod_names_list = prodNames.map(obj => obj.label)
        const swapIndex = prod_names_list.indexOf(referenceProduct.label)
        prod_names_list[swapIndex] = prod_names_list[0]
        prod_names_list[0] = referenceProduct.label

        let comps = [ datasetData.map(row => row[0]).map(prod => prod_names_list.indexOf(prod) + 1), datasetData.map(row => row[1]).map(prod => prod_names_list.indexOf(prod) + 1)].flat().map(val => parseFloat(val))

        let data = {
            "function_group": "multiple_2afc",
            "num_prods": num_prods,
            "num_comps": num_comps,
            "comps": comps,
            "num_correct": datasetData.map(row => parseFloat(row[2])),
            "num_total": datasetData.map(row => parseFloat(row[3]))
        }

        axios
            .post(api, data)
            .then((response) => {

                if(checkAndPostError(response)) {
                    return
                }
                
                const means = base64ToFloats(response.data.means)
                const diffs = base64ToFloats(response.data.diff)
                const variance = base64ToFloats(response.data.variance)
                const pValues = base64ToFloats(response.data.prob)
                
                const modelTableContent = [
                    ['Model', '# Parameters', '-2*log(Likelihood)'], 
                    ['Saturated', num_comps, response.data.sat_n2ll.toFixed(2)],
                    ['Specified', num_prods - 1, response.data.spec_n2ll.toFixed(2)],
                    ['Null', 0, response.data.null_n2ll.toFixed(2)],
                ]

                const modelCompTableContent = [
                    ['Model 1', 'Model 2', 'p-Value'], 
                    ['Saturated', 'Specified', roundPValue(response.data.spec_vs_sat)],
                    ['Saturated', 'Null', roundPValue(response.data.null_vs_sat)],
                    ['Specified', 'Null', roundPValue(response.data.null_vs_spec)]
                ]

                const prodCompTableContent = [
                    ['Product 1', 'Product 2', 'd\'', 'Variance', 'p-Value']
                ].concat(
                    datasetData.map((row, rowIndex) => [ row[0], row[1], diffs[rowIndex].toFixed(2), variance[rowIndex].toFixed(2), roundPValue(pValues[rowIndex]) ])
                )

                const refProdTableContent = [
                    ['Product', 'd\'']
                ].concat(
                        prod_names_list.slice(1).map((prod_name, index) => [ prod_name, means[index + 1].toFixed(2)])
                )

                const modelTable = {
                    TableId: nanoid(),
                    Title: "Models",
                    OptionsList: { ColumnHeaders: true },
                    TableContent: modelTableContent
                }
                const modelCompTable = {
                    TableId: nanoid(),
                    Title: "Model Tests",
                    OptionsList: { ColumnHeaders: true },
                    TableContent: modelCompTableContent
                }
                const prodCompTable = {
                    TableId: nanoid(),
                    Title: "Product Comparisons",
                    OptionsList: { ColumnHeaders: true },
                    TableContent: prodCompTableContent
                }
                const refProdTable = {
                    TableId: nanoid(),
                    Title: "Comparisons to Reference Product " + referenceProduct.label,
                    OptionsList: { ColumnHeaders: true },
                    TableContent: refProdTableContent
                }

                let tempPlotData = means.map((d_prime, index) => ({
                    curveType: 'normal',
                    x_lb: -4,
                    x_ub: d_prime + 4.0,
                    mean: d_prime,
                    sd: 1,
                    type: 'line',
                    color: getDefaultColorByIndex(index),
                    name: prod_names_list[index],
                    showlegend: false,
                    hoverinfo: 'none',
                    //hovertemplate: 'Delta: %{x:.2f}  Prob.: %{y:.2f}',
                }))

                tempPlotData.concat({
                    x: means,
                    y: Array(means.length).fill(0.4),
                    mode: 'text',
                    name: 'labels',
                    text: prod_names_list,
                    textposition: 'top',
                    type: 'scatter',
                    align: 'center'
                })

                const newPlotId = nanoid()
                const newOutputId = nanoid()
                const plotObject = { //addPlot({
                        OwnerId: userId,
                        PlotId: newPlotId,
                        Title: 'Multiple 2-AFC: ' + selectedDatasetData.Name,
                        Data: tempPlotData,
                        OutputReferences: [newOutputId]
                    }

                const outputObject = {
                    OwnerId: userId,
                    OutputId: newOutputId,
                    Title: 'Multiple 2-AFC: ' + selectedDatasetData.Name,
                    Stream: [
                        { Type: 'table', Content: modelTable.TableId },
                        { Type: 'table', Content: modelCompTable.TableId },
                        { Type: 'table', Content: prodCompTable.TableId },
                        { Type: 'table', Content: refProdTable.TableId },
                        { Type: 'plot', Content: newPlotId }
                    ],
                    Tables: [modelTable, modelCompTable, prodCompTable, refProdTable], 
                    Plots: [newPlotId],
                    ReferencingProjectIds: currentProjectId,
                }
                
                addOutput(outputObject, plotObject)
            })
            .catch((error) => {
                console.log('error: ')
                console.log(error)
            })
    };

    return (
        <div id='multipleTwoAFCDialog'>
            <h2>Multiple 2-AFC</h2>
            <form onSubmit={onSubmit}>
                <AutocompleteDropdown 
                    label="Choose dataset:"
                    variant="outlined"
                    options={datasetOptions} 
                    value={selectedDataset} setValue={handleSetSelectedDataset}
                    getOptionLabel={option => option.label ? option.label : ""}
                    renderOption={(option) => (
                        <React.Fragment>
                          {option.label}
                        </React.Fragment>
                      )}
                />
                <br />
                <br />
                <AutocompleteDropdown 
                    label="Choose reference product:"
                    variant="outlined"
                    options={prodNames} 
                    value={referenceProduct} setValue={setReferenceProduct}
                    getOptionLabel={option => option.label ? option.label : ""}
                    renderOption={(option) => (
                        <React.Fragment>
                          {option.label}
                        </React.Fragment>
                      )}
                />
                <br />
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </form>
        </div>
    )
}

export default MultipleTwoAFC
