import { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'

import { AddProjectModal } from '../dialogs/projects/AddProject'
import { AddProjectFolderModal } from '../dialogs/projects/AddProjectFolder'

import MainLayout from './MainLayout'
import { makeStyles } from '@material-ui/core/styles'
import { skipToken } from '@reduxjs/toolkit/query'
import ProjectFolderTree from '../ui/ProjectFolderTree'
import ProjectList from '../ui/ProjectList'
import { useGetProjectFoldersByUserIdQuery, usePutProjectFolderMutation, useDeleteProjectFolderMutation, 
    useDeleteProjectMutation, deleteProjectContents, deleteProjectFolderContents } from '../../services/databaseApi'
import ConfirmationDialog from '../ui/ConfirmationDialog'
import { useSession } from '../../hooks/store/sessionHook'

const useStyles = makeStyles({
    buttonRow: {
        display: 'inline-block',
        margin: '5px',
    },
    headerButton: {
        margin: '5px 10px 5px 5px',
    },
    projectTree: {
        border: 'grey solid 1px',
        borderRadius: '2px',
        flex: '50',
        margin: '2px',
    },
    projectList: {
        border: 'grey solid 1px',
        borderRadius: '2px',
        flex: '50',
        margin: '2px',
    }
})

const ManageProjects = (props) => {
    
    const classes = useStyles()

    //const rootProjectFolderId = useSelector(state => state.session.rootProjectFolderId)
    const { currentProjectId, setCurrentProjectId, defaultProjectId } = useSession()
    const userId = useSelector(state => state.session.userId)

    const { data: projectFolderData,
        isLoading: isProjectFolderDataLoading,
        isSuccess: isProjectFolderDataSuccess }  = useGetProjectFoldersByUserIdQuery(userId ?? skipToken)

    const [ deleteProject ] = useDeleteProjectMutation()
    const [ deleteProjectFolder ] = useDeleteProjectFolderMutation()        
    const [ putProjectFolder ] = usePutProjectFolderMutation()

    const [ selectedProjectId, setSelectedProjectId ] = useState(null)
    const [ selectedProjectFolderId, setSelectedProjectFolderId ] = useState(null)
    const [isAddProjectOpen, setIsAddProjectOpen] = useState(false)
    const [isAddProjectFolderOpen, setIsAddProjectFolderOpen] = useState(false)
    const [ isOpenConfirmProjectDelete, setIsOpenConfirmProjectDelete ] = useState(false)
    const [ isOpenConfirmProjectFolderDelete, setIsOpenConfirmProjectFolderDelete ] = useState(false)

    useEffect(() => {

        if(projectFolderData && projectFolderData.length > 0 && selectedProjectFolderId == null) {
            setSelectedProjectFolderId(projectFolderData[0].ProjectFolderId)
        }
        //  else if((!dataFolders || dataFolders.length === 0) && !isDataFoldersLoading) {
        //     setSelectedFolderId(null)
        // }

    }, [projectFolderData, isProjectFolderDataLoading, isProjectFolderDataSuccess, selectedProjectFolderId])

    const handleSelectedProjectFolderChange = (newNodeId) => {
        setSelectedProjectFolderId(newNodeId)
    }

    const handleAddProjectClick = () => {
        setIsAddProjectOpen(true)
    }

    const handleAddProjectClose = () => {
        setIsAddProjectOpen(false)
    }

    const handleDeleteProject = async () => {

        if(selectedProjectId) {
            deleteProjectContents(selectedProjectId)
            deleteProject(selectedProjectId).then(() => setSelectedProjectId(null))
            
            if(projectFolderData) {
                const thisProjectData = projectFolderData.find(projectData => projectData.ProjectFolderId === selectedProjectFolderId)
                if(thisProjectData) {
                        putProjectFolder({
                        ...thisProjectData,
                        Projects: thisProjectData.Projects.filter(project => project.ProjectId !== selectedProjectId)
                    })
                }
            }

            if(selectedProjectId === currentProjectId) {
                setCurrentProjectId(userId + "_NO_PROJECT_SELECTED_")
            }
        }
    }

    const handleAddProjectFolderClick = () => {
        setIsAddProjectFolderOpen(true)
    }

    const handleAddProjectFolderClose = () => {
        setIsAddProjectFolderOpen(false)
    }

    const handleDeleteProjectFolder = () => {

        deleteProjectFolderContents(selectedProjectFolderId)
        deleteProjectFolder(selectedProjectFolderId).then(() => setSelectedProjectFolderId(null))
    }

    const handleProjectDoubleClick = () => { /* TODO: Either open 'preview' window or just open the project */}

    const selectedFolderProjects = useMemo(() => projectFolderData?.find(folder => folder.ProjectFolderId === selectedProjectFolderId)?.Projects, [ projectFolderData, selectedProjectFolderId ])    
    
    return (
        <div id="manageProjects">
            <MainLayout rightPanel={null}
                leftPanel={null}
            >
                <div style={{ margin: '0 auto' }}>
                    <div className={classes.buttonRow}>
                    <Button
                            onClick={handleAddProjectFolderClick}
                            className={classes.headerButton}
                            variant="contained"
                            color="primary"
                            type="button"
                        >
                            Add project folder
                        </Button>
                        <Button
                            onClick={() => setIsOpenConfirmProjectFolderDelete(true)}
                            className={classes.headerButton}
                            variant="contained"
                            color="primary"
                            type="button"
                        >
                            Delete project folder
                        </Button>
                        <Button
                            onClick={handleAddProjectClick}
                            className={classes.headerButton}
                            variant="contained"
                            color="primary"
                            type="button"
                        >
                            Add project
                        </Button>
                        <Button
                            onClick={() => setIsOpenConfirmProjectDelete(true)}
                            className={classes.headerButton}
                            variant="contained"
                            color="primary"
                            type="button"
                        >
                            Delete project
                        </Button>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <div className={classes.projectTree}>
                            <ProjectFolderTree
                                projectFolderData={projectFolderData}
                                selectedProjectFolderId={selectedProjectFolderId}
                                onProjectFolderChange={handleSelectedProjectFolderChange}
                            />
                        </div>
                        <div className={classes.projectList}>
                            <ProjectList 
                                displayProjects={selectedFolderProjects}
                                selectedProjectId={selectedProjectId}
                                setSelectedProjectId={setSelectedProjectId}
                                onProjectDoubleClick={handleProjectDoubleClick}
                            />
                        </div>
                    </div>
                </div>
            </MainLayout>
            <ConfirmationDialog title="Confirm Project Deletion" text="Are you sure you want to delete this project?" 
                isOpen={isOpenConfirmProjectDelete} onClose={()=> setIsOpenConfirmProjectDelete(false)} onConfirm={handleDeleteProject} />
            <ConfirmationDialog title="Confirm Project Folder Deletion" text="Are you sure you want to delete this project folder, including any projects inside?" 
                isOpen={isOpenConfirmProjectFolderDelete} onClose={()=> setIsOpenConfirmProjectFolderDelete(false)} onConfirm={handleDeleteProjectFolder} />
            <ConfirmationDialog />
            <AddProjectModal
                open={isAddProjectOpen}
                onClose={handleAddProjectClose}
                parentFolderId={selectedProjectFolderId}
            />
            <AddProjectFolderModal
                open={isAddProjectFolderOpen}
                onClose={handleAddProjectFolderClose}
                parentFolderId={selectedProjectFolderId}
            />
        </div>
    )
}

export default ManageProjects
