import { Redirect, Route } from "react-router-dom";
import { useSelector } from 'react-redux'

const PrivateRoute = ({ children, ...rest }) => {

    const isLoggedIn = useSelector(state => Boolean(state.session.currentSessionToken))

    return(
        <Route { ...rest } render={({ location }) => {
            return isLoggedIn ? children : <Redirect to={{ pathname: '/login', state: { from: location }}} />
        }} />
    )
}

export default PrivateRoute
