export default class InMemoryStorage {
    
    constructor() {
        this.kvStore = {}
    }

    length() {
        return Object.keys(this.kvStore).length
    }

    key(n) {
        return Object.keys(this.kvStore)[n]
    }

    getItem(key) {
        return this.kvStore[key]
    }

    setItem(key, value) {
        this.kvStore[key] = value
    }

    removeItem(key) {
        delete this.kvStore[key]
    }

    clear() {
        this.kvStore = {}
    }
}
