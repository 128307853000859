import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Input from '@material-ui/core/Input'

import { usePutFolderMutation, useGetFolderByIdQuery } from '../../../services/databaseApi'
import { useSelector } from 'react-redux'
import { nanoid } from '@reduxjs/toolkit'
import { skipToken } from '@reduxjs/toolkit/dist/query'

const AddDatasetFolder = ({ onClose, parentFolderId, ...otherProps }) => {

    const currentProjectId = useSelector(state => state.session.currentProjectId)
    const userId = useSelector(state => state.session.userId)

    const { data: parentFolderObject,
        isLoading: isParentFolderLoading, } = useGetFolderByIdQuery(parentFolderId ?? skipToken)

    const [ putFolder ] = usePutFolderMutation()

    const [ folderName, setFolderName ] = useState('')
    const [ errorText, setErrorText ] = useState(null)

    const onSubmit = (event) => {
        
        event.preventDefault()

        if(!isParentFolderLoading && parentFolderObject) {
            const newFolderId = nanoid()

            putFolder({
                FolderId: newFolderId,
                Name: folderName,
                Children: [],
                Datasets: [],
                ParentFolderId: parentFolderId,
                ParentProjectId: currentProjectId,
                OwnerId: userId,
            })

            if(parentFolderObject.Children) {
                putFolder({
                    ...parentFolderObject,
                    Children: [...parentFolderObject.Children, { Name: folderName, FolderId: newFolderId }]
                })
            } else {
                putFolder({
                    ...parentFolderObject,
                    Children: [ { Name: folderName, FolderId: newFolderId } ]
                })
            }
            
        } else {
            //TODO: Display an error
        }

        onClose()
    }

    const handleBlur = (event) => {
        if(errorText) {
            // TODO - Add error checking
            // if(!getErrorMessage(itemName)) {
            //     setErrorText(null)
            // }
        }
    }
                
    return (
        <div id="addDatasetFolderDialog" style={{ margin: '0px 15px 10px 15px', minWidth: '250px' }}>
            <form onSubmit={onSubmit}>
                <h4>Add Folder</h4>
                <FormControl error={errorText ? true : false} style={{width: '95%'}}>
                    <FormLabel>Folder Name</FormLabel>
                    <Input style={{width: '100%'}} value={folderName} onChange={(event) => setFolderName(event.target.value)} onBlur={handleBlur} />
                    <FormHelperText error>{errorText}</FormHelperText>
                </FormControl>
                <br style={{margin: '25px 0px'}}/>
                <div style={{ display: 'flex'}} >
                    <div style={{ display: 'inline-block', margin: '0 auto' }}>
                        <Button
                            style={{ padding: 3, marginRight: '10px' }}
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Add
                        </Button>
                        <Button
                            style={{ padding: 3 }}
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddDatasetFolder
