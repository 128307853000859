import { createSlice } from '@reduxjs/toolkit'


const datasetFoldersSlice = createSlice({
    name: 'datasetFolders',
    initialState: [{
        FolderId: '_dataset_root_folder_',
        ParentFolderId: null,
        Name: "Project Root",
        Children: [],
        Datasets: [] }],
    reducers: {
        addDatasetFolder(state, action) {
            return ([
                action.payload,
                ...state,
            ])
        },
        deleteDatasetFolder(state, action) {
            const { id } = action.payload
            return state.filter(dataset => dataset.DatasetId !== id)
        },
        updateDatasetFolderName(state, action) {
            const { datasetFolderId, newName } = action.payload
            const existingDatasetFolder = state.find(datasetFolder => datasetFolder.FolderId === datasetFolderId)
            if(existingDatasetFolder) {
                existingDatasetFolder.Name = newName
            }
        },
        addDatasetToFolder(state, action) {
            const { datasetFolderId, datasetId } = action.payload
            const existingDatasetFolder = state.find(datasetFolder => datasetFolder.FolderId === datasetFolderId)
            if(existingDatasetFolder) {
                existingDatasetFolder.Datasets.push(datasetId)
            }
        },
        removeDatasetFromFolder(state, action) {
            const { datasetFolderId, datasetId } = action.payload
            const existingDatasetFolder = state.find(datasetFolder => datasetFolder.FolderId === datasetFolderId)
            if(existingDatasetFolder) {
                existingDatasetFolder.Datasets = existingDatasetFolder.Datasets.filter(dataId => dataId !== datasetId)
            }
        },
    }
})

export const { addDatasetFolder, updateDatasetFolderName, deleteDatasetFolder, addDatasetToFolder, removeDatasetFromFolder } = datasetFoldersSlice.actions

export default datasetFoldersSlice.reducer
