import React from 'react'

import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'

// import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import AnchoredMenu from '../ui/AnchoredMenu'

import { climbTreeUntilElementType } from '../../utils'
import { ClickAwayListener } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        '&:hover': {
            background: 'lightgrey',
        },
        borderRadius: '0px',
    },
    subMenuButton: {
        '&:hover': {
            background: 'lightgrey',
        },
        borderRadius: '0px',
    },
    drawer: {
        zIndex: theme.zIndex.appBar - 50 + '!important',
    },
    analyzeButtonGroup: {
        // display: 'table',
        // margin: 'auto',
        float: 'center',
    },
}))

const AnalyzeMenu = (props) => {
    const classes = useStyles()

    const forcedChoiceMenuRef = React.useRef();

    const [ menuAnchor, setMenuAnchor ] = React.useState(null)
    const [ isOpenDiscrimination, setIsOpenDiscrimination ] = React.useState(false);
    const [ isOpenRatings, setIsOpenRatings ] = React.useState(false);
    const [ isOpenHedonics, setIsOpenHedonics ] = React.useState(false);
    const [ isOpenLSA, setIsOpenLSA ] = React.useState(false);
    const [ isOpenCombinatorial, setIsOpenCombinatorial ] = React.useState(false);
    const [ isOpenExperimentalDesign, setIsOpenExperimentalDesign ] = React.useState(false);
    const [ isOpenStatisticalTests, setIsOpenStatisticalTests ] = React.useState(false);
    const [ isOpenForcedChoice, setIsOpenForcedChoice ] = React.useState(false);
    
    // const handleDrawerOpen = () => {
    //     setOpen(true);
    // };

    // const handleDrawerClose = () => {
    //     setOpen(false);
    // };

    const handleClick = (event) => {
        
        if(!event.defaultPrevented) {
            setIsOpenForcedChoice(false);
            setIsOpenDiscrimination(false);
            setIsOpenRatings(false);
            setIsOpenHedonics(false);
            setIsOpenLSA(false);
            setIsOpenCombinatorial(false);
            setIsOpenExperimentalDesign(false);
            setIsOpenStatisticalTests(false);
            props.onMenuClick()
        }
    }

    const handleClickAway = (event) => {
        handleClick(event)
    }

    // const handleHoverChange = (event, buttonId, isOpen) => {

    //     if (buttonId === "") {
    //         event.preventDefault()
    //         setMenuAnchor(event.currentTarget)
    //     } else {
    //         handleHoverChange(event, "", false)
    //     }

    //     switch(buttonId) {
    //         case "DiscriminationButton":
    //             setIsOpenDiscrimination(isOpen);
    //             break
    //         case "RatingsButton":
    //             setIsOpenRatings(isOpen);
    //             break
    //         case "HedonicsButton":
    //             setIsOpenHedonics(isOpen);
    //             break
    //         case "LSAButton":
    //             setIsOpenLSA(isOpen);
    //             break
    //         case "CombinatorialButton":
    //             setIsOpenCombinatorial(isOpen);
    //             break
    //         case "ExperimentalDesignButton":
    //             setIsOpenExperimentalDesign(isOpen);
    //             break
    //         case "StatisticalButton":
    //             setIsOpenStatisticalTests(isOpen);
    //             break
    //         default:
    //             setIsOpenForcedChoice(false);
    //             setIsOpenDiscrimination(false);
    //             setIsOpenRatings(false);
    //             setIsOpenHedonics(false);
    //             setIsOpenLSA(false);
    //             setIsOpenCombinatorial(false);
    //             setIsOpenExperimentalDesign(false);
    //             setIsOpenStatisticalTests(false);
    //     }
    // }
    // const handleClose = (event) => {
    //     if (event.currentTarget.localName !== "ul") {
    //         const menu = document.getElementById("discriminationMenu")
    //         if(menu !== null) {
    //             menu = menu.children[2];
    //             const menuBoundary = {
    //             left: menu.offsetLeft,
    //             top: event.currentTarget.offsetTop + event.currentTarget.offsetHeight,
    //             right: menu.offsetLeft + menu.offsetHeight,
    //             bottom: menu.offsetTop + menu.offsetHeight
    //             };
    //             if (
    //             event.clientX >= menuBoundary.left &&
    //             event.clientX <= menuBoundary.right &&
    //             event.clientY <= menuBoundary.bottom &&
    //             event.clientY >= menuBoundary.top
    //             ) {
    //             return;
    //             }
    //         }
    //     }
      
    //       setIsOpenDiscrimination(false);
    // }
    const handleTopMenuClick = (event) => {
        event.preventDefault()
        setMenuAnchor(event.currentTarget)

        setIsOpenForcedChoice(false);
        setIsOpenDiscrimination(false);
        setIsOpenRatings(false);
        setIsOpenHedonics(false);
        setIsOpenLSA(false);
        setIsOpenCombinatorial(false);
        setIsOpenExperimentalDesign(false);
        setIsOpenStatisticalTests(false);
        
        const buttonElement = climbTreeUntilElementType(event.target, "button", 2)
        if(buttonElement) {
            switch(buttonElement.id) {
                case "discriminationButton":
                    setIsOpenDiscrimination(true);
                    break
                case "ratingsButton":
                    setIsOpenRatings(true);
                    break
                case "hedonicsButton":
                    setIsOpenHedonics(true);
                    break
                case "lsaButton":
                    setIsOpenLSA(true);
                    break
                case "combinatorialButton":
                    setIsOpenCombinatorial(true);
                    break
                case "experimentalDesignButton":
                    setIsOpenExperimentalDesign(true);
                    break
                case "statisticalButton":
                    setIsOpenStatisticalTests(true);
                    break
                default:
                    break
            }
        }
    }

    const handleSubMenuClick = (event, source) => {

        const liElement = climbTreeUntilElementType(event.target, "li", 2)
        if(liElement !== null) {
            switch(liElement.id) {
                case "forcedChoiceButton":
                    event.preventDefault()
                    setIsOpenForcedChoice(!isOpenForcedChoice)
                    break
                default:
                    break
            }
        }
    }

    const closeAllMenus = () => {

        setIsOpenForcedChoice(false);
        setIsOpenDiscrimination(false);
        setIsOpenRatings(false);
        setIsOpenHedonics(false);
        setIsOpenLSA(false);
        setIsOpenCombinatorial(false);
        setIsOpenExperimentalDesign(false);
        setIsOpenStatisticalTests(false);

        props.onClose()
    }

    return (
        <div className="analyzeMenu" style={{ display: props?.visible ? "block" : "none", background: "white", width: "100%"}}>
            {/* <Drawer
                variant="persistent" // or "temporary" if we don't want it to stay on the screen
                anchor="top"
                open={props.isOpen}
                //onClick={props.onMenuClick}
                className={classes.drawer}
                onClick={handleClick}
            > */}
            <ClickAwayListener onClickAway={handleClickAway}>
            <div onClick={handleClick} style={{ display: "block", margin: "0 auto"}}>
                {/* <div className={props.classMixinsStyle} /> */}
                <div className={classes.analyzeButtonGroup}>
                    <Button id="discriminationButton" className={classes.menuButton}
                        onClick={handleTopMenuClick}>Discrimination</Button>
                    <Button id="ratingsButton" className={classes.menuButton} onClick={handleTopMenuClick}
                        >Ratings</Button>
                    <Button id="hedonicsButton" className={classes.menuButton} onClick={handleTopMenuClick}>Hedonics</Button>
                    <Button id="lsaButton" className={classes.menuButton} onClick={handleTopMenuClick}>Landscape Segmentation Analysis</Button>
                    <Button id="combinatorialButton" className={classes.menuButton} onClick={handleTopMenuClick}>Combinatorial Tools</Button>
                    <Button id="experimentalDesignButton" className={classes.menuButton} onClick={handleTopMenuClick}>Experimental Design</Button>
                    <Button id="statisticalButton" className={classes.menuButton}  onClick={handleTopMenuClick}>Statistical Tests</Button>
                </div>
                <AnchoredMenu id="discriminationMenu" isOpen={isOpenDiscrimination} menuAnchor={menuAnchor} zIndex={1301}>
                    <MenuItem className={classes.subMenuButton} 
                        id="forcedChoiceButton"
                        onClick={handleSubMenuClick}
                        ref={forcedChoiceMenuRef}
                        style={isOpenForcedChoice ? { background: 'lightgrey'} : {} }
                        >
                            <p>Forced Choice</p><ChevronRightIcon style={{"marginLeft": "2px"}}/>
                        <AnchoredMenu id="forcedChoiceMenu" 
                            menuAnchor={forcedChoiceMenuRef.current} 
                            zIndex={1302} 
                            isOpen={isOpenForcedChoice}
                            paperProps={{
                                style: {
                                    borderTopLeftRadius: '0px',
                                    borderBottomLeftRadius: '0px',
                                    borderWidth: '1px 1px 1px 0px',
                                    borderColor: 'black',
                                    borderStyle: 'solid',
                                },
                                variant: 'outlined',
                            }}
                            placement='left-start'
                            transformOrigin='top left'
                            >
                            <MenuItem component={Link} to="/analyze/forced_choice/delta_estimation" className={classes.subMenuButton} onClick={closeAllMenus}> Delta Estimation</MenuItem>
                            <MenuItem component={Link} to="/analyze/forced_choice/multiple_two_afc"  className={classes.subMenuButton} onClick={closeAllMenus}>Multiple 2-AFC</MenuItem>
                            <MenuItem component={Link} to="/analyze/forced_choice/power"  className={classes.subMenuButton} onClick={closeAllMenus}>Power</MenuItem>
                            <MenuItem component={Link} to="/analyze/forced_choice/psychometric_functions"  className={classes.subMenuButton} onClick={closeAllMenus}>Psychometric</MenuItem>
                            <MenuItem component={Link} to="/analyze/forced_choice/simulation"  className={classes.subMenuButton} onClick={closeAllMenus}>Simulation</MenuItem>
                            <MenuItem component={Link} to="/analyze/forced_choice/demonstration"  className={classes.subMenuButton} onClick={closeAllMenus}>Demonstration</MenuItem>
                            <MenuItem component={Link} to="/analyze/forced_choice/equivalence"  className={classes.subMenuButton} onClick={closeAllMenus}>Equivalence</MenuItem>
                            <MenuItem component={Link} to="/analyze/forced_choice/proportion_discriminators"  className={classes.subMenuButton} onClick={closeAllMenus}>Proportion of Discriminators</MenuItem>
                        </AnchoredMenu>
                    </MenuItem>
                    <MenuItem id="sameDifferentButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/same_different"><p>Same-Different</p></MenuItem>
                    <MenuItem id="consumerRelevantConfidenceButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/consumer_relevant_confidence"><p>Consumer Relevant Confidence</p></MenuItem>
                    <MenuItem id="twoACButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/two_AC"><p>Two-AC</p></MenuItem>
                    <MenuItem id="applicabilityButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/applicability"><p>Applicability</p></MenuItem>
                    <MenuItem id="aNotAButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/a_not_a"><p>A/Not A</p></MenuItem>
                    <MenuItem id="torgersonButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/torgersons"><p>Torgerson</p></MenuItem>
                    <MenuItem id="dPrimeTestButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/d_prime_test"><p>d' Test</p></MenuItem>
                    <MenuItem id="betaBinomialButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/beta_binomial"><p>Beta-Binomial</p></MenuItem>
                </AnchoredMenu>
                <AnchoredMenu isOpen={isOpenRatings} menuAnchor={menuAnchor} zIndex={1301}>
                    <MenuItem id="scaleAnalysisButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/scale_analysis"><p>Scale Analysis</p></MenuItem>
                    <MenuItem id="scaleDemonstrationButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/scaling_demo"><p>Scaling Demonstration</p></MenuItem>
                    <MenuItem id="degreeOfDifferenceButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/degree_of_difference"><p>Degree of Difference</p></MenuItem>
                    <MenuItem id="relativeScalesButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/relative_scales"><p>Relative Scales</p></MenuItem>
                    <MenuItem id="ranksButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/ranks"><p>Ranks</p></MenuItem>
                    <MenuItem id="dPrimeTestButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/d_prime_test"><p>d' Test</p></MenuItem>
                    <MenuItem id="multiplicativeComparisonButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/multiplicative_comparison"><p>Multiplicative Comparison</p></MenuItem>
                </AnchoredMenu>
                <AnchoredMenu isOpen={isOpenHedonics} menuAnchor={menuAnchor} zIndex={1301}>
                    <MenuItem className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/preference"><p>Preference</p></MenuItem>
                    <MenuItem id="acceptanceButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/acceptance"><p>Acceptance</p></MenuItem>
                    <MenuItem id="justAboutRightButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/just_about_right"><p>Just About Right</p></MenuItem>
                </AnchoredMenu>
                <AnchoredMenu isOpen={isOpenLSA} menuAnchor={menuAnchor} zIndex={1301}>
                    <MenuItem id="lsaButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/lsa"><p>Landscape Segmentation Analysis</p></MenuItem>
                    <MenuItem id="computeDistancesButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/compute_distances"><p>Compute Distances</p></MenuItem>
                </AnchoredMenu>
                <AnchoredMenu isOpen={isOpenCombinatorial} menuAnchor={menuAnchor} zIndex={1301}>
                    <MenuItem id="eTURFButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/eTURF"><p>eTURF 2.0</p></MenuItem>
                    <MenuItem id="gtaButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/gta"><p>Graph Theoretic Analysis</p></MenuItem>
                    <MenuItem id="productSelectionButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/product_selection"><p>Product Selection</p></MenuItem>
                    <MenuItem id="tabulateMeansButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/tabulate_means"><p>Tabulate Means</p></MenuItem>
                    <MenuItem id="cldButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/cld"><p>Compact Letter Display</p></MenuItem>
                </AnchoredMenu>
                <AnchoredMenu isOpen={isOpenExperimentalDesign} menuAnchor={menuAnchor} zIndex={1301}>
                    <MenuItem id="rotationsButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/rotations"><p>Rotations</p></MenuItem>
                </AnchoredMenu>
                <AnchoredMenu isOpen={isOpenStatisticalTests} menuAnchor={menuAnchor} zIndex={1301}>
                    <MenuItem id="binomialTestButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/binomial_test"><p>Binomial Test</p></MenuItem>
                    <MenuItem id="tTestButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/t_test"><p><i>t</i>-Test</p></MenuItem>
                    <MenuItem id="chiSquaredTestButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/chi_squared_test"><p>Chi-Squared Test</p></MenuItem>
                    <MenuItem id="anovaButton" className={classes.subMenuButton} onClick={closeAllMenus} component={Link} to="/analyze/anova"><p>ANOVA</p></MenuItem>
                </AnchoredMenu>
            {/* </Drawer> */}
            </div>
            </ClickAwayListener>
        </div>
    )
}

export default AnalyzeMenu
