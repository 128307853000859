import { useState } from 'react'
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core'

const ProjectList = ({ displayProjects, onProjectDoubleClick, selectedProjectId, setSelectedProjectId }) => {

    const [ errorMessage, setErrorMessage ] = useState('Loading...')

    return (
        <List>
            { displayProjects ? displayProjects.map(project => (<ProjectListItem 
                                                                    key={project.ProjectId} 
                                                                    projectInfo={project} 
                                                                    onClick={() => setSelectedProjectId(project.ProjectId)}
                                                                    onDoubleClick={()=> onProjectDoubleClick(project.ProjectId)}
                                                                    selected={selectedProjectId === project.ProjectId}
                                                                />)) : <p>{errorMessage}</p> }
        </List>
    )
}

const useStyles = makeStyles({
    root: {
        '&$selected': {
            backgroundColor: "#756EDB88", //147, 157, 213
            '&:hover': {
                backgroundColor: "#756EDBBB",
            }
        },
        '&:hover': {
            backgroundColor: "#756EDBBB",
        }
    },
    selected: {},
    hover: {},
});

const ProjectListItem = ({projectInfo, onClick, onDoubleClick, selected}) => {
    
    const classes = useStyles()

    const [ checked, setChecked ] = useState(false)

    const onDoubleClickWrapper = (event) => {
        if(event.target.type !== "checkbox") {
            onDoubleClick()
        }
    }
    
    return (
        <ListItem onClick={onClick} selected={selected} onDoubleClick={onDoubleClickWrapper} 
            classes={{ root: classes.root, selected: classes.selected }}>
            {/* <ListItemIcon>
                <Checkbox
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    tabIndex={-1}
                    disableRipple
                />
            </ListItemIcon> */}
            <ListItemText id={projectInfo.ProjectId} primary={projectInfo.Name} style={{userSelect: 'none' }} />
        </ListItem>
    )
}

export default ProjectList
