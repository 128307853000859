import { makeStyles } from '@material-ui/core';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles({
    root: {
      height: 264,
      flexGrow: 1,
      maxWidth: 400,
      width: 'max-content',
    },
});

const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'start',
        color: theme.palette.text.secondary,
        '&:hover > $content': {
            backgroundColor: theme.palette.action.selected,
        },
        '&$selected > $content': {
            // backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
            backgroundColor: theme.palette.action.focus,
            color: "#1a73e8",
        },
        // '&:focus > $content $label, &:active > $content $label': {
        //     backgroundColor: 'transparent',
        // },
        '&:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'transparent',
        },
    },
    content: {
        // color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
         marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
    },
}));

const CustomTreeView = (props) => {

    const classes = useStyles()

    const treeItemClasses = useTreeItemStyles()

    const {
        children,
        multiSelect,
        expanded,
        selectedNodeId,
        onNodeSelect,
    } = props;

    const onFocus = () => console.log('focused')
    const onBlur = () => console.log('blurred')

    const renderTreeItem = (nodes) => {
        
        if(!nodes || (Array.isArray(nodes) && nodes.length === 0)) {
            return null
        }

        const LabelIcon = nodes.labelIcon

        return(
            <TreeItem 
                key={nodes.id} 
                nodeId={nodes.id}
                label={
                    <div className={treeItemClasses.labelRoot}>
                        { LabelIcon && <LabelIcon color="inherit" className={treeItemClasses.labelIcon} /> }
                        <Typography onFocus={onFocus} onBlur={onBlur} className={treeItemClasses.labelText}>{nodes.name}</Typography>
                    </div>
                }
                classes={{
                    root: treeItemClasses.root,
                    content: treeItemClasses.content,
                    expanded: treeItemClasses.expanded,
                    selected: treeItemClasses.selected,
                    group: treeItemClasses.group,
                    label: treeItemClasses.label,
                }} 
                onClick={() => onNodeSelect(nodes.id)} >
                { Array.isArray(nodes.children) ? nodes.children.map((node) => renderTreeItem(node)) : null }
            </TreeItem>
        )
    }

    return (
        <TreeView
            className={classes.root}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            selected={selectedNodeId}
            multiSelect={multiSelect}
            expanded={expanded}
            >
                { Array.isArray(children) ? children.map(child => renderTreeItem(child)) : renderTreeItem(children) }
        </TreeView>
    )

}

export default CustomTreeView
