import { DynamoDBClient, GetItemCommand } from '@aws-sdk/client-dynamodb'
import { CognitoUserPool, CookieStorage, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import poolData from '../app/cognito-pool-data.json'
import { setCurrentSessionToken } from '../features/sessionSlice'
import InMemoryStorage from './components/InMemoryStorage'
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers"

const userPool = new CognitoUserPool({ ...poolData, Storage: new CookieStorage({domain: "localhost"}) })
const inMemoryStorage = new InMemoryStorage()

export let dbClient = new DynamoDBClient({ region: 'us-east-1' })

export const verifyUser = (username, verifyCode, callback) => {
    const userData = {
        Username: username,
        Pool: userPool,
        Storage: inMemoryStorage, //new CookieStorage({domain: "localhost"})
    }

    const cognitoUser = new CognitoUser(userData)

    cognitoUser.confirmRegistration(verifyCode, true, callback)
}

export const forgotPassword = (username, callback) => {
    const userData = { 
        Username: username,
        Pool: userPool,
        Storage: inMemoryStorage,
    }

    const cognitoUser = new CognitoUser(userData)

    cognitoUser.forgotPassword({
        onSuccess: function(result) {
            console.log('call result: ' + result);
        },
        onFailure: function(err) {
            alert(err);
        },
        inputVerificationCode() {
            var verificationCode = prompt('Please input verification code ', '');
            var newPassword = prompt('Enter new password ', '');
            cognitoUser.confirmPassword(verificationCode, newPassword, this);
        }})
}

export const authenticateUser = (email, password, callback) => {
    
    const authData = {
        Username: email,
        Password: password,
    }

    const authDetails = new AuthenticationDetails(authData)

    const userData = { 
        Username: email,
        Pool: userPool,
        Storage: inMemoryStorage,
    }
     //"$context.identity.cognitoIdentityId"
    const cognitoUser = new CognitoUser(userData)
    cognitoUser.authenticateUser(authDetails, {
        onSuccess: result => {
            //console.log('id token + ' + result.getIdToken().getJwtToken())

            // const loginURL = 'cognito-idp.us-east-1.amazonaws.com/' + poolData.UserPoolId

            // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            //     IdentityPoolId: poolData.IdentityPoolId,
            //     Logins: {
            //         loginURL : result.getIdToken().getJwtToken()
            //     }
            // }, { region: 'us-east-1'});

            callback(null, result.getIdToken().getJwtToken(), cognitoUser)
        },
        onFailure: error => {
            callback(error)
        },
        newPasswordRequired: (userAttributes) => { //to-do: make a change password dialog if necessary
            callback("newPasswordRequired", null, cognitoUser, userAttributes)
            //delete userAttributes.email_verified;
            //userAttributes.given_name = 'Test'
            //userAttributes.family_name = "User"
            // delete userAttributes.email_verified; // it's returned but not valid to submit

            // let newPassword = prompt('A new password is required!');
        
            // cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, newPasswordRequired);

            // cognitoUser.completeNewPasswordChallenge("iForgot23$", userAttributes, {
            //     onSuccess: result => {
            //         console.log("success: ")
            //         console.log(result)
            //     },
            //     onFailure: result => {
            //         console.log("failure: ")
            //         console.log(result)
            //     }
            // });
            //callback(null, userAttributes)
            // cognitoUser.changePassword(password, "iForgot23$", function(error, result) {
            //     if (error) {
            //         alert(error.message || JSON.stringify(error));
            //         return;
            //     }

            //     console.log('call result: ' + result);
            // })
        }
    })
}

export const signOut = () => {

    if(userPool.getCurrentUser()) {
        userPool.getCurrentUser().signOut()
    }
    setCurrentSessionToken(null)
    window.location.reload()
}

export const getSessionToken = (callback) => {
    const cognitoUser = userPool.getCurrentUser()
    if(!cognitoUser) {
        callback(null)
        return
    }

    console.log(cognitoUser)

    cognitoUser.getSession((error, session) => {
        if(error) {
            console.log(error)
            callback(null)
            return
        }

        callback(session.getAccessToken().getJwtToken())
    })
}

export const getCurrentUser = (callback) => {
    const cognitoUser = userPool.getCurrentUser()
    if(!cognitoUser) {
        return false
    }

    cognitoUser.getSession((error, session) => {
        if(error) {
            console.log(error)
            return
        }

        console.log('Session valid? ', session.isValid())
        console.log(session)

        cognitoUser.getUserAttributes((error, attributes) => {
            if(error) {
                console.log(error)
                return
            }

            callback(attributes)
        })
    })
}

export const getCurrentUserSync = () => {
    return userPool.getCurrentUser()
}


// export const updateCredentials = () => {
//     AWS.config.credentials = new CognitoIdentityCredentials({
//         IdentityPoolId: '...', // your identity pool id here
//         Logins: {
//             // Change the key below according to the specific region your user pool is in.
//             'cognito-idp.<region>.amazonaws.com/<YOUR_USER_POOL_ID>': session
//                 .getIdToken()
//                 .getJwtToken(),
//         },
//     });
// }