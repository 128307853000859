import { useState } from "react"
import Button from '@material-ui/core/Button'
import axios from 'axios'
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import NumericInput from "../../ui/NumericInput";
import { useUpdateOutput } from "../../../hooks/store/useUpdateOutput";
import { useErrorHandler } from "../../../utils/apiUtils";
import { nanoid } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const api = process.env.REACT_APP_API_URL

const ConsumerRelevantConfidence = (props) => {

    const [ dPrime, setDPrime ] = useState()
    const [ dPrimeVar, setDPrimeVar ] = useState()
    const [ tau, setTau ] = useState()
    const [ tauVar, setTauVar ] = useState()
    const [ deltaLower, setDeltaLower ] = useState('yes')
    const [ isValidating, setIsValidating ] = useState(false)

    const { addOutput } = useUpdateOutput()

    const { checkAndPostError } = useErrorHandler()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const onSubmit = (event) => {

        event.preventDefault()

        setIsValidating(true)

        if(!(dPrime && dPrimeVar && tau && tauVar)) {
            return
        }

        setIsValidating(false)

        
        const apiData = {
            function_group: 'statistical_tests',
            function_name: 'multiplicative_comparison',
            mu_x: deltaLower === 'yes' ? tau : dPrime,
            mu_y: deltaLower === 'yes' ? dPrime : tau,
            var_x: deltaLower === 'yes' ? tauVar : dPrimeVar,
            var_y: deltaLower === 'yes' ? dPrimeVar : tauVar,
            cov: 0.0,
            multiplier: 1.0,
        }

        axios
        .post(api, apiData)
        .then((response) => {

            if(checkAndPostError(response)) {
                return
            }

            const title = 'Consumer Relevant Confidence'
            const tableContent = [
                ["d'", dPrime.toFixed(2)],
                ["Tau", tau.toFixed(2)],
                ["d' Variance", dPrimeVar.toFixed(2)],
                ["Tau Variance", tauVar.toFixed(2)],
                ["Confidence Level: " + (deltaLower === 'yes' ? "δ ≤ τ" : "τ ≤ δ"), (100 * response.data.conf_level).toFixed(1) + "%"],
            ]

            const newOutputId = nanoid()
            const tableId = nanoid()

            const table = {
                TableId: tableId,
                TableContent: tableContent
            }
            
            const outputObject = {
                OwnerId: userId,
                OutputId: newOutputId,
                Title: title, 
                Stream: [
                    { Type: 'table', Content: tableId },
                ],
                Tables: [table], 
                ReferencingProjectIds: currentProjectId,
            }
            
            addOutput(outputObject)
            
        })
        .catch((error) => {
            console.log("error: ")
            console.log(error)
        })
    }

    return (
        <div id="consumerRelevantConfidenceDialog">
            <form onSubmit={onSubmit}>
                <NumericInput label="d'" setValidatedValue={setDPrime} isValidating={isValidating}
                    minValue='0' errorMessage='Must be greater than 0.'/>
                <NumericInput label="d' Variance" setValidatedValue={setDPrimeVar} isValidating={isValidating}
                    minValue='0' errorMessage="d' Variance must be greater than 0."/>
                <NumericInput label="Tau" setValidatedValue={setTau} isValidating={isValidating}
                    minValue='0' errorMessage='Tau must be greater than 0.'/>
                <NumericInput label="Tau Variance" setValidatedValue={setTauVar} isValidating={isValidating}
                    minValue='0' errorMessage='Tau variance must be greater than 0.'/>
                <br />
                <RadioGroup
                        style={{ display: 'flex', alignItems: 'center' }}
                        aria-label="method"
                        name="method"
                        value={deltaLower}
                        onChange={(event, newValue) => { setDeltaLower(newValue)}}
                    >
                        <div id='radioGroupContainer' style={{ display: 'flex', width: 'max-content', flexFlow: 'column nowrap', alignItems: 'center' }}>
                            <FormLabel style={{ color: 'black', alignSelf: 'start' }}>Direction</FormLabel>
                            <FormControlLabel value='yes' control={<Radio />} label = "δ ≤ τ" />
                            <FormControlLabel value='no' control={<Radio />} label = "τ ≤ δ" />
                        </div>
                    </RadioGroup>
                <br />
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </form>
        </div>

    )
}

export default ConsumerRelevantConfidence
