import { useState, useEffect } from 'react'
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';
import { makeStyles, rgbToHex } from '@material-ui/core/styles'
import { useGetFolderByIdQuery } from '../../services/databaseApi';
import { skipToken } from '@reduxjs/toolkit/dist/query'

const DatasetList = ({ displayDatasets, onDatasetDoubleClick, selectedDatasetId, setSelectedDatasetId }) => {

    //const [ selectedDatasetId, setSelectedDatasetId ] = useState(-1)
    // const [ displayDatasets, setDisplayDatasets ] = useState(null)
    // const { data: dataFolderObject,
    //     isLoading: isDataFolderLoading,
    //     isSuccess: isDataFolderSuccess } = useGetFolderByIdQuery(selectedFolderId ?? skipToken)
    const [ errorMessage, setErrorMessage ] = useState('Loading...')

    // const handleListItemClick = (datasetId) => {
    //     setSelectedDatasetId(datasetId);
    //     console.log("Dataset clicked: " + datasetId.toString())
    // };

    // useEffect(() => {
    //     if(dataFolderObject && selectedFolderId) {
    //         setDisplayDatasets(dataFolderObject?.Datasets)//.filter(dataset => dataset.ParentFolderId === selectedFolderId).sort((a, b) => Date.parse(b.Created) - Date.parse(a.Created)))
    //     } else {
    //         setDisplayDatasets(null)
    //     }

    // }, [dataFolderObject, selectedFolderId])

    // useEffect(() => {
    //     if ((!isDataFolderLoading && !dataFolderObject) || (!isDataFolderLoading && dataFolderObject.Datasets.length < 1)) {
    //         setDisplayDatasets(null)
    //         setErrorMessage("This folder is empty")
    //     }
    // }, [isDataFolderLoading, dataFolderObject])

    return (
        <List>
            { displayDatasets ? displayDatasets.map(dataset => (<DatasetListItem 
                                                                    key={dataset.DatasetId} 
                                                                    datasetInfo={dataset} 
                                                                    onClick={() => setSelectedDatasetId(dataset.DatasetId)}
                                                                    onDoubleClick={()=> onDatasetDoubleClick(dataset.DatasetId)}
                                                                    selected={selectedDatasetId === dataset.DatasetId}
                                                                />)) : <p>{errorMessage}</p> }
        </List>
    )
}

const useStyles = makeStyles({
    root: {
        '&$selected': {
            backgroundColor: "#756EDB88", //147, 157, 213
            '&:hover': {
                backgroundColor: "#756EDBBB",
            }
        },
        '&:hover': {
            backgroundColor: "#756EDBBB",
        }
    },
    selected: {},
    hover: {},
});

const DatasetListItem = ({datasetInfo, onClick, onDoubleClick, selected}) => {
    
    const classes = useStyles()

    const [ checked, setChecked ] = useState(false)

    const onDoubleClickWrapper = (event) => {
        if(event.target.type !== "checkbox") {
            onDoubleClick()
        }
    }
    
    return (
        <ListItem onClick={onClick} selected={selected} onDoubleClick={onDoubleClickWrapper} 
            classes={{ root: classes.root, selected: classes.selected }}>
            <ListItemIcon>
                <Checkbox
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    tabIndex={-1}
                    disableRipple
                />
            </ListItemIcon>
            <ListItemText id={datasetInfo.DatasetId} primary={datasetInfo.Name} style={{userSelect: 'none' }} />
        </ListItem>
    )
}

export default DatasetList