import { createApi } from '@reduxjs/toolkit/query/react'
import { getDataset, getFolder, getOutput, getPlot, getProject, getProjectFolder, getUserInfo, 
    putDataset, putFolder, putPlot, putProject, putProjectFolder, putOutput, putUserInfo,
    deleteDataset, deleteFolder, deletePlot, deleteProjectFolder, deleteOutput, deleteProject,
    getFoldersByProjectId,
    getProjectFoldersByUserId, } from './dynamoDB'
// import { reduceDataArray, expandDataArray } from '../utils/dataUtils'

const customBaseQuery = (
    args, 
    { signal, dispatch, getState },
    extraOptions
) => {
    
    if(!args) {
        return //{ error: "No args provided" }
    }

    if(extraOptions.action === "query") {
        if(extraOptions.type === 'output') {
            return { data: getOutput(args) }
        } else if(extraOptions.type === 'project') {
            return { data: getProject(args) }
        } else if(extraOptions.type === 'projectFolder') {
            return  { data: getProjectFolder(args) }
        } else if(extraOptions.type === 'folder') {
            return { data:  getFolder(args) }
        } else if(extraOptions.type === 'plot') {
            return  { data: getPlot(args) } 
        } else if(extraOptions.type === 'user') {
            return  { data: getUserInfo(args) }
        } else if(extraOptions.type === 'dataset') {
            return { data: getDataset(args) }
        } else if(extraOptions.type === 'foldersByProjectId') {
            return { data: getFoldersByProjectId(args) }
        } else if(extraOptions.type === 'projectFoldersByUserId') {
            return { data: getProjectFoldersByUserId(args) }
        } else {
            return { data: 'stuff goes here!' } // or return { error: "Error stuff here!"}
        }
    } else if(extraOptions.action === "put") {
        
        if(extraOptions.type === 'output') {
            return { data: putOutput(args) }
        } else if(extraOptions.type === 'project') {
            return { data: putProject(args) }
        } else if(extraOptions.type === 'projectFolder') {
            return  { data: putProjectFolder(args) }
        } else if(extraOptions.type === 'folder') {
            return { data:  putFolder(args) }
        } else if(extraOptions.type === 'plot') {
            return  { data: putPlot(args) } 
        } else if(extraOptions.type === 'user') {
            return  { data: putUserInfo(args) }
        } else if(extraOptions.type === 'dataset') {
            return { data:  putDataset(args) }
        }

    } else if(extraOptions.action === "delete") {
        if(extraOptions.type === 'output') {
            return { data:  deleteOutput(args) }
        } else if(extraOptions.type === 'project') {
            return { data:  deleteProject(args) }
        } else if(extraOptions.type === 'projectFolder') {
            return { data:  deleteProjectFolder(args) }
        } else if(extraOptions.type === 'folder') {
            return { data:  deleteFolder(args) }
        } else if(extraOptions.type === 'plot') {
            return { data:  deletePlot(args) }
        } else if(extraOptions.type === 'dataset') {
            return { data:  deleteDataset(args) }
        }
    }
}

export const databaseApi = createApi({
    reducerPath: 'databaseApi',
    baseQuery: customBaseQuery,
    tagTypes: [ 'Project', 'Output', 'Plot', 'Dataset', 'Folder', 'ProjectFolder', 'FoldersByProjectId', 'ProjectFoldersByUserId' ],
    endpoints: (builder) => ({
        getProjectFoldersByUserId: builder.query({
            query: userId => userId,
            extraOptions: { type: 'projectFoldersByUserId', action: 'query' },
            providesTags: (result, error, arg) =>
                result ? [ ...result.map(({ ProjectFolderId }) => ({ type: 'ProjectFolder', id: ProjectFolderId })), 'ProjectFolder'] : [ 'ProjectFoldersByUserId' ],
        }),
        getFoldersByProjectId: builder.query({
            query: projectId => projectId,
            extraOptions: { type: 'foldersByProjectId', action: 'query' },
            providesTags: (result, error, arg) =>
                result ? [ ...result.map(({ FolderId }) => ({ type: 'Folder', id: FolderId })), 'Folder'] : [ 'FoldersByProjectId' ],
        }),
        getOutputById: builder.query({
            query: outputId => outputId,
            extraOptions: { type: 'output', action: 'query' },
            providesTags: (result, error, arg) =>
                result ? [{ type: 'Output', id: arg }] : ['Output'],
        }),
        getProjectById: builder.query({
            query: projectId => projectId,
            extraOptions: { type: 'project', action: 'query' },
            providesTags: (result, error, arg) =>
                result ? [{ type: 'Project', id: arg }] : ['Project'],
        }),
        getProjectFolderById: builder.query({
            query: folderId => folderId,
            extraOptions: { type: 'projectFolder', action: 'query' }
        }),
        getFolderById: builder.query({
            query: folderId => folderId,
            extraOptions: { type: 'folder', action: 'query' }
        }),
        getPlotById: builder.query({
            query: plotId => plotId,
            extraOptions: { type: 'plot', action: 'query' },
            providesTags: (result, error, arg) =>
                result ? [{ type: 'Plot', id: arg }] : ['Plot'],
        }),
        getUserInfo: builder.query({
            query: username => username,
            extraOptions: { type: 'user', action: 'query' }
        }),
        getDatasetById: builder.query({
            query: datasetId => datasetId,
            extraOptions: { type: 'dataset', action: 'query' },
        }),
        putOutput: builder.mutation({
            query: outputObject => outputObject,
            extraOptions: { type: 'output', action: 'put' },
            invalidatesTags: (result, error, arg) => [{ type: 'Output', id: arg.OutputId}],
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    databaseApi.util.updateQueryData('getOutputById', patch.OutputId, (draft) => {
                        Object.assign(draft, patch)
                    })
                )
                try {
                    await queryFulfilled
                } catch(e) {
                    //patchResult.undo()
                    dispatch(databaseApi.util.invalidateTags([ {type: 'Output', id: patch.OutputId }]))
                }
            }
        }),
        putProject: builder.mutation({
            query: projectObject => projectObject,
            extraOptions: { type: 'project', action: 'put' },
            invalidatesTags: (result, error, arg) => [{ type: 'Project', id: arg.ProjectId}],
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    databaseApi.util.updateQueryData('getProjectById', patch.ProjectId, (draft) => {
                        Object.assign(draft, patch)
                    })
                )
                try {
                    await queryFulfilled
                } catch(e) {
                    //patchResult.undo()
                    dispatch(databaseApi.util.invalidateTags([ {type: 'Project', id: patch.ProjectId }]))
                }
            }
        }),
        putProjectFolder: builder.mutation({
            query: folderObject => folderObject,
            extraOptions: { type: 'projectFolder', action: 'put' },
            invalidatesTags: (result, error, arg) => [{ type: 'ProjectFolder', id: arg.ProjectFolderId}],
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    databaseApi.util.updateQueryData('getProjectFolderById', patch.ProjectFolderId, (draft) => {
                        Object.assign(draft, patch)
                    })
                )
                try {
                    await queryFulfilled
                } catch(e) {
                    //patchResult.undo()
                    dispatch(databaseApi.util.invalidateTags([ {type: 'ProjectFolder', id: patch.ProjectFolderId }]))
                }
            },
        }),
        putFolder: builder.mutation({
            query: folderObject => folderObject,
            extraOptions: { type: 'folder', action: 'put' },
            invalidatesTags: (result, error, arg) => [{ type: 'Folder', id: arg.FolderId}],
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    databaseApi.util.updateQueryData('getFolderById', patch.FolderId, (draft) => {
                        Object.assign(draft, patch)
                    })
                )
                try {
                    await queryFulfilled
                } catch(e) {
                    //patchResult.undo()
                    dispatch(databaseApi.util.invalidateTags([ {type: 'Folder', id: patch.FolderId }]))
                }
            },
        }),
        putPlot: builder.mutation({
            query: plotObject => plotObject,
            extraOptions: { type: 'plot', action: 'put' },
            invalidatesTags: (result, error, arg) => [{ type: 'Plot', id: arg.PlotId}],
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    databaseApi.util.updateQueryData('getPlotById', patch.PlotId, (draft) => {
                        Object.assign(draft, patch)
                    })
                )
                try {
                    await queryFulfilled
                } catch(e) {
                    //patchResult.undo()
                    dispatch(databaseApi.util.invalidateTags([ {type: 'Plot', id: patch.PlotId }]))
                }
            }
        }),
        putUserInfo: builder.mutation({
            query: userInfo => userInfo,
            extraOptions: { type: 'user', action: 'put' }
        }),
        putDataset: builder.mutation({
            query: datasetObject => datasetObject,
            extraOptions: { type: 'dataset', action: 'put' },
            invalidatesTags: (result, error, arg) => [{ type: 'Dataset', id: arg.ProjectId}],
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    databaseApi.util.updateQueryData('getDatasetById', patch.DatasetId, (draft) => {
                        Object.assign(draft, patch)
                    })
                )
                try {
                    await queryFulfilled
                } catch(e) {
                    //patchResult.undo()
                    dispatch(databaseApi.util.invalidateTags([ {type: 'Dataset', id: patch.DatasetId }]))
                }
            }
        }),
        deleteOutput: builder.mutation({
            query: outputId => outputId,
            extraOptions: { type: 'output', action: 'delete' },
            invalidatesTags: (result, error, arg) => [{ type: 'Output', id: arg.OutputId}],
        }),
        deleteProject: builder.mutation({
            query: projectId => projectId,
            extraOptions: { type: 'project', action: 'delete' },
            invalidatesTags: (result, error, arg) => [{ type: 'Project', id: arg.ProjectId}],
        }),
        deleteProjectFolder: builder.mutation({
            query: projectFolderId => projectFolderId,
            extraOptions: { type: 'projectFolder', action: 'delete' },
            invalidatesTags: (result, error, arg) => [{ type: 'ProjectFolder', id: arg.ProjectFolderId}],
        }),
        deleteFolder: builder.mutation({
            query: folderId => folderId,
            extraOptions: { type: 'folder', action: 'delete' },
            invalidatesTags: (result, error, arg) => [{ type: 'Folder', id: arg.FolderId}],
        }),
        deletePlot: builder.mutation({
            query: plotId => plotId,
            extraOptions: { type: 'plot', action: 'delete' },
            invalidatesTags: (result, error, arg) => [{ type: 'Plot', id: arg.PlotId}],
        }),
        deleteDataset: builder.mutation({
            query: datasetId => datasetId,
            extraOptions: { type: 'dataset', action: 'delete' },
            invalidatesTags: (result, error, arg) => [{ type: 'Dataset', id: arg.DatasetId}],
        }),
    }),
})

export const deleteOutputById = async (outputId, removeReferencingProjectLinks = true) => {

    const outputData = await getOutput(outputId)

    if(outputData.Plots && outputData.Plots.length > 0) {
        outputData.Plots.map(plotId => deletePlot(plotId))
    }

    if(removeReferencingProjectLinks && outputData.ReferencingProjectIds && outputData.ReferencingProjectIds.length > 0) {
        outputData.ReferencingProjectIds.map(projectId => {
            getProject(removeReferencingProjectLinks).then(projectData => putProject({
                ...projectData,
                Output: projectData.Output.filter(someOutputId => someOutputId !== outputId)
            }))
        })
    }

    deleteOutput(outputId)
}

export const deleteProjectFolderContents = async (projectFolderId, recursive = true) => {
    
    const projectFolderData = await getProjectFolder(projectFolderId)

    if(projectFolderData.Children && recursive && projectFolderData.Children.length > 0) {
        projectFolderData.Children.map(child => deleteProjectFolderContents(child.ProjectFolderId))
        projectFolderData.Children.map(child => deleteProjectFolder(child.ProjectFolderId))
    }

    if(projectFolderData.Projects && projectFolderData.Projects.length > 0) {
        projectFolderData.Projects.map(projectInfo => deleteProjectContents(projectInfo.ProjectId))
        projectFolderData.Projects.map(projectInfo => deleteProject(projectInfo.ProjectId))
    }
}

export const deleteDatasetFolderById = async (folderId, recursive = true) => {
    
    const dataFolderData = await getFolder(folderId)

    console.log(dataFolderData)

    if(dataFolderData && dataFolderData.Children && recursive && dataFolderData.Children.length > 0) {
        dataFolderData.Children.map(child => deleteDatasetFolderById(child.FolderId))
    }

    if(dataFolderData && dataFolderData.Datasets && dataFolderData.Datasets.length > 0) {
        dataFolderData.Datasets.map(datasetInfo => deleteDataset(datasetInfo.DatasetId))
    }

    deleteFolder(folderId)
}

export const deleteProjectContents = async (projectId) => {

    const projectData = await getProject(projectId)

    if (projectData) {
        
        if(projectData.DatasetFolders && projectData.DatasetFolders.length > 0) {
            projectData.DatasetFolders.map(datasetFolderInfo => deleteDatasetFolderById(datasetFolderInfo.FolderId))
        }

        if(projectData.Output && projectData.Output.length > 0) {
            projectData.Output.map(outputId => deleteOutputById(outputId, false))
        }
    }
}

export const { useGetProjectByIdQuery, useGetOutputByIdQuery, useGetFolderByIdQuery,
    useGetDatasetByIdQuery, useGetPlotByIdQuery, useGetProjectFolderByIdQuery, useGetUserInfoQuery,
    useGetFoldersByProjectIdQuery, useGetProjectFoldersByUserIdQuery,
    usePutProjectMutation, usePutOutputMutation, usePutFolderMutation, usePutDatasetMutation,
    usePutPlotMutation, usePutProjectFolderMutation, usePutUserInfoMutation,
    useDeleteDatasetMutation, useDeleteFolderMutation, useDeleteOutputMutation, useDeletePlotMutation,
    useDeleteProjectMutation, useDeleteProjectFolderMutation
  } = databaseApi
