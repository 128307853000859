import './App.css'
import { useState, useEffect } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import Analyze from './components/layouts/Analyze'
import InputTab from './components/layouts/InputTab'
import ManageProjects from './components/layouts/ManageProjects'
import Login from './components/layouts/Login'
//import { withAuthenticator } from '@aws-amplify/ui-react'
import { useSession } from './hooks/store/sessionHook'
import { getSessionToken } from './services/cognito'
import PrivateRoute from './components/PrivateRoute'
import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles'

const theme = createMuiTheme()

function App() {

    const [ isLoading, setIsLoading ] = useState(false)
    const { setCurrentSessionToken } = useSession()

    // useEffect(() => getSessionToken((sessionToken) => {
    //     setCurrentSessionToken(sessionToken)
    //     setIsLoading(false)
    // }), [setCurrentSessionToken])

    return (

        <div className="App">
            {isLoading && <p>Loading...</p>}
            {!isLoading && 
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <Switch>
                            <Route path="/login" component={Login} />
                            <PrivateRoute path="/projects"><ManageProjects /></PrivateRoute>
                            <PrivateRoute path="/input"><InputTab/></PrivateRoute>
                            <PrivateRoute path="/analyze"><Analyze /></PrivateRoute>
                            <Redirect from="/" to="/analyze" />
                        </Switch>
                    </BrowserRouter>
                </ThemeProvider>
            }
        </div>
    )
}

export default App
