import { useState } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import FCPowerSampleSize from './FCPowerSampleSize';
import FCPowerCurve from './FCPowerCurve';
import FCPowerCalculation from './FCPowerCalculation';
import FCPowerDelta from './FCPowerDelta'
import FCPowerAlpha from './FCPowerAlpha'
//import { Link } from 'react-router-dom'

const ForcedChoicePower = (props) => {

    const [ selectedTab, setSelectedTab ] = useState("sampleSize")
    const [ alpha, setAlpha ] = useState()
    const [ delta, setDelta ] = useState()
    const [ power, setPower ] = useState()
    const [ numTrials, setNumTrials ] = useState()
    const [ methods, setMethods ] = useState()

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue)
    }

    const shared_props = {
        alpha, setAlpha,
        delta, setDelta,
        power, setPower,
        numTrials, setNumTrials,
        methods, setMethods,
    }

    return(
        <div id="forcedChoicePowerDialog">
            <TabContext value={selectedTab}>
                <Tabs value={selectedTab} onChange={handleTabChange} variant='scrollable' scrollButtons='auto'>
                    <Tab label="Sample Size" value="sampleSize" />
                    <Tab label="Power Calculation" value="powerCalculation"/>
                    <Tab label="Delta Calculation" value="deltaCalculation"/>
                    <Tab label="Power Curve" value="powerCurve"/>
                    <Tab label="Alpha Calculation" value="alphaCalculation"/>

                </Tabs>
                <TabPanel value="sampleSize">
                    <FCPowerSampleSize {...shared_props} />
                </TabPanel>
                <TabPanel value="powerCalculation">
                    <FCPowerCalculation {...shared_props} />
                </TabPanel>
                <TabPanel value="deltaCalculation">
                    <FCPowerDelta {...shared_props} />
                </TabPanel>
                <TabPanel value="powerCurve">
                    <FCPowerCurve {...shared_props} />
                </TabPanel>
                <TabPanel value="alphaCalculation">
                    <FCPowerAlpha {...shared_props} />
                </TabPanel>
            </TabContext>
        </div>
    )
}

export default ForcedChoicePower
