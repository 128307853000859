import { useState, useEffect, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import XLSX from 'xlsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    uploadBoxContainer: {
        minWidth: '400px',
        borderStyle: 'dashed',
        borderWidth: '3px',
        borderRadius: '3px',
        margin: '10px',
        minHeight: '100px',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
    },
    uploadBoxInteral: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
    },
    trackersContainer: {
        border: 'gray solid 1 px',
        borderRadius: '2px',
        padding: '5px',
        margin: '5px',
    },
    uploadTracker: {
        width: '95%',
        padding: '5px',
        margin: '5px auto',
        display: 'flex',
        flexFlow: 'row nowrap',
    },
    trackerProgressBar: {
        width: '100%',
    },
    trackerFilename: {
        alignSelf: 'start',
    },
    trackerPercentage: {
        alignSelf: 'end',
    },
    trackerTextContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
    },
    cancelUploadButton: {
        padding: '4px',
        minWidth: '45px',
        margin: '8px 0px 3px 3px'
    },
})

const UploadDatasets = (props) => {

    const classes = useStyles()

    const [ exampleTrackers, setExampleTrackers ] = useState(null)
    const [ alertSeverity, setAlertSeverity ] = useState(null)
    const [ alertMessage, setAlertMessage ] = useState(null)

    useEffect(() => {

        setTimeout(() => {
            setExampleTrackers(
                <>
                    <UploadTracker />
                    <UploadTracker />
                    <UploadTracker />
                </>
            )
        }, 1500)
    }, [])

    const onDrag = () => {
        console.log("onDrag")
    }

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        // Do something with the files
        const files = acceptedFiles.map(file => file)
        files.forEach(file => {
            var reader = new FileReader()
            reader.onload = function(e) {
                var data = new Uint8Array(e.target.result)
                var workbook = XLSX.read(data, {type: 'array'})
                var firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];
                var worksheetData = XLSX.utils.sheet_to_json(firstWorksheet, {header:1}); // header == 1 -> array of arrays, header == "A" -> row object keys are literal column labels
                var columnHeaders = worksheetData[0]
                console.log(columnHeaders)
            }
            reader.readAsArrayBuffer(file)
        })
        
        if(rejectedFiles.length > 0) {
            console.log("rejected file events: ")
            const errors = rejectedFiles.map((rejectEvent) =>
                rejectEvent.errors.map((error) => error.code)
            ).flat()

            let alerts = []
            if(errors.includes('file-invalid-type')) {
                alerts.push("Only .xlsx and .csv files are supported.")
            }
            if(errors.includes('file-too-large')) {
                alerts.push("Files must be 1 megabyte or smaller.")
            }

            if(alerts.length > 0) {
                setAlertSeverity("error")
                setAlertMessage(alerts)
            }
        }
      }, [])

    const { 
        acceptedFiles,
        fileRejections, 
        rootRef,
        getRootProps, 
        getInputProps, 
        isDragActive } = useDropzone({ 
            onDrop,
            onDrag,
            accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv',
            maxFiles: 25,
            maxSize: 409600, // 400kb // 1048576 = one megabyte in bytes
        });

    return (
        <div id="uploadDatasetDialog">
            <div className={classes.uploadBoxContainer} style={{ borderColor: (isDragActive ? "#0066FF" : "gray") }}>
                <div style={{ display: 'flex', flex: '1', width: '100%', height: '100%', margin: '5px', justifyContent: 'center', alignItems: 'center' }}
                    {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <div className={classes.uploadBoxInteral}>
                        <CloudUploadIcon fontSize='large'/>
                        <Typography>
                            {
                                isDragActive ? "Drop files here" : <>Drag and drop files, or <strong>click here to select files.</strong></>
                            }
                        </Typography>
                    </div>
                </div>
            </div>
            { alertMessage && 
            <Alert 
                severity={alertSeverity}
                onClose={() => setAlertMessage(null)}
                >
                <AlertTitle>{ alertSeverity === "error" ? "Error" : alertSeverity === "success" ? "Success" : null }</AlertTitle>
                { Array.isArray(alertMessage) ? alertMessage.map(message => (<Typography>{message}</Typography>)) : <Typography>{alertMessage}</Typography>}
            </Alert> }
            <div className={classes.trackerContainer}>
                { exampleTrackers }
            </div>
        </div>
    )
}

export default UploadDatasets

const UploadTracker = (props) => {

    const classes = useStyles()

    const [ progress, setProgress ] = useState(0)
    const [ exampleMult, setExampleMult ] = useState(5)

    useEffect(() => {
        setExampleMult(Math.random() * 10)

        const timer = setInterval(() => {
            setProgress(oldProgress => {
                if (oldProgress === 100) {
                    clearInterval(timer)
                    return 100
                }
                const diff = 2 * Math.random() * exampleMult;
                return Math.min(oldProgress + diff, 100)
            })
        }, 500);

        return () => {
            clearInterval(timer)
        }
    }, [])

    return(
        <div className={classes.uploadTracker}>
            <div style={{ width: '100%' }}>
                <div className={classes.trackerTextContainer}>
                    <Typography className={classes.trackerFilename} >Filename</Typography>
                    <Typography className={classes.trackerPercentage} >{Math.round(progress) + '%'}</Typography>
                </div>
                <LinearProgress className={classes.trackerProgressBar} variant="determinate" value={progress} />
            </div>
            <Button className={classes.cancelUploadButton}>
                { progress < 100 ? <CancelIcon /> : <CheckCircleOutlineIcon /> }
            </Button>
        </div>
    )
}
