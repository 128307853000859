import { createSlice, current } from '@reduxjs/toolkit'
import { root_project_folder_id } from '../app/global_state'

const initialProjectState = [
    {
        ProjectId: '_no_project_selected_',
        Name: 'No Project Selected',
        DatasetFolders: [ '_dataset_root_folder_' ],
        Output: [ '1' ],
        ParentFolder: root_project_folder_id,
    },
]

const projectsSlice = createSlice({
    name: 'projects',
    initialState: initialProjectState,
    reducers: {
        addProject(state, action) {
            return ([
                ...state,
                action.payload,
            ])
        },
        deleteProject(state, action) {
            const { id } = action.payload
            return state.filter(project => project.ProjectId !== id)
        },
        addDatasetFolderToProject(state, action) {
            const { projectId, datasetFolderId } = action.payload
            const existingProject = state.find(project => project.ProjectId === projectId)
            if(existingProject) {
                existingProject.DatasetFolders.push(datasetFolderId)
            }
        },
        removeDatasetFolderFromProject(state, action) {
            const { projectId, datasetFolderId } = action.payload
            const existingProject = state.find(project => project.ProjectId === projectId)
            if(existingProject) {
                existingProject.DatasetFolders = existingProject.DatasetFolders.filter(datasetFolder => datasetFolder.FolderId !== datasetFolderId)
            }
        },
        addOutputToProject(state, action) {
            const { projectId, outputId } = action.payload
            const existingProject = state.find(project => project.ProjectId === projectId)
            if(existingProject) {
                existingProject.Output.push(outputId)
            }
        },
        removeOutputFromProject(state, action) {
            const { projectId, outputId } = action.payload
            const existingProject = state.find(project => project.ProjectId === projectId)

            if(existingProject) {
                const newOutputArray = existingProject.Output.filter(outId => outId !== outputId)
                existingProject.Output = newOutputArray
            }
        },
        changeProjectName(state, action) {
            const { projectId, newName } = action.payload
            const existingProject = state.find(project => project.ProjectId === projectId)
            if(existingProject) {
                existingProject.Name = newName
            }
        },
        changeProjectParentFolder(state, action) {
            const { projectId, newParentId } = action.payload
            const existingProject = state.find(project => project.ProjectId === projectId)
            if(existingProject) {
                existingProject.ParentFolder = newParentId
            }
        }
    }
})

export const { addProject, deleteProject, addOutputToProject, removeOutputFromProject, 
    addDatasetFolderToProject, removeDatasetFolderFromProject, 
    changeProjectName, changeProjectParentFolder } = projectsSlice.actions

export default projectsSlice.reducer
