import { useState, useEffect } from 'react'
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box'

export const DecisionRuleChooserRadio = ({methodNameDict, setValidatedValue, isValidating, defaultValue=null}) => {

    const [ method, setMethod ] = useState(defaultValue)
    const [ error, setError ] = useState(false)
    
    let borderStyle = { border: '0px solid red', paddingLeft: '6px', marginTop: '6px' }
    if(error) {
        borderStyle = { border: '2px solid red', paddingLeft: '6px', marginTop: '6px' }
    }

    useEffect(() => {
        if(!method) {
            setError(isValidating)
            setValidatedValue(null)
        } else {
            setError(false)
            setValidatedValue(method)
        }
    }, [method, isValidating, setValidatedValue])

    return (
        <FormControl component="fieldset" error={error}>
            <Box style={borderStyle} borderRadius="6px">
                <FormLabel component="legend" style={{color: 'black', textAlign: 'left'}}>Method</FormLabel>
                <RadioGroup
                    aria-label="method"
                    name="method"
                    value={method}
                    onChange={(event) => setMethod(event.target.value)}
                >
                    {Object.entries(methodNameDict).map(([key, value]) => {
                        return (
                            <FormControlLabel
                                value={key}
                                control={<Radio />}
                                label={value}
                                key={key}
                            />
                        )
                    })}
                </RadioGroup>
                <FormHelperText>
                    {error ? 'Please select a method.' : ''}
                </FormHelperText>
            </Box>
        </FormControl>
    )
}

export const DecisionRuleChooserCheckbox = ({ methodNameDict, setValidatedValue, isValidating=false, defaultValue }) => {

    const [ error, setError ] = useState(false)
    const [ checkBoxState, setCheckBoxState ] = useState(defaultValue ? defaultValue : Object.assign({}, ...Object.keys(methodNameDict).map(key => ({[key]: false}))))

    let borderStyle = { border: '0px solid red', paddingLeft: '6px', marginTop: '6px' }
    if(error) {
        borderStyle = { border: '2px solid red', paddingLeft: '6px', marginTop: '6px' }
    }

    useEffect(() => {
            
        if(!isValidCheckBoxState(checkBoxState)) {
            setError(isValidating)
            setValidatedValue(null)
        } else {
            setError(false)
            setValidatedValue(checkBoxState)
        }
    }, [checkBoxState, isValidating, setValidatedValue])

    const handleMethodsChange = (event) => {
        setCheckBoxState({ ...checkBoxState, [event.target.name]: event.target.checked });
    }

    return (
        <FormControl component="fieldset" error={error}>
            <FormLabel component="legend" style={{color: 'black'}}>Method</FormLabel>
            <Box style={borderStyle} borderRadius='6px' >
            <FormGroup>
                {
                    Object.entries(methodNameDict).map(([key, value]) => {
                        return(<FormControlLabel
                            value={key}
                            control={<Checkbox checked={checkBoxState[key]} onChange={handleMethodsChange} name={key} />}
                            label={value}
                            key={key}
                        />)
                    })
                }
            </FormGroup>
            <FormHelperText style={{ marginBottom: '2px' }}>{error ? 'Please select a method.' : ''}</FormHelperText>
            </Box>
        </FormControl>
    )
}

export function isValidCheckBoxState(checkBoxState) {
    if(checkBoxState) {
        return Object.values(checkBoxState).filter(v => v).length > 0
    } else {
        return false
    }
}

export function decisionRuleDictToArray(checkBoxState) {
    return Object.keys(Object.fromEntries(Object.entries(checkBoxState).filter(([key, value]) => value)));
}
