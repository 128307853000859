import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import AutocompleteDropdown from './AutocompleteDropdown'
import { useGetProjectFoldersByUserIdQuery } from '../../services/databaseApi'
import { skipToken } from '@reduxjs/toolkit/dist/query'

const ProjectsSelector = ({ selectedProject, setSelectedProject }) => {
 
    const projectFolderId = useSelector(state => state.session.rootProjectFolderId)
    const userId = useSelector(state => state.session.userId)
    //const { allProjects } = useProjects()
    const { data: projectFolderData, isLoading : isProjectFolderLoading } = useGetProjectFoldersByUserIdQuery(userId ?? skipToken)
    const [ projects, setProjects ] = useState(null)

    useEffect(() => {
        if(projectFolderData) {
           setProjects(getProjectsFromProjectFolders(projectFolderData))
        }
    }, [projectFolderData, isProjectFolderLoading])

    const getProjectsFromProjectFolders = projectFolderArray => {
        return(projectFolderArray.map(projInfo => projInfo.Projects).flat())
    }

    const getOptionLabelFunc = (option) => {
        return option.Name
    }

    const renderOption = (option) => (<p>{option.Name}</p>)

    return (
        <div className="projectsSelector">
            <AutocompleteDropdown options={projects} //llProjects.filter(project => project.ProjectId !== "_no_project_selected_").map(project => project.Name)
                value={selectedProject} 
                setValue={setSelectedProject} 
                getOptionLabel={getOptionLabelFunc}
                renderOption={renderOption}
                label="Choose project:" 
                variant="outlined" />
        </div>
    )

}

export default ProjectsSelector
