import { useState, useRef } from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Input from '@material-ui/core/Input'
import FormHelperText from '@material-ui/core/FormHelperText'
import Button from '@material-ui/core/Button'
import { useLocation, Redirect } from 'react-router-dom'
import { useSession } from '../../hooks/store/sessionHook'

import { authenticateUser, forgotPassword } from '../../services/cognito'
import { setupDBClient, getUserInfo } from "../../services/dynamoDB"

const Login = (props) => {

    const { isLoggedIn, setCurrentSessionToken, setUserId, setRootProjectFolderId, setDefaultProjectId, setCurrentProjectId } = useSession()

    const [ isLoading, setIsLoading ] = useState(false)
    const [ redirectToReferrer, setRedirectToReferrer ] = useState(isLoggedIn)
    const [ showNewPassword, setShowNewPassword ] = useState(false)
    const [ cognitoUserTemp, setCognitoUserTemp ] = useState(null)
    const [ userAttributesTemp, setUserAttributesTemp ] = useState(null)
    const [ errorMessage, setErrorMessage ] = useState(null)
    const formData = useRef()
    
    const { state } = useLocation()
    
    const handleSubmit = (event) => {

        event.preventDefault()

        setIsLoading(true)

        const username = event.target.username.value
        const password = event.target.password.value

        authenticateUser(username, password, (error, sessionToken, cognitoUser, userAttributes) => {
            
            if(error === "newPasswordRequired") {
                
                setCognitoUserTemp(cognitoUser)
                delete userAttributes.email_verified
                delete userAttributes.email
                setUserAttributesTemp(userAttributes)

                setShowNewPassword(true)
                setIsLoading(false)
                return
            }


            if (error) {
                console.log(error)
                setIsLoading(false)
                return
            }

            setCurrentSessionToken(sessionToken)
            setRedirectToReferrer(true)
            setupDBClient()
            getUserInfo(username).then(userInfo => {
                if(userInfo) {
                    setUserId(userInfo.UserId)
                    setRootProjectFolderId(userInfo.RootProjectFolderId)
                    setDefaultProjectId(userInfo.DefaultProjectId)
                    if(userInfo.CurrentProjectId) {
                        setCurrentProjectId(userInfo.CurrentProjectId)
                    } else {
                        setCurrentProjectId(userInfo.DefaultProjectId)
                    }
                } else {
                    //TODO: Handle error!
                }
            })
        })
    }

    const handleForgotPassword = (event) => {
        
        event.preventDefault()
        
        setIsLoading(true)

        // const username = event.target.username.value
        const username = formData.current.username.value

        forgotPassword(username, null)

        setIsLoading(false)

    }

    const handleChangePassword = (event) => {
        
        event.preventDefault()

        setIsLoading(true)
        setErrorMessage(null)

        const newPassword = event.target.newPassword.value
        
        cognitoUserTemp.completeNewPasswordChallenge(newPassword, userAttributesTemp, {
            onSuccess: result => {
                //login
                console.log("Success")
                console.log(result)
                setIsLoading(false)
                setShowNewPassword(false)
            },
            onFailure: error => {
                console.log("Failed to change password")
                console.log(error)
                setIsLoading(false)
                setErrorMessage(error.message)
            }
        })
        setIsLoading(false)
    }

    if(redirectToReferrer) {
        let redirectPath = state?.from.pathname || '/'
        if(redirectPath === '/login') {
            redirectPath = '/'
        }
        return <Redirect to={redirectPath} />
    }

    if(showNewPassword) {
        return(<NewPasswordDialog submitHandler={handleChangePassword} isLoading={isLoading} errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>)
    } else {
        return(<LoginDialog submitHandler={handleSubmit} forgotPasswordHandler={handleForgotPassword} 
            formRef={formData} isLoading={isLoading} errorMessage={errorMessage} setErrorMessage={setErrorMessage} />)
    }
}

const LoginDialog = ({submitHandler, forgotPasswordHandler, isLoading, errorMessage, formRef, setErrorMessage}) => {
    return(
        <div id="loginDialog">
            <form onSubmit={submitHandler} 
                style={{ display: 'flex', flexFlow: 'column nowrap', justifyItems: 'space-around', alignItems: 'center' }}
                ref={formRef}>
                <h4>Login to IFPrograms Online</h4>
                <img src='img/IFPrograms_Opening.png' alt='IFPrograms Logo' style={{ maxWidth: '20%', maxHeight: '20%' }}/>
                <div style={{ display: 'flex', flexFlow: 'column nowrap', alignItems: 'start', justifyContent: 'space-around' }}>
                    <FormLabel htmlFor='username' style={{ marginTop: '5px'}} >Username</FormLabel>
                    <Input id='username' style={{ marginTop: '0px', marginBottom: '8px' }} />
                    {/* <FormControl> */}
                    <FormLabel  htmlFor='password' >Password</FormLabel>
                    <Input id='password' style={{ marginTop: '0px', marginBottom: '8px' }} 
                        type="password" />
                        {/* <FormHelperText error></FormHelperText> */}
                    {/* </FormControl> */}
                </div>
                { errorMessage ? <p>{errorMessage}</p> : null }
                <Button                
                        //className={classes.loginButton}
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isLoading}
                    >Login</Button>
                <Button
                    style={{marginTop: '10px'}}
                    variant="contained"
                    color="primary"
                    type="button"
                    disabled={isLoading}
                    onClick={(event) => forgotPasswordHandler(event)}>
                    Forgot Password
                </Button>
            </form>
        </div>
    )
}

const NewPasswordDialog = ({submitHandler, isLoading, errorMessage, setErrorMessage}) => {
    
    const [ passwordMismatch, setPasswordMismatch ] = useState(false)

    const handleSubmitWrapper = (event) => {
        
        event.preventDefault()

        if(event.target.newPassword.value !== event.target.newPasswordCheck.value) {
            setPasswordMismatch(true)
            setErrorMessage("Passwords do not match")
        } else {
            setPasswordMismatch(false)
            setErrorMessage(null)
            submitHandler(event)
        }
    }

    return(
        <div id="newPasswordDialog">
            <form onSubmit={handleSubmitWrapper} style={{ display: 'flex', flexFlow: 'column nowrap', justifyItems: 'space-around', alignItems: 'center' }}>
                <h4>Change Password</h4>
                <img src='img/IFPrograms_Opening.png' alt='IFPrograms Logo' style={{ maxWidth: '20%', maxHeight: '20%' }}/>
                <div style={{ display: 'flex', flexFlow: 'column nowrap', alignItems: 'start', justifyContent: 'space-around' }}>
                    <FormLabel htmlFor='newPassword' style={{ marginTop: '5px'}} >New Password</FormLabel>
                    <Input id='newPassword' style={{ marginTop: '0px', marginBottom: '8px' }} type="password"/>
                    {/* <FormControl> */}
                    <FormLabel  htmlFor='newPasswordCheck' >Confirm New Password</FormLabel>
                    <Input id='newPasswordCheck' style={{ marginTop: '0px', marginBottom: '8px' }} 
                        type="password" />
                        {/* <FormHelperText error></FormHelperText> */}
                    {/* </FormControl> */}
                    { errorMessage ? <p>{errorMessage}</p> : null }
                </div>
                <Button                
                        //className={classes.loginButton}
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isLoading}
                    >Submit</Button>
            </form>
        </div>
    )
}

export default Login
