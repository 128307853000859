import { React, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'

import NavigationBar from './NavigationBar'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
    mainLayout: {
        height: '100%'
    },
    // mainWindow: {
    //     height: '85%' //89
    // },
    centerPanel: {
        float: 'left',
        minWidth: '50%',
        flex: 1,
    },
    rightPanel: {
        width: '100%',
        flex: 1,
    }
//     appBar: {
//         // Make the app bar z-index always one more than the drawer z-index
//         zIndex: theme.zIndex.drawer + 1,
//     },
//     toolbar: theme.mixins.toolbar,
}))

const MainLayout = (props) => {

    const classes = useStyles()

    const location = useLocation()
    
    const [ mainWindowHeight, setMainWindowHeight ] = useState('85%')

    useEffect(() => {
        switch(location.pathname.split('/')[1]) {
            case 'analyze':
                setMainWindowHeight('83%')
                break
            default:
                setMainWindowHeight('85%')
                break
        }
    }, [location])

    return (
        <div className={classes.mainLayout}>
            <NavigationBar pathname={props.pathname} />
            <Box className={classes.mainWindow} display='flex' flexDirection='row' padding={2} height={mainWindowHeight} >
                <div id='leftPanel' >{props.leftPanel}</div>
                <div id='centerPanel' className={classes.centerPanel} style={{marginRight: 8}}>{props.children}</div>
                {props.rightPanel && <div id='rightPanel' className={classes.rightPanel}>{props.rightPanel}</div>}
            </Box>
        </div>
    )
}

export default MainLayout
