import { useState, useEffect, Fragment } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip'
//import Typography from '@material-ui/core/Typography';
import Bookmark from '@material-ui/icons/Bookmark'
import BookmarkBorder from '@material-ui/icons/BookmarkBorder'
import AddToPhotos from '@material-ui/icons/AddToPhotos';
import Delete from '@material-ui/icons/Delete';
import ZoomOutMap from '@material-ui/icons/ZoomOutMap'
import SaveAlt from '@material-ui/icons/SaveAlt';
import Comment from '@material-ui/icons/Comment'
//import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CardActions from '@material-ui/core/CardActions';
import { TableHead, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { makeStyles } from '@material-ui/core/styles';
import Plotly from 'plotly.js'

import LineChart from './LineChart'
import { useAllOutput } from '../../hooks/store/allOutputHook'
import { useGetOutputByIdQuery } from '../../services/databaseApi';
import { skipToken } from '@reduxjs/toolkit/dist/query';


const useStyles = makeStyles({
    card: {
        minWidth: '275px',
        margin: '4px 2px',
        padding: '4px',
        height: 'auto'
    },
    table: {
        width: 'auto',
        tableLayout: 'auto',
        height: 'auto'
    },
    plotStyle: {
        width: 'auto',
        height: '30vh',
        display: 'block',
    },
    comment: {
        width: '95%',
        minWidth: '100px',
        height: '90%',
        minHeight: '40px',
        resize: 'none',
        marginBottom: '10px'
    }
})

const OutputEntry = ({ outputId, onModalOpen, onDeleteEntry, onToggleSavedToProject }) => {

    const classes = useStyles()
    
    const { data: entry, error: outputError, isLoading: isOutputLoading, isSuccess: isOutputSuccess,
         isFetching: isOutputFetching, refetch } = useGetOutputByIdQuery(outputId ?? skipToken)

    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
      })
    
      const detectSize = () => {
        detectHW({
          winWidth: window.innerWidth,
          winHeight: window.innerHeight,
        })
      }
    
      useEffect(() => {
        window.addEventListener('resize', detectSize)
    
        return () => {
          window.removeEventListener('resize', detectSize)
        }
      }, [windowDimension])

    const [ graphDiv, setGraphDiv ] = useState(null)
    const [ saved, setSaved ] = useState(true) //entry.savedToProject)
    const [ isOpenComments, setIsOpenComments ] = useState((entry && entry.isOpenComment) || false)
    const [ textAreaContent, setTextAreaContent ] = useState('')
    const [ message, setMessage ] = useState(null)
    const [ refetches, setRefetches ] = useState(0)

    const { updateComment, toggleIsOpenComment } = useAllOutput()

    useEffect(() => {
        if(entry && entry.Comment) {
            setTextAreaContent(entry.Comment)
        }
    }, [entry])

    useEffect(() => {
        if(!entry && !isOutputSuccess && !isOutputLoading) {
            setMessage("Error...")
        } else if(isOutputLoading && !entry) {
            setMessage("Loading...")
        } else {
            setMessage(null)
        }
    }, [ entry, isOutputLoading, isOutputSuccess])
    
    if(message && !entry) {
        return(<p>{message}</p>)
    } else if(!entry) {
        if(!isOutputLoading && !isOutputFetching && refetches < 5) {
            refetch()
            setRefetches(a => a + 1)
        }
        return(<p>Error</p>)
    }

    const handleToggleSavedToProject = () => {
        setSaved(!saved)
        if(onToggleSavedToProject) {
            onToggleSavedToProject(entry.OutputId)
        }
    }

    const handleCommentsClick = (entryId) => {
        setIsOpenComments(!isOpenComments)
        toggleIsOpenComment(entryId)
    }

    const handleCommentsBlur = (entryId) => {
        updateComment(entryId, textAreaContent)
    }

    const downloadChart = () => {
        if(graphDiv !== null) {
            Plotly.downloadImage(graphDiv, { format:'png', height:450, width:800, filename:entry.Title })
        }
    }

    const getPlot = (plotId, heightMultiplier = 0.25) => {
        return(
            <LineChart key={plotId} plotId={plotId} height={windowDimension.winHeight * heightMultiplier} width={windowDimension.winWidth * 0.25}
            onDoubleClick={() => onModalOpen(plotId)} onInitialized={(figure, graphDivEl) => setGraphDiv(graphDivEl)} />)
    }

    const getTable = (tableId) => {

        const tableData = entry.Tables.find(table => table.TableId === tableId)

        if(!tableData || !tableData.TableContent) {
            return(null)
        }

        const title = tableData?.Title
        let columnHeaders = null
        if(tableData.OptionsList) {
            if(tableData.OptionsList.ColumnHeaders) {
                columnHeaders = tableData.TableContent[0]
            }
        }
        
        const rowStartIndex = columnHeaders ? 1 : 0

        return(
            <Fragment>
                <div style={{ display: "block", marginBottom: '10px'}}>
                    {title ? (<div style={{marginBottom: '5px'}}><strong>{title}:</strong></div>) : null}
                    <TableContainer component={Paper} style={{display: 'inline-block', width: 'auto'}}>
                        <Table className={classes.table} size='small' >
                            { columnHeaders ?
                                <TableHead>
                                    <TableRow style={{ backgroundColor: 'lightblue', bottomBorder: '2px solid gray' }}>
                                        {columnHeaders.map((header) => (
                                            <TableCell
                                                key={header}
                                                align="center">
                                                {header}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                            : null }
                            <TableBody sx={( columnHeaders ? null : { borderTop: '12px solid lightblue'})}> 
                                {tableData.TableContent.slice(rowStartIndex).map((row, index) => (
                                    <TableRow key={row[0] + "@" + index} >
                                            {Array.isArray(row) ? 
                                                row.map((column) => (
                                                    <TableCell align="center">
                                                        {column}
                                                    </TableCell>
                                                )) : null 
                                            }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Fragment>
        )
    }

    const streamItems = entry.Stream?.map(streamItem => {
        
        if(!streamItem || !streamItem.Type || !streamItem.Content) {
            return(null)
        }

        if(streamItem.Type === "text") {
            return(<p>{streamItem.Content}</p>)
        } else if(streamItem.Type === "plot") {
            return(getPlot(streamItem.Content, streamItem.HeightMultiplier ?? 0.25))
        } else if(streamItem.Type === "table") {
            return(getTable(streamItem.Content))
        } else {
            return(null)
        }
    })

    // const plots = entry.Plots ? (
    //     entry.Plots.map(plotId => {
    //         return(
    //             <LineChart key={plotId} plotId={plotId} height={windowDimension.winHeight * 0.25} width={windowDimension.winWidth * 0.25}
    //             onDoubleClick={() => onModalOpen(plotId)} onInitialized={(figure, graphDivEl) => setGraphDiv(graphDivEl)} />) // {/*(plotInfo, graphDivId) => setGraphDiv(graphDivId)*/}
    //     })) : null

    // const table = entry.Table !== undefined && entry.Table !== null && entry.Table !== "" ? (
    //     <TableContainer component={Paper} style={{display: 'inline-block', width: 'auto'}}>
    //         <Table className={classes.table} size='small' >
    //             <TableBody>
    //                 {entry.Table.map(row => (
    //                     <TableRow key={row[0]}>
    //                         <TableCell align="center">
    //                             {row[0]}
    //                         </TableCell>
    //                         <TableCell align="center">
    //                             {row[1]}
    //                             {/* {isNaN(row[1]) ? row[1] : Number(row[1]).toFixed(2)} */}
    //                         </TableCell>
    //                     </TableRow>
    //                 ))}
    //             </TableBody>
    //         </Table>
    //     </TableContainer>
    // ) : null

    // const tables = entry.Tables ? (
    //     entry.Tables.map(table => {
    //         const title = table[0][0] === "title" ? table[0][1] : null
    //         const columnHeaders = table[1][0] === "columnHeaders" ? table[1][1] : false
    //         const rowStartIndex = columnHeaders ? 1 : 0
    //         const reducedTable = table.map(row => (row[0] === "title" || row[0] === "columnHeaders") ? null : row).filter(row => row)
    //         return(<Fragment>
    //     <div style={{ display: "block", marginBottom: '10px'}}>
    //     {title ? (<div style={{marginBottom: '5px'}}><strong>{title}:</strong></div>) : null}
    //     <TableContainer component={Paper} style={{display: 'inline-block', width: 'auto'}}>
    //         <Table className={classes.table} size='small' >
    //             { columnHeaders ?
    //             <TableHead>
    //                 <TableRow style={{ backgroundColor: 'lightblue', bottomBorder: '2px solid gray' }}>
    //                 {reducedTable[0].map((header) => (
    //                     <TableCell
    //                         key={header}
    //                         align="center">
    //                         {header}
    //                     </TableCell>
    //                 ))}
    //                 </TableRow>
    //             </TableHead>
    //             : null }
    //             <TableBody>
    //                 {reducedTable.slice(rowStartIndex).map(row => (
    //                     <TableRow key={row[0]}>
    //                         <TableCell align="center">
    //                             {row[0]}
    //                         </TableCell>
    //                         {
    //                             row.slice(1).map((column) => (
    //                                 <TableCell align="center">
    //                                     {/* {isNaN(column) ? column : Number(column).toFixed(2)} */}
    //                                     {column}
    //                                 </TableCell>
    //                             ))
    //                         }
    //                     </TableRow>
    //                 ))}
    //             </TableBody>
    //         </Table>
    //     </TableContainer>
    //     </div>
    //     </Fragment>)}
    //     )
    // ) : null



    return (
        <Card className={classes.card} variant="outlined">
            <CardContent style={{padding: '0px', margin: '0px'}}>
                <h4 style={{ textAlign: 'left', padding: '4px 4px', margin: '4px 4px 4px 4px' }}>{entry.Title}</h4>
                {/* {entry.TextContent !== null && <p>{entry.TextContent}</p>} */}
                {entry.Loading && <h4>Loading...</h4>}
                {/* {table}
                {tables}
                {plots} */}
                {streamItems}
            </CardContent>
            <CardActions disableSpacing>
                <Tooltip title={saved ? "Release from this project" : "Keep in this project"} aria-label="Toggle Saved to Project">
                    <IconButton onClick={handleToggleSavedToProject} >
                        {saved ? <Bookmark /> : <BookmarkBorder />}
                    </IconButton>
                </Tooltip>
                <Tooltip title="Comments" aria-label="Comments">
                    <IconButton onClick={() => handleCommentsClick(entry.OutputId)}>
                        <Comment />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title="Add to another project"
                    aria-label="Add to another project"
                >
                    <IconButton>
                        <AddToPhotos />
                    </IconButton>
                </Tooltip>
                {entry.Plots && (
                    <Tooltip title="Expand chart" aria-label="Expand chart">
                        <IconButton onClick={() => entry.Plots ? onModalOpen(entry.Plots[0]) : null }>
                            <ZoomOutMap />
                        </IconButton>
                    </Tooltip>
                )}
                {
                    graphDiv !== null && (
                    <Tooltip title="Download chart" aria-label="Download chart">
                        <IconButton onClick={() => downloadChart()}>
                            <SaveAlt />
                        </IconButton>
                    </Tooltip>
                    )
                }
                <Tooltip title="Delete" aria-label="Delete">
                    <IconButton onClick={() => onDeleteEntry(entry.OutputId, entry)}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </CardActions>
            {isOpenComments && <TextareaAutosize
                className={classes.comment}
                value={textAreaContent}
                minRows={1}
                onChange={(event) => setTextAreaContent(event.target.value)}
                onBlur={event => handleCommentsBlur(entry.OutputId)}></TextareaAutosize>
            }
        </Card>
    )
}

export default OutputEntry
