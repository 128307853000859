import { createSlice } from '@reduxjs/toolkit'
import { removePlotReference } from './plotsSlice'

const initialState = [
    {
        OutputId: '1',
        SavedToProject: true,
    },
]

const mainOutputSlice = createSlice({
    name: 'mainOutput',
    initialState,
    reducers: {
        addCard(state, action) {
            return([
                action.payload,
                ...state
            ])
        },
        removeCard(state, action) {
            const { id } = action.payload
            return state.filter(entry => entry.OutputId !== id)
        },
        toggleSavedToProject(state, { payload: outputId}) {
            const output = state.find(entry => entry.OutputId === outputId)
            output.savedToProject = !output.savedToProject
        },
        removeAllCards(state, action) {
            return []
        }
    }
})

export const { addCard, removeCard, removeAllCards, toggleSavedToProject } = mainOutputSlice.actions

export default mainOutputSlice.reducer