import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    currentProjectId: null,
    currentSessionToken: null,
    userId: null,
    rootProjectFolderId: null,
    defaultProjectId: null,
}

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setCurrentProjectId(state, action) {
            state.currentProjectId = action.payload.projectId
        },
        setCurrentSessionToken(state, action) {
            state.currentSessionToken = action.payload.token
        },
        setUserId(state, action) {
            state.userId = action.payload.userId
        },
        setRootProjectFolderId(state, action) {
            state.rootProjectFolderId = action.payload.rootProjectFolderId
        },
        setDefaultProjectId(state, action) {
            state.defaultProjectId = action.payload.projectId
        },
    }
})

export const { setCurrentProjectId, setCurrentSessionToken, setUserId, setRootProjectFolderId, setDefaultProjectId } = sessionSlice.actions

export default sessionSlice.reducer
