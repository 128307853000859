import { DynamoDBClient, GetItemCommand, PutItemCommand,
    BatchExecuteStatementCommand, BatchGetItemCommand } from '@aws-sdk/client-dynamodb'
import { DynamoDBDocumentClient, PutCommand, GetCommand, UpdateCommand, QueryCommand, DeleteCommand } from "@aws-sdk/lib-dynamodb";
//import { CognitoIdentityClient }  from '@aws-sdk/client-cognito-identity'
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers"
import store from '../app/store'
import axios from 'axios'

import poolData from '../app/cognito-pool-data.json'

let dbClient = new DynamoDBClient({ region: 'us-east-1' });
let ddbDocumentClient = null

const dynamoDBEndPoint = 'dynamodb.us-east-1.amazonaws.com'
const api = 'https://0f4krtz5li.execute-api.us-east-1.amazonaws.com/live/'

export const getAPITest = async () => {
    
    const state = store.getState()
    const token = state.session.currentSessionToken

    const data = await axios.get(api, { headers: { "Authorization": `${token}` }})
    console.log(data)
    return data
}

export const setupDBClient = () => {

    console.log("Setting up DB Client")

    const state = store.getState()
    const token = state.session.currentSessionToken
    const COGNITO_ID = 'cognito-idp.us-east-1.amazonaws.com/' + poolData.UserPoolId
    const loginData = {
        [COGNITO_ID]: token,
    }

    dbClient = new DynamoDBClient({ 
        region: 'us-east-1',
        credentials: fromCognitoIdentityPool({
            clientConfig: { region: 'us-east-1' },
            identityPoolId: poolData.IdentityPoolId,
            logins: loginData
          })
    });

    const marshallOptions = {
        convertEmptyValues: false,
        removeUndefinedValues: false,
        convertClassInstanceToMap: false,
    }
    const unmarshallOptions = {
        wrapNumbers: false, // wrapped = number as string
    }

    const translateConfig = { marshallOptions, unmarshallOptions }

    ddbDocumentClient = new DynamoDBDocumentClient.from(dbClient, translateConfig)
}

const sendGetCommand = async(params) => {
    
    const getCommand = new GetCommand(params)

    try {

        const getData = await ddbDocumentClient.send(getCommand)
        
        return(getData.Item)
        
    } catch (error) {
        //error handling
        console.log(error)
        window.history.pushState({}, '', "/login")
    } finally {
        //clean up
    }
}

const sendQueryCommand = async(params) => {
    
    const queryCommand = new QueryCommand(params)

    try {

        const queryData = await ddbDocumentClient.send(queryCommand)

        return(queryData.Items)
        
    } catch (error) {
        //error handling
        console.log(error)
    } finally {
        //clean up
    }
}

const sendDeleteCommmand = async(params) => {

    const deleteCommand = new DeleteCommand(params)

    try {

        const deleteData = await ddbDocumentClient.send(deleteCommand)

        return(deleteData)
      
    } catch (error) {
        //error handling
        console.log(error)
    } finally {
        //clean up
    }
}

export const getUserInfo = async (username) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    if(!username) {
        console.log("Error (getUserInfo): No username provided.")
        return
    }

    const getParams = {
        TableName: 'IFProgramsOnline_Users',
        Key: {
            EmailAddress: username,
        }
    };
    
    return await sendGetCommand(getParams)
}

export const getProject = async (projectId) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    if(!projectId) {
        console.log("Error (getProject): No projectId provided.")
        return
    }

    const getParams = {
        TableName: 'IFProgramsOnline_Projects',
        Key: {
            ProjectId: projectId,
        }
    };

    const projectData = await sendGetCommand(getParams)
    if(projectData) {
        projectData.id = projectData.ProjectId
    }
    return projectData
}

export const getFolder = async (folderId) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    if(!folderId) {
        console.log("Error (getFolder): No folderId provided.")
        return
    }

    const getParams = {
        TableName: 'IFProgramsOnline_Folders',
        Key: {
            FolderId: folderId,
        }
    };
    
    return await sendGetCommand(getParams)
}

export const getFoldersByProjectId = async (projectId) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    if(!projectId) {
        console.log("Error (getFoldersByProjectId): No projectId provided.")
        return
    }

    const queryParams = {
        TableName: 'IFProgramsOnline_Folders',
        IndexName: 'ParentProjectId-index',
        KeyConditionExpression: "ParentProjectId = :parentProjectId",
        ExpressionAttributeValues: {
            ":parentProjectId": projectId
        }
    };
    
    return await sendQueryCommand(queryParams)
}

export const getProjectFoldersByUserId = async (userId) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    if(!userId) {
        console.log("Error (getProjectFoldersByUserId): No userId provided.")
        return
    }

    const queryParams = {
        TableName: 'IFProgramsOnline_ProjectFolders',
        IndexName: 'OwnerId-index',
        KeyConditionExpression: "OwnerId = :ownerId",
        ExpressionAttributeValues: {
            ":ownerId": userId
        }
    };
    
    return await sendQueryCommand(queryParams)
}

export const getProjectFolder = async (projectFolderId) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    if(!projectFolderId) {
        console.log("Error (getProjectFolder): No projectFolderId provided.")
        return
    }

    const getParams = {
        TableName: 'IFProgramsOnline_ProjectFolders',
        Key: {
            ProjectFolderId: projectFolderId,
        }
    };
    
    return await sendGetCommand(getParams)
}

export const getPlot = async (plotId) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    if(!plotId) {
        console.log("Error (getPlot): No plotId provided.")
        return
    }

    const getParams = {
        TableName: 'IFProgramsOnline_Plots',
        Key: {
            PlotId: plotId,
        }
    };
    
    return await sendGetCommand(getParams)
}

export const getOutput = async (outputId) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    if(!outputId) {
        console.log("Error (getOutput): No outputId provided.")
        return
    }

    const getParams = {
        TableName: 'IFProgramsOnline_Output',
        Key: {
            OutputId: outputId,
        }
    };
    
    return await sendGetCommand(getParams)
}

export const getDataset = async (datasetId) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    if(!datasetId) {
        console.log("Error (getDatasetId): No datasetId provided.")
        return
    }

    const getParams = {
        TableName: 'IFProgramsOnline_Datasets',
        Key: {
            DatasetId: datasetId,
        }
    };
    
    return await sendGetCommand(getParams)
}

export const getAllUserOutput = async () => {

    // const params = {
    //     "RequestItems": {
    //         "IFProgramsOnline-Output": {
    //             //ConsistentRead: ,
    //             "Keys": [
    //                 {"UserID": { "S": "b0d580df-a4fa-473a-913c-4fe76054934f" }}, // S for String, N for Integer, 
    //             ]
    //         }
    //     },
    //     ReturnConsumerCapacity: 'NONE',
    // };

    // const command = new BatchGetItemCommand(params);

    try {
        // const data = await client.send(command);
        const state = store.getState()
        const token = state.session.currentSessionToken

        const data = await axios.get(`${api}/output`, { headers: { "Authorization": `${token}` }})
        console.log(data)
        return data
        // process data
    } catch (error) {
        //error handling
    } finally {
        //clean up
    }
}

export const getUserOutput = async (outputId) => {

    const getParams = {
        TableName: 'IFProgramsOnline_Output',
        Key: {
            OutputId: { S: outputId },
        },
        //ProjectionExpression: "ATTRIBUTE_NAME", // if we only want some attributes
    };
    const getCommand = new GetItemCommand(getParams)


    try {

        const getData = await dbClient.send(getCommand)
        
        // process data
        return getData.Item
    } catch (error) {
        //error handling
        console.log(error)
    } finally {
        //clean up
    }
}

export const putOutput = async (output) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    const params = {
        TableName: 'IFProgramsOnline_Output',
        Item: {
            OwnerId: output.OwnerId,
            OutputId: output.OutputId,
            Title: output.Title,
            Stream: output.Stream ? output.Stream : [],
            Tables: output.Tables ? output.Tables : [],
            Plots: output.Plots ? output.Plots : [],
            ReferencingProjectIds: output.ReferencingProjectIds,
        },
    };

    const command = new PutCommand(params);

    try {

        const data = await ddbDocumentClient.send(command);
        return data
        // process data
    } catch (error) {
        //error handling
        console.log(error)
    } finally {
        //clean up
    }

}

export const putPlot = async (plotData) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    const params = {
        TableName: 'IFProgramsOnline_Plots',
        Item: {
            OwnerId: plotData.OwnerId,
            PlotId: plotData.PlotId,
            Title: plotData.Title,
            Data: plotData.Data,
            OutputReferences: plotData.OutputReferences,
            Layout: plotData.Layout ? plotData.Layout : {}
        },
    };

    const command = new PutCommand(params);

    try {

        const data = await ddbDocumentClient.send(command);
        return data
        // process data
    } catch (error) {
        //error handling
        console.log(error)
    } finally {
        //clean up
    }
}

export const putProjectFolder = async (projectFolder) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    const params = {
        TableName: 'IFProgramsOnline_ProjectFolders',
        Item: {
            ProjectFolderId: projectFolder.ProjectFolderId,
            OwnerId: projectFolder.OwnerId,
            Name: projectFolder.Name,
            ParentFolderId: projectFolder.ParentFolderId,
            Children: projectFolder.Children,
            Projects: projectFolder.Projects,
        },
    };

    const command = new PutCommand(params);

    try {

        const data = await ddbDocumentClient.send(command);
        return data
        // process data
    } catch (error) {
        //error handling
        console.log(error)
    } finally {
        //clean up
    }
}

export const putFolder = async (folder) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    const params = {
        TableName: 'IFProgramsOnline_Folders',
        Item: {
            FolderId: folder.FolderId,
            OwnerId: folder.OwnerId,
            Name: folder.Name,
            ParentFolderId: folder.ParentFolderId,
            ParentProjectId: folder.ParentProjectId,
            Children: folder.Children,
            Datasets: folder.Datasets
        },
    };

    const command = new PutCommand(params);

    try {

        const data = await ddbDocumentClient.send(command);
        return data
        // process data
    } catch (error) {
        //error handling
        console.log(error)
    } finally {
        //clean up
    }
}

export const putProject = async (projectData) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    const params = {
        TableName: 'IFProgramsOnline_Projects',
        Item: {
            OwnerId: projectData.OwnerId,
            ProjectId: projectData.ProjectId,
            Name: projectData.Name,
            DatasetFolders: projectData.DatasetFolders,
            Output: projectData.Output,
            ParentFolderId: projectData.ParentFolderId,
        },
    };

    const command = new PutCommand(params);

    try {

        const data = await ddbDocumentClient.send(command);
        return data
        // process data
    } catch (error) {
        //error handling
        console.log(error)
    } finally {
        //clean up
    }
}

export const updateProjectOutput = async (projectId, outputIdList) => {
//TODO
    if(!ddbDocumentClient) {
        setupDBClient()
    }

    const params = {
        TableName: 'IFProgramsOnline_Projects',
        Key: {
            ProjectId: projectId,
        },
        Item: {
            Output: outputIdList,
        },
    };

    const command = new UpdateCommand(params);

    try {

        const data = await ddbDocumentClient.send(command);
        return data
        // process data
    } catch (error) {
        //error handling
        console.log(error)
    } finally {
        //clean up
    }
}

export const putDataset = async (dataset) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    const params = {
        TableName: 'IFProgramsOnline_Datasets',
        Item: {
            OwnerId: dataset.OwnerId,
            DatasetId: dataset.DatasetId,
            Name: dataset.Name,
            ParentFolderId: dataset.ParentFolderId,
            Data: dataset.Data,
            Created: dataset.Created,
        },
    };

    const command = new PutCommand(params);

    try {

        const data = await ddbDocumentClient.send(command);
        return data
        // process data
    } catch (error) {
        //error handling
        console.log(error)
    } finally {
        //clean up
    }
}

export const putUserInfo = async (userInfo) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    const params = {
        TableName: 'IFProgramsOnline_Users',
        Item: {
            EmailAddress: userInfo.username,
            CurrentProjectId: userInfo.currentProjectId,
            DefaultProjectId: userInfo.defaultProjectId,
            RootProjectFolderId: userInfo.rootProjectFolderId,
            UserId: userInfo.userID,
        },
    };

    const command = new PutCommand(params);

    try {

        const data = await ddbDocumentClient.send(command);
        return data
        // process data
    } catch (error) {
        //error handling
        console.log(error)
    } finally {
        //clean up
    }
}

export const deletePlot = async (plotId) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    if(!plotId) {
        console.log("Error (deletePlot): No plotId provided.")
        return
    }

    const deleteParams = {
        TableName: 'IFProgramsOnline_Plots',
        Key: {
            PlotId: plotId,
        }
    };
    
    return await sendDeleteCommmand(deleteParams)
}

export const deleteProject = async (projectId) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    if(!projectId) {
        console.log("Error (deleteProject): No projectId provided.")
        return
    }

    const deleteParams = {
        TableName: 'IFProgramsOnline_Projects',
        Key: {
            ProjectId: projectId,
        }
    };
    
    return await sendDeleteCommmand(deleteParams)
}

export const deleteOutput = async (outputId) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    if(!outputId) {
        console.log("Error (deleteOutput): No outputId provided.")
        return
    }

    const deleteParams = {
        TableName: 'IFProgramsOnline_Output',
        Key: {
            OutputId: outputId,
        }
    };
    
    return await sendDeleteCommmand(deleteParams)
}
    
export const deleteProjectFolder = async (projectFolderId) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    if(!projectFolderId) {
        console.log("Error (deleteProjectFolder): No projectFolderId provided.")
        return
    }

    const deleteParams = {
        TableName: 'IFProgramsOnline_ProjectFolders',
        Key: {
            ProjectFolderId: projectFolderId,
        }
    };
    
    return await sendDeleteCommmand(deleteParams)
}

export const deleteFolder = async (folderId) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    if(!folderId) {
        console.log("Error (deleteFolder): No folderId provided.")
        return
    }

    const deleteParams = {
        TableName: 'IFProgramsOnline_Folders',
        Key: {
            FolderId: folderId,
        }
    };
    
    return await sendDeleteCommmand(deleteParams)
}

export const deleteDataset = async (datasetId) => {

    if(!ddbDocumentClient) {
        setupDBClient()
    }

    if(!datasetId) {
        console.log("Error (deleteDataset): No datasetId provided.")
        return
    }

    const deleteParams = {
        TableName: 'IFProgramsOnline_Datasets',
        Key: {
            DatasetId: datasetId,
        }
    };
    
    return await sendDeleteCommmand(deleteParams)
}
