import { nanoid } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { addProject, deleteProject, addDatasetFolderToProject, removeDatasetFolderFromProject, addOutputToProject, removeOutputFromProject, 
    changeProjectName, changeProjectParentFolder } from '../../features/projectsSlice'
import { addProjectFolder, deleteProjectFolder, addFolderToProjectFolder, 
    addProjectToProjectFolder, removeFolderFromProjectFolder, removeProjectFromProjectFolder } from '../../features/projectFoldersSlice'
import { getProject } from '../../services/dynamoDB'

export function useProjects() {
    
    const dispatch = useDispatch()
    const _projectFolders = useSelector(store => store.projectFolders)
    const _projects = useSelector(store => store.projects)

    const _addProject = useCallback(
        (projectData) => {
            if(!projectData.ProjectId) {
                projectData.ProjectId = nanoid()
            }

            dispatch(
                addProject({
                    ...projectData,
                })
            )

            return projectData.ProjectId
        },
        [dispatch]
    )

    const _getProjectById = useCallback((id) => {

        return _projects.find(project => project.ProjectId === id)
        // if((!projectData) && id) {
        //     console.log("Attempting to get project: " + id)
        //     projectData = await getProject(id)
        //     _addProject(projectData)
        // }
        //return(projectData)

    }, [_projects] )

    const _getProjectByName = useCallback((name) => {
        return _projects.find(project => project.Name === name)
    }, [_projects])

    const _addDatasetFolderToProject = useCallback((projectId, datasetFolderId) => {
        dispatch(addDatasetFolderToProject({
            projectId,
            datasetFolderId
        }))
    }, [dispatch])

    const _removeDatasetFolderFromProject = useCallback((projectId, datasetFolderId) => {
        dispatch(removeDatasetFolderFromProject({
            projectId,
            datasetFolderId
        }))
    }, [dispatch])

    const _addOutputToProject = useCallback((projectId, outputId) => {
        dispatch(addOutputToProject({
            projectId,
            outputId
        }))
    }, [dispatch])

    const _removeOutputFromProject = useCallback((projectId, outputId) => {
        dispatch(removeOutputFromProject({
            projectId,
            outputId
        }))
    }, [dispatch])

    const _getProjectFolderById = useCallback((id) => {
        return _projectFolders.find(projectFolder => projectFolder.FolderId === id)
    }, [_projectFolders])

    const _getProjectFolderByName = useCallback((name) => {
        return _projectFolders.find(projectFolder => projectFolder.Name === name)
    }, [_projectFolders])

    const _addProjectToProjectFolder = useCallback((parentFolderId, newProjectId) => {

        dispatch(addProjectToProjectFolder({
            parentFolderId: parentFolderId,
            newProjectId: newProjectId,
        }))
    }, [dispatch])

    const _addFolderToProjectFolder = useCallback((parentFolderId, childFolderId) => {

        dispatch(addFolderToProjectFolder({
            parentFolderId: parentFolderId,
            childFolderId: childFolderId,
        }))
    }, [dispatch])

    const _removeProjectFromProjectFolder = useCallback((parentFolderId, projectId) => {

        dispatch(removeProjectFromProjectFolder({
            parentFolderId: parentFolderId,
            projectId: projectId,
        }))
    }, [dispatch])

    const _removeFolderFromProjectFolder = useCallback((parentFolderId, childFolderId) => {

        dispatch(removeFolderFromProjectFolder({
            parentFolderId: parentFolderId,
            childFolderId: childFolderId,
        }))
    }, [dispatch])

    const _addProjectFolder = useCallback(
        (projectFolderData) => {
            const _id = nanoid()

            dispatch(
                addProjectFolder({
                    ...projectFolderData,
                    FolderId: _id,
                })
            )

            if(projectFolderData.parentFolderId) {
                _addFolderToProjectFolder(projectFolderData.parentFolderId, _id)
            }

            return _id
        },
        [dispatch, _addFolderToProjectFolder]
    )

    
    const addProjectWithinFolder = (projectData, projectFolderId) => {
        const newProjectId = _addProject({...projectData, parentFolderId: projectFolderId})
        dispatch(addProjectToProjectFolder({
            parentFolderId: projectFolderId,
            newProjectId: newProjectId,
        }))

        return newProjectId
    }

    return {
        allProjects: _projects,
        getProjectById: _getProjectById,
        getProjectByName: _getProjectByName,
        addProject: _addProject,
        addProjectWithinFolder: addProjectWithinFolder,
        addOutputToProject: _addOutputToProject,
        removeOutputFromProject: _removeOutputFromProject,
        addDatasetFolderToProject: _addDatasetFolderToProject,
        removeDatasetFolderFromProject: _removeDatasetFolderFromProject,
        
        allProjectFolders: _projectFolders,
        getProjectFolderById: _getProjectFolderById,
        getProjectFolderByName: _getProjectFolderByName,
        addProjectFolder: _addProjectFolder,
        addProjectToProjectFolder: _addProjectToProjectFolder,
        removeProjectFromProjectFolder: _removeProjectFromProjectFolder,
        addFolderToProjectFolder: _addFolderToProjectFolder,
        removeFolderFromProjectFolder: _removeFolderFromProjectFolder,
    }
}
