import React, { useRef, useEffect, useState } from 'react'
import jspreadsheet from 'jspreadsheet-ce'
import jsuites from 'jsuites'
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useGetDatasetByIdQuery, usePutDatasetMutation } from '../../services/databaseApi'
import { expandDataArray, reduceDataArray } from '../../utils/dataUtils'

//import "../../../node_modules/jspreadsheet-ce/dist/jspreadsheet.datatables.css";
import "../../../node_modules/jspreadsheet-ce/dist/jspreadsheet.css";
import "../../../node_modules/jsuites/dist/jsuites.css"

const useStyles = makeStyles({
    modalContainer: {
        minWidth: '100px',
        minHeight: '100px',
        display: 'flex', 
        width: '100%',
        height: '100%',
        alignItems: 'center', 
        justifyContent: 'center', 
    },
    backgroundContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'left', 
        padding: '5px', 
        background: 'white',
        maxHeight: '100%',
        maxWidth: '100%',
        minHeight: 0,
    },
    spreadSheetDiv: {
        display: 'block',
        height: '100000px', /* note: I'm not sure why but if height is not set to an exact value (i.e. not 100%, etc.) it will overflow */
        minHeight: 0,
        minWidth: 0,
        overflow: 'hidden',
        padding: '5px',
        //border: '0.5px grey solid',
    },
    headerButton: {
        margin: '5px 10px 5px 5px'
    },
})
    

const Dataset = ({ datasetId, onClose }) => {
    
    const classes = useStyles()

    const { data: datasetObject,
        isLoading: isDatasetLoading } = useGetDatasetByIdQuery(datasetId)

    const [ putDataset ] = usePutDatasetMutation()
    //const datasetsHook = useDatasets()

    //let datasetData = datasetsHook.getDatasetById(datasetId)

    const [ datasetName, setDatasetName ] = useState(datasetObject?.Name)
    const [ numChanges, setNumChanges ] = useState(0)
    const [ saved, setSaved ] = useState(true)
    // const [ tableRef, setTableRef ] = useState()
    const jRef = useRef(null);

    const onCellEdit = () => {
        setNumChanges(n => n + 1)
        setSaved(false)
    }
    const onUndo = () => {
        setNumChanges(n => n - 1)
    }

    const alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];

    useEffect(() => {

        if(!isDatasetLoading && datasetObject) {

            setDatasetName(datasetObject.Name)

            let theData = [[]]
            if(datasetObject && datasetObject.Data && datasetObject.Data.length > 0 && datasetObject.Data[0].length > 0) {
                // console.log("Setting Data: ")
                // console.log(datasetObject.Data)
                theData = expandDataArray(JSON.parse(datasetObject.Data))
            }

            let sheetStyle = { A1: 'background-color: #D3D3D3;'}
            for(let i = 1; i < 26; i++) {
                sheetStyle[alphabet[i] + 1] = 'background-color: #D3D3D3;'
            }
            for(let i = 1; i < 24; i++) {
                sheetStyle['A' + alphabet[i] + 1] = 'background-color: #D3D3D3;'
            }   
            // console.log(sheetStyle)
            
            const options = {

                data: theData,
                minDimensions: [50, 500],
                // columns: [
                //     // { title:'Product', width: 200 },
                //     // { title:'Scale', width: 200 },
                //     // { title:'Mean', width: 100 },
                // ],
                onChange: onCellEdit,
                onUndo: onUndo,
                tableWidth: '100%',
                tableHeight: '100%',
                tableOverflow: true,
                style: sheetStyle,
                allowComments: true,
                contextMenu: function(obj, x, y, e) {
                    var items = [];
            
                    if (y == null) {
                        // Insert a new column
                        if (obj.options.allowInsertColumn) {
                            items.push({
                                title:obj.options.text.insertANewColumnBefore,
                                onclick:function() {
                                    obj.insertColumn(1, parseInt(x), 1);
                                }
                            });
                        }
            
                        if (obj.options.allowInsertColumn) {
                            items.push({
                                title:obj.options.text.insertANewColumnAfter,
                                onclick:function() {
                                    obj.insertColumn(1, parseInt(x), 0);
                                }
                            });
                        }
            
                        // Delete a column
                        if (obj.options.allowDeleteColumn) {
                            items.push({
                                title:obj.options.text.deleteSelectedColumns,
                                onclick:function() {
                                    obj.deleteColumn(obj.getSelectedColumns().length ? undefined : parseInt(x));
                                }
                            });
                        }
            
                        // Rename column
                        if (obj.options.allowRenameColumn) {
                            items.push({
                                title:obj.options.text.renameThisColumn,
                                onclick:function() {
                                    obj.setHeader(x);
                                }
                            });
                        }
                    } else {
                        // Insert new row
                        if (obj.options.allowInsertRow) {
                            items.push({
                                title:obj.options.text.insertANewRowBefore,
                                onclick:function() {
                                    obj.insertRow(1, parseInt(y), 1);
                                }
                            });
                            
                            items.push({
                                title:obj.options.text.insertANewRowAfter,
                                onclick:function() {
                                    obj.insertRow(1, parseInt(y));
                                }
                            });
                        }
            
                        if (obj.options.allowDeleteRow) {
                            items.push({
                                title:obj.options.text.deleteSelectedRows,
                                onclick:function() {
                                    const numRowsToDelete = obj.getSelectedRows().length;
                                    obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
                                    obj.insertRow(numRowsToDelete, obj.rows.length, false);
                                }
                            });
                        }
                    }
            
                    // Line
                    //items.push({ type:'line' });
            
                    // Save
                    // if (obj.options.allowExport) {
                    //     items.push({
                    //         title: obj.options.text.saveAs,
                    //         shortcut: 'Ctrl + S',
                    //         onclick: function () {
                    //             obj.download();
                    //         }
                    //     });
                    // }
            
                    return items;
                }
            }

            if (jRef.current) {
                if(!jRef.current.jspreadsheet) {
                    // setTableRef(
                        jspreadsheet(jRef.current, options)
                        // );
                    // console.log("tableRef")
                    // if(tableRef) {
                    //     console.log(tableRef)
                    // }
                    // tableRef.setStyle()
                }
            }
        }
      }, [datasetObject, isDatasetLoading ]);

    const handleBlur = () => {

    }

    const onSave = () => {
        
        putDataset({
            ...datasetObject,
            Name: datasetName,
            Data: JSON.stringify(reduceDataArray(jRef.current.jspreadsheet.getData()).dataArray),
        })
        setSaved(true)
    }

    const onCloseModal = () => {
        if(!saved && numChanges > 0) {
            // TODO: confirm close without saving

        }
        
        onClose()
    }

    if(!datasetObject && !isDatasetLoading) {
        return(
            <div className={classes.modalContainer}>
                <div className={classes.backgroundContainer}>
                    <p>No dataset available</p>
                    <div style={{ display: 'inline-block', margin: '0 auto' }}>
                    <Button
                            style={{ padding: 3 }}
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={onCloseModal}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        )
    } else if(!datasetObject && isDatasetLoading) {
        return(
            <div className={classes.modalContainer}>
                <div className={classes.backgroundContainer}>
                    <p>Loading...</p>
                    <div style={{ display: 'inline-block', margin: '0 auto' }}>
                    <Button
                            style={{ padding: 3 }}
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={onCloseModal}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        )
    } else {
        return(
            <div className={classes.modalContainer}>
                <div className={classes.backgroundContainer}>
                    <Input style={{ width: '300px', margin: '5px'}} value={datasetName} onChange={(event) => setDatasetName(event.target.value)} onBlur={handleBlur} />
                    <div ref={jRef} className={classes.spreadSheetDiv} /> 
                    <div style={{ display: 'inline-block', margin: '0 auto' }}>
                        <Button
                            style={{ padding: 3, marginRight: '10px' }}
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={onSave}
                        >
                            Save
                        </Button>
                        <Button
                            style={{ padding: 3 }}
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={onCloseModal}
                        >
                            Close
                        </Button>
                    </div>  
                </div>
            </div>
        )
    }
}

export default Dataset
