
export function trimDataVector(dataVector) {
    
    if(!dataVector) {
        return null
    }

    let firstNonEmptyIndex = 0
    for(; firstNonEmptyIndex < dataVector.length; firstNonEmptyIndex++) {
        if(dataVector[firstNonEmptyIndex] && dataVector[firstNonEmptyIndex] !== "") {
            break
        }
    }

    let lastNonEmptyIndex = dataVector.length - 1
    for(; lastNonEmptyIndex > 0; lastNonEmptyIndex--) {
        if(dataVector[lastNonEmptyIndex] && dataVector[lastNonEmptyIndex] !== "") {
            break
        }
    }

    return(dataVector.slice(firstNonEmptyIndex, lastNonEmptyIndex + 1))
}

export function transposeArray(array){
    var newArray = Array(array[0].length)
    for(let i = 0; i < array[0].length; i++){
        newArray[i] = Array(array.length);
    };

    for(let i = 0; i < array.length; i++){
        for(let j = 0; j < array[0].length; j++){
            newArray[j][i] = array[i][j];
        };
    };

    return newArray;
}

export function oneToTwoDimensions(arr, numCols) {
    
    const len = (arr && arr.slice && arr.length) | 0;

    if(numCols !== Math.floor(numCols) || numCols < 1)
        throw new Error("invalid numCols: " + numCols);

    for(var chunks = [], i = 0; i < len; i += numCols)
        chunks.push(arr.slice(i, (i + 1) * numCols));
    return chunks;
}

export function reduceDataArray(dataArray) {

    if(!dataArray) {
        return null
    }

    let lastFilledRow = -1;
    let lastFilledCol = 0;
    for(let rowInd = 0; rowInd < dataArray.length; rowInd++) {
        for(let colInd = 0; colInd < dataArray[rowInd].length; colInd++) {
            if(dataArray[rowInd][colInd] !== "") {
                if(rowInd > lastFilledRow) {
                    lastFilledRow = rowInd;
                }
                if(colInd > lastFilledCol) {
                    lastFilledCol = colInd;
                }
            }
        }
    }

    let newDataArray = new Array(lastFilledRow)
    for(let rowInd = 0; rowInd <= lastFilledRow; ++rowInd) {
        newDataArray[rowInd] = (dataArray[rowInd]).slice(0, lastFilledCol + 1)
    }

    return({
        lastFilledRow: lastFilledRow,
        lastFilledCol: lastFilledCol,
        dataArray: newDataArray,
    })
}

export function expandDataArray(dataArray, minNumRows = 500, minNumCols = 30) {
    const numRows = Math.max(dataArray.length, minNumRows)
    let numCols = minNumCols
    for(let rowInd = 0; rowInd < dataArray.length; rowInd++) {
        if(dataArray[rowInd].length > numCols) {
            numCols = dataArray[rowInd].length
        }
    }

    let newDataArray = new Array(numRows)
    for(let rowInd = 0; rowInd < dataArray.length; rowInd++) {
        var newRow = (dataArray[rowInd]).concat(new Array(numCols - (dataArray[rowInd]).length))
        newDataArray[rowInd] = newRow
    }

    return newDataArray
}

export function getSigStars(pValue) {
    let sig_indicator = ""
    if(pValue < 0.001) {
        sig_indicator = "***"
    } else if(pValue < 0.01) {
        sig_indicator = "**"
    } else if(pValue < 0.05) {
        sig_indicator = "*"
    }

    return sig_indicator
}

export function roundPValue(pValue, numDigits=3, addSigStars=true) {
    
    let threshold = Math.pow(10, -numDigits)
    let result = ""

    if(pValue < threshold) {
        result = "< " + threshold;
    } else {
        result = pValue.toFixed(numDigits)
    }

    if(addSigStars) {
        result += ' ' + getSigStars(pValue)
    }

    return(result)
}
