import { useAllOutput } from "../hooks/store/allOutputHook"
import { useMainOutput } from "../hooks/store/mainOutputHook"

export const makeTimeout = (promise, time, exception) => {
	let timer;
	return Promise.race([
		promise,
		new Promise((_, reject) => timer = setTimeout(reject, time, exception))
	]).finally(() => clearTimeout(timer));
}

export function useErrorHandler() {

    const { addCard } = useMainOutput()
    const { addEntry } = useAllOutput()

    const checkAndPostError = (response) => {
        if(response.data.errorMessage) {
            const newEntryId = addEntry("Error", response.data.errorMessage)
            addCard(newEntryId)
            return true
        } else {
            return false
        }
    }

    return({
        checkAndPostError
    })
}
