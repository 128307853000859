
export const climbTreeUntilElementType = (searchElement, searchType, maxLayers = 3) => {
    
    let layerCount = 0

    while (searchElement && layerCount < maxLayers) {
        if (searchElement.type === searchType || searchElement.tagName.toLowerCase() === searchType) {
            return searchElement
        } else {
            searchElement = searchElement.parentElement
        }
    }

    return null
}

export function getFloat(data, offset = 0) {
    return new DataView(data.buffer, offset, 8).getFloat64(0, true);
}

export function getFloats(data) {
    let result = []
    let i = 0
    const dv = new DataView(data.buffer);
    while(i < data.length) {
        result.push(dv.getFloat64(i, true))
        i += 8;
    }
    return result;
}

export function getInts(data) {
    let result = []
    let i = 0
    const dv = new DataView(data.buffer);
    while(i < data.length) {
        result.push(dv.getInt16(i, true))
        i += 8;
    }
    return result;
}

export function base64ToFloats(base64Data) {
    if(base64Data) {
        return getFloats(Buffer.from(base64Data, "base64"))
    } else {
        return null
    }
}

export function base64ToInts(base64Data) {
    if(base64Data) {
        return getInts(Buffer.from(base64Data, "base64"))
    } else {
        return null
    }
}

export function FloatsToBase64(floatData) {
    if(floatData) {
        return atob(Uint8Array(floatData.buffer), "base64") //TODO
    } else {
        return null
    }
}

let IF9ColorList = [ 'blue', 'red', 'green', 'purple', 'orange', 'gray', 'lightblue', 'violet', 'brown', 'greenyellow', 'indianred', 'pink']
let D3ColorList = [
    '#1f77b4',  // muted blue
    '#ff7f0e',  // safety orange
    '#2ca02c',  // cooked asparagus green
    '#d62728',  // brick red
    '#9467bd',  // muted purple
    '#8c564b',  // chestnut brown
    '#e377c2',  // raspberry yogurt pink
    '#7f7f7f',  // middle gray
    '#bcbd22',  // curry yellow-green
    '#17becf'   // blue-teal
]

export function getDefaultColorByIndex(index) {
    //return IF9ColorListindex[index % IF9ColorList.length]
    return D3ColorList[index % D3ColorList.length]
}
