
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import MainLayout from './MainLayout'
import OutputList from '../ui/OutputList'

import DeltaEstimation from '../dialogs/discrimination/forced_choice/DeltaEstimation'
import SameDifferent from '../dialogs/discrimination/SameDifferent'
import ForcedChoicePower from '../dialogs/discrimination/forced_choice/power/Power'
import PsychometricFunctions from '../dialogs/discrimination/forced_choice/PsychometricFunctions'
import Simulation from '../dialogs/discrimination/forced_choice/Simulation'
import Equivalence from '../dialogs/discrimination/forced_choice/Equivalence'
import ProportionDiscriminators from '../dialogs/discrimination/forced_choice/PropDiscriminators'
import FCDemonstration from '../dialogs/discrimination/forced_choice/FCDemonstration'
import ANotA from '../dialogs/discrimination/ANotA'
import Applicability from '../dialogs/discrimination/forced_choice/Applicability'
import MultipleTwoAFC from '../dialogs/discrimination/forced_choice/MultipleTwoAFC'
import TwoAC from '../dialogs/discrimination/TwoAC'
import Torgersons from '../dialogs/discrimination/Torgerson'
import BetaBinomial from '../dialogs/discrimination/BetaBinomial'
import ScaleAnalysis from '../dialogs/ratings/ScaleAnalysis'
import ScalingDemo from '../dialogs/ratings/ScalingDemo'
import ConsumerRelevantConfidence from '../dialogs/discrimination/ConsumerRelevantConfidence'
import DegreeOfDifference from '../dialogs/ratings/DegreeOfDifference'
import RelativeScales from '../dialogs/ratings/RelativeScales'
import BinomialTest from '../dialogs/statistical_tests/BinomialTest'
import TTest from '../dialogs/statistical_tests/TTest'
import DPrimeTest from '../dialogs/statistical_tests/DPrimeTest'
import MultiplicativeComparison from '../dialogs/ratings/MultiplicativeComparison'
import Ranks from '../dialogs/ratings/Ranks'

const Analyze = (props) => {

    const projectId = useSelector(state => state.session.currentProjectId)

    return(
        <MainLayout rightPanel={<OutputList projectId={projectId} />}>
            <Switch>
                <Route path="/analyze/forced_choice/delta_estimation" component={DeltaEstimation} />
                <Route path="/analyze/forced_choice/power" component={ForcedChoicePower} />
                <Route path="/analyze/forced_choice/psychometric_functions" component={PsychometricFunctions} />
                <Route path="/analyze/forced_choice/simulation" component={Simulation} />
                <Route path="/analyze/forced_choice/demonstration" component={FCDemonstration} />
                <Route path="/analyze/forced_choice/equivalence" component={Equivalence} />
                <Route path="/analyze/forced_choice/proportion_discriminators" component={ProportionDiscriminators} />
                <Route path="/analyze/forced_choice/multiple_two_afc" component={MultipleTwoAFC} />
                <Route path="/analyze/a_not_a" component={ANotA} />
                <Route path="/analyze/applicability" component={Applicability} />
                <Route path="/analyze/same_different" component={SameDifferent} />
                <Route path="/analyze/two_ac" component={TwoAC} />
                <Route path="/analyze/torgersons" component={Torgersons} />
                <Route path="/analyze/beta_binomial" component={BetaBinomial} />
                <Route path="/analyze/scale_analysis" component={ScaleAnalysis} />
                <Route path="/analyze/scaling_demo" component={ScalingDemo} />
                <Route path="/analyze/degree_of_difference" component={DegreeOfDifference} />
                <Route path="/analyze/relative_scales" component={RelativeScales} />
                <Route path="/analyze/ranks" component={Ranks} />
                <Route path="/analyze/multiplicative_comparison" component={MultiplicativeComparison} />
                <Route path="/analyze/consumer_relevant_confidence" component={ConsumerRelevantConfidence} />
                <Route path="/analyze/binomial_test" component={BinomialTest} />
                <Route path="/analyze/t_test" component={TTest} />
                <Route path="/analyze/d_prime_test" component={DPrimeTest} />
                {/* <Redirect from="/analyze/" to="/analyze/forced_choice/delta_estimation"/> */}
                <Route path="/" />
            </Switch>
        </MainLayout>
    )
}

export default Analyze
