import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import axios from 'axios'

import AutocompleteDropdown from '../../ui/AutocompleteDropdown'

import { useErrorHandler } from '../../../utils/apiUtils'
import { useSelector } from 'react-redux'
import { base64ToFloats, getDefaultColorByIndex } from '../../../utils'
import { roundPValue, transposeArray } from '../../../utils/dataUtils'
import { nanoid } from '@reduxjs/toolkit'
import { useUpdateOutput } from '../../../hooks/store/useUpdateOutput'
import { useDatasets } from '../../../hooks/useDatasets'

const api = process.env.REACT_APP_API_URL

const RelativeScales = (props) => {

    const [ direction, setDirection ] = useState('leftSimilar')

    const { selectedDataset, setSelectedDataset, selectedDatasetData, datasetData, datasetOptionsList, getDatasetSize } = useDatasets()

    const { checkAndPostError } = useErrorHandler()

    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const onSubmit = (event) => {
 
        event.preventDefault()

        const { numRows, numCols } = getDatasetSize()
        const numProds = numRows - 1// we assume only 1 scale for now (minus 1 for the column headers)
        const numCats = numCols - 2 // first 2 columns are "scale" and "product"
        const catCounts = transposeArray(datasetData.slice(1).map(row => row.slice(2))).flat().map(a => Number(a))
        const prodNames = ["Reference", ...datasetData.slice(1).map(row => row[0])]

        let data = {
            function_group: "just_about_right",
            function_name: "just_about_right",
            num_prods: numProds,
            num_cats: numCats,
            alpha: 0.05,
            cat_counts: catCounts,
        }

        axios
            .post(api, data)
            .then((response) => {

                if(checkAndPostError(response)) {
                    return
                }
                
                const prodMeans = base64ToFloats(response.data.prod_means)
                const dPrimes = base64ToFloats(response.data.deltas)
                const boundaries = base64ToFloats(response.data.boundaries)
                const dPrimeVCV = base64ToFloats(response.data.d_prime_vcv)
                const dPrimeDiffs = base64ToFloats(response.data.d_prime_diffs)
                const pValues = base64ToFloats(response.data.p_values)
                const variance = base64ToFloats(response.data.variance)
                const compVars = base64ToFloats(response.data.comp_vars)
                const idealProps = base64ToFloats(response.data.ideal_props)
                
                const relativeBoundariesTableContent = [ boundaries.map((_, index) => ["" + (index + 1).toString()]),
                                                         boundaries.map(a => [a.toFixed(2)]) ]

                const ratingMeansTableContent = [
                    ['Product', 'Rating Mean'], 
                    ...prodNames.map((prodName, index) => ([prodName, dPrimes[index].toFixed(2)]))
                ]

                let prodCompTableContent = [['Product 1', 'Product 2', 'd\'', 'Variance', 'p-Value']]
                for(let prodIndex1 = 0; prodIndex1 < prodNames.length; prodIndex1++) {
                    for(let prodIndex2 = prodIndex1 + 1; prodIndex2 < prodNames.length; prodIndex2++) {
                        const dataIndex = prodIndex1 * prodNames.length + prodIndex2
                        prodCompTableContent.push([prodNames[prodIndex1] , prodNames[prodIndex2], 
                            dPrimeDiffs[dataIndex].toFixed(2), 
                            compVars[dataIndex].toFixed(2), 
                            roundPValue(pValues[dataIndex])])
                    }
                }

                const referencePropsTableContent = [ idealProps.map((_, index) => ["" + (index + 1 - (numCats + 1) / 2).toString()]),
                                                     idealProps.map(a => [a.toFixed(2)]) ]

                const relativeBoundariesTable = {
                    TableId: nanoid(),
                    Title: "Relative Boundaries",
                    OptionsList: { ColumnHeaders: true },
                    TableContent: relativeBoundariesTableContent
                }

                const ratingMeansTable = {
                    TableId: nanoid(),
                    Title: "Rating Means",
                    OptionsList: { ColumnHeaders: true },
                    TableContent: ratingMeansTableContent
                }

                const prodCompTable = {
                    TableId: nanoid(),
                    Title: "Product Comparisons",
                    OptionsList: { ColumnHeaders: true },
                    TableContent: prodCompTableContent
                }
                
                const referencePropsTable = {
                    TableId: nanoid(),
                    Title: "Reference Proportions",
                    OptionsList: { ColumnHeaders: true },
                    TableContent: referencePropsTableContent
                }

                let tempPlotData = dPrimes.map((d_prime, index) => ({
                    curveType: 'normal',
                    x_lb: -4,
                    x_ub: d_prime + 4.0,
                    mean: d_prime,
                    sd: 1,
                    type: 'line',
                    color: getDefaultColorByIndex(index),
                    name: 'name',
                    showlegend: false,
                    hoverinfo: 'none',
                    //hovertemplate: 'Delta: %{x:.2f}  Prob.: %{y:.2f}',
                }))

                // boundary lines
                // tempPlotData.push(...boundaries.map(boundary => ({
                //     x: [boundary, boundary],
                //     y: [0, 0.45],
                //     type: 'scatter',
                //     mode: 'lines',
                //     color: 'black',
                //     showlegend: false,
                //     hoverinfo: 'none',
                // })))

                const newPlotId = nanoid()
                const newOutputId = nanoid()
                const plotObject = { //addPlot({
                        OwnerId: userId,
                        PlotId: newPlotId,
                        Title: 'Relative Scales: ' + selectedDatasetData.Name,
                        Data: tempPlotData,
                        OutputReferences: [newOutputId]
                    }

                const outputObject = {
                    OwnerId: userId,
                    OutputId: newOutputId,
                    Title: 'Relative Scales: ' + selectedDatasetData.Name,
                    Stream: [
                        { Type: 'table', Content: relativeBoundariesTable.TableId },
                        { Type: 'table', Content: ratingMeansTable.TableId },
                        { Type: 'table', Content: prodCompTable.TableId },
                        { Type: 'table', Content: referencePropsTable.TableId },
                        { Type: 'plot', Content: newPlotId }
                    ],
                    Tables: [ relativeBoundariesTable, ratingMeansTable, prodCompTable, referencePropsTable ], 
                    Plots: [ newPlotId ],
                    ReferencingProjectIds: currentProjectId,
                }
                
                addOutput(outputObject, plotObject)
            })
            .catch((error) => {
                console.log('error: ')
                console.log(error)
            })
    };

    return (
        <div id='RelativeScalesDialog'>
            <h2>Relative Scales</h2>
            <form onSubmit={onSubmit}>
                <AutocompleteDropdown 
                    label="Choose dataset:"
                    variant="outlined"
                    options={datasetOptionsList} 
                    value={selectedDataset} setValue={setSelectedDataset}
                />
                <br />
                <br />
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </form>
        </div>
    )
}

export default RelativeScales
