import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Input from '@material-ui/core/Input'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import CustomTreeView from '../../ui/CustomTreeView';

//import { useDatasets } from '../../../hooks/store/datasetsHook'
import { usePutDatasetMutation, usePutFolderMutation, useGetFolderByIdQuery } from '../../../services/databaseApi'
import { useSelector } from 'react-redux'
import { nanoid } from '@reduxjs/toolkit'
import { skipToken } from '@reduxjs/toolkit/dist/query'


const useStyles = makeStyles({
    buttonRow: {
        display: 'inline-block',
        margin: '5px',
    },
    headerButton: {
        margin: '5px 10px 5px 5px'
    },
})

const AddEmptyDatasets = ({ onClose, parentFolderId, ...otherProps }) => {

    const classes = useStyles()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const { data: currentFolderObject,
        isLoading: isFolderLoading, } = useGetFolderByIdQuery(parentFolderId ?? skipToken)

    //const datasetsHook = useDatasets()
    const [ putDataset ] = usePutDatasetMutation()
    const [ putFolder ] = usePutFolderMutation()

    const [ datasetName, setDatasetName ] = useState('')
    const [ type, setType ] = useState('blank')
    const [ errorText, setErrorText ] = useState(null)

    const onSubmit = (event) => {
        
        event.preventDefault()

        if(type === "blank") {
            addBlankDataset()
        } else {
            
        }

        onClose()
    }

    const handleBlur = (event) => {
        if(errorText) {
            // TODO - Add error checking
            // if(!getErrorMessage(itemName)) {
            //     setErrorText(null)
            // }
        }
    }

    const addBlankDataset = () => {
        // const newDatasetId = datasetsHook.addDataset({ 
        //     name: datasetName,
        //     parentFolderId: parentFolderId,
        // })

        // datasetsHook.addDatasetToFolder(newDatasetId, parentFolderId)
        // datasetsHook.addProjectReferenceToDataset()

        if(!isFolderLoading && currentFolderObject) {
            const newDatasetId = nanoid()

            console.log({ 
                Name: datasetName,
                OwnerId: userId,
                ParentFolderId: parentFolderId,
                DatasetId: newDatasetId,
                Data: [[]],
                Created: Date.UTC().toString(),
            })

            putDataset({ 
                    Name: datasetName,
                    OwnerId: userId,
                    ParentFolderId: parentFolderId,
                    DatasetId: newDatasetId,
                    Data: [[]],
                    Created: Date.UTC().toString(),
                })

            if(currentFolderObject.Datasets) {
                putFolder({
                    ...currentFolderObject,
                    Datasets: [...currentFolderObject.Datasets, { Name: datasetName, DatasetId: newDatasetId }]
                })
            } else {
                putFolder({
                    ...currentFolderObject,
                    Datasets: [ { Name: datasetName, DatasetId: newDatasetId } ]
                })
            }
            
        } else {
            //TODO: Display an error
        }
    }

    const handleOpenTemplateSelector = () => {
        
    }
                
    return (
        <div id="addEmptyDatasetDialog" style={{ margin: '0px 10px 10px 10px' }}>
            <form onSubmit={onSubmit}>
                <h4>Add Empty Dataset</h4>
                <FormControl error={errorText ? true : false} style={{width: '100%'}}>
                    <FormLabel>Dataset Name</FormLabel>
                    <Input style={{width: '100%'}} value={datasetName} onChange={(event) => setDatasetName(event.target.value)} onBlur={handleBlur} />
                    <FormHelperText error>{errorText}</FormHelperText>
                </FormControl>
                {/* <div style={{margin: '10px'}}/> */}
                {/* <RadioGroup
                    style={{ display: 'flex', alignItems: 'center' }}
                    aria-label="templated"
                    name="templatedVsBlank"
                    value={type}
                    onChange={(event, newValue) => { setType(newValue)}}
                >
                    <div id='radioGroupContainer' style={{ display: 'flex', width: 'max-content', flexFlow: 'column nowrap', alignItems: 'center' }}>
                        <FormLabel style={{ color: 'black', alignSelf: 'start' }}>Type</FormLabel>
                        <FormControlLabel value='blank' control={<Radio />} label = "Blank" />
                        <FormControlLabel value='templated' control={<Radio />} label = "Use Template" />    
                    </div>
                </RadioGroup> */}
                <br style={{margin: '25px 0px'}}/>
                <div style={{ display: 'flex' }} >
                    <div style={{ display: 'inline-block', margin: '0 auto' }}>
                        <Button
                            style={{ padding: 3, marginRight: '10px' }}
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Add
                        </Button>
                        <Button
                            style={{ padding: 3 }}
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddEmptyDatasets
