import { useState } from 'react'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { nanoid } from '@reduxjs/toolkit'

import { DecisionRuleChooserRadio } from '../../../ui/DecisionRuleChoosers'
import NumericInput from '../../../ui/NumericInput'

import { methodNameDict } from '../../../../app/thurstonianDicts'
import { useErrorHandler } from '../../../../utils/apiUtils'
import { useUpdateOutput } from '../../../../hooks/store/useUpdateOutput'
import { getSigStars } from '../../../../utils/dataUtils'

const api = 'https://ci86z1c6w3.execute-api.us-east-1.amazonaws.com/default/ifplip_api_test'

const DeltaEstimation = (props) => {

    const [ method, setMethod ] = useState(null)
    const [ numCorrect, setNumCorrect ] = useState(null)
    const [ numTotal, setNumTotal ] = useState(null)
    const [ isValidating, setIsValidating ] = useState(false)

    const { checkAndPostError } = useErrorHandler()

    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const onSubmit = (event) => {
 
        event.preventDefault()
        
        setIsValidating(true);

        if(!(method && numCorrect && numTotal)) {
            return;
        }
        
        setIsValidating(false)

        let methodName = method
        if(method === 'twoAFC' || method === 'threeAFC') {
            methodName = 'mafc'
        }

        let data = {
            "function_group": "delta_estimation",
            "function_name": methodName,
            "num_correct": numCorrect,
            "num_total": numTotal
        }

        if(method === 'twoAFC' || method === 'threeAFC') {
            data = {
                ...data,
                "num_choices": (method === 'twoAFC' ? 2 : 3)
            }
        }

        axios
            .post(api, data)
            .then((response) => {

                if(checkAndPostError(response)) {
                    return
                }
                
                const tableContent = [
                    ['d-prime', response.data.d_prime.toFixed(2)], 
                    ['Variance of d-prime', response.data.d_prime_var.toFixed(2)],
                    ['Lower 95% confidence bound', response.data.d_prime_lb.toFixed(2)],
                    ['Upper 95% confidence bound', response.data.d_prime_ub.toFixed(2)],
                    //['Probably of ' + numCorrect.toString() + ' or more correct responses', response.data.binom_prob]
                    ['Binomial p-value', response.data.binom_prob.toFixed(2) + ' ' + getSigStars(response.data.binom_prob)]
                ]

                const tableId = nanoid()

                const table = {
                    TableId: tableId,
                    TableContent: tableContent
                }

                let tempPlotData = [{
                    curveType: 'normal',
                    x_lb: -4,
                    x_ub: response.data.d_prime + 4.0,
                    mean: response.data.d_prime,
                    sd: 1,
                    type: 'line',
                    color: 'blue',
                    name: 'Y',
                    showlegend: false,
                    hoverinfo: 'none',
                    //hovertemplate: 'Delta: %{x:.2f}  Prob.: %{y:.2f}',
                },
                {
                    curveType: 'normal',
                    x_lb: -4,
                    x_ub: response.data.d_prime + 4.0,
                    mean: 0,
                    sd: 1,
                    type: 'line',
                    color: 'red',
                    name: 'X',
                    showlegend: false,
                    hoverinfo: 'none',
                    //hovertemplate: 'Delta: %{x:.2f}  Prob.: %{y:.2f}',
                }]

                const newPlotId = nanoid()
                const newOutputId = nanoid()
                const plotObject = {
                        OwnerId: userId,
                        PlotId: newPlotId,
                        Title: 'Delta Estimation: ' + methodNameDict[method],
                        Data: tempPlotData,
                        OutputReferences: [newOutputId]
                    }
                
                const message = methodNameDict[method] + ' with ' + numCorrect + ' out of ' + numTotal + ' has a d-prime of ' + Number(response.data.d_prime).toFixed(3)

                const outputObject = {
                        OwnerId: userId,
                        OutputId: newOutputId,
                        Title: 'Delta Estimation: ' + methodNameDict[method], 
                        Stream: [
                            //{ Type: 'text', Content: message},
                            { Type: 'table', Content: tableId },
                            { Type: 'plot', Content: newPlotId }
                        ],
                        Tables: [table], 
                        Plots: [newPlotId],
                        ReferencingProjectIds: currentProjectId,
                    }
                
                addOutput(outputObject, plotObject)
            })
    }

    return (
        <div>
            <h2>Delta Estimation</h2>
            <form onSubmit={onSubmit}>
                <DecisionRuleChooserRadio
                        methodNameDict={methodNameDict}
                        setValidatedValue={setMethod}
                        isValidating={isValidating}
                    />
                <NumericInput label="Number correct" id='numCorrect' setValidatedValue={setNumCorrect}
                    minValue='0' minInclusive isInteger isValidating={isValidating} errorMessage="Number 'correct' must be greater than 0." />
                <NumericInput label="Sample size" id='numTotal' setValidatedValue={setNumTotal}
                    minValue='0' minInclusive isInteger isValidating={isValidating} errorMessage="Number total must be greater than 0." />
                <br />
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </form>
        </div>
    )
}

export default DeltaEstimation
