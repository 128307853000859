import React, { useEffect, useState } from 'react'
import { alpha, makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import IconButton from '@material-ui/core/IconButton'
//import Button from '@material-ui/core/Button'
//import Typography from '@material-ui/core/Typography'
//import InputBase from '@material-ui/core/InputBase'
import Badge from '@material-ui/core/Badge'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import MenuIcon from '@material-ui/icons/Menu'
//import SearchIcon from '@material-ui/icons/Search'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MailIcon from '@material-ui/icons/Mail'
import NotificationsIcon from '@material-ui/icons/Notifications'
import MoreIcon from '@material-ui/icons/MoreVert'
//import AccountTreeIcon from '@material-ui/icons/AccountTree'
import StorageIcon from '@material-ui/icons/Storage'
import FunctionsIcon from '@material-ui/icons/Functions'
import AssessmentIcon from '@material-ui/icons/Assessment'

import { Link, useLocation, useHistory } from 'react-router-dom'

import ProjectMenuButton from './ProjectMenu'
import AnalyzeMenu from './AnalyzeMenu'

import { signOut } from '../../services/cognito'
import { useSession } from '../../hooks/store/sessionHook'
import { Slide } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        '&$selected': {
            backgroundColor: 'white', //'#3f51b5',
        },
    },
    menuIcon: {
        verticalAlign: 'bottom',
        marginRight: theme.spacing(1),
    },
    toolbarRightButtons: {
        marginLeft: 'auto',
    },
    title: {
        flexGrow: 1,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    appBar: {
        // Make the app bar z-index always one more than the drawer z-index
        zIndex: theme.zIndex.drawer + 1,
        // Must not be static position to use zIndex
        position: 'relative',
    },
    toolbar: theme.mixins.toolbar,
    selected: {},
}))

const NavigationBar = (props) => {

    const location = useLocation()
    const history = useHistory()
    
    const [ bottomMargin, setBottomMargin ] = useState('0')

    useEffect(() => {
        switch(location.pathname.split('/')[1]) {
            case 'analyze':
                setSelectedTab(1)
                setAnalyzeMenuIsOpen(true)
                setBottomMargin('0px')
                break
            case 'input':
                setSelectedTab(0)
                setBottomMargin('0px')
                setAnalyzeMenuIsOpen(false)
                break
            case 'output':
                setSelectedTab(2)
                setBottomMargin('0px')
                setAnalyzeMenuIsOpen(false)
                break
            default:
                setSelectedTab(false)
                setBottomMargin('0px')
                setAnalyzeMenuIsOpen(false)
                break
        }
    }, [location])

    const { setCurrentSessionToken } = useSession()

    const classes = useStyles()
    //const [projectName, setProjectName] = React.useState('No project selected')
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
    const [ popperAnchorEl, setPopperAnchorEl ] = React.useState(null)

    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
    const isPopperOpen = Boolean(popperAnchorEl)

    const [ selectedTab, setSelectedTab ] = React.useState(0)
    const [ analyzeMenuIsOpen, setAnalyzeMenuIsOpen ] = React.useState(false)
    // const [isSelectedDatasets, setIsSelectedDatasets] = React.useState(false)
    // const [isSelectedAnalyze, setIsSelectedAnalyze] = React.useState(false)
    // const [isSelectedResults, setIsSelectedResults] = React.useState(false)

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget)
    }
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null)
    }
    const handleMenuClose = () => {
        setAnchorEl(null)
        handleMobileMenuClose()
    }

    const handleLogOut = () => {
        handleMenuClose()
        signOut()
        setCurrentSessionToken(null)
    }
    
    const handleTabChange = (event, newValue) => {
        switch(newValue) {
            case 0:
                setPopperAnchorEl(event.target)
                //closeAnalyzeMenuHandler()
                setAnalyzeMenuIsOpen(false)
                // setSelectedTab(newValue)
                break
            case 2:
                //closeAnalyzeMenuHandler()
                setAnalyzeMenuIsOpen(false)
                // setSelectedTab(newValue)
                break
            case 1:
                // setSelectedTab(newValue)
                openAnalyzeMenuHandler()
                break
            default:
                console.log(newValue)
        }
    }

    const menuId = 'primary-search-account-menu'
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
            <MenuItem onClick={handleLogOut}>Log out</MenuItem>
        </Menu>
    )

    const mobileMenuId = 'primary-search-account-menu-mobile'
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="secondary">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton
                    aria-label="show 11 new notifications"
                    color="inherit"
                >
                    <Badge badgeContent={11} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    )

    const openAnalyzeMenuHandler = (event) => {
        //if(selectedTab !== 1) {
            history.push('/analyze')
        //}
        setAnalyzeMenuIsOpen(true)
    }
    const closeAnalyzeMenuHandler = (event) => {
        //setAnalyzeMenuIsOpen(false)
    }
//style={{marginBottom: bottomMargin}}
    return (
        <div className="NavigationBar" >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open menu"
                        id="appBarButtonMenu"
                    >
                        <MenuIcon />
                    </IconButton>
                    {/* <Typography variant="h6" className={classes.title}>
          News
        </Typography> */}
                    {/* <Button color="secondary" variant="contained" className={classes.menuButton} startIcon={<AccountTreeIcon />}>{projectName}</Button> */}
                    <ProjectMenuButton />
                    <Tabs value={selectedTab} onChange={handleTabChange} >
                        <Tab
                            label={
                                <React.Fragment>
                                    <div>
                                        <StorageIcon className={classes.menuIcon} />
                                        <span>Input</span>
                                    </div>
                                </React.Fragment>
                            }
                            // color="primary"
                            // variant="contained"
                            // className={classes.menuButton}
                            // startIcon={<StorageIcon />}
                            // selected={isSelectedDatasets}
                            // //onClick={onButtonClick}
                            // id="appBarButtonDatasets"
                            component={Link}
                            to="/input"
                        />
                        <Tab
                            label={
                                <React.Fragment>
                                    <div>
                                        <FunctionsIcon className={classes.menuIcon} />
                                        <span>Analyze</span>
                                    </div>
                                </React.Fragment>
                            }
                            // color="primary"
                            // variant="contained"
                            // className={classes.menuButton}
                            // startIcon={<FunctionsIcon />}
                            // onClick={props.onOpenAnalyzeMenu}
                            // //selected={isSelectedAnalyze}
                            // id="appBarButtonAnalyze"
                            // component={Link}
                            // to="/analyze"
                        />
                        <Tab
                            label={
                                <React.Fragment>
                                    <div>
                                        <AssessmentIcon className={classes.menuIcon} />
                                        <span>Output</span>
                                    </div>
                                </React.Fragment>
                            }
                            // color="primary"
                            // variant="contained"
                            // className={classes.menuButton}
                            // startIcon={<AssessmentIcon />}
                            // //selected={isSelectedResults}
                            // id="appBarButtonResults"
                            component={Link}
                            to="/output"
                        />
                    </Tabs>
                    <div className={classes.toolbarRightButtons}>
                        <div className={classes.grow} />
                        <div className={classes.sectionDesktop}>
                            <IconButton
                                aria-label="show 4 new mails"
                                color="inherit"
                            >
                                <Badge badgeContent={4} color="secondary">
                                    <MailIcon />
                                </Badge>
                            </IconButton>
                            <IconButton
                                aria-label="show 17 new notifications"
                                color="inherit"
                            >
                                <Badge badgeContent={17} color="secondary">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MoreIcon />
                            </IconButton>
                        </div>
                    </div>
                </Toolbar>
                <AnalyzeMenu 
                classMixinsStyle={classes.toolbar} 
                visible={analyzeMenuIsOpen} 
                onMenuClick={closeAnalyzeMenuHandler}
                onClose={closeAnalyzeMenuHandler}
            />
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            {/* <Popper id='asdf' open={isPopperOpen} anchorEl={popperAnchorEl}>
                <div>
                    <IconButton>Test Button!</IconButton>
                </div>
            </Popper> */}
        </div>
    )
}

export default NavigationBar
