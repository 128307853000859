import { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import TabPanel from '@material-ui/lab/TabPanel'
import TabContext from '@material-ui/lab/TabContext'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import NumericInput from '../../ui/NumericInput'
import AutocompleteDropdown from '../../ui/AutocompleteDropdown'
import { FormLabel } from '@material-ui/core'
import { useDatasets } from '../../../hooks/useDatasets'
import { base64ToFloats, getDefaultColorByIndex } from '../../../utils'
import { roundPValue, transposeArray } from '../../../utils/dataUtils'
import { useErrorHandler } from '../../../utils/apiUtils'
import { useUpdateOutput } from '../../../hooks/store/useUpdateOutput'
import { useSelector } from 'react-redux'
import { nanoid } from '@reduxjs/toolkit'

const exampleListItems = [ 'Column 1', 'Column 2', 'Column 3', 'Column 4']

const api = process.env.REACT_APP_API_URL

const ScaleAnalysis = () => {

    const [ selectedTab, setSelectedTab ] = useState('frequencyData')
    
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue)
    }

    return (
        <div id="scaleAnalysisDialog">
        <h2>Scale Analysis</h2>
        <TabContext value={selectedTab}>
            <Tabs value={selectedTab} onChange={handleTabChange} centered scrollButtons='auto' >
                <Tab label="Frequency Data" value="frequencyData" />
                <Tab label="Raw Data" value="rawData"/>
            </Tabs>
            <TabPanel value="frequencyData">
               <ScaleAnalysisFrequency />
            </TabPanel>
            <TabPanel value="rawData">
                <ScaleAnalysisRaw />
            </TabPanel>
        </TabContext>
    </div>
    )
}

function processScaleResponse (response, scaleNames, currentProjectId, prodNamesByScale, userId, datasetName) {
    return(scaleNames.map((scaleName, scaleIndex) => {
        const scalePrefix = "scale_" + (scaleIndex + 1).toString() + "_"

        if(response.data[scalePrefix + "result"] !== "success") {
            // Error for this scale
            return "error"
        }

        const prodMeans = base64ToFloats(response.data[scalePrefix + "prod_means"])
        const dPrimes = base64ToFloats(response.data[scalePrefix + "deltas"])
        const boundaries = base64ToFloats(response.data[scalePrefix + "boundaries"])
        const dPrimeVCV = base64ToFloats(response.data[scalePrefix + "d_prime_vcv"])
        const confBounds = base64ToFloats(response.data[scalePrefix + "conf_bounds"])
        const pValues = base64ToFloats(response.data[scalePrefix + "p_values"])
        const compVars = base64ToFloats(response.data[scalePrefix + "comp_vars"])

        const sortedProds = prodNamesByScale[scaleIndex]
                                .map((name, index) => ({ Name: name, dPrime: dPrimes[index], sortIndex: index}))
                                .sort(a => -a.dPrime)

        const boundariesTableContent = [ boundaries.map((_, index) => ["Bound " + (index + 1).toString()]),
                                        boundaries.map(a => [a.toFixed(2)]) ]

        const ratingMeansTableContent = [
            ['Product', 'Ratings Mean', 'd\'', '95% Lower Bound', '95% Upper Bound'], 
            [sortedProds[0].Name, prodMeans[0].toFixed(2), dPrimes[0].toFixed(2), '', ''],
            ...sortedProds.slice(1).map(prodObject => [prodObject.Name, prodMeans[prodObject.sortIndex].toFixed(2), 
                                                    dPrimes[prodObject.sortIndex].toFixed(2), 
                                                    confBounds[prodObject.sortIndex].toFixed(2), confBounds[prodObject.sortIndex + sortedProds.length].toFixed(2) ])
        ]

        let dPrimeTemp = dPrimeVCV
        const dPrimeVCVTableContent = []
        while(dPrimeTemp.length > 0) {
            dPrimeVCVTableContent.push(dPrimeTemp.splice(0, sortedProds.length))
        }

        let prodCompTableContent = [['Comparison', 'd\'', 'Variance', 'p-Value']]
        for(let prodIndex1 = 0; prodIndex1 < sortedProds.length; prodIndex1++) {
            for(let prodIndex2 = prodIndex1 + 1; prodIndex2 < sortedProds.length; prodIndex2++) {
                const dataIndex = sortedProds[prodIndex1].sortIndex * sortedProds.length + sortedProds[prodIndex2].sortIndex
                prodCompTableContent.push([sortedProds[prodIndex1].Name + "  vs.  " + sortedProds[prodIndex2].Name, 
                    Math.abs(dPrimes[sortedProds[prodIndex2].sortIndex] - dPrimes[sortedProds[prodIndex1].sortIndex]).toFixed(2), 
                    compVars[dataIndex].toFixed(2), 
                    roundPValue(pValues[dataIndex])])
            }
        }

        const boundariesTable = {
            TableId: nanoid(),
            Title: "Boundaries",
            OptionsList: { ColumnHeaders: true },
            TableContent: boundariesTableContent
        }
        const ratingMeansTable = {
            TableId: nanoid(),
            Title: "Products Summary",
            OptionsList: { ColumnHeaders: true },
            TableContent: ratingMeansTableContent
        }

        const dPrimeVCVTable = {
            TableId: nanoid(),
            Title: "d' Variance-Covariance Matrix",
            OptionsList: {ColumnHeaders: false},
            TableContent: dPrimeVCVTableContent
        }

        const prodCompTable = {
            TableId: nanoid(),
            Title: "Product Comparisons",
            OptionsList: { ColumnHeaders: true },
            TableContent: prodCompTableContent
        }

        let tempPlotData = dPrimes.map((d_prime, index) => ({
            curveType: 'normal',
            x_lb: -4,
            x_ub: d_prime + 4.0,
            mean: d_prime,
            sd: 1,
            type: 'line',
            color: getDefaultColorByIndex(index),
            name: 'name',
            showlegend: false,
            hoverinfo: 'none',
            //hovertemplate: 'Delta: %{x:.2f}  Prob.: %{y:.2f}',
        }))

        tempPlotData.push(...boundaries.map(boundary => ({
            x: [boundary, boundary],
            y: [0, 0.45],
            type: 'scatter',
            mode: 'lines',
            color: 'black',
            showlegend: false,
            hoverinfo: 'none',
        })))

        const newPlotId = nanoid()
        const newOutputId = nanoid()
        const plotObject = { //addPlot({
                OwnerId: userId,
                PlotId: newPlotId,
                Title: 'Scale Analysis: ' + datasetName + ": " + scaleName,
                Data: tempPlotData,
                OutputReferences: [newOutputId]
            }

        const outputObject = {
            OwnerId: userId,
            OutputId: newOutputId,
            Title: 'Scale Analysis: ' + datasetName + ": " + scaleName,
            Stream: [
                { Type: 'text', Content: "Test of equality of delta values: " + roundPValue(response.data[scalePrefix + "overall_p_value"]) },
                { Type: 'table', Content: boundariesTable.TableId },
                { Type: 'table', Content: ratingMeansTable.TableId },
                { Type: 'table', Content: dPrimeVCVTable.TableId },
                { Type: 'table', Content: prodCompTable.TableId },
                { Type: 'plot', Content: newPlotId }
            ],
            Tables: [boundariesTable, ratingMeansTable, prodCompTable ], 
            Plots: [newPlotId],
            ReferencingProjectIds: currentProjectId,
        }
        
        return({outputObject, plotObject})
    }))
}

const ScaleAnalysisFrequency = (props) => {
    
    const [ prodNames, setProdNames ] = useState([]);
    const [ referenceProduct, setReferenceProduct ] = useState(null);
    const [ datasetError, setDatasetError ] = useState(false);
    const [ referenceProductError, setReferenceProductError ] = useState(false);

    const { selectedDataset, setSelectedDataset, selectedDatasetData, datasetData, datasetOptionsList, getDatasetSize } = useDatasets()

    const { checkAndPostError } = useErrorHandler()

    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    useEffect(() => {
        if(datasetData) {
            setProdNames([...new Set([datasetData.slice(1).map(row => row[1])].flat())].map((element, index) => ({ label: element, id: index })))
        }
    }, [ datasetData ])

    const handleSubmit = (event) => {
        
        event.preventDefault()

        const { numRows, numCols } = getDatasetSize()
        const scaleNames = [...new Set(datasetData.slice(1).map(row => row[0]))]
        const maxNumCats = numCols - 2 // first 2 columns are "scale" and "product"
        
        let data = {
            function_group: 'scaling',
            function_name: 'scale_estimation',
            alpha: 0.05,
            num_scales: scaleNames.length
        }

        let prodNamesByScale = []
        scaleNames.forEach((scaleName, scaleIndex) => {
            let thisScaleProdNames = [...new Set(datasetData.slice(1).filter(row => row[0] === scaleName).map(row => row[1]))]
            thisScaleProdNames = [referenceProduct.label].concat(thisScaleProdNames.filter(prodName => prodName !== referenceProduct.label))
            prodNamesByScale.push(thisScaleProdNames)
            const thisScaleNumProds = thisScaleProdNames.length
            const thisScaleNumCats = maxNumCats //TODO: allow for unequal num cats with warning?

            const dataSubset = datasetData.slice(1).filter(row => row[0] === scaleName)
            const refProdIndex = dataSubset.findIndex(row => row[1] === referenceProduct.label)
            const thisScaleCatCounts = transposeArray([dataSubset[refProdIndex]]
                .concat(dataSubset.filter((_, index) => index !== refProdIndex))
                .map(row => row.slice(2))).flat().map(a => Number(a))

            const testCatCountsSize = thisScaleNumProds * thisScaleNumCats
            if(thisScaleCatCounts.length !== testCatCountsSize) {
                // TODO: Warning
                return
            }

            data["scale_" + (scaleIndex + 1).toString() + "_num_prods"] = thisScaleNumProds
            data["scale_" + (scaleIndex + 1).toString() + "_num_cats"] = thisScaleNumCats
            data["scale_" + (scaleIndex + 1).toString() + "_cat_counts"] = thisScaleCatCounts
        })

        axios
            .post(api, data)
            .then((response) => {
                
                if(checkAndPostError(response)) {
                    return
                }

                const outputList = processScaleResponse(response, scaleNames, currentProjectId, prodNamesByScale, userId, selectedDatasetData.Name)
                outputList.forEach(outputItem => { if(outputItem !== "error") { addOutput(outputItem.outputObject, outputItem.plotObject) } })
            })
            .catch((error) => {
                console.log('error: ')
                console.log(error)
            })
    }
    
    return (
        <div id="scaleAnalysisFrequency" style={{ width: 'max-content', margin: '0 auto' }} >
            <form onSubmit={handleSubmit}>
                <AutocompleteDropdown options={datasetOptionsList}
                    value={selectedDataset} setValue={setSelectedDataset} label="Choose dataset:" variant="outlined" />
                <br />
                <br />
                <AutocompleteDropdown options={prodNames} disabled={ prodNames.length < 1 }
                    value={referenceProduct} setValue={setReferenceProduct} label="Choose reference product:" variant="outlined" />
                <br />
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </form>
        </div>
    )
}

const ScaleAnalysisRaw = () => {

    const [ prodNames, setProdNames ] = useState(null);
    const [ productColumn, setProductColumn ] = useState(null);
    const [ referenceProduct, setReferenceProduct ] = useState(null);
    const [ columnNamesList, setColumnNamesList ] = useState([])
    const [ reducedColumnNamesList, setReducedColumnNamesList ] = useState([])
    const [ datasetError, setDatasetError ] = useState(false);
    const [ productColumnError, setProductColumnError ] = useState(false);
    const [ referenceProductError, setReferenceProductError ] = useState(false);
    const [ selectedScaleColumns, setSelectedScaleColumns ] = useState([])
    const [ numRatingCategories, setNumRatingCategories ] = useState()
    const [ isValidating, setIsValidating ] = useState(false)

    const { selectedDataset, setSelectedDataset, selectedDatasetData, datasetData, columnNames, datasetOptionsList, getDatasetSize } = useDatasets()

    const { checkAndPostError } = useErrorHandler()

    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    useEffect(() => {
        setColumnNamesList(columnNames.map((element, index) => ({ label: element, id: index })))
    }, [columnNames])

    useEffect(() => {
        if(productColumn && datasetData) {
            setProdNames([...new Set([datasetData.slice(1).map(row => row[productColumn.id])].flat())].map((element, index) => ({ label: element, id: index })))
        } else {
            setProdNames(null)
            setReferenceProduct(null)
        }
    }, [productColumn, datasetData])

    useEffect(() => {
        if(columnNamesList && productColumn) {
            setReducedColumnNamesList(columnNamesList.filter(obj => obj.id !== productColumn.id))
        } else {
            setReducedColumnNamesList([])
        }
    }, [productColumn, columnNamesList])

    const onSubmit = (event) => {

        event.preventDefault()

        const { numRows, numCols } = getDatasetSize()
        const scaleNameObjs = reducedColumnNamesList.filter(obj => selectedScaleColumns.includes(obj.id))
        
        let data = {
            function_group: 'scaling',
            function_name: 'scale_estimation',
            alpha: 0.05,
            num_scales: scaleNameObjs.length
        }

        let prodNamesByScale = []
        scaleNameObjs.forEach((scaleObj, scaleIndex) => {

            const scaleName = scaleObj.label
            const scaleColIndex = scaleObj.id

            let thisScaleProdNames = [...new Set(datasetData.slice(1).map(row => row[productColumn.id]))]
            thisScaleProdNames = [referenceProduct.label].concat(thisScaleProdNames.filter(prodName => prodName !== referenceProduct.label))
            prodNamesByScale.push(thisScaleProdNames)
            const thisScaleNumProds = thisScaleProdNames.length
            const thisScaleNumCats = numRatingCategories //TODO: allow for unequal num cats with warning?

            let thisScaleCatCounts = new Array(thisScaleNumCats * thisScaleNumProds).fill(0)

            thisScaleProdNames.forEach((prodName, prodIndex) => {
                const counts = datasetData.slice(1).filter(row => row[productColumn.id] === prodName)
                    .map(row => row[scaleColIndex])
                    .reduce((acc, e) => acc.set(e, (acc.get(e) || 0) + 1), new Map());
                counts.forEach((value, key) => { thisScaleCatCounts[thisScaleNumProds * (Number(key) - 1) + prodIndex] = value})
            })

            data["scale_" + (scaleIndex + 1).toString() + "_num_prods"] = thisScaleNumProds
            data["scale_" + (scaleIndex + 1).toString() + "_num_cats"] = thisScaleNumCats
            data["scale_" + (scaleIndex + 1).toString() + "_cat_counts"] = thisScaleCatCounts
        })

        axios
            .post(api, data)
            .then((response) => {
                
                if(checkAndPostError(response)) {
                    return
                }

                const outputList = processScaleResponse(response, scaleNameObjs.map(obj => obj.label), currentProjectId, prodNamesByScale, userId, selectedDatasetData.Name)
                outputList.forEach(outputItem => { if(outputItem !== "error") { addOutput(outputItem.outputObject, outputItem.plotObject) } })
            })
            .catch((error) => {
                console.log('error: ')
                console.log(error)
            })
    }

    const handleListItemClick = (event, index) => {
        if(selectedScaleColumns.includes(index)) {
            setSelectedScaleColumns(selectedScaleColumns.filter(val => val !== index))
        } else {
            setSelectedScaleColumns(selectedScaleColumns.concat(index))
        }
    }

    return (
        <div
            id="scaleAnalysisRaw"
            style={{ width: 'max-content', margin: '0 auto' }}
        >
            <form onSubmit={onSubmit}>
                <AutocompleteDropdown options={datasetOptionsList}
                    value={selectedDataset} setValue={setSelectedDataset} label="Choose dataset:" variant="outlined" />
                <br />
                <br />
                <AutocompleteDropdown options={columnNamesList} disabled={ columnNamesList.length < 2 }
                    value={productColumn} setValue={setProductColumn} label="Choose product column:" variant="outlined" />
                <br />
                <br />
                <AutocompleteDropdown options={prodNames} disabled={ !productColumn }
                    value={referenceProduct} setValue={setReferenceProduct} label="Choose reference product:" variant="outlined" />
                <br />
                <br />
                <div style={{ display: 'flex', flexFlow: 'column nowrap', width: 'max-100%', alignItems: 'start', margin: '0 auto' }}>
                    <FormLabel htmlFor="ratingColumnChooser" style={{ color: 'black', textAlign: 'left', marginBottom: '8px' }}>Choose rating columns:</FormLabel>
                    <List variant="outlined" id="ratingColumnChooser" style={{ width: '100%', border: '1px solid gray', borderRadius: '6px', minHeight: '26px' }}>
                        { reducedColumnNamesList.map((listItem) => {
                            return(<ListItem
                                key={listItem.id}
                                button
                                selected={selectedScaleColumns.includes(listItem.id)}
                                onClick={(event) => handleListItemClick(event, listItem.id)}
                            >
                                <ListItemText primary={listItem.label} />
                            </ListItem>)
                        })}
                    </List>
                </div>
                <br/>
                <NumericInput
                    setValidatedValue={setNumRatingCategories}
                    isValidating={isValidating}
                    label="# of Rating Categories"
                    minValue="0"
                    errorMessage="Must be greater than 0."
                />
                <br />
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </form>
        </div>
    )

}

export default ScaleAnalysis
