import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function PopperMenu(props) {
  const classes = useStyles();

  //const anchorRef = React.useRef(null);

  // const handleClose = (event) => {
  //   // if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //   //   return;
  //   // }
  //   console.log("from popper handleclose")
  //   setOpen(false);
  // };

  // function handleListKeyDown(event) {
  //   if (event.key === 'Tab') {
  //     event.preventDefault();
  //     setOpen(false);
  //   }
  // }

  // return focus to the button when we transitioned from !open -> open
  // const prevOpen = React.useRef(open);
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = open;
  // }, [open]);

  let placement = "bottom"
  if (props.placement !== undefined) {
    placement = props.placement
  }

  let transformOrigin = placement === 'bottom' ? 'center top' : 'center top'
  if (props.transformOrigin !== undefined) {
    transformOrigin = props.transformOrigin
  }

  return (
    <div className={classes.root}>
        <Popper open={props.open} anchorEl={props.anchorEl} role={undefined} transition style={{zIndex: props.zIndex}} placement={placement}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: transformOrigin }}
            >
              <Paper {...props.PaperProps}>
                <ClickAwayListener onClickAway={props.onClose}>
                  <MenuList id="menu-list-grow" onKeyDown={props.handleListKeyDown}>
                    {props.children}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
    </div>
  );
}
