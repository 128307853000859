import CustomTreeView from './CustomTreeView';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Folder from '@material-ui/icons/Folder';
import HourglassEmpty from '@material-ui/icons/HourglassEmpty'

const BuildTreeFromProjectFolderData = (folders, allFolders) => {

    if(!folders || !allFolders || folders.length === 0 || allFolders.length === 0) {
        return(null)
    }

    return(folders.map(node => {
        if(node && node.ProjectFolderId) {
            return({
                id: node.ProjectFolderId,
                name: node.Name === '_root_project_folder_' ? "Project Folders Root" : node.Name,
                labelIcon: node.Name === '_root_project_folder_' ? AccountTreeIcon : Folder,
                children: node.Children ? 
                BuildTreeFromProjectFolderData(node.Children.map(stubChild => allFolders.find(folder => folder.ProjectFolderId === stubChild.FolderId)), allFolders) : null,
            }) 
        } else {
            return(null)
        }
    }))
}

const ProjectFolderTree = ({ projectFolderData, onProjectFolderChange, selectedProjectFolderId }) => {

    let treeStructure = null

    if(!projectFolderData || (projectFolderData && projectFolderData.length === 0)) {
        treeStructure = [
            { id: "_loading", name: "Loading...", children: [], labelIcon: HourglassEmpty },
            // { id: '_datatree', name: 'Data Root', children: [testData], labelIcon: AccountTreeIcon},
            // { id: '_temp', name: 'Temp', labelIcon: AlarmIcon},
            // { id: '_trash', name: 'Trash', labelIcon: DeleteIcon},
        ]
    } else {

        const rootFolder = projectFolderData.find(folder => folder.Name === "_root_project_folder_")
        if(rootFolder) {
            treeStructure = BuildTreeFromProjectFolderData([rootFolder], projectFolderData)
        } else {
            treeStructure = BuildTreeFromProjectFolderData([projectFolderData[0]], projectFolderData)
        }
    }

    return (
        <CustomTreeView onNodeSelect={onProjectFolderChange} selectedNodeId={selectedProjectFolderId}>
            { treeStructure }
        </CustomTreeView>
    )

}

export default ProjectFolderTree
