import { useState, useEffect, useMemo } from 'react'
import { Switch, Route } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux'

import MainLayout from './MainLayout'
import DatasetTree from '../ui/DatasetTree'
import Dialog from '@material-ui/core/Dialog'
import Modal from '@material-ui/core/Modal'

import UploadDatasets from '../dialogs/datasets/UploadDatasets'
import DatasetList from '../ui/DatasetList'
import Dataset from '../documents/Dataset'
import AddEmptyDatasets from '../dialogs/datasets/AddEmptyDataset'
import { useGetFoldersByProjectIdQuery, useDeleteDatasetMutation, useDeleteFolderMutation, usePutFolderMutation } from '../../services/databaseApi';
import { skipToken } from '@reduxjs/toolkit/dist/query'
import AddDatasetFolder from '../dialogs/datasets/AddDatasetFolder'
import ConfirmationDialog from '../ui/ConfirmationDialog'

const useStyles = makeStyles({
    buttonRow: {
        display: 'inline-block',
        margin: '5px',
    },
    headerButton: {
        margin: '5px 10px 5px 5px'
    },
    projectTree: {
        border: 'grey solid 1px',
        borderRadius: '2px',
        height: '100%',
    },
    datasetList: {
        border: 'grey solid 1px',
        borderRadius: '2px',
        height: '100%',
        minHeight: '265px',
    },
    modalContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContents: {
        height: '80%',
        width: '80%'
    }
})

const InputTab = (props) => {

    const classes = useStyles()

    const [ selectedFolderId, setSelectedFolderId ] = useState(null)
    const [ selectedDatasetId, setSelectedDatasetId ] = useState(null)
    const [ isOpenUploadDatasets, setIsOpenUploadDatasets ] = useState(false)
    const [ isOpenAddEmptyDataset, setIsOpenAddEmptyDataset ] = useState(false)
    const [ isOpenAddDatasetFolder, setIsOpenAddDatasetFolder ] = useState(false)
    const [ isOpenConfirmDatasetDelete, setIsOpenConfirmDatasetDelete ] = useState(false)
    const [ isOpenConfirmFolderDelete, setIsOpenConfirmFolderDelete ] = useState(false)
    const [ isOpenModal, setIsOpenModal ] = useState(false)
    

    const currentProjectId = useSelector(state => state.session.currentProjectId)
    
    // const { data: projectData,
    //     isLoading: isProjectLoading,
    //     isSuccess: isProjectSuccess }  = useGetProjectByIdQuery(currentProjectId ?? skipToken)

    // const { data: dataFolderObject,
    //     isLoading: isDataFolderLoading,
    //     isSuccess: isDataFolderSuccess } = useGetFolderByIdQuery(projectData?.DatasetFolders[0]?.FolderId ?? skipToken)


    const { data: dataFolders,
        isLoading: isDataFoldersLoading,
        isSuccess: isDataFoldersSuccess } = useGetFoldersByProjectIdQuery(currentProjectId ?? skipToken)

    const [ deleteDataset ] = useDeleteDatasetMutation()
    const [ deleteFolder ] = useDeleteFolderMutation()        
    const [ putFolder ] = usePutFolderMutation()

    useEffect(() => {

        if(dataFolders && dataFolders.length > 0 && selectedFolderId == null) {
            setSelectedFolderId(dataFolders[0].FolderId)
        }
        //  else if((!dataFolders || dataFolders.length === 0) && !isDataFoldersLoading) {
        //     setSelectedFolderId(null)
        // }

    }, [dataFolders, isDataFoldersLoading])

    const handleFolderChange = (newFolderId) => {
        setSelectedFolderId(newFolderId)
    }

    const handleAddDatasetFolderClick = () => {
        setIsOpenAddDatasetFolder(true)
    }

    const handleCloseAddDatasetFolder = () => {
        setIsOpenAddDatasetFolder(false)
    }

    const handleDeleteDatasetFolderClick = () => {
        setIsOpenConfirmFolderDelete(true)
    }

    const handleDeleteFolder = () => {
        
        const selectedFolderData = dataFolders?.find(folder => folder.FolderId === selectedFolderId)
        if(selectedFolderData && selectedFolderData.Name !== "_project_root_dataset_folder_") {
            const includedDatasets = selectedFolderData?.Datasets
            if(includedDatasets && includedDatasets.length > 0) {
                includedDatasets.map(dataset => deleteDataset(dataset.DatasetId))
            }

            const parentFolderData = dataFolders?.find(folder => folder.FolderId === selectedFolderData.ParentFolderId)
            putFolder({
                ...parentFolderData,
                Children: parentFolderData.Children.filter(folder => folder?.FolderId !== selectedFolderId),
            })

            deleteFolder(selectedFolderId)

            setSelectedFolderId(null)

        } else if(selectedFolderData && selectedFolderData.Name === "_project_root_dataset_folder_") {
            // TODO: Alert that you cannot delete the root folder
        }
    }

    const handleSendDatasetToProjectClick = (event) => {
        
    }

    const handleDeleteDatasetClick = (event) => {
        setIsOpenConfirmDatasetDelete(true)
    }

    const handleDeleteDataset = () => {

        const selectedFolderData = dataFolders?.find(folder => folder.FolderId === selectedFolderId)
        if(selectedFolderData) {

            putFolder({
                ...selectedFolderData,
                Datasets: selectedFolderData?.Datasets.filter(dataset => dataset.DatasetId !== selectedDatasetId)
            })
        }

        deleteDataset(selectedDatasetId)

        setSelectedDatasetId(null)
    }

    const handleOpenUploadDatasets = () => {
        setIsOpenUploadDatasets(true)
    }

    const handleCloseUploadDatasets = () => {
        setIsOpenUploadDatasets(false)
    }

    const handleOpenAddEmptyDataset = () => {
        setIsOpenAddEmptyDataset(true)
    }

    const handleCloseAddEmptyDataset = () => {
        setIsOpenAddEmptyDataset(false)
    }

    const handleModalOpen = (datasetId) => {
        setSelectedDatasetId(datasetId)
        setIsOpenModal(true)
    }

    const handleModalClose = () => {
        setIsOpenModal(false)
    }

    const selectedFolderDatasets = useMemo(() => dataFolders?.find(folder => folder.FolderId === selectedFolderId)?.Datasets, [ dataFolders, selectedFolderId ])

    return (
        <MainLayout
            leftPanel={
                <div id="folderExplorer" style={{ margin: '0 10px 0 0', height: '95%' }}>
                    <div className={classes.buttonRow}>
                        <Button
                                onClick={handleAddDatasetFolderClick}
                                className={classes.headerButton}
                                variant="contained"
                                color="primary"
                                type="button"
                        >Add dataset folder</Button>
                        <Button
                            onClick={handleDeleteDatasetFolderClick}                    
                            className={classes.headerButton}
                            variant="contained"
                            color="primary"
                            type="button"
                        >Delete dataset folder</Button>
                        </div>
                        <div className={classes.projectTree}>
                        <DatasetTree
                            onFolderChange={handleFolderChange} 
                            selectedFolderId={selectedFolderId} 
                            folderData={dataFolders} />
                    </div>
                </div>
            }
            rightPanel={
                null
            }
            style={{ width: '100%', height: '100%'}}
        >
            <div style={{ margin: '0 auto', width: '100%', height: '95%' }}>
                <div className={classes.buttonRow}>
                    <Button
                        onClick={handleOpenAddEmptyDataset}
                        className={classes.headerButton}
                        variant="contained"
                        color="primary"
                        type="button"
                    >Add empty dataset</Button>
                    <Button
                        onClick={handleOpenUploadDatasets}
                        className={classes.headerButton}
                        variant="contained"
                        color="primary"
                        type="button"
                    >Upload dataset</Button>
                    <Button
                        onClick={handleSendDatasetToProjectClick}
                        className={classes.headerButton}
                        variant="contained"
                        color="primary"
                        type="button"
                    >Send dataset to another project</Button>
                    <Button
                        onClick={handleDeleteDatasetClick}                    
                        className={classes.headerButton}
                        variant="contained"
                        color="primary"
                        type="button"
                    >Delete dataset</Button>
                </div>
                <div className={classes.datasetList}>
                    <DatasetList
                        displayDatasets={selectedFolderDatasets} 
                        // selectedFolderId={selectedFolderId}
                        onDatasetDoubleClick={handleModalOpen} 
                        selectedDatasetId={selectedDatasetId}
                        setSelectedDatasetId={setSelectedDatasetId} />
                </div>
            </div>
            <Switch></Switch>
            <Dialog open={isOpenUploadDatasets} onClose={handleCloseUploadDatasets} parentFolderId={selectedFolderId} >
                <UploadDatasets />
            </Dialog>
            <Dialog open={isOpenAddEmptyDataset} onClose={handleCloseAddEmptyDataset}>
                <AddEmptyDatasets onClose={handleCloseAddEmptyDataset} parentFolderId={selectedFolderId} />
            </Dialog>
            <Dialog open={isOpenAddDatasetFolder} onClose={handleCloseAddDatasetFolder}>
                <AddDatasetFolder onClose={handleCloseAddDatasetFolder} parentFolderId={selectedFolderId} />
            </Dialog>
            <ConfirmationDialog title="Confirm Dataset Deletion" text="Are you sure you want to delete this dataset?" 
                isOpen={isOpenConfirmDatasetDelete} onClose={()=> setIsOpenConfirmDatasetDelete(false)} onConfirm={handleDeleteDataset} />
            <ConfirmationDialog title="Confirm Folder Deletion" text="Are you sure you want to delete this folder, including any datasets inside?" 
                isOpen={isOpenConfirmFolderDelete} onClose={()=> setIsOpenConfirmFolderDelete(false)} onConfirm={handleDeleteFolder} />
            <ConfirmationDialog />
            <Modal id="datasetModal" open={isOpenModal}
                className={classes.modalContainer} classes={{paper: classes.paper}} 
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleModalClose(event, reason)
                    }
                }} >
                <div style={{ width: '80%', height: '80%' }}>
                    {isOpenModal && selectedDatasetId !== null && 
                        <Dataset 
                            datasetId={selectedDatasetId} 
                            showTitle={true} 
                            onClose={handleModalClose}
                        />}
                </div>
            </Modal>
        </MainLayout>
    )
}

export default InputTab
