import { skipToken } from '@reduxjs/toolkit/dist/query'
import React, { useEffect, useState } from 'react'
//import Plot from 'react-plotly.js'
//import createPlotlyComponent from 'react-plotly.js/factory'
import Plot from 'react-plotly.js'
//const PlotComponent = createPlotlyComponent(Plotly)
import { useSelector } from 'react-redux'
import { selectPlotById } from '../../features/plotsSlice'
import { useGetPlotByIdQuery } from '../../services/databaseApi'
import { calcCurveCoords } from '../../utils/plotUtils'

const LineChart = ({ plotId, onDoubleClick, onInitialized, showTitle = false, width = 160, height = 100 }) => {

    const [ plotObject, setPlotObject ] = useState()
    const { data: plotData, plotError, isLoading: isPlotLoading } = useGetPlotByIdQuery(plotId ?? skipToken)
    //let plotData = useSelector(state => selectPlotById(state, plotId))
    
    let overallHoverMode = true
    useEffect(() => {

        if(!plotObject && plotData && !isPlotLoading) {

            let tempData = []
            plotData.Data.forEach(oneData => {
                const { hovermode, customColors, color, ...otherParams } = oneData
                const colorArray = customColors !== undefined ? [...customColors] : color

                const curveCoords = calcCurveCoords(oneData)

                // if(oneData.type === 'scatter') {
                //     console.log("scatter")
                //     let abc = 1 + 1
                // }
                let tempMarker = {}
                if(otherParams.marker) {
                    tempMarker = {...otherParams.marker}
                }
                if(colorArray) {
                    tempMarker.color = colorArray
                }

                tempData.push({
                    ...otherParams,
                    ...curveCoords,
                    marker: tempMarker,
                })

                if(hovermode !== undefined && !hovermode) {
                    overallHoverMode = false
                }
            })
            setPlotObject(tempData)
        }
    }, [plotData, isPlotLoading])

    if(isPlotLoading || !plotObject) {
        return(<p>Loading...</p>)
    }
    
    if(!plotData) {
        return (
            <div><p>No plot available</p></div>
        )
    }

    const config = {
        displayModeBar: false,
        responsive: false, // this does weird things in Material-UI Card elements if true
        // staticPlot: true,
    }

    let title = plotData.Title
    if(!showTitle) {
        title = ""
    }

    let layout = { 
        autosize: false,
        width: width,
        height: height,
        title: { text: title },
        yaxis: { showticklabels: false, zeroline: true },
        xaxis: { zeroline: true, showline: false },
        margin: { b: 40, l: 50, r: 40, t: title === '' ? 10 : 60 },
        hovermode: overallHoverMode,
        dragmode: false, 
        datarevision: 0
    }

    if (plotData.Layout) {

        layout = { ...layout, ...JSON.parse(JSON.stringify(plotData.Layout)) }
    //     layout = { ...layout, ...Object.assign({}, plotData.Layout) }
    //     // let tempLayout = Object.assign({}, plotData.Layout)
    //     // console.log('temp layout: ')
    //     // console.log(tempLayout)
    //     // layout = { ...layout, ...tempLayout }
    //     // console.log('layout: ')
    //     // console.log(layout)
    }

    //{/**/}
    return (
        //#TODO: Add a parameter for setting the size based on type of analysis or display location (e.g., inside a card vs full screen)
        <div style={{ display: 'block', width: '100%', height: '100%', margin: '0 auto', justifyContent: 'center', alignItems: 'center'}} >
            <Plot data={plotObject} layout={layout} config={config} useResizeHandler
            onInitialized={onInitialized} onDoubleClick={onDoubleClick}/>
        </div>)
}

export default LineChart
