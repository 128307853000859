import { useState } from "react"
import Button from '@material-ui/core/Button'
import axios from 'axios'

import { getFloats } from '../../../../../utils'
import { DecisionRuleChooserCheckbox } from "../../../../ui/DecisionRuleChoosers";
import NumericInput from '../../../../ui/NumericInput'

import { methodColorDict, methodNameDict } from '../../../../../app/thurstonianDicts'

import { useErrorHandler } from "../../../../../utils/apiUtils";
import { nanoid } from "@reduxjs/toolkit";
import { useUpdateOutput } from "../../../../../hooks/store/useUpdateOutput";
import { useSelector } from "react-redux";

const api = 'https://ci86z1c6w3.execute-api.us-east-1.amazonaws.com/default/ifplip_api_test'

const FCPowerCurve = ({ alpha, setAlpha, numTrials, setNumTrials, methods, setMethods }) => {
    
    const { checkAndPostError } = useErrorHandler()
    
    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    // const [ alpha, setAlpha ] = useState()
    // const [ numTrials, setNumTrials ] = useState()
    const [ deltaMax, setDeltaMax ] = useState()
    // const [ methods, setMethods ] = useState()
    const [ isValidating, setIsValidating ] = useState()

    const onSubmit = (event) => {
        
        event.preventDefault()

        setIsValidating(true)

        if(!(alpha && numTrials && deltaMax && methods)) {
            return
        }

        setIsValidating(false)

        const methodsArray = Object.keys(Object.fromEntries(Object.entries(methods).filter(([key, value]) => value)))

        const apiData = {
            "function_group": "forced_choice_power",
            "function_name": "power_curve",
            "methods": methodsArray.join('|'),
            "alpha": alpha,
            "num_trials": numTrials,
            "delta_max": deltaMax,
        }

        axios
            .post(api, apiData)
            .then((response) => {

                if(checkAndPostError(response)) {
                    return
                }

                let tempPlotData = []
                methodsArray.forEach((method) => {

                    const delta_vals = getFloats(Buffer.from(response.data[method + "_delta_vals"], "base64"))
                    const power_vals = getFloats(Buffer.from(response.data[method + "_power_vals"], "base64"))
                    
                    tempPlotData.push({
                        x: delta_vals,
                        y: power_vals,
                        type: 'line',
                        color: methodColorDict[method],
                        name: methodNameDict[method],
                        showlegend: true,
                        hovertemplate: 'Delta: %{x:.2f}  Power: %{y:.2f}',
                    })
                })

                const layout = {
                    yaxis: { showticklabels: true, zeroline: true },
                }

                const title = (methodsArray.length > 1 ? 'Power Curves: ' : 'Power Curve: ') + methodsArray.map(method => methodNameDict[method]).join(', ')
                
                const newPlotId = nanoid()
                const newOutputId = nanoid()
                const plotObject = {
                        OwnerId: userId,
                        PlotId: newPlotId,
                        Title: title,
                        Data: tempPlotData,
                        OutputReferences: [newOutputId],
                        Layout: layout,
                    }
                
                const outputObject = {
                    OwnerId: userId,
                    OutputId: newOutputId,
                    Title: title, 
                    Stream: [
                        { Type: 'plot', Content: newPlotId }
                    ],
                    Plots: [newPlotId],
                    ReferencingProjectIds: currentProjectId,
                }
                
                addOutput(outputObject, plotObject)
                
            })
            .catch((error) => {
                console.log("error: ")
                console.log(error)
            })
    }

    return (
        <div id="FCPowerSampleSizeDialog">
            <form onSubmit={onSubmit}>
                <DecisionRuleChooserCheckbox methodNameDict={methodNameDict} setValidatedValue={setMethods} isValidating={isValidating} defaultValue={methods} />
                <NumericInput label="Alpha" value={alpha} setValidatedValue={setAlpha} minValue='0' defaultValue={ alpha ? alpha.toString() : '0.05' }
                    maxValue='1' id='alpha' errorMessage='Alpha must be between 0 and 1.' isValidating={isValidating} />
                <NumericInput label="# Trials" value={numTrials} setValidatedValue={setNumTrials} minValue='0' defaultValue={ numTrials ? numTrials.toString() : ''}
                    maxValue='100000000' id='numTrials' errorMessage='# Trials must be greater than 0.' isValidating={isValidating} />
                <NumericInput label="Delta Max" value={deltaMax} setValidatedValue={setDeltaMax} minValue='0'
                    maxValue='1000' id='deltaMax' errorMessage='Delta Max must be greater than 0.' isValidating={isValidating} />
                <br />
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </form>
        </div>
    )
}

export default FCPowerCurve
