import React from 'react'
import { useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import { DecisionRuleChooserRadio } from '../../../ui/DecisionRuleChoosers'
import NumericInput from '../../../ui/NumericInput'
import { methodNameDict } from '../../../../app/thurstonianDicts'
import { useUpdateOutput } from '../../../../hooks/store/useUpdateOutput'
import { nanoid } from '@reduxjs/toolkit'
import { useErrorHandler } from '../../../../utils/apiUtils'
import { base64ToFloats } from '../../../../utils'
import { calcNormalFunction, makeNormalCurve } from '../../../../utils/plotUtils'

const api = process.env.REACT_APP_API_URL

function plot2AFC (samples, delta) {
    return ([{
            x: [samples[0]],
            y: [calcNormalFunction(samples[0])],
            mode: 'markers',
            color: 'red',
            marker: { 
                symbol: "square",
                opacity: 1,
                size: 10,
            },
            showlegend: false,
            hoverinfo: 'none',
        },
        {
            x: [samples[1]],
            y: [calcNormalFunction(samples[1], delta)],
            mode: 'markers',
            color: 'blue',
            marker: { 
                symbol: 'circle',
                opacity: 1,
                size: 10,
            },
            showlegend: false,
            hoverinfo: 'none',
        },
        {
            curveType: 'normal',
            x_lb: -4,
            x_ub: delta + 4.0,
            mean: delta,
            sd: 1,
            type: 'line',
            color: 'blue',
            name: 'Y',
            showlegend: false,
            hoverinfo: 'none',
            //hovertemplate: 'Delta: %{x:.2f}  Prob.: %{y:.2f}',
        },
        {
            curveType: 'normal',
            x_lb: -4,
            x_ub: delta + 4.0,
            mean: 0,
            sd: 1,
            type: 'line',
            color: 'red',
            name: 'X',
            showlegend: false,
            hoverinfo: 'none',
            //hovertemplate: 'Delta: %{x:.2f}  Prob.: %{y:.2f}',
        }])
}

const FCDemonstration = (props) => {
    
    const [ method, setMethod ] = React.useState()
    const [ delta, setDelta ] = React.useState()
    const [ isValidating, setIsValidating ] = React.useState()

    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const { checkAndPostError } = useErrorHandler()

    const handleSubmit = (event) => {
        
        event.preventDefault()

        setIsValidating(true)

        if(!(method && delta)) {
            return
        }

        setIsValidating(false)
        
        const data = {
            function_group: 'demonstration',
            function_name: method,
            delta: delta,
            num_trials: 1,
        }
        axios
            .post(api, data)
            .then((response) => {
                
                if(checkAndPostError(response)) {
                    return
                }

                const samples = base64ToFloats(response.data.samples)
                const results = response.data.results

                let tempPlotData = plot2AFC(samples, delta)
                //TODO
                const title = 'Demonstration'

                const sampleTable = {
                    TableId: nanoid(),
                    TableContent: [samples.map(a => [a])]
                }

                const newPlotId = nanoid()
                const newOutputId = nanoid()
                const plotObject = {
                    OwnerId: userId,
                    PlotId: newPlotId,
                    Title: title,
                    Data: tempPlotData,
                    OutputReferences: [newOutputId]
                }

                const outputObject = {
                    OwnerId: userId,
                    OutputId: newOutputId,
                    Title: title,
                    Stream: [
                        { Type: 'table', Content: sampleTable.TableId },
                        { Type: 'plot', Content: newPlotId }
                    ],
                    Tables: [sampleTable],
                    Plots: [newPlotId],
                    ReferencingProjectIds: currentProjectId,
                }
                
                addOutput(outputObject, plotObject)

            })
            .catch((error) => {
                console.log('error: ')
                console.log(error)
            })
    }
    
    return (
        <div id="demonstrationDialog">
            <form onSubmit={handleSubmit}>
                <DecisionRuleChooserRadio methodNameDict={methodNameDict} setValidatedValue={setMethod} isValidating={isValidating} />
                <NumericInput label="Delta" setValidatedValue={setDelta} isValidating={isValidating}
                    minValue='0' errorMessage='Delta must be greater than 0.' />
                <br />
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </form>
        </div>
    )
}

export default FCDemonstration
