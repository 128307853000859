import Dialog from '@material-ui/core/Dialog'
//import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { AddNamedItem } from '../../ui/AddNamedItem'
import { useSession } from '../../../hooks/store/sessionHook'
import { useGetProjectFolderByIdQuery, 
    usePutProjectMutation, usePutFolderMutation, usePutProjectFolderMutation } from '../../../services/databaseApi'
import { nanoid } from '@reduxjs/toolkit'
import { skipToken } from '@reduxjs/toolkit/dist/query'

const AddProject = ({onClose, parentFolderId, switchProjectsOnAdd = false, ...otherProps }) => {

    const { setCurrentProjectId, userId } = useSession()

    const { data: projectFolderData, isLoading : isProjectFolderLoading } = useGetProjectFolderByIdQuery(parentFolderId ?? skipToken)
    const [ putProject, { isLoading: isUpdating } ] = usePutProjectMutation()
    const [ putFolder ] = usePutFolderMutation()
    const [ putProjectFolder ] = usePutProjectFolderMutation()

    const getErrorMessage = (projectName) => {
        
        // if(allProjects.find(project => project.Name === projectName)) {
        //     return('A project already exists with that name.')
        // } else {
             return null
        // }
    }

    const onAdd = async (projectName) => {

        const newProjectId = nanoid()
        const newDatasetFolderId = nanoid()//addDatasetFolder({

        if(!isProjectFolderLoading) {

        //const newProjectId = addProjectWithinFolder({
            putProject({
                OwnerId: userId,
                ProjectId: newProjectId,
                Name: projectName,
                Output: [],
                DatasetFolders: [ { Name: "_project_root_dataset_folder_", FolderId: newDatasetFolderId } ],
                ParentFolderId: parentFolderId,
            })

            putFolder({
                Name: "_project_root_dataset_folder_",
                Children: [],
                Datasets: [],
                FolderId: newDatasetFolderId,
                ParentFolderId: null,
                OwnerId: userId,
                ParentProjectId: newProjectId,
            })
            
            if(projectFolderData) {
                if(projectFolderData.Projects && Object.keys(projectFolderData.Projects).length !== 0) {
                    putProjectFolder({
                        ...projectFolderData,
                        Projects: [ ...projectFolderData.Projects, { ProjectId: newProjectId, Name: projectName }]
                    })
                } else {
                    putProjectFolder({
                        ...projectFolderData,
                        Projects: [ { ProjectId: newProjectId, Name: projectName }]
                    })
                }
            }
            
            if(switchProjectsOnAdd) {
                //unloadMainOutput()
                setCurrentProjectId(newProjectId)
            }
        }
    }

    return(
        <AddNamedItem 
            title='Add Project'
            label='Project Name'
            onAdd={onAdd}
            getErrorMessage={getErrorMessage}
            onClose={onClose} 
            {...otherProps} 
        />
    )

}

const AddProjectModal = ({open, onClose, ...otherProps}) => {

    return(
        <Dialog open={open} onClose={onClose} >
            <AddProject onClose={onClose} {...otherProps} />
        </Dialog>
    )
}

export { AddProject, AddProjectModal }
