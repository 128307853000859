import React, { useRef, useEffect } from 'react'
import { Virtuoso } from 'react-virtuoso'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal'

import LineChart from '../documents/LineChart';
import OutputEntry from '../documents/OutputEntry';

//import { useSession } from '../../hooks/store/sessionHook';
import { useMainOutput } from '../../hooks/store/mainOutputHook';
import { useAllOutput } from '../../hooks/store/allOutputHook';
import { useGetProjectByIdQuery, useDeleteOutputMutation, usePutProjectMutation, useDeletePlotMutation } from '../../services/databaseApi';
import { skipToken } from '@reduxjs/toolkit/dist/query';

const useStyles = makeStyles({
    modalContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContents: {
        height: '80%',
        width: '80%'
    },
})

function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

const OutputList = ({ projectId }) => {

    const classes = useStyles()
    // const { currentProjectId } = useSession()
    // const { loadMainOutput } = useMainOutput()
    const { data: projectData, 
        isLoading: isProjectLoading } = useGetProjectByIdQuery(projectId ?? skipToken)

    const [ deleteDataset ] = useDeleteOutputMutation()
    const [ deletePlot ] = useDeletePlotMutation()
    const [ putProject ] = usePutProjectMutation()

    const [ isOpenModal, setIsOpenModal ] = React.useState(false)
    const [ modalPlotId, setModalPlotId ] = React.useState(null)
    const [ allCards, setAllCards ] = React.useState([])

    // useEffect(() => {
    //     loadMainOutput()
    // }, [ currentProjectId ])

    const [windowSize, setWindowSize] = React.useState(getWindowSize());

    useEffect(() => {
      function handleWindowResize() {
        setWindowSize(getWindowSize());
      }
  
      window.addEventListener('resize', handleWindowResize);
  
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

    const handleModalOpen = (plotId) => {
        setModalPlotId(plotId)
        setIsOpenModal(true)
    }

    const handleModalClose = () => {
        setIsOpenModal(false)
    }

    useEffect(() => {
        if(projectData && !isProjectLoading) {
            if(projectData.Output) {
                setAllCards(projectData.Output)
            }
        }
    }, [projectData, isProjectLoading])

    //const { allCards, removeCard, toggleSavedToProject } = useMainOutput()
    //const { getEntry } = useAllOutput()

    const handleRemoveCard = (entryId, outputData) => {

        if(outputData && outputData.Plots) {
            outputData.Plots.map(plotId => deletePlot(plotId))
        }

        deleteDataset(entryId)

        if(projectData) {
            putProject({
                ...projectData,
                Output: projectData?.Output.filter(outputId => outputId !== entryId)
            })
        }
    }

    const renderedCards = itemIndex => {
        
        if(itemIndex >= allCards.length) {
            return(<p>No entry found.</p>)
        }

        //const entry = getEntry(allCards[itemIndex].OutputId)


        // if(!entry) {
        //     return(<p>No entry found.</p>)
        // }
        //const id = allCards[itemIndex].OutputId
        const id = projectData.Output[itemIndex]

        return(
            <OutputEntry outputId={id} onModalOpen={handleModalOpen} //entry={{ outputId: id, savedToProject: allCards[itemIndex].savedToProject}} 
                onDeleteEntry={handleRemoveCard} onToggleSavedToProject={null}/> 
        )
    }

    const virtuosoRef = useRef(null)

    useEffect(() => {

        const scrollToTop = (event) => {
            if(virtuosoRef.current !== null) {
                virtuosoRef.current.scrollToIndex(0, 'start')
            }
        }

        document.addEventListener('mainOutput:scrollToTop', scrollToTop)
        return () => document.removeEventListener('mainOutput:scrollToTop', scrollToTop)
    }, [])

    return (
        <div id='mainOutput' style={{ width: '100%', height: '100%' }}>
            <Virtuoso
                ref={virtuosoRef}
                style={{ width: '100%', minWidth: '300px', height: '100%' }}
                totalCount={projectData ? (projectData.Output ? projectData.Output.length : 0) : 0 }
                itemContent={renderedCards}
            />
            <Modal open={isOpenModal} onClose={handleModalClose} className={classes.modalContainer} classes={{paper: classes.paper}}>
                <div style={{ width: '80%', height: '80%' }}>
                    {modalPlotId !== null && <LineChart plotId={modalPlotId} showTitle={true} width={windowSize.innerWidth*0.8} height={windowSize.innerHeight*0.8}/>}
                </div>
            </Modal>
        </div>
    )
}

export default OutputList
