import React from 'react'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import { DecisionRuleChooserRadio } from '../../../ui/DecisionRuleChoosers'
import NumericInput from '../../../ui/NumericInput'
import { methodNameDict } from '../../../../app/thurstonianDicts'
import { useUpdateOutput } from '../../../../hooks/store/useUpdateOutput'
import { useErrorHandler } from '../../../../utils/apiUtils'
import { useSelector } from 'react-redux'
import { nanoid } from '@reduxjs/toolkit'

const api = process.env.REACT_APP_API_URL

const Equivalence = (props) => {
    
    const [ method, setMethod ] = React.useState()
    const [ deltaUpperBound, setDeltaUpperBound ] = React.useState()
    const [ sampleSize, setSampleSize ] = React.useState()
    const [ alpha, setAlpha ] = React.useState(0.05)
    const [ isValidating, setIsValidating ] = React.useState()

    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const { checkAndPostError } = useErrorHandler()

    const handleSubmit = (event) => {
        
        event.preventDefault()

        setIsValidating(true)

        if(!(method && deltaUpperBound && sampleSize && alpha)) {
            return
        }

        setIsValidating(false)
        
        const apiData = {
            function_group: 'equivalence',
            function_name: 'equivalence',
            method: method,
            delta_upper_bound: deltaUpperBound,
            sample_size: sampleSize,
            alpha: alpha,
        }

        axios
            .post(api, apiData)
            .then((response) => {
                
                if(checkAndPostError(response)) {
                    return
                }

                const title = 'Equivalence'
                    
                const tableContent = [
                    [ "Max d'", response.data.d_prime_bound.toFixed(2) ],
                    [ "Max # correct responses", response.data.bin_bound ]
                ]

                const tableId = nanoid()

                const table = {
                    TableId: tableId,
                    TableContent: tableContent
                }

                const newOutputId = nanoid()

                const outputObject = {
                    OwnerId: userId,
                    OutputId: newOutputId,
                    Title: title, 
                    Stream: [
                        { Type: 'table', Content: tableId },
                    ],
                    Tables: [table], 
                    ReferencingProjectIds: currentProjectId,
                }
            
                addOutput(outputObject)
            })
            .catch((error) => {
                console.log('error: ')
                console.log(error)
            })
    }
    
    return (
        <div id="equivalenceDialog">
            <br />
            <form onSubmit={handleSubmit}>
                <DecisionRuleChooserRadio methodNameDict={methodNameDict} setValidatedValue={setMethod} isValidating={isValidating} />
                <NumericInput label="Delta Upper Bound" setValidatedValue={setDeltaUpperBound} isValidating={isValidating}
                    minValue='0' errorMessage='The bound must be greater than 0.' />
                <NumericInput label="Sample Size" setValidatedValue={setSampleSize} isValidating={isValidating}
                    minValue='0' errorMessage='The sample size must be greater than 0.' isInteger />
                <NumericInput label="Alpha" setValidatedValue={setAlpha} isValidating={isValidating}
                    defaultValue='0.05'
                    minValue='0' maxValue='1' errorMessage='Alpha must be between 0 and 1.' />
                <br />
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </form>
        </div>
    )
}

export default Equivalence
