import { useState } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import { DecisionRuleChooserCheckbox, decisionRuleDictToArray } from '../../../ui/DecisionRuleChoosers'
import NumericInput from '../../../ui/NumericInput'
import { methodNameDict, methodColorDict } from '../../../../app/thurstonianDicts'
import { base64ToFloats } from '../../../../utils'
import { range } from '../../../../utils/plotUtils'
import { useErrorHandler } from '../../../../utils/apiUtils'
import { nanoid } from '@reduxjs/toolkit'
import { useUpdateOutput } from '../../../../hooks/store/useUpdateOutput'
import { useSelector } from 'react-redux'

const api = process.env.REACT_APP_API_URL

const Simulation = () => {
    
    const [ selectedTab, setSelectedTab ] = useState('equalVariance')
    const [ methods, setMethods ] = useState()
    const [ sampleSize, setSampleSize ] = useState()
    const [ delta, setDelta ] = useState()
    const [ varianceRatio, setVarianceRatio ] = useState()
    const [ isValidating, setIsValidating ] = useState(false)

    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const { checkAndPostError } = useErrorHandler()

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue)
    }

    const handleSubmit = (event) => {

        event.preventDefault()

        setIsValidating(true)

        if(!(methods && sampleSize && delta)) {
            if(selectedTab === "unequalVariance" && !varianceRatio) {
                return
            } else if(selectedTab === "equalVariance") {
                return
            }
        }

        setIsValidating(false)

        let tempVarianceRatio = 1.0
        if(selectedTab === "unequalVariance") {
            tempVarianceRatio = varianceRatio
        }

        const methodArray = decisionRuleDictToArray(methods)
    
        const data = {
            function_group: 'forced_choice_simulation',
            function_name: 'simulation',
            methods: methodArray.join('|'),
            delta: delta,
            sample_size: sampleSize,
            variance_ratio: tempVarianceRatio,
        }
        axios
            .post(api, data)
            .then((response) => {
                if(checkAndPostError(response)) {
                    return
                }

                let tempPlotData = []
                let accumProbsTableContent = [["Method", "Accumulated Prop."]]
                methodArray.forEach((method) => {

                    const sample_indices = range(1, sampleSize, 1)
                    const accum_probs = base64ToFloats(response.data[method + "_accum_probs"])
                    
                    tempPlotData.push({
                        x: sample_indices,
                        y: accum_probs,
                        type: 'line',
                        color: methodColorDict[method],
                        name: methodNameDict[method],
                        showlegend: true,
                        hovertemplate: 'Accum. Prop.: %{y:.2f}',
                    })

                    accumProbsTableContent = accumProbsTableContent.concat([[methodNameDict[method], accum_probs[accum_probs.length - 1].toFixed(2)]])
                })

                const title = (selectedTab === 'unequalVariance' ? 'Unequal' : 'Equal') + ' Variance Simulation: ' + 
                    methodArray.map(method => methodNameDict[method]).join(', ')

                const newPlotId = nanoid()
                const newOutputId = nanoid()
                const plotObject = {
                    OwnerId: userId,
                    PlotId: newPlotId,
                    Title: title,
                    Data: tempPlotData,
                    OutputReferences: [newOutputId]
                }

                const table = {
                    TableId: nanoid(),
                    TableContent: accumProbsTableContent,
                    OptionsList: { ColumnHeaders: true },
                }

                const outputObject = {
                    OwnerId: userId,
                    OutputId: newOutputId,
                    Title: title,
                    Stream: [
                        { Type: 'table', Content: table.TableId },
                        { Type: 'plot', Content: newPlotId }
                    ],
                    Tables: [ table ],
                    Plots: [newPlotId],
                    ReferencingProjectIds: currentProjectId,
                }
                
                addOutput(outputObject, plotObject)
            })
            .catch((error) => {
                console.log('error: ')
                console.log(error)
            })
    }

    return (
        <div id="simulationDialog">
            <form onSubmit={handleSubmit}>
                <TabContext value={selectedTab}>
                    <Tabs value={selectedTab} onChange={handleTabChange}>
                        <Tab label="Equal Variance" value="equalVariance" />
                        <Tab label="Unequal Variance" value="unequalVariance" />
                    </Tabs>
                    <br />
                    <DecisionRuleChooserCheckbox
                        methodNameDict={methodNameDict}
                        setValidatedValue={setMethods}
                        isValidating={isValidating}
                    />
                    <NumericInput label="Sample Size" setValidatedValue={setSampleSize} minValue='0'
                        errorMessage="Sample size must be greater than 0." isValidating={isValidating} />
                    <NumericInput label="Delta" setValidatedValue={setDelta} minValue='0'
                        errorMessage="Delta must be greater than 0." isValidating={isValidating} />
                    {selectedTab === 'unequalVariance' && (
                        <NumericInput label="Variance Ratio" setValidatedValue={setVarianceRatio} minValue='0'
                        errorMessage="Variance ratio must be greater than 0." isValidating={isValidating} />
                    )}
                    <br/>
                    <Button
                        style={{ padding: 3 }}
                        variant="contained"
                        color="primary"
                        type="submit"
                    >
                        Run
                    </Button>
                </TabContext>
            </form>
        </div>
    )
}

export default Simulation
