import { useState } from "react"
import Button from '@material-ui/core/Button'
import axios from 'axios'

import { methodColorDict, methodNameDict } from '../../../../../app/thurstonianDicts'
import { DecisionRuleChooserCheckbox } from "../../../../ui/DecisionRuleChoosers";
import NumericInput from "../../../../ui/NumericInput";
import { useUpdateOutput } from "../../../../../hooks/store/useUpdateOutput";
import { useErrorHandler } from "../../../../../utils/apiUtils";
import { useSelector } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";

const api = 'https://ci86z1c6w3.execute-api.us-east-1.amazonaws.com/default/ifplip_api_test'

const FCPowerSampleSize = ({ methods, setMethods, delta, setDelta, alpha, setAlpha, power, setPower }) => {
    
    const [ maxTrials, setMaxTrials ] = useState()
    const [ isValidating, setIsValidating ] = useState(false)

    const { checkAndPostError } = useErrorHandler()
    
    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const onSubmit = (event) => {
        
        event.preventDefault()

        setIsValidating(true)

        if(!(methods && delta && alpha && power)) {
            return
        }

        setIsValidating(false)

        const methodsArray = Object.keys(Object.fromEntries(Object.entries(methods).filter(([key, value]) => value)))

        const data = {
            "function_group": "forced_choice_power",
            "function_name": "sample_size",
            "methods": methodsArray.join('|'),
            "delta": delta,
            "alpha": alpha,
            "power": power,
            "max_trials": maxTrials,
        }

        axios
            .post(api, data)
            .then((response) => {

                if(checkAndPostError(response)) {
                    return
                }

                let tempTable = [['Method:', 'Sample Size:']]
                methodsArray.forEach((method) => {
                    tempTable.push([methodNameDict[method], response.data[method + '_sample_size']])
                })

                const title = 'Delta Calculation: ' + methodsArray.map(method => methodNameDict[method]).join(', ')
                const text = 'Delta = ' + delta.toFixed(2) + '   |   Alpha = ' + alpha.toFixed(3) + '   |   Power = ' + power.toFixed(2) + '   |   Max Trials = ' + maxTrials.toString()
                
                let tempPlotData = [
                    {
                        x: methodsArray.map((method) => methodNameDict[method]),
                        y: methodsArray.map(
                            (method) => response.data[method + '_sample_size']
                        ),
                        type: 'bar',
                        text: methodsArray
                            .map((method) => response.data[method + '_sample_size'])
                            .map((val) => val.toFixed(0)),
                        textposition: 'auto',
                        width: 0.3,
                        customColors: methodsArray.map(
                            (method) => methodColorDict[method]
                        ),
                        hideTrace: true,
                        showlegend: false,
                        hovertemplate: ' %{x}: %{y:.0f}<extra></extra>',
                    },
                ]

                const newPlotId = nanoid()
                const newOutputId = nanoid()
                const plotObject = {
                        OwnerId: userId,
                        PlotId: newPlotId,
                        Title: title,
                        Data: tempPlotData,
                        OutputReferences: [newOutputId]
                    }
                
                const outputObject = {
                    OwnerId: userId,
                    OutputId: newOutputId,
                    Title: title, 
                    Stream: [
                        { Type: 'text', Content: text},
                        { Type: 'plot', Content: newPlotId }
                    ],
                    Plots: [newPlotId],
                    ReferencingProjectIds: currentProjectId,
                }
                
                addOutput(outputObject, plotObject)
                
            })
            .catch((error) => {
                console.log("error: ")
                console.log(error)
            })
    }

    return (
        <div id="FCPowerSampleSizeDialog">
            <form onSubmit={onSubmit}>
                <DecisionRuleChooserCheckbox methodNameDict={methodNameDict} setValidatedValue={setMethods} isValidating={isValidating} defaultValue={methods} />
                <NumericInput label="Delta" id="delta"
                    setValidatedValue={setDelta}
                    defaultValue={ delta ? delta.toString() : ''}
                    minValue='0' maxValue='100000000' 
                    errorMessage='Delta must be greater than 0.'
                    isValidating={isValidating}
                />
                <NumericInput label="Alpha" id="alpha"
                    setValidatedValue={setAlpha}
                    defaultValue={ alpha ? alpha.toString() : '0.05' }
                    minValue='0' maxValue='1' 
                    errorMessage='Alpha must be between 0 and 1.'
                    isValidating={isValidating}
                />
                <NumericInput label="Power" id="power"
                    setValidatedValue={setPower}
                    defaultValue={ power ? power.toString() : ''}
                    minValue='0' maxValue='1' 
                    errorMessage='Power must be between 0 and 1.'
                    isValidating={isValidating}
                />
                <NumericInput label="Max Trials" id="maxTrials"
                    setValidatedValue={setMaxTrials}
                    minValue='0' maxValue='100000000' 
                    errorMessage='Max Trials must be greater than 0.'
                    isValidating={isValidating}
                />
                <br />
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </form>
        </div>
    )
}

export default FCPowerSampleSize
