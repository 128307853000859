import React from 'react'
import Menu from '@material-ui/core/Menu'
import PopperMenu from '../ui/PopperMenu'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
}));

// Need to specify in props: isOpen, menuItems (each with label) and  anchor (for where menu should start)
const AnchoredMenu = (props) => {

    const classes = useStyles();

    // const handleMenu = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    const handleClose = () => {
        //anchorEl = null
        console.log(props.isOpen)
    }

    // const handleSubMenuClick = (event) => {
    //     console.log(event)
    // }

    // let placement = {
    //     vertical: 'bottom',
    //     horizontal: 'left',
    //     }

    let placement = 'bottom-start'
    if (props.placement !== undefined) {
        placement = props.placement
    }

    let transformOrigin = 'center top'
    if (props.transformOrigin !== undefined) {
        transformOrigin = props.transformOrigin
    }

    let paperProps = {
        style: {
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            borderWidth: '0px 1px 1px',
            borderColor: 'black',
            borderStyle: 'solid',
        },
        variant: 'outlined',
    }
    if (props.paperProps !== undefined) {
        paperProps = props.paperProps
    }

    return (
        <div className='anchoredMenu'>
            <PopperMenu
                //MenuListProps={{onMouseLeave: props.onMouseLeave}}
                onClick={handleClose}
                disableEnforceFocus
                zIndex={props.zIndex}
                className={classes.menu}
                id="menu-project" 
                anchorEl={props.menuAnchor}
                getContentAnchorEl={null}
                placement={placement}
                keepMounted
                transformOrigin={transformOrigin}
                open={props.isOpen}
                onClose={handleClose}
                PaperProps={paperProps}
                MenuListProps={{
                    disableEnforceFocus: true,
                }}
            >
                {props.children}
            </PopperMenu>
        </div>
    )
}

export default AnchoredMenu