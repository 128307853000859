import { useState, useEffect, useCallback } from 'react'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Input from '@material-ui/core/Input'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { FormHelperText } from '@material-ui/core'
import NumericInput from '../../ui/NumericInput'
import { useErrorHandler } from '../../../utils/apiUtils'
import { useSelector } from 'react-redux'
import { useUpdateOutput } from '../../../hooks/store/useUpdateOutput'
import { base64ToFloats, getDefaultColorByIndex } from '../../../utils'
import { nanoid } from '@reduxjs/toolkit'

const api = process.env.REACT_APP_API_URL

const useStyles = makeStyles({
    root: {
        marginTop: '14px',
    },
    formLabel: {
        textAlign: 'left',
        color: 'black'
    },
})

const ScalingDemo = () => {

    const classes = useStyles()

    const [ type, setType ] = useState('monadic')
    const [ boundary1, setBoundary1 ] = useState()
    const [ boundary2, setBoundary2 ] = useState()
    const [ boundary3, setBoundary3 ] = useState()
    const [ boundary4, setBoundary4 ] = useState()
    const [ boundary5, setBoundary5 ] = useState()
    const [ boundary6, setBoundary6 ] = useState()
    const [ delta, setDelta ] = useState()
    const [ errorText, setErrorText ] = useState(null)
    const [ isValidating, setIsValidating ] = useState()

    const { checkAndPostError } = useErrorHandler()
    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    useEffect(() => {

        setErrorText(null)

        if(isValidating) {
            if(!(!isNaN(boundary1) && !isNaN(boundary2) && !isNaN(boundary3) && !isNaN(boundary4) && !isNaN(boundary5) && !isNaN(boundary6))) {
                setErrorText("Please specify all boundaries.")
            } else if(!(boundary1 < boundary2 && boundary2 < boundary3 && boundary3 < boundary4 && boundary4 < boundary5 && boundary5 < boundary6)) {
                setErrorText("Please make sure all boundaries are in increasing order.")
            } 
        }

    }, [ boundary1, boundary2, boundary3, boundary4, boundary5, boundary6, isValidating ])

    const onSubmit = (event) => {
        
        event.preventDefault()

        setIsValidating(true)

        if(!(!isNaN(boundary1) && !isNaN(boundary2) && !isNaN(boundary3) && !isNaN(boundary4) && !isNaN(boundary5) && !isNaN(boundary6) && !isNaN(delta)) ||
            !(boundary1 < boundary2 && boundary2 < boundary3 && boundary3 < boundary4 && boundary4 < boundary5 && boundary5 < boundary6)) {
            return
        }

        let data = {
            "function_group": "scaling",
            "function_name": "scale_demonstration",
            "num_stimuli": type === "monadic" ? 1 : 2,
            "num_cats": 7,
            "deltas": type === "monadic" ? [delta] : [ 0, delta],
            "boundaries": [ boundary1, boundary2, boundary3, boundary4, boundary5, boundary6 ],
        }

        axios
            .post(api, data)
            .then((response) => {

                if(checkAndPostError(response)) {
                    return
                }

                const probs = base64ToFloats(response.data.probs)
                const means = base64ToFloats(response.data.means)
                const stdDevs = base64ToFloats(response.data.std_devs)
                
                let probPrecision = 2

                const stimuliMult = (type === "monadic") ? 1 : 2

                let tableContent = [
                    ["Rating", "Probability"],
                    ["1", probs[0].toFixed(probPrecision)],
                    ["2", probs[1 * stimuliMult].toFixed(probPrecision)],
                    ["3", probs[2 * stimuliMult].toFixed(probPrecision)],
                    ["4", probs[3 * stimuliMult].toFixed(probPrecision)],
                    ["5", probs[4 * stimuliMult].toFixed(probPrecision)],
                    ["6", probs[5 * stimuliMult].toFixed(probPrecision)],
                    ["7", probs[6 * stimuliMult].toFixed(probPrecision)],
                ]

                if(type === "twoProducts") {
                    tableContent[0][1] = "Probability 1"
                    tableContent[0][2] = "Probability 2"
                    tableContent[1][2] = probs[1].toFixed(probPrecision)
                    tableContent[2][2] = probs[3].toFixed(probPrecision)
                    tableContent[3][2] = probs[5].toFixed(probPrecision)
                    tableContent[4][2] = probs[7].toFixed(probPrecision)
                    tableContent[5][2] = probs[9].toFixed(probPrecision)
                    tableContent[6][2] = probs[11].toFixed(probPrecision)
                    tableContent[7][2] = probs[13].toFixed(probPrecision)
                }

                const table = {
                    TableId: nanoid(),
                    TableContent: tableContent,
                    OptionsList: { ColumnHeaders: true },
                }

                let tempPlotData = (type === "monadic" ? [delta] : [0, delta]).map((d_prime, index) => ({
                    curveType: 'normal',
                    x_lb: -4,
                    x_ub: d_prime + 4.0,
                    mean: d_prime,
                    sd: 1,//stdDevs[index],
                    type: 'line',
                    color: getDefaultColorByIndex(index),
                    showlegend: false,
                    hoverinfo: 'none',
                }))

                tempPlotData.push(...[ boundary1, boundary2, boundary3, boundary4, boundary5, boundary6 ].map(boundary => ({
                    x: [boundary, boundary],
                    y: [0, 0.45],
                    type: 'scatter',
                    mode: 'lines',
                    color: 'black',
                    showlegend: false,
                    hoverinfo: 'none',
                })))

                const newOutputId = nanoid()
                const newPlotId = nanoid()

                const title = "Scale Demonstration - " + (type === "monadic" ? "Monadic" : "Two Products")

                const plotObject = { //addPlot({
                    OwnerId: userId,
                    PlotId: newPlotId,
                    Title: title,
                    Data: tempPlotData,
                    OutputReferences: [newOutputId]
                }

                const outputObject = {
                    OwnerId: userId,
                    OutputId: newOutputId,
                    Title: title,
                    Stream: [
                        { Type: 'table', Content: table.TableId },
                        { Type: 'plot', Content: newPlotId },
                    ],
                    Tables: [table ], 
                    Plots: [newPlotId],
                    ReferencingProjectIds: currentProjectId,
                }
                
                addOutput(outputObject, plotObject)
            })
            .catch((error) => {
                console.log('error: ')
                console.log(error)
            })

        setIsValidating(false)
        setErrorText(null)
    }

    return (
        <div
            id="scalingDemonstrationDialog"
            style={{ width: 'max-content', margin: '0 auto' }}
        >
            <h2>Scaling Demonstration</h2>
            <form onSubmit={onSubmit} style={{ textAlign: 'left' }} >
                <FormLabel htmlFor='scale_boundaries' className={classes.formLabel} >Scale Boundaries</FormLabel>
                <div id='scale_boundaries' style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-evenly' }} >
                    <ScaleBoundaryInput id='boundary_1_input' setValidatedValue={setBoundary1} isValidating={isValidating} maxValue={boundary2 ? boundary2 : undefined} />
                    <ScaleBoundaryInput id='boundary_2_input' setValidatedValue={setBoundary2} isValidating={isValidating} 
                        minValue={boundary1 ? boundary1 : undefined} />
                    <ScaleBoundaryInput id='boundary_3_input' setValidatedValue={setBoundary3} isValidating={isValidating} 
                        minValue={boundary2 ? boundary2 : undefined} />
                    <ScaleBoundaryInput id='boundary_4_input' setValidatedValue={setBoundary4} isValidating={isValidating} 
                        minValue={boundary3 ? boundary3 : undefined} />
                    <ScaleBoundaryInput id='boundary_5_input' setValidatedValue={setBoundary5} isValidating={isValidating} 
                        minValue={boundary4 ? boundary4 : undefined} />
                    <ScaleBoundaryInput id='boundary_6_input' setValidatedValue={setBoundary6} isValidating={isValidating} minValue={boundary5 ? boundary5 : undefined}/>
                </div>
                {errorText && <FormHelperText error>{errorText}</FormHelperText>}
                <br />
                <NumericInput
                    setValidatedValue={setDelta}
                    isValidating={isValidating}
                    label="Delta"
                    minValue="0"
                    errorMessage="Delta must be greater than 0."
                />
                <br />
                <RadioGroup
                    style={{ display: 'flex', alignItems: 'center' }}
                    aria-label="method"
                    name="demoType"
                    value={type}
                    onChange={(event, newValue) => { setType(newValue)}}
                >
                    <div id='radioGroupContainer' style={{ display: 'flex', width: 'max-content', flexFlow: 'column nowrap', alignItems: 'left' }}>
                        <FormControlLabel
                                    value='monadic'
                                    control={<Radio />}
                                    label='Monadic'
                                />
                        <FormControlLabel
                                    value='twoProducts'
                                    control={<Radio />}
                                    label='Two Products'
                                />
                    </div>
                </RadioGroup>
                <br />
                <div style={{ display: 'flex', alignItems: 'center' }} >
                    <Button
                        style={{ padding: 3, margin: '0 auto' }}
                        variant="contained"
                        color="primary"
                        type="submit"
                    >
                        Run
                    </Button>
                </div>
            </form>
        </div>
    )

}

export default ScalingDemo


const ScaleBoundaryInput = ({ id, setValidatedValue, defaultValue='', minValue, maxValue, minInclusive=false, maxInclusive=false,
    forceLeadingZero=true, isInteger=false, isRequired=true, isValidating }) => {
    
    const classes = useStyles()

    const [ value, setValue ] = useState(defaultValue)
    const [ error, setError ] = useState(false)

    useEffect(() => {
        if(defaultValue) {
            setValidatedValue(Number(defaultValue))
        }
    }, [setValidatedValue, defaultValue])

    const updateErrorState = useCallback((newErrorState = true) => {
        
        //setValidatedValue(value)

        if(isValidating) {
            setError(newErrorState)
        } else {
            setError(false)
        }
    }, [ isValidating, setError ])

    const handleBlur = useCallback((value, isValidating) => {

        // Remove the whitespace
        setValue(value.replace(/\s/g, ""))

        // Remove the error if the field is empty
        if(value === '') {
            if(isValidating && isRequired) {
                setValidatedValue(null)
                updateErrorState(true)
            } else {
                setError(false)
            }
            return
        }

        // Check that input is a number
        if(isNaN(value)) {
            setValidatedValue(null)
            updateErrorState(true)
            return
        }

        const newValue = Number(value)

        // Add a leading 0 to decimals if user omitted it
        if(forceLeadingZero && !isInteger) {
            if(Math.abs(newValue) > 0 && Math.abs(newValue) < 1) {
                const firstNumber = value[0] === '-' ? value[1] : value[0]
                if(firstNumber !== '0') {
                    if(value[0] === '-') {
                        setValue('-0' + value.substr(1))
                    } else {
                        setValue('0' + value)
                    }
                }
            }
        }

        setValidatedValue(value)

        // check that value meets the minValue if it exists
        if(!isNaN(minValue)) {
            if(newValue < Number(minValue)) {
                updateErrorState(true)
                return
            } else if(!minInclusive && newValue <= Number(minValue)) {
                updateErrorState(true)
                return
            }
        }

        // check that value meets the maxValue if it exists
        if(!isNaN(maxValue)) {
            if(newValue > Number(maxValue)) {
                updateErrorState(true)
                return
            } else if(!maxInclusive && newValue >= Number(maxValue)) {
                updateErrorState(true)
                return
            }
        }
        
        // // check that input is an integer if it should be
        // if(isInteger && !Number.isInteger(newValue)) {
        //     updateErrorState(true)
        //     return
        // }

        // Set the validated value if we made it
        setError(false)
        setValidatedValue(Number(value))
        
    }, [ setValidatedValue, forceLeadingZero, isInteger, isRequired, minValue, maxValue, minInclusive, maxInclusive, updateErrorState ])

    useEffect(() => {
        if(isValidating) {
            handleBlur(value, isValidating)
        }
    }, [isValidating, handleBlur, value])

    return(
        <div style={{ maxWidth: '50px', margin: '0 8px' }}>
            {/* <TextField label={label} id={id} value={value} onChange={(event) => setValue(event.target.value) } 
                error={helperText !== ''} helperText={helperText} onBlur={handleBlur} style={{ marginTop: '4px' }} /> */}
            <FormControl error={error} className={classes.root} >
                <Input value={value} onChange={(event) => setValue(event.target.value) } id={id} onBlur={()=> handleBlur(value, isValidating)} style={{ marginTop: '0px' }} />
            </FormControl>
        </div>
    )
}
