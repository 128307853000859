import { useSelector } from 'react-redux'
import { usePutOutputMutation, usePutPlotMutation, usePutProjectMutation,
    useGetProjectByIdQuery } from '../../services/databaseApi'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useCallback } from 'react'

export function useUpdateOutput(projectId) {
    
    const [ putOutput ] = usePutOutputMutation()
    const [ putPlot ] = usePutPlotMutation()
    const [ putProject ] = usePutProjectMutation()

    //const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)
    
    if(!projectId) {
        projectId = currentProjectId
    }

    const { data: projectData,
        isLoading: isProjectLoading,
        isSuccess: isProjectSuccess } = useGetProjectByIdQuery(projectId ?? skipToken)

    const _addOutput = useCallback((outputObject, plotObject) => {

        if(plotObject) {
            putPlot(plotObject)
        }

        if(outputObject) {
            putOutput({
                ...outputObject,
                ReferencingProjectIds: projectId,
            })
        }

        putProject({
            ...projectData,
            Output: [outputObject.OutputId, ...projectData.Output],
        })
    }, [projectId, projectData, putOutput, putPlot, putProject])

    return {
        addOutput: _addOutput,
    }
}
