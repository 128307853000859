import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useGetFoldersByProjectIdQuery, useGetDatasetByIdQuery } from '../services/databaseApi'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { reduceDataArray } from '../utils/dataUtils';


export function useDatasets() {
    
    const [ selectedDataset, setSelectedDataset ] = useState(null);
    const [ selectedDatasetId, setSelectedDatasetId ] = useState(null);
    const [ datasetOptionsList, setDatasetOptionsList ] = useState([])
    const [ datasetData, setDatasetData ] = useState(null)
    const [ columnNames, setColumnNames ] = useState([])
    const [ datasetError, setDatasetError ] = useState(false)

    const currentProjectId = useSelector(state => state.session.currentProjectId)
    
    const { data: dataFolders,
        isLoading: isDataFoldersLoading,
        isSuccess: isDataFoldersSuccess } = useGetFoldersByProjectIdQuery(currentProjectId ?? skipToken)

    const { data: selectedDatasetData,
        isLoading: isSelectedDatasetDataLoading,
        isSuccess: isSelectedDatasetDataSuccess } = useGetDatasetByIdQuery(selectedDatasetId ?? skipToken)

    useEffect(() => {
        if(dataFolders) {
            setDatasetOptionsList(dataFolders.map(dataFolderInfo => dataFolderInfo.Datasets.map(datasetInfo => ({ label: datasetInfo.Name, id: datasetInfo.DatasetId }))).flat())
        } else {
            setDatasetOptionsList([])
            setSelectedDataset(null)
            setSelectedDatasetId(null)
            setDatasetData(null)
        }
    }, [dataFolders, isDataFoldersLoading, isDataFoldersSuccess])

    useEffect(() => {
        if(selectedDatasetData && selectedDatasetData.Data) {
            const datasetDataTemp = JSON.parse(selectedDatasetData.Data)
            if(datasetDataTemp.length < 1 || datasetDataTemp[0].length < 1) {
                setDatasetError(true)
            } else {
                setDatasetError(false)
                setDatasetData(datasetDataTemp)
                setColumnNames(datasetDataTemp[0])
            }
        } else {
            setDatasetData(null)
        }
    }, [selectedDatasetData, isSelectedDatasetDataLoading, isSelectedDatasetDataSuccess, selectedDatasetId])

    useEffect(() => {
        if(selectedDataset && selectedDataset.id) {
            setSelectedDatasetId(selectedDataset.id)
        }
    }, [selectedDataset])

    const getDatasetSize = () => {
        if(datasetData && Array.isArray(datasetData)) {
            const reducedData = reduceDataArray(datasetData)
            return({ numCols: reducedData.lastFilledCol + 1, numRows: reducedData.lastFilledRow + 1 })
        } else {
            return(null)
        }
    }

    const getColumnData = (columnIndex) => {
        if(datasetData) {
            return(datasetData.map(row => row[columnIndex]))
        } else {
            return null
        }
    }

    return({
        selectedDataset, setSelectedDataset,
        selectedDatasetId, setSelectedDatasetId,
        dataFolders, isDataFoldersLoading, isDataFoldersSuccess,
        datasetOptionsList,
        selectedDatasetData, isSelectedDatasetDataLoading, isSelectedDatasetDataSuccess,
        datasetData,
        getDatasetSize,
        columnNames,
        getColumnData,
        datasetError
    })
}
    