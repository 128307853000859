import { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import TabPanel from '@material-ui/lab/TabPanel'
import TabContext from '@material-ui/lab/TabContext'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import NumericInput from '../../ui/NumericInput'
import AutocompleteDropdown from '../../ui/AutocompleteDropdown'
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core'
import { useDatasets } from '../../../hooks/useDatasets'
import { base64ToFloats, getDefaultColorByIndex } from '../../../utils'
import { roundPValue, transposeArray } from '../../../utils/dataUtils'
import { nanoid } from '@reduxjs/toolkit'
import { useUpdateOutput } from '../../../hooks/store/useUpdateOutput'
import { useSelector } from 'react-redux'
import { useErrorHandler } from '../../../utils/apiUtils'

const exampleListItems = [ 'Column 1', 'Column 2', 'Column 3', 'Column 4']

const api = process.env.REACT_APP_API_URL

const Ranks = () => {

    const [ selectedTab, setSelectedTab ] = useState('frequencyData')
    
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue)
    }

    return (
        <div id="RanksDialog">
        <h2>Rank Analysis</h2>
        <TabContext value={selectedTab}>
            <Tabs value={selectedTab} onChange={handleTabChange} centered scrollButtons='auto' >
                <Tab label="Frequency Data" value="frequencyData" />
                <Tab label="Raw Data" value="rawData"/>
            </Tabs>
            <TabPanel value="frequencyData">
               <RanksFrequency />
            </TabPanel>
            <TabPanel value="rawData">
                <RanksRaw />
            </TabPanel>
        </TabContext>
    </div>
    )
}

const RanksFrequency = (props) => {
    
    const [ prodNames, setProdNames ] = useState([]);
    const [ referenceProduct, setReferenceProduct ] = useState(null);
    const [ direction, setDirection ] = useState('increasingToRight')
    const [ datasetError, setDatasetError ] = useState(false);
    const [ referenceProductError, setReferenceProductError ] = useState(false);

    const { selectedDataset, setSelectedDataset, selectedDatasetData, datasetData, datasetOptionsList, getDatasetSize } = useDatasets()

    const { addOutput } = useUpdateOutput()

    const userId = useSelector(state => state.session.userId)
    const currentProjectId = useSelector(state => state.session.currentProjectId)

    const { checkAndPostError } = useErrorHandler()

    useEffect(() => {
        if(datasetData) {
            setProdNames([...new Set([datasetData.slice(1).map(row => row.slice(0, datasetData[0].length - 1))].flat().flat())].map((element, index) => ({ label: element, id: index })))
        }
    }, [ datasetData ])

    const handleSubmit = (event) => {
        
        event.preventDefault()

        const { numRows, numCols } = getDatasetSize()
        const numProds = prodNames.length
        const numRanks = numCols - 1 // last column contains the count
        let orderedProdNames = prodNames.map(pair => pair.label).filter(name => name !== referenceProduct.label)
        orderedProdNames.splice(0, 0, referenceProduct.label)
        let rawPerms
        if(direction === "increasingToLeft") {
            rawPerms = transposeArray(datasetData.slice(1).map(row => row.slice(0, numCols - 1))).flat()
        } else {
            rawPerms = transposeArray(datasetData.slice(1).map(row => row.slice(0, numCols - 1).reverse())).flat()
        }
        const perms = rawPerms.map(prodName => orderedProdNames.findIndex(name => name === prodName) + 1)
        const counts = datasetData.slice(1).map(row => Number(row[numCols - 1]))
        
        const data = {
            function_group: 'ranks',
            function_name: 'ranks',
            num_prods: numProds,
            group_size: numRanks,
            num_lines: numRows - 1, // minus 1 for column headers
            alpha: 0.05,
            perms: perms,
            counts: counts,
        }
        axios
            .post(api, data)
            .then((response) => {
                
                if(checkAndPostError(response)) {
                    return
                }

                const dPrimes = base64ToFloats(response.data.deltas)
                const dPrimeDiffs = base64ToFloats(response.data.d_prime_diffs)
                // const dPrimeVCV = base64ToFloats(response.data.d_prime_vcv)
                const pValues = base64ToFloats(response.data.p_values)
                const compVars = base64ToFloats(response.data.comp_vars)

                const sortedProds = orderedProdNames.map((name, index) => ({ Name: name, dPrime: dPrimes[index], sortIndex: index})).sort(a => -a.dPrime)

                let prodCompTableContent = [['Product 1', 'Product 2', 'd\'', 'Variance', 'p-Value']]
                    for(let prodCount1 = 0; prodCount1 < prodNames.length; prodCount1++) {
                        for(let prodCount2 = prodCount1 + 1; prodCount2 < prodNames.length; prodCount2++) {
                            const dataIndex = sortedProds[prodCount1].sortIndex * sortedProds.length + sortedProds[prodCount2].sortIndex
                            prodCompTableContent.push([sortedProds[prodCount1].Name , sortedProds[prodCount2].Name, 
                                -dPrimeDiffs[dataIndex].toFixed(2), 
                                compVars[dataIndex].toFixed(2), 
                                roundPValue(pValues[dataIndex])])
                        }
                    }

                let refProdTableContent = [['Product', 'd\'', 'Variance', 'Probability']].concat(
                        sortedProds.slice(1).map((prodObject, index) => 
                            [ prodObject.Name, 
                                -dPrimeDiffs[prodObject.sortIndex].toFixed(2), 
                                compVars[prodObject.sortIndex].toFixed(2),
                                roundPValue(pValues[prodObject.sortIndex])
                            ])
                    )
                
                const prodCompTable = {
                    TableId: nanoid(),
                    Title: "Product Comparisons",
                    OptionsList: { ColumnHeaders: true },
                    TableContent: prodCompTableContent
                }
                const refProdTable = {
                    TableId: nanoid(),
                    Title: "Comparisons to Reference Product " + referenceProduct.label,
                    OptionsList: { ColumnHeaders: true },
                    TableContent: refProdTableContent
                }
                
                const newPlotId = nanoid()
                const newOutputId = nanoid()

                const title = "Ranks: " + selectedDatasetData.Name

                let tempPlotData = dPrimes.map((d_prime, index) => ({
                        curveType: 'normal',
                        x_lb: -4,
                        x_ub: d_prime + 4.0,
                        mean: d_prime,
                        sd: 1,
                        type: 'line',
                        color: getDefaultColorByIndex(index),
                        name: orderedProdNames[index],
                        showlegend: false,
                        hoverinfo: 'none',
                        //hovertemplate: 'Delta: %{x:.2f}  Prob.: %{y:.2f}',
                    }))

                tempPlotData.concat({
                    x: dPrimes,
                    y: Array(dPrimes.length).fill(0.4),
                    mode: 'text',
                    name: 'labels',
                    text: orderedProdNames,
                    textposition: 'top',
                    type: 'scatter',
                    align: 'center'
                })
                
                let plotObject = {
                    OwnerId: userId,
                    PlotId: newPlotId,
                    Title: title,
                    Data: tempPlotData,
                    OutputReferences: [newOutputId]
                }

                const outputObject = {
                    OwnerId: userId,
                    OutputId: newOutputId,
                    Title: title,
                    Stream: [{ Type: 'table', Content: prodCompTable.TableId },
                             { Type: 'table', Content: refProdTable.TableId },
                             { Type: 'plot', Content: newPlotId }],
                    Tables: [ prodCompTable, refProdTable ],
                    Plots: [newPlotId],
                    ReferencingProjectIds: currentProjectId,
                }
                
                addOutput(outputObject, plotObject)
            })
            .catch((error) => {
                console.log('error: ')
                console.log(error)
            })
    }
    
    return (
        <div id="RanksFrequencyDialog" style={{ width: 'max-content', margin: '0 auto' }} >
            <form onSubmit={handleSubmit}>
                <AutocompleteDropdown options={datasetOptionsList}
                    value={selectedDataset} setValue={setSelectedDataset} label="Choose dataset:" variant="outlined" />
                <br />
                <RadioGroup
                        style={{ display: 'flex', alignItems: 'center' }}
                        aria-label="method"
                        name="method"
                        value={direction}
                        onChange={(event, newValue) => { setDirection(newValue)}}
                    >
                    <div id='radioGroupContainer' style={{ display: 'flex', width: 'max-content', flexFlow: 'column nowrap', alignItems: 'center' }}>
                        {/* <FormLabel style={{ color: 'black', alignSelf: 'start' }}>Direction</FormLabel> */}
                        <FormControlLabel value='increasingToRight' control={<Radio />} label = "Attribute intensity increases from left to right" />
                        <FormControlLabel value='increasingToLeft' control={<Radio />} label = "Attribute intensity increases from right to left" />
                    </div>
                </RadioGroup>
                <br />
                <AutocompleteDropdown options={prodNames} disabled={ prodNames.length < 1 }
                    value={referenceProduct} setValue={setReferenceProduct} label="Choose reference product:" variant="outlined" />
                <br />
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </form>
        </div>
    )
}

const RanksRaw = () => {

    const [ productColumn, setProductColumn ] = useState(null);
    const [ referenceProduct, setReferenceProduct ] = useState(null);
    const [ columnNamesList, setColumnNamesList ] = useState([])
    const [ datasetError, setDatasetError ] = useState(false);
    const [ productColumnError, setProductColumnError ] = useState(false);
    const [ referenceProductError, setReferenceProductError ] = useState(false);
    const [ selectedScaleColumns, setSelectedScaleColumns ] = useState()
    const [ numRatingCategories, setNumRatingCategories ] = useState()
    const [ isValidating, setIsValidating ] = useState(false)

    const { selectedDataset, setSelectedDataset, datasetData, datasetOptionsList, columnNames, getDatasetSize } = useDatasets()

    const [ selectedIndex, setSelectedIndex ] = useState(null)

    const onSubmit = (event) => {

    }

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index)
    }

    useEffect(() => {
        setColumnNamesList(columnNames.map((element, index) => ({ label: element, id: index })))
    }, [columnNames])

    return (
        <div
            id="RanksRawDialog"
            style={{ width: 'max-content', margin: '0 auto' }}
        >
            <form onSubmit={onSubmit}>
                <AutocompleteDropdown options={datasetOptionsList}
                    value={selectedDataset} setValue={setSelectedDataset} label="Choose dataset:" variant="outlined" />
                <br />
                <br />
                <AutocompleteDropdown options={columnNamesList} disabled={ columnNamesList.length < 2 }
                    value={productColumn} setValue={setProductColumn} label="Choose product column:" variant="outlined" />
                <br />
                <br />
                <AutocompleteDropdown options={datasetData ? ["A", "B", "C", "D"] : []} disabled={ !productColumn }
                    value={referenceProduct} setValue={setReferenceProduct} label="Choose reference product:" variant="outlined" />
                <br />
                <br />
                <div style={{ display: 'flex', flexFlow: 'column nowrap', width: 'max-100%', alignItems: 'start', margin: '0 auto' }}>
                    <FormLabel htmlFor="ratingColumnChooser" style={{ color: 'black', textAlign: 'left', marginBottom: '8px' }}>Choose rating columns:</FormLabel>
                    <List variant="outlined" id="ratingColumnChooser" style={{ width: '100%', border: '1px solid gray', borderRadius: '6px' }}>
                        { exampleListItems.map((listItem, index) => {
                            return(<ListItem
                                key={index}
                                button
                                selected={selectedIndex === index}
                                onClick={(event) => handleListItemClick(event, index)}
                            >
                                <ListItemText primary={listItem} />
                            </ListItem>)
                        })}
                    </List>
                </div>
                <br/>
                <NumericInput
                    setValidatedValue={setNumRatingCategories}
                    isValidating={isValidating}
                    label="# of Rating Categories"
                    minValue="0"
                    errorMessage="Must be greater than 0."
                />
                <br />
                <Button
                    style={{ padding: 3 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Run
                </Button>
            </form>
        </div>
    )

}

export default Ranks
