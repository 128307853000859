import { useCallback } from 'react'
import { nanoid } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
//import { useCallback } from 'react'
import { addPlot, removePlot, addOutputReference, removeOutputReference } from '../../features/plotsSlice'

export function usePlots() {
    
    const dispatch = useDispatch()
    const _plots = useSelector(store => store.plots)

    const _addPlot = useCallback(
        (title, data) => {
            const _id = nanoid()
            
            dispatch(addPlot(
                {
                    Title: title,
                    Data: data,
                    PlotId: _id,
                    OutputReferences: [],
                }
            ))

            return _id
        }, [dispatch])
    const _removePlot = useCallback((plotId) => {
        dispatch(removePlot(plotId))
    }, [dispatch])

    const _addOutputReferenceToPlot = useCallback((plotId, outputId) => {
        dispatch(addOutputReference({plotId, outputId}))
    }, [dispatch])

    const _removeOutputReferenceFromPlot = useCallback((plotId, outputId) => {
        dispatch(removeOutputReference({plotId, outputId}))
    }, [dispatch])

    const _selectPlotById = useCallback((plotId) => _plots.find(plot => plot.PlotId === plotId), [_plots])

    return {
        allPlots: _plots,
        addPlot: _addPlot,
        removePlot: _removePlot,
        selectPlotById: _selectPlotById,
        addOutputReferenceToPlot: _addOutputReferenceToPlot,
        removeOutputReferenceFromPlot : _removeOutputReferenceFromPlot,
    }
}
