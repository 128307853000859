import { createSlice } from '@reduxjs/toolkit'

const initialState = [
    {
        PlotId: null,
        Name: '',
        OutputReferences: null
    }
]

const plotsSlice = createSlice({
    name: 'plots',
    initialState,
    reducers: {
        addPlot(state, action) {
            return([
                ...state,
                action.payload
            ])
        },
        removeOutputReference(state, action) {
            const { plotId, outputId } = action.payload
            
            const thePlot = state.find(plot => plot.PlotId === plotId)
            if(thePlot) {
                thePlot.OutputReferences = thePlot.OutputReferences.filter(output => output !== outputId)
            }
        },
        addOutputReference(state, action) {
            const { plotId, outputId } = action.payload
            const thePlot = state.find(plot => plot.PlotId === plotId)
            if(thePlot) {
                thePlot.OutputReferences.push(outputId)
            }
        },
        removePlot(state, { payload: plotId }) {
            return state.filter(plot => plot.PlotId !== plotId)
        }
    }
})

export const { addPlot, removePlot, addOutputReference, removeOutputReference } = plotsSlice.actions

export default plotsSlice.reducer

export const selectAllPlots = state => state.plots
export const selectPlotById = (state, plotId) => state.plots.find(plot => plot.PlotId === plotId)
